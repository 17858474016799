import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { updateData } from "../../services/api/patchApi";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getData } from "../../services/api/getApiCalls";

import MuiAlert from "@mui/material/Alert";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditAppointmentForm = (props) => {
  //show  error/success message

  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const patientId = props.pDetails.patientId;
  const appointmentId = props.pDetails.appointmentId;
  const docId = props.pDetails.docId;
  const caesId = props.pDetails.caseId;

  const patientName = props.pDetails.patientName;
  const [symptom, setSymptom] = useState(props.pDetails.symptom);
  const [specialization, setSpecialization] = useState(
    props.pDetails.doctorSpeciality
  );
  const [appointmentDate, setAppointmentDate] = useState(
    new Date(props.pDetails.date)
  );
  const [doctor, setDoctor] = useState({
    firstName: props.pDetails.doctorFullName,
  });
  const [slotNo, setSlotNo] = useState({
    startTime: props.pDetails.startTime,
    endTime: props.pDetails.endTime,
    slotNo: props.pDetails.slotNo,
  });
  const [startTime, setStartTime] = useState(props.pDetails.startTime);
  const [endTime, setEndTime] = useState(props.pDetails.endTime);
  const [firstTimeFlag, setFirstTimeFlag] = useState(true);

  const cancelActionHandeler = () => {
    props.onEditAppointment("CANCEL");
  };

  const updateAppointmentHandler = (event) => {
    event.preventDefault();
    props.onEditAppointment("UPDATE");

    const updatedObj = {
      docId: doctor.docId,
      startTime: startTime,
      endTime: endTime,
      date: appointmentDate.toISOString().split("T")[0],
      appointmentId: appointmentId,
      slotNo: slotNo.slotNo,
    };

    console.log({ updatedObj });

    updateAppointment(updatedObj);
  };

  const updateAppointment = async (obj) => {
    const user = await updateData(BASE_URL + "clinic/appointment", obj, "");

    if (user.error) {
      setSeverity("error");
      setMessage("Appointment editing failed!");
      setOpenNotice(true);
    } else {
      setSeverity("success");
      setMessage("Appointment successfully updated!");
      setOpenNotice(true);
    }
  };

  const [specializationList, setSpecializationList] = useState([]);

  const fetchSpecializationHandler = useCallback(async () => {
    const user = await getData(
      BASE_URL +
        "clinic/getSpecializations/" +
        localStorage.getItem("BRANCH_ID")
    );

    if (user.error) {
    } else {
      const data1 = await user.data;
      setSpecializationList(data1);
      console.log({ setSpecializationList: data1 });
    }
  }, []);

  useEffect(() => {
    fetchSpecializationHandler();
  }, [fetchSpecializationHandler]);

  const [listOfDoctors, setListOfDoctors] = useState([]);
  const [docSlots, setDocSlots] = useState([]);

  const fetchDoctorListHandler = useCallback(async (sp, d) => {
    const user = await getData(
      BASE_URL + "clinic/searchDoctor/" + sp + "?date=" + d
    );

    if (user.error) {
      setListOfDoctors([]);
    } else {
      const data1 = await user.data;
      setListOfDoctors(data1);
    }
  }, []);

  useEffect(() => {
    fetchDoctorListHandler(
      specialization,
      appointmentDate.toISOString().split("T")[0]
    );

    if (!firstTimeFlag) {
      setDoctor("");
      setSlotNo("");
      setStartTime("");
      setEndTime("");

      setDocSlots([]);
    }
  }, [appointmentDate, specialization]);

  console.log({ specialization: specialization });
  console.log({ listOfDoctors: listOfDoctors });

  console.log({ appointmentDate });

  return (
    <Box>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <form id="updateAppointment" onSubmit={updateAppointmentHandler}>
            <Box
              sx={{
                minHeight: "47vh",
                background: "#F3F3F3",
                borderRadius: 2,
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={12} md={12}>
                  <TextField
                    disabled
                    size={"small"}
                    fullWidth
                    name="symptom"
                    label="Issue*"
                    variant="outlined"
                    value={symptom || ""}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={12}>
                  <Autocomplete
                    size={"small"}
                    fullWidth
                    freeSolo
                    value={specialization}
                    disableClearable
                    options={specializationList.filter((ele) => {
                      return ele !== null;
                    })}
                    onChange={(event, newValue) => {
                      setSpecialization(newValue);
                      setFirstTimeFlag(false);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Doctor's Specialization" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd MMM yyyy"
                      disableMaskedInput={true}
                      label="Date"
                      openTo="day"
                      views={["year", "month", "day"]}
                      value={appointmentDate}
                      onChange={(newValue) => {
                        setAppointmentDate(newValue);
                        setFirstTimeFlag(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          sx={{ fontSize: 10 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={12}>
                  <Autocomplete
                    size={"small"}
                    freeSolo
                    value={doctor}
                    disableClearable
                    options={listOfDoctors}
                    getOptionLabel={(option) =>
                      (option.firstName ? option.firstName : "") +
                      " " +
                      (option.middleName ? option.middleName : "") +
                      " " +
                      (option.lastName ? option.lastName : "")
                    }
                    onChange={(event, value) => {
                      setDoctor(value);
                      setSlotNo("");
                      setStartTime("");
                      setEndTime("");
                      value ? setDocSlots(value.slots) : setDocSlots([]);
                      setFirstTimeFlag(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...params}
                        label="Doctor's Name"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={12}>
                  <Autocomplete
                    size={"small"}
                    freeSolo
                    value={slotNo}
                    disableClearable
                    options={docSlots}
                    getOptionLabel={(option) =>
                      option ? option.startTime + " to " + option.endTime : ""
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.startTime} to {option.endTime}
                      </Box>
                    )}
                    onChange={(event, value) => {
                      setSlotNo(value);
                      setStartTime(value.startTime);
                      setEndTime(value.endTime);
                      setFirstTimeFlag(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...params}
                        label="Choose a slot"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={3} sm={12} md={12}>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    label="Start Time"
                    variant="outlined"
                    value={startTime}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={3} sm={12} md={12}>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    label="End Time"
                    variant="outlined"
                    value={endTime}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack
            direction={"row"}
            display="flex"
            justifyContent={"center"}
            spacing={2}
          >
            <Button variant="outlined" type="submit" form="updateAppointment">
              Submit
            </Button>
            <Button variant="outlined" onClick={cancelActionHandeler}>
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditAppointmentForm;
