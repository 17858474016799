import React, { useEffect, useCallback, useState } from "react";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import ImageModal from "../familyHistory/imageModal";
import { useParams } from "react-router-dom";

const Item = ({ reportTitle, image, reportId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCardClick = () => {
    // setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Card
        sx={{ textAlign: "center", m: 1, p: 0.1 }}
        onClick={handleCardClick}
      >
        <CardContent>
          <Typography variant="h6" component="div">
            {reportTitle}
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
      {modalOpen && (
        <ImageModal
          open={modalOpen}
          onClose={handleModalClose}
          images={reportTitle}
        />
      )}
    </>
  );
};

const Reports = () => {
  let [reports, setReports] = useState({});
  const { caseId } = useParams();

  const fetchData = useCallback(async () => {
    const user = await getData(`${BASE_URL}/some_Reports_endpoint/${caseId}`);
    if (user.data) {
      setReports(user.data);
    } else {
      console.error(user.error);
    }
  }, []);

  reports = [
    {
      doctor: "aman",
      patientName: "shubham",
      reports: "1 reports",
      date: "17 SEP, 2020",
    },
    {
      doctor: "arpit",
      patientName: "shubham",
      reports: "2 reports",
      date: "5 FEB 2021",
    },
    {
      doctor: "abhilash",
      patientName: "shubham",
      reports: "3 reports",
      date: "3 JAN , 2022",
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData, caseId]);

  return (
    <>
      <Grid container xs={12} lg={12} spacing={2}>
        {reports.map((e, i) => (
          <>
            <Grid item xs={1} lg={4}></Grid>
            <Grid item xs={10} lg={4} spacing={1}>
              <Item
                {...{
                  thumbnail: e.thumbnail,
                  reportTitle: e.reportTitle,
                  reportId: e.reportId,
                }}
              />
            </Grid>
            <Grid item xs={1} lg={4}></Grid>
          </>
        ))}
      </Grid>
    </>
  );
};

export default Reports;
