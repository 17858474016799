import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApiCalls";
import { CustomIOSSwitch } from "../../comps/customIOSSwitch";

const SwitchData = [
  {
    id: 1,
    title: "CBC",
    property: "cbc",
  },
  {
    id: 2,
    title: "Urine",
    property: "urine",
  },
  {
    id: 3,
    title: "Fitness",
    property: "fitness",
  },
  {
    id: 4,
    title: "Eye",
    property: "eye",
  },
  {
    id: 5,
    title: "Audiometry",
    property: "audiometry",
  },
  {
    id: 6,
    title: "PFT",
    property: "pft",
  },
  {
    id: 7,
    title: "Test Code",
    property: "testCode",
  },
  {
    id: 8,
    title: "Stool Sample",
    property: "stoolSample",
  },
  {
    id: 9,
    title: "X-Ray",
    property: "xray",
  },
  {
    id: 10,
    title: "Serum Bilirubin",
    property: "sbilirubin",
  },
];

const CreatePackage = ({
  open,
  setOpen,
  flag,
  setFlag,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const init = {
    corpId: corpId,
    packageName: "",
    cbc: false,
    urine: false,
    fitness: false,
    eye: false,
    audiometry: false,
    pft: false,
    testCode: false,
    stoolSample: false,
    xray: false,
    sbilirubin: false,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormValues(init);
  };

  const [formValues, setFormValues] = useState(init);

  console.log({ formValues });

  const _submitHandler = async () => {
    const url = BASE_URL + `org/package`;

    const packageData = await saveData(url, formValues);

    if (packageData.error) {
      console.log({ packageData_error: packageData.error });
      setSeverity("error");
      setMessage("Failed to save!");
      setOpenNotice(true);
    } else {
      console.log({ packageData_success: packageData.data });
      handleClose();
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
      setFlag(!flag);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box>
        <Button variant="contained" onClick={handleClickOpen}>
          Create new package
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>Create New Package</DialogTitle>
          <DialogContent sx={{ p: 5 }}>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item lg={12} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Package Name"
                  value={formValues.packageName || ""}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      packageName: e.target.value,
                    });
                  }}
                />
              </Grid>

              {SwitchData.map((item, index) => (
                <Grid item lg={3} xs={6} key={index}>
                  <CustomIOSSwitch
                    title={item.title}
                    property={item.property}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handleClose}
                size="small"
                sx={{ width: 140 }}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: 140 }}
                variant="contained"
                onClick={_submitHandler}
                autoFocus
                size="small"
                disabled={formValues.packageName ? false : true}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </Fragment>
  );
};

export default CreatePackage;
