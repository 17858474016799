import { Box, Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { Fragment, useRef, useState } from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";
import SignalStrengthWiFi from "../../common/signalStrength/signalStrengthWiFi";
import VideoMain from "../../videoModule/videoMain";

const CallSection = (props) => {
  const [wifiSpeed, setwifiSpeed] = useState();
  const callRef = useRef();
  const joined = () => {
    console.log("joined by doc");
  };

  const joined1 = () => {
    console.log("joined by doc");
  };

  if (callRef.current) {
    callRef.current.addEventListener("ready", joined1);
    callRef.current.addEventListener("knock", joined1);
    callRef.current.addEventListener("participantupdate", joined1);
    callRef.current.addEventListener("join", joined1);
    callRef.current.addEventListener("leave", joined1);
    callRef.current.addEventListener("participant_join", joined);
    callRef.current.addEventListener("participant_leave", joined1);
    callRef.current.addEventListener("microphone_toggle", joined1);
    callRef.current.addEventListener("camera_toggle", joined1);
  }

  const [toggle, setToggle] = useState(false);

  return (
    <Fragment>
      <Box>
        <Box sx={{ position: "absolute", zIndex: 999 }}>
          {toggle ? (
            <Button onClick={() => setToggle(false)} sx={{ color: "#fff" }}>
              100ms
            </Button>
          ) : (
            <Button onClick={() => setToggle(true)} sx={{ color: "#000" }}>
              Whereby
            </Button>
          )}
        </Box>
        <Grid container columns={36}>
          <Grid item lg>
            <Box
              sx={{ minHeight: "50vh", background: "#9AE7EB", pt: 0, pb: 0 }}
            >
              {toggle ? (
                <whereby-embed
                  ref={callRef}
                  style={{ height: "79vh" }}
                  minimal
                  room={props.patient.videoURL}
                ></whereby-embed>
              ) : (
                <VideoMain
                  openStetho={props?.openStetho}
                  name={props.patient.patientName}
                  token={props.patient.hostURLToken}
                />
              )}

              <Box sx={{ position: "absolute", bottom: 10, left: "2vw" }}>
                <ReactInternetSpeedMeter
                  txtSubHeading="Internet is too slow"
                  outputType="empty"
                  customClassName={null}
                  txtMainHeading="Opps..."
                  pingInterval={3000} // milliseconds
                  thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                  threshold={8}
                  imageUrl="https://storage-echikitsalaya.s3.ap-south-1.amazonaws.com/test/test+doctor/young-doctor-16088825.jpg"
                  downloadSize="48198" //bytes
                  callbackFunctionOnNetworkDown={(speed) =>
                    console.log(`Internet speed is down ${speed}`)
                  }
                  callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
                />
                <SignalStrengthWiFi wifiSpeed={wifiSpeed} />
              </Box>
            </Box>
          </Grid>
          {props?.openStetho ? <Grid item lg={16}></Grid> : ""}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CallSection;
