import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const _diseaseGroup = [
  {
    id: 0,
    title: "सर्दी/खांसी/बुखार ",
    value: "cold/cough/fever",
    property: "fever",
  },
  {
    id: 5,
    title: "नाक कान गला",
    value: "ent",
    property: "ent",
  },
  {
    id: 7,
    title: "शुगर/BP",
    value: "sugar/bp",
    property: "sugar",
  },
  {
    id: 8,
    title: "थायरॉइड",
    value: "thioride",
    property: "thioride",
  },
  {
    id: 9,
    title: "हार्ट-किडनी",
    value: "heart/kideny",
    property: "heart",
  },
  {
    id: 11,
    title: "दमा/लकवा",
    value: "paralysis",
    property: "paralysis",
  },
  {
    id: 12,
    title: "अस्थमा",
    value: "asthma",
    property: "asthma",
  },
  {
    id: 13,
    title: "हर्निया/पाइल्स/मोल/कॉर्न",
    value: "hernia/piles/mole/corn",
    property: "hernia",
  },
  // {
  //   id: 1,
  //   title: "स्त्री रोग",
  //   value: "female problems",
  //   property: "femaleproblem",
  // },
  // {
  //   id: 2,
  //   title: "चर्मरोग",
  //   value: "dermatological problems",
  //   property: "skin",
  // },
  // { id: 3, title: "शिशु रोग", value: "children's problems", property: "child" },
  // {
  //   id: 4,
  //   title: "आँखों की बीमारी",
  //   value: "eye problems",
  //   property: "eye",
  // },

  // {
  //   id: 6,
  //   title: "हड्डी रोग",
  //   value: "bone problems",
  //   property: "bone",
  // },

  // {
  //   id: 10,
  //   title: "अन्य",
  //   value: "other",
  //   property: "other",
  // },
];
const RespondentDisease = ({
  formValues,
  setFormValues,
  diseases,
  setDiseases,
}) => {
  const [diseaseTextStatic, setTiseaseTextStatic] = useState("");
  useEffect(() => {
    const selectedDiseases = Object.entries(diseases)
      .filter((it) => it[1])
      .map((item, index) => item[0]);
    setFormValues({ ...formValues, diseases: selectedDiseases?.toString() });

    setTiseaseTextStatic(selectedDiseases?.toString());
  }, [diseases]);

  const [selectedOther, setSelectedOther] = useState(false);
  const [textOther, setTextOther] = useState("");

  useEffect(() => {
    const getData = setTimeout(() => {
      setFormValues({
        ...formValues,
        diseases: textOther
          ? `${diseaseTextStatic}${diseaseTextStatic ? "," : ""}${textOther}`
          : `${diseaseTextStatic}${diseaseTextStatic ? "," : ""}others`,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [textOther, selectedOther]);

  console.log({ selectedOther, formValues });

  return (
    <Box>
      <Grid container>
        <Grid item xs={2} lg={4}>
          {/* <Typography>Disease:</Typography> */}
        </Grid>
        <Grid
          item
          xs={10}
          lg={8}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <Stack>
            <FormControl>
              <FormGroup row>
                {_diseaseGroup.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={diseases[item?.value] || false}
                        onChange={(e) => {
                          setDiseases({
                            ...diseases,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                        name={item.value}
                      />
                    }
                    label={item.title}
                  />
                ))}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOther || false}
                      onChange={(e) => {
                        setSelectedOther(e.target.checked);
                        if (!e.target.checked) {
                          setTextOther("");
                        }
                      }}
                      name={"other"}
                    />
                  }
                  label={"अन्य"}
                />
              </FormGroup>
            </FormControl>

            {selectedOther && (
              <TextField
                required
                size="small"
                fullWidth
                placeholder="Others..."
                value={textOther}
                onChange={(e) => {
                  setTextOther(e.target.value);
                }}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RespondentDisease;
