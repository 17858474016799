import { Button, Stack } from "@mui/material";
import { Fragment } from "react";

const ActionWithFreeTokenHC = ({
    saveAction,
    cancelAction,
    handleClickOpen,
    freeTokenHandler,
    isVitals,
    isFreeToken,
}) => {
    return (
        <Fragment>
            <Stack direction="row" spacing={3} sx={{ width: "100%" }}>
                {isVitals ? (
                    <Fragment>
                        <Button variant="outlined" fullWidth onClick={cancelAction}>
                            Clear
                        </Button>

                        <Button variant="contained" fullWidth onClick={saveAction}>
                            Save
                        </Button>
                    </Fragment>
                ) : (
                    ""
                )}
                {isFreeToken ? (
                    <Fragment>
                        <Button variant="contained" fullWidth onClick={freeTokenHandler}>
                            Free Token
                        </Button>
                        <Button variant="contained" fullWidth onClick={handleClickOpen}>
                            Print
                        </Button>
                    </Fragment>
                ) : (
                    ""
                )}
            </Stack>
        </Fragment>
    );
};

export default ActionWithFreeTokenHC;
