import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import CreatePackage from "./components/createPackage";
import PackageAutocomplete from "./components/packageAutocomplete";
import DisplayPackage from "./components/displayPackage";

const PackageMainComp = () => {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor: "#F5F5F5", minHeight: "90vh" }}
    >
      <Box sx={{ pt: 1 }}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}></Grid>
          <Grid
            item
            lg={6}
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CreatePackage
              open={open}
              setOpen={setOpen}
              setFlag={setFlag}
              flag={flag}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <PackageAutocomplete
              setSelectedPackage={setSelectedPackage}
              flag={flag}
            />
          </Grid>
          {selectedPackage && (
            <Grid item lg={12} xs={12}>
              <DisplayPackage selectedPackage={selectedPackage} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default PackageMainComp;
