import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const PhoneNumberElement = ({ element, variant }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <Fragment>
      <TextField
        sx={{ p: 0.3 }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={variant === "small" ? "Ph Number..." : ""}
        value={element.mobile || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length < 11) {
            element["mobile"] = e.target.value;
            setPhoneNumber(e.target.value);
          }
        }}
      />
    </Fragment>
  );
};

export default PhoneNumberElement;
