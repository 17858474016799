import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback, useEffect, useState } from "react";
import { getData } from "../services/api/getApiCalls";
import {
  BASE_URL,
  PRIMARY_CUSTOM,
  WHITE_CUSTOM,
} from "../assets/constantsFile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import useHttp from "../common/hooks/labHooks";
import PatientListLab from "./patientListLab";
import SearchIcon from "@mui/icons-material/Search";
import PageviewIcon from "@mui/icons-material/Pageview";
import OnGoingPatientListLab from "./onGoingPatientListLab";
import SearchPatientListLab from "./searchPatientListLab";
import PrintPatientListLab from "./printPatientListLab";

const TableContentLab = (props) => {
  const [value, setValue] = useState("1");
  const [dateValue, setDateValue] = useState(new Date());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [flag, setFlag] = useState(false);
  const [patients, setPatients] = useState([]);
  const [searchPatient, setSearchPatient] = useState("");
  const [isSearchPatient, setIsSearchPatient] = useState(false);

  const tabClick = (event, newValue) => {
    setFlag(!flag);
  };

  const transformTasks = (data) => {
    const transformedPatients = data.map((patientData, index) => {
      return {
        index: index,
        patientId: patientData.patientId,
        patientName: patientData.patientName,
        age: patientData.age,
        gender: patientData.gender,
        mobileNo: patientData.mobileNo,
        prescribedBy: patientData.prescribedBy,

        appointmentId: patientData.appointmentId,
        reports: {
          prescribedBy: patientData.prescribedBy,
          patientName: patientData.patientName,
          appointmentId: patientData.appointmentId,
          patientId: patientData.patientId,
          reports: patientData.reports,
          directBooking: patientData.directBooking,
          totalAvailedNumber: patientData.totalAvailedNumber,
          totalPrintedNumber: patientData.printedNumber,
          paymentVM: patientData.paymentVM,
        },
        appointmentTime: new Date(patientData.startTime)
          .toISOString()
          .split("T")[0],
        appointmentDelay: index,
        collectionTime:
          patientData.reports && patientData.reports.length > 0
            ? new Date(patientData.reports[0].sampleCollectionDate)
                .toISOString()
                .split("T")[0]
            : "",
        pendingUpload: {
          totalAvailedNumber: patientData.totalAvailedNumber,
          totalUploadedNumber: patientData.reportUploadedNumber,
        },
        printedReports: {
          totalAvailedNumber: patientData.totalAvailedNumber,
          totalPrintedNumber: patientData.printedNumber,
        },
        paymentVM: {
          paymentVM: patientData.paymentVM,
          appointmentId: patientData.appointmentId,
          patientId: patientData.patientId,
          directBooking: patientData.directBooking,
        },

        collectedSamples: {
          sampleCollectedNumber: patientData.sampleCollectedNumber,
          totalAvailedNumber: patientData.totalAvailedNumber,
        },
        attended: patientData.attended,
        paymentStatus: patientData.paymentStatus,
        totalAvailedNumber: patientData.totalAvailedNumber,
        printedNumber: patientData.printedNumber,
        sampleCollectedNumber: patientData.sampleCollectedNumber,

        directBooking: patientData.directBooking,
      };
    });
    setPatients(transformedPatients);
  };

  const { fetchPataientsHandler: fetchData } = useHttp(
    {
      url:
        BASE_URL +
        "lab/basic/sampleNotCollected/" +
        localStorage.getItem("LAB_ID"),
    },
    transformTasks
  );

  const { fetchPataientsHandler: fetchData1 } = useHttp(
    {
      url:
        BASE_URL +
        "lab/basic/reportNotUploaded/" +
        localStorage.getItem("LAB_ID"),
    },
    transformTasks
  );

  const { fetchPataientsHandler: fetchData2 } = useHttp(
    {
      url:
        BASE_URL +
        "lab/basic/reportToBePrinted/" +
        localStorage.getItem("LAB_ID"),
    },
    transformTasks
  );

  // console.log({ fetchData });

  useEffect(() => {
    // fetchPataientsHandler(dateValue.toISOString().split("T")[0]);
    if (value === "1") {
      setIsSearchPatient(false);
      fetchData();
    } else if (value === "2") {
      fetchData1();
      setIsSearchPatient(false);
    } else if (value === "4") {
      fetchData2();
      setIsSearchPatient(false);
    }
  }, [dateValue, flag]);

  const updateHandler = (data) => {
    if (data === "BACK") {
      setFlag(!flag);
    }
  };

  const getPatientData = async (e) => {
    e.preventDefault();
    if (searchPatient) {
      const url = isNaN(searchPatient)
        ? BASE_URL + "lab/search?name=" + searchPatient
        : BASE_URL + "lab/search?patientId=" + searchPatient;
      const user = await getData(url);
      if (user.error) {
        setIsSearchPatient(false);
      } else {
        const data1 = await user.data;
        if (data1 && data1.length > 0) {
          setIsSearchPatient(true);
          transformTasks(data1);
          console.log({ data1 });
          setValue("3");
        }
      }
    }
  };
  console.log(patients);
  return (
    <Grid container spacing={0}>
      <Grid item lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                minHeight: "8vh",
                width: "100%",
              }}
            >
              <Grid container spacing={0}>
                <Grid xs={12} item lg={9}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      onClick={tabClick}
                      label="Sample Collection"
                      value="1"
                      sx={{
                        width: 200,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                    <Tab
                      onClick={tabClick}
                      label="Report Upload"
                      value="2"
                      sx={{
                        width: 240,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                        ml: 2,
                      }}
                    />
                    <Tab
                      onClick={tabClick}
                      label="Print Report"
                      value="4"
                      sx={{
                        width: 240,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                        ml: 2,
                      }}
                    />

                    <Tab
                      onClick={tabClick}
                      label="Search"
                      value="3"
                      sx={{
                        display: isSearchPatient ? "flex" : "none",
                        width: 200,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                        ml: 2,
                      }}
                    />
                  </TabList>
                </Grid>
                <Grid xs={12} item lg={3}>
                  <Stack
                    direction="row"
                    spacing={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {value === "7" ? (
                      <Paper
                        sx={{ width: 180 }}
                        elevation={0}
                        component={Stack}
                        justifyContent="flex-end"
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label=""
                            openTo="day"
                            views={["year", "month", "day"]}
                            disableMaskedInput={true}
                            inputFormat="dd MMM yyyy"
                            value={dateValue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </LocalizationProvider>
                      </Paper>
                    ) : (
                      ""
                    )}
                    <form onSubmit={getPatientData}>
                      <Stack direction={"row"}>
                        <TextField
                          sx={{ width: 300 }}
                          size="small"
                          fullWidth
                          label="Search patient Id/Name..."
                          id="fullWidth1"
                          value={searchPatient}
                          onChange={(e) => setSearchPatient(e.target.value)}
                        />
                        <IconButton aria-label="delete" type="submit">
                          <SearchIcon />
                        </IconButton>
                      </Stack>
                    </form>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: "100%",
                background: "#fff",
                height: "75vh",
                borderRadius: 2,
              }}
            >
              <TabPanel value="1" sx={{ pt: 2, pl: 0, pr: 0, pb: 0 }}>
                <PatientListLab
                  patients={patients}
                  root={props.root}
                  onUpdateFields={updateHandler}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ pt: 2, pl: 0, pr: 0, pb: 0 }}>
                <OnGoingPatientListLab
                  patients={patients}
                  root={props.root}
                  onUpdateFields={updateHandler}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ pt: 2, pl: 0, pr: 0, pb: 0 }}>
                <SearchPatientListLab
                  patients={patients}
                  root={props.root}
                  onUpdateFields={updateHandler}
                />
              </TabPanel>
              <TabPanel value="4" sx={{ pt: 2, pl: 0, pr: 0, pb: 0 }}>
                <PrintPatientListLab
                  patients={patients}
                  root={props.root}
                  onUpdateFields={updateHandler}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableContentLab;
