import { Grid, TextField, Typography } from "@mui/material";
import React, { Fragment } from "react";

const EmailField = ({ userName, setUserName }) => {
  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginBlock: "10px" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#6B6B6B",
          }}>
          Email
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          required
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          autoComplete="userName"
          autoFocus
          InputProps={{
            style: { color: "#127DDD" },
          }}
          InputLabelProps={{
            style: { fontSize: ".875rem", color: "#127DDD" },
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default EmailField;
