import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import MedicalHistoryForm from "../uploadMedicalHistory/medicalHistoryForm";
import CloseIcon from "@mui/icons-material/Close";

const UploadMedicalHistory = (props) => {
  useEffect(() => {
    if (props.pDetails.patientInfoVM) {
      const patientInfoVM = props.pDetails.patientInfoVM;
      console.log({ useEffectPatientInfo: props });
      setFormValues({
        pPreExcond: patientInfoVM
          ? patientInfoVM.preExistingConditions
          : "Not Available",
        pDrugHistory: patientInfoVM
          ? patientInfoVM.drugHistory
          : "Not Available1",
        pDrugAllergy: patientInfoVM
          ? patientInfoVM.drugAllergy
          : "Not Available",
        pHabit: patientInfoVM ? patientInfoVM.habits : "Not Available",
        pOccupation: patientInfoVM ? patientInfoVM.occupation : "Not Available",
        pParentalHistory: patientInfoVM
          ? patientInfoVM.parentalHistory
          : "Not Available",
        menstualHistory: patientInfoVM
          ? patientInfoVM.menstualHistory
          : "Not Available",
        obstraticHistory: patientInfoVM
          ? patientInfoVM.obstraticHistory
          : "Not Available",
        maritalHistory: patientInfoVM
          ? patientInfoVM.maritalHistory
          : "Not Available",
        surgicalHistory: patientInfoVM
          ? patientInfoVM.surgicalHistory
          : "Not Available",
        personalHistory: patientInfoVM
          ? patientInfoVM.personalHistory
          : "Not Available",
      });
    }
  }, [props.pDetails]);

  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);

  const [formValues, setFormValues] = useState({ test: "" });

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<UploadIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>

      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="Medical History"
        aria-describedby="Medical History form"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" display="flex" justifyContent="space-between">
            <Fragment>
              {props.pDetails.patientName}
              {"'s Medical History"}
            </Fragment>

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <MedicalHistoryForm
            patientId={props.pDetails.patientId}
            formValues={formValues}
            setFormValues={setFormValues}
            handleClose={handleClose}
            pDetails={props.pDetails}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default UploadMedicalHistory;
