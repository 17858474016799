import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AgeElement from "./formComps/ageElement";
import DOBElement from "./formComps/dobElement";
import NameElement from "./formComps/nameElement";
import PhoneNumberElement from "./formComps/phoneNumberElement";
import RelationElement from "./formComps/relationElement";
import ActionHC from "../common/action/actionHC";
import SelectTokenElement from "../common/formElements/selectTokenElement";
import { BASE_URL } from "../../assets/constantsFile";
import plusIcon from "../../assets/images/svg/plus.svg";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import { updateDataNew } from "../../services/api/patchApi";
import GenderElement from "./formComps/genderElement";
import HeaderHC from "../common/components/headersHC";

const AddFamily = ({ searchToken, setSearchToken, searchTokenHandler, isPatient, setisPatient, patient, title }) => {


     console.log({patient: patient})
    const [noticeData, setNoticeData] = useState({
        severity: "info",
        message: "",
        openNotice: false,
    });
    const [formValues, setFormValues] = useState([{ dob: null }]);
    const [key, setKey] = useState("");

    const [age, setAge] = useState("");
    const [dob, setDOB] = useState(null);

    useEffect(() => {
        setFormValues(patient && patient.familyMembers ? patient.familyMembers : []);
    }, [patient]);

    let addFormFields = () => {
        setFormValues([...formValues, { dob: null }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const clearData = () => {
        setisPatient(false);
        setFormValues([]);
    };

    const registerData = () => {
        const newFormValues = validate(formValues);
        console.log({ formValues: formValues });
        console.log({ newFormValues: newFormValues });

        const obj = {
            name: patient.name,
            familyId: patient.familyId,
            token: searchToken,
            familyMembers: newFormValues,
        };
        console.log({ obj: obj });
        saveRegistrationData(obj);
    };

    const saveRegistrationData = async (data) => {
        const url = BASE_URL + "healthcamp/update";
        const token = await updateDataNew(url, data);
        if (token.error) {
            console.log("error");
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "error",
                message: "Failed.",
                openNotice: true,
            });
        } else {
            console.log({ success: token.data });
            clearData();
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "success",
                message: "Assigned Successfully.",
                openNotice: true,
            });
        }
    };

    const validate = (data) => {
        return data.filter((item, index) => item.name);
    };
    const Cards = formValues.map((element, index) => (
        <Grid
            container
            key={index}
            rowSpacing={2}
            columnSpacing={0.9}
            sx={{
                border: "1px solid #A6A6A6",
                borderRadius: "15px",
                my: 2,
                p: 1.5,
            }}>
            <Grid item lg={2} xs={2} display="flex" justifyContent="center" alignItems="center">
                <Box
                    sx={{
                        border: "1px solid #777777",
                        borderRadius: "15px",
                        padding: "11px 16px",
                        fontSize: "14px",
                    }}>
                    {index + 1}{" "}
                </Box>
            </Grid>
            <Grid item lg={8} xs={8}>
                <NameElement element={element} variant="small" index={index} />
            </Grid>
            <Grid item lg={2} xs={2} display="flex" justifyContent={"flex-end"} sx={{ pt: "0px !important", mb: -2 }}>
                <IconButton onClick={() => removeFormFields(index)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>

            <Grid item lg={4.5} xs={4.5}>
                <RelationElement element={element} variant="small" />
            </Grid>
            <Grid item lg={7.5} xs={7.5}>
                <PhoneNumberElement element={element} variant="small" />
            </Grid>
            <Grid item lg={6} xs={6}>
                <GenderElement element={element} variant="small" />
            </Grid>
            <Grid item lg={6} xs={6}>
                <DOBElement element={element} setAge={setAge} dob={dob} setDOB={setDOB} variant="small" />
            </Grid>
        </Grid>
    ));

    return (
        <Fragment>
            <ShowNoticeMessage noticeData={noticeData} key={key} />
            <Grid container display="flex" justifyContent={"center"} sx={{ p: 2 }}>
                <Grid container item display={"flex"} flexDirection={"column"} spacing={2} sm={7} md={6} lg={4}>
                    <HeaderHC />
                    <Grid item textAlign={"center"}>
                        <Typography variant="h6" sx={{ pt: 2 }}>
                            {title}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <form onSubmit={searchTokenHandler}>
                            <SelectTokenElement
                                token={searchToken}
                                setToken={setSearchToken}
                                searchTokenHandler={searchTokenHandler}
                            />
                        </form>
                    </Grid>

                    {isPatient ? (
                        <Fragment>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={() => addFormFields()}
                                    startIcon={<Box component="img" src={plusIcon}></Box>}>
                                    Add New
                                </Button>
                            </Grid>
                            <Grid item>{Cards}</Grid>

                            <Grid item>
                                <ActionHC
                                    buttonText1={"Save"}
                                    buttonText2={"Clear"}
                                    saveAction={registerData}
                                    cancelAction={clearData}
                                />
                            </Grid>
                        </Fragment>
                    ) : (
                        <Grid item textAlign={"center"}>
                            <Box sx={{ mt: 5 }}>
                                <Typography>No Token found</Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default AddFamily;
