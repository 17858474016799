import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { Fragment } from "react";
import { patientHistoryContext } from "../../common/context/usercontext";
import SearchPatientByIdComp from "./searchPatientByIdComp";
import SearchPatientByNameComp from "./searchPatientByNameComp";
import SearchPatientByPhoneComp from "./searchPatientByPhoneComp";
import SearchResults from "./searchResults";

const SearchPatientMainComp = (props) => {
  const patientHistoryContextData = useContext(patientHistoryContext);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const rowSelectHandler = (rData) => {
    if (rData && rData.length > 0) {
      setPatientId(rData[0]);
      patientHistoryContextData.patientId = rData[0];
      setIsRowSelected(true);
    } else {
      setIsRowSelected(false);
      setPatientId(null);
    }

    console.log({ rData });
  };

  const searchdataHandler = (data) => {
    patientHistoryContextData.patientList = data;
    patientHistoryContextData.caseList = [];
    setSearchResult(
      <SearchResults pData={data} onRowSelect={rowSelectHandler} />
    );
    console.log({ data });
  };

  const viewPatientHandler = () => {
    props.onViewSearch(patientId, "VIEWCASELIST");
  };

  const [serachResult, setSearchResult] = useState(
    <SearchResults
      pData={patientHistoryContextData.patientList}
      onRowSelect={rowSelectHandler}
    />
  );
  return (
    <Fragment>
      <Box sx={{ minHeight: "80vh" }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "8vh", background: "fff" }}>
              <Stack direction={"row"} spacing={2}>
                <SearchPatientByNameComp onSearchPatient={searchdataHandler} />
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "72vh", background: "red" }}>
              {serachResult}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "5vh", background: "pink" }}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ background: "#D9D9D9" }}>
                    <Stack direction={"row"}>
                      <Button
                        disabled={!isRowSelected}
                        variant="contained"
                        fullWidth
                        onClick={viewPatientHandler}
                      >
                        View
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default SearchPatientMainComp;
