import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import React from "react"
import { Fragment } from "react"

const PatientDetailCard = ({ item }) => {
  const formatDob = dob => {
    const options = { day: "numeric", month: "numeric", year: "numeric" }
    const date = new Date(dob)
    return date.toLocaleDateString("en-GB", options)
  }

  return (
    <Fragment>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          padding: "10px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid rgba(0, 0, 0, 0.15)",
          borderRadius: "7.5px",
        }}>
        <Grid item xs={4} lg={4} sx={{ marginBottom: "10px" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            {/* Name */}
            नाम
          </Typography>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.fullName}
          </Typography>
        </Grid>

        <Grid item xs={4} lg={4} sx={{ marginBottom: "10px" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            {/* D.O.B. */}
            जन्म की तारीख
          </Typography>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {formatDob(item.dob)}
          </Typography>
        </Grid>
        <Grid item xs={4} lg={4} sx={{ marginBottom: "10px" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            {/* Mobile No. */}
            मोबाइल नंबर
          </Typography>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.mobile}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ marginBlock: "10px" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            {/* Address */}
            पता
          </Typography>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.address}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default PatientDetailCard
