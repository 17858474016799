import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Chip, Grid, Stack, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import ClinicAppointmentSucess from "../bookAppointment/clinicAppointmentSucess";
import ConsultationregistrationForm from "../bookAppointment/consultationRegistrationForm";
import ResumeConsltMainComp from "../bookAppointment/resumeConsultation/resumeConsltMainComp";
import BookLabTestSuccess from "../bookLabTesting/bookLabTestSuccess";
import LabRegistrationForm from "../bookLabTesting/labRegistrationForm";
import BookPharmacySuccess from "../bookPharmacy/bookPharmacySuccess";
import PharmacyRegistrationForm from "../bookPharmacy/pharmacyRegistrationForm";
import PaymentComp from "../paymentComp/paymentComp";

const BookingComp = (props) => {
  console.log({ "pid from booking page": props.patientId });

  const [value, setValue] = useState(props.tabValue ? props.tabValue : "1");

  useEffect(() => {
    if (
      localStorage.getItem("isAdminUser_local") ||
      localStorage.getItem("isClinicUser_local")
    ) {
      setValue(props.tabValue ? props.tabValue : "1");
    }

    if (localStorage.getItem("isLabUser_local")) {
      setValue("3");
    }
    if (localStorage.getItem("isPharmacyUser_local")) {
      setValue("4");
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const bookConstHandler = (d, doctor) => {
    if (d.error) {
    } else {
      setConsltRegContent(
        <PaymentComp
          data={d.data}
          patientId={props.patientId}
          doctor={doctor}
          source="CLINIC"
        />
      );
    }
    console.log({ viewPatientHistoryHandler_data: d.data });
  };

  const labHandler = (d) => {
    if (d.error) {
      console.log("error");
    } else {
      setBookLab(
        <PaymentComp data={d.data} patientId={props.patientId} source="LAB" />
      );
    }
  };

  const pharmacyHandler = (d) => {
    if (d.error) {
      console.log("error");
    } else {
      setBookPrescription(
        <PaymentComp
          data={d.data}
          patientId={props.patientId}
          source="PHARMACY"
        />
      );
    }
  };

  const [consltRegContent, setConsltRegContent] = useState(
    <ConsultationregistrationForm
      onBookConsult={bookConstHandler}
      patientId={props.patientId}
    />
  );

  const [bookLab, setBookLab] = useState(
    <LabRegistrationForm onBookLab={labHandler} patientId={props.patientId} />
  );

  const [bookPrescription, setBookPrescription] = useState(
    <PharmacyRegistrationForm
      onBookPharmacy={pharmacyHandler}
      patientId={props.patientId}
    />
  );

  return (
    <Grid container>
      <Grid item lg={12} xs={12}>
        <Box sx={{ height: "75vh", p: 2, background: "#fff" }}>
          <TabContext value={value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", borderRadius: 4 }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
                textColor="primary"
                indicatorColor="primary"
              >
                {localStorage.getItem("isAdminUser_local") ||
                localStorage.getItem("isClinicUser_local") ? (
                  <Tab
                    label={"New Consultation"}
                    value="1"
                    sx={{
                      width: 200,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "#208F94",
                    }}
                  />
                ) : (
                  ""
                )}

                {localStorage.getItem("isAdminUser_local") ||
                localStorage.getItem("isClinicUser_local") ? (
                  <Tab
                    label={"Resume Consultation"}
                    value="2"
                    sx={{
                      width: 240,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "#208F94",
                    }}
                  />
                ) : (
                  ""
                )}

                {localStorage.getItem("isAdminUser_local") ||
                localStorage.getItem("isLabUser_local") ? (
                  <Tab
                    label={"Lab Test"}
                    value="3"
                    sx={{
                      width: 200,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "#208F94",
                    }}
                  />
                ) : (
                  ""
                )}

                {localStorage.getItem("isAdminUser_local") ||
                localStorage.getItem("isPharmacyUser_local") ? (
                  <Tab
                    label={"Pharmacy"}
                    value="4"
                    sx={{
                      width: 200,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "#208F94",
                    }}
                  />
                ) : (
                  ""
                )}
              </TabList>
            </Box>

            {localStorage.getItem("isAdminUser_local") ||
            localStorage.getItem("isClinicUser_local") ? (
              <TabPanel value="1">{consltRegContent}</TabPanel>
            ) : (
              ""
            )}

            {localStorage.getItem("isAdminUser_local") ||
            localStorage.getItem("isClinicUser_local") ? (
              <TabPanel value="2">
                <ResumeConsltMainComp patientId={props.patientId} />
              </TabPanel>
            ) : (
              ""
            )}

            {localStorage.getItem("isAdminUser_local") ||
            localStorage.getItem("isLabUser_local") ? (
              <TabPanel value="3">{bookLab}</TabPanel>
            ) : (
              ""
            )}

            {localStorage.getItem("isAdminUser_local") ||
            localStorage.getItem("isPharmacyUser_local") ? (
              <TabPanel value="4">{bookPrescription}</TabPanel>
            ) : (
              ""
            )}
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BookingComp;
