import { Fragment, useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";

import BPElement from "../bp/formComps/bpElement";
import ActionWithFreeTokenHC from "../common/action/actionWithFreeTokenHC";
import EyeSightElement from "../eyeSight/formComps/eyeSightElement";
import FreeTokenHC from "../common/freeToken/freeTokenHC";
import HeightElement from "../height/formComps/heightElement";
import WeightElement from "../weight/formComps/weightElement";
import SelectTokenElement from "../common/formElements/selectTokenElement";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import SugarElement from "./formComps/sugarElement";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApiCalls";
import { updateDataNew } from "../../services/api/patchApi";
import DownArrow from "../../assets/images/svg/downArrow.svg";
import HeaderHC from "../common/components/headersHC";

import HCTheme from "../HCTheme";

const AddVitalsHC = ({
    title,
    isHeight,
    isWeight,
    isEyeSight,
    isBP,
    isSugar,
    token,
    isPatient,
    setisPatient,
    patient,
    openConfiguration,
    searchToken,
    setSearchToken,
    searchTokenHandler,
}) => {
    let navigate = useNavigate();
    let location = useLocation();
    const isVitals = location.pathname === "/vitalsHC";
    const isFreeToken = location.pathname === "/freeTokenHC";

    const [open, setOpen] = useState(false);
    // const [FreeToken, setFreeToken] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [formValues, setFormValues] = useState([{ name: "" }]);

    useEffect(() => {
        setFormValues(patient && patient.familyMembers ? patient.familyMembers : []);
    }, [patient]);

    const clearData = () => {
        setisPatient(false);
        setFormValues([{ name: "" }]);
    };

    const registerData = () => {
        console.log({ formValues: formValues });
        const formValuesTransformed = formValues.map((element, index) => ({
            patientId: element.patientId,
            weight: isWeight ? element.weight : null,
            height: isHeight ? element.height : null,
            eyesight: isEyeSight ? element.eyesight : null,
            bp: isBP ? element.bp : null,
            sugar: isSugar ? element.sugar : null,
        }));
        console.log({
            formValuesTransformed: formValuesTransformed,
        });
        console.log({ token });
        const payload = {
            name: patient.name,
            token: token,
            familyId: patient.familyId,
            familyMembers: formValuesTransformed,
        };
        saveRegistrationData(payload);
    };

    const saveRegistrationData = async (data) => {
        const url = BASE_URL + "healthcamp/update";
        const result = await updateDataNew(url, data);
        if (result.error) {
            console.log("error");
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "error",
                message: "Failed.",
                openNotice: true,
            });
        } else {
            console.log({ success: result.data });
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "success",
                message: "Assigned Successfully.",
                openNotice: true,
            });
        }
    };
    const freeTokenHandler = async () => {
        const url = BASE_URL + "healthcamp/freeToken/" + token;

        const result = await saveData(url, "");

        if (result.error) {
            console.error(result.error);
        } else {
            console.log("success");
            // handleClose();
            clearData();
            // setFreeToken(false);
        }
    };

    const [key, setKey] = useState("");
    const [noticeData, setNoticeData] = useState({
        severity: "info",
        message: "",
        openNotice: false,
    });

    const cards = formValues.map((element, index) => (
        <Fragment key={index}>
            <Grid
                container
                item
                sx={{
                    border: "0.5px solid #A6A6A6",
                    borderRadius: "15px",
                    p: 2,
                    my: 1,
                }}
                spacing={1}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Typography p={0.4}>S.No</Typography>
                    <Box
                        sx={{
                            border: "1px solid #777777",
                            borderRadius: "15px",
                            padding: "12px 20px",
                            fontSize: "14px",
                        }}>
                        {index + 1}
                    </Box>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography p={0.4} px={0.9}>
                        Name
                    </Typography>
                    <Box
                        sx={{
                            border: "1px solid #777777",
                            borderRadius: "15px",
                            padding: "12px 20px",
                            fontSize: "14px",
                        }}>
                        {element.name}
                    </Box>
                </Grid>
                {isHeight && (
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Fragment>
                            <Typography p={0.4}>Height(cm)</Typography>
                            <HeightElement element={element} />
                        </Fragment>
                    </Grid>
                )}

                {isWeight && (
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Fragment>
                            <Typography p={0.4}>Weight(Kgs)</Typography>
                            <WeightElement element={element} />
                        </Fragment>
                    </Grid>
                )}
                {isEyeSight && (
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Fragment>
                            <Typography p={0.4}>Eye-Sight</Typography>
                            <EyeSightElement element={element} />
                        </Fragment>
                    </Grid>
                )}
                {isBP && (
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Fragment>
                            <Typography p={0.4}>BP</Typography>
                            <BPElement element={element} />
                        </Fragment>
                    </Grid>
                )}

                <Grid item lg={4} md={4} sm={4} xs={4}>
                    {isSugar && (
                        <Fragment>
                            <Typography p={0.4}>RBS</Typography>
                            <SugarElement element={element} />
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    ));

    console.log({ patientName: patient });

    return (
        <ThemeProvider theme={HCTheme}>
            <Fragment>
                <ShowNoticeMessage noticeData={noticeData} key={key} />
                <FreeTokenHC
                    open={open}
                    handleClose={handleClose}
                    token={token}
                    clearData={clearData}
                    isFreeTokenPage={true}
                />

                <Grid container display="flex" justifyContent={"center"} sx={{ p: 2 }}>
                    <Grid container item display={"flex"} flexDirection={"column"} spacing={2} sm={7} md={6} lg={4}>
                        <HeaderHC />
                        <Grid item textAlign={"center"}>
                            <Typography variant="h6" sx={{ pt: 2 }}>
                                {isVitals ? "Vitals" : "Free Token"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <form onSubmit={searchTokenHandler}>
                                <SelectTokenElement
                                    token={searchToken}
                                    setToken={setSearchToken}
                                    searchTokenHandler={searchTokenHandler}
                                />
                            </form>
                        </Grid>

                        {/* -------------------- My Card  -------------- */}
                        {isPatient ? (
                            <Fragment>
                                {isVitals ? (
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={openConfiguration}
                                            endIcon={<Box component="img" src={DownArrow}></Box>}>
                                            Add Confugration
                                        </Button>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                                <Grid container item>
                                    <Grid item lg={2} xs={2}>
                                        <Typography color="#6B6B6B">Name:</Typography>
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <Typography
                                            sx={{
                                                textTransform: "capitalize",
                                            }}>
                                            {patient?.name || patient?.familyMembers[0]?.name || "NA"}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} xs={2}>
                                        <Typography color="#6B6B6B">Ph.No:</Typography>
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <Typography>
                                            {patient?.mobile || patient?.familyMembers[0]?.mobile || "NA"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item sx={{ py: 2 }}>
                                    {cards}
                                </Grid>
                                <Grid item>
                                    <ActionWithFreeTokenHC
                                        handleClickOpen={handleClickOpen}
                                        saveAction={registerData}
                                        cancelAction={clearData}
                                        isVitals={isVitals}
                                        isFreeToken={isFreeToken}
                                        freeTokenHandler={freeTokenHandler}
                                    />{" "}
                                </Grid>
                            </Fragment>
                        ) : (
                            <Grid item textAlign={"center"}>
                                <Box sx={{ mt: 5 }}>
                                    <Typography>No Token found</Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        </ThemeProvider>
    );
};

export default AddVitalsHC;
