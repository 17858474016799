import { Box, IconButton, Typography } from "@mui/material";
import React, { Fragment } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";

const BackButton = ({ title, url, onClick }) => {
  const handleButtonClick = () => {
    if (url) {
      navigate(url);
    } else if (onClick) {
      onClick();
    }
  };
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
        <IconButton onClick={handleButtonClick}>
          <ArrowBackIcon />
        </IconButton>

        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "15px",
            color: "#404040",
            marginTop: "12px",
            marginInline: "2px",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default BackButton;
