import { Box, Modal, Fade } from "@mui/material";

const ImageModal = ({ open, onClose, images, sx }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            display: "grid",
            h: 5,
            w: 5,
            p: 1,
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridGap: 2,
          }}
        >
          {images.map((tile) => (
            <img key={tile.reportId} src={tile.reportUrl} alt={tile.title} sx={{ maxWidth: "100%" }} />
          ))}
        </Box>
      </Fade>
    </Modal>
  );
};
export default ImageModal;
