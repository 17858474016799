import { Box, Grid, Typography } from "@mui/material"
import React, { Fragment } from "react"
import { useEffect } from "react"
import { useLocation } from "react-router"
import CardHeader from "../../../assets/images/svg/CardHeader.svg"
import CardFooter from "../../../assets/images/svg/CardFooter.svg"
import "./printCard.css"
import { Translate } from "@mui/icons-material"

const PrintCard = ({ data }) => {
  const formatDob = dob => {
    const options = { day: "numeric", month: "numeric", year: "numeric" }
    const date = new Date(dob)
    return date.toLocaleDateString("en-GB", options)
  }

  return (
    <Fragment>
      {/* <Box sx={{ width: "776px", textAlign: "center" }}> */}
      <Grid container spacing={1} sx={{ paddingInline: "10px", marginBlock: "20px" }}>
        <Grid item xs={12} lg={12}>
          <Box
            component="img"
            src={CardHeader}
            style={{
              marginRight: "10px",
            }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Box
            component="img"
            src={data.imageUrl}
            style={{
              height: "140px",
              width: "140px",
              marginLeft: "10px",
              marginRight: "10px",
              border: "1px solid black",
            }}
          />
        </Grid>
        <Grid item xs={9} lg={9}>
          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={3} lg={3}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#8A8A8A",
                }}>
                नाम
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "##404040",
                }}>
                {data.fullName}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#8A8A8A",
                }}>
                लिंग
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "##404040",
                }}>
                {data.gender}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#8A8A8A",
                }}>
                डी.ओ.बी.
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "##404040",
                }}>
                {formatDob(data.dob)}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#8A8A8A",
                }}>
                फ़ोन नंबर
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "##404040",
                }}>
                {data.mobile}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "20px",
                lineHeight: "32px",
                color: "#8A8A8A",
              }}>
              पता
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "32px",
                color: "#404040",
              }}>
              {data.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box
            component="img"
            src={CardFooter}
            style={{
              marginRight: "10px",
            }}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "32px",
          color: "#000000",
          position: "absolute",
          transform: "translateY(-65px)",
          marginLeft: "20px",
        }}>
        सहायता के लिए कॉल करे: 18008890189
      </Typography>
      {/* </Grid> */}
      {/* </Box> */}
    </Fragment>
  )
}

export default PrintCard
