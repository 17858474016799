import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const data = [
  {
    id: 1,
    label: "Package 1",
    value: "package_1",
  },
  {
    id: 2,
    label: "Package 2",
    value: "package_2",
  },
  {
    id: 3,
    label: "Package 3",
    value: "package_3",
  },
];

const CorpPackage = ({
  selctedPackage,
  setSelctedPackage,
  formValues,
  setFormValues,
}) => {
  return (
    <Box>
      <Autocomplete
        fullWidth
        size="small"
        disablePortal
        value={selctedPackage}
        onChange={(event, newValue) => {
          setSelctedPackage(newValue);
          setFormValues({
            ...formValues,
            ecg: false,
            xrayDone: false,
            bloodSampleCollected: false,
            urineSampleCollected: false,
            stoolSampleCollected: false,
            audiometryDone: false,
            pft: false,
            eyeTest: false,
          });
        }}
        //getOptionLabel={(option) => option.label}
        options={data}
        renderInput={(params) => (
          <TextField {...params} label="Select package" />
        )}
      />
    </Box>
  );
};

export default CorpPackage;
