import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import CorporateMainComp from "../../healthcamp/corporate/corporateHome/corporateMainComp";

const CorpoprateIndex = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <CorporateMainComp />
    </Fragment>
  );
};
export default CorpoprateIndex;
