import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import CenterBannerImg from "../assets/images/svg/centerBanner.svg";
import CardImg5 from "../assets/images/svg/card5.svg";
import CardImg6 from "../assets/images/svg/card6.svg";

const AboutUsComponent = () => {
    return (
        <Box sx={{ width: "100%", pt: 4, pb:4 }}>
  <Typography variant="h3" sx={{mb:3}}>About Us</Typography>

  <Grid container rowSpacing={4} columnSpacing={2} sx={{minHeight:100}} alignItems='center'>

    <Grid item lg={12}>
    <Box component="img"
      alt="card1"
      src={CenterBannerImg}
      sx={{ 

        background: 'rgba(255, 255, 255, 0.75)',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }} 
    />

    </Grid>

    

    <Grid item lg={12}>
    <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
    </Grid>

  </Grid>
</Box>
    );
}
 
export default AboutUsComponent;