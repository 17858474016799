import { Fragment } from "react";
import MyAppBarBeforeLogin from "../../header/myAppBarBeforeLogin";
import LoginHC from "../../healthcamp/login/loginHC";

const LoginPageHC = (props) => {
  return (
    <Fragment>
      <MyAppBarBeforeLogin />
      <LoginHC />
    </Fragment>
  );
};

export default LoginPageHC;
