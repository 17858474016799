import { Box, Button, Grid } from "@mui/material";
import { Fragment } from "react";

const RegisterContentMain = (props) => {
  const registerOldPatient = () => {
    props.onChangeContent("OLDPATIENT", "");
  };

  const registerNewPatient = () => {
    props.onChangeContent("NEWPATIENT", "");
  };
  return (
    <Fragment>
      <Box
        sx={{
          height: "74vh",
          background: "#fff",
          p: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container rowSpacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={registerOldPatient}
            >
              Register Old Patient
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={registerNewPatient}
            >
              Register New Patient
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RegisterContentMain;
