import { Avatar, Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import CardImg7 from "../assets/images/svg/card7.svg";
import CardImg8 from "../assets/images/svg/card8.svg";
import CardImg9 from "../assets/images/svg/card9.svg";

const PrimaryHealthComponent = () => {
    return (

        <Box sx={{ width: "100%", pt: 4,  }}>
  <Typography variant="h3" sx={{mb:3}}>Primary Health in just 3 Steps</Typography>

  <Grid container columnSpacing={2} >

    <Grid item lg={4}>
    <Avatar sx={{ background: '#9AE7EB', boxShadow:'0px 4px 4px 2px rgba(32, 143, 148, 0.25)', width:75, height:75 }}>1</Avatar>
 
    <Box component="img"
      alt="card1"
      src={CardImg7}
      sx={{ 
        height:237,
        width:265,
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px', mt:3,
      }} 
    />
<Typography variant="body2">Visit eChikitsalaya near you or call us to book an appointment </Typography>
    </Grid>

    <Grid item lg={4}>
    <Avatar sx={{ background: '#208F94', boxShadow:'0px 4px 4px 2px rgba(32, 143, 148, 0.25)', width:75, height:75 }}>2</Avatar>
    <Box component="img"
      alt="card1"
      src={CardImg8}
      sx={{ 
        height:237,
        width:265,
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px', mt:3,
      }} 
    />
   <Typography variant="body2">Get video consultation from reknowned Doctors while we share your vitals</Typography>
    </Grid>

    <Grid item lg={4}>
    <Avatar sx={{ background: '#9AE7EB', boxShadow:'0px 4px 4px 2px rgba(32, 143, 148, 0.25)', width:75, height:75 }}>3</Avatar>
    <Box component="img"
      alt="card1"
      src={CardImg9}
      sx={{ 
        height:237,
        width:265,
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px', mt:3,
      }} 
    />
<Typography variant="body2">Get diagnostic tests and your medicines</Typography>
    </Grid>

  </Grid>
</Box>
    );
}
 
export default PrimaryHealthComponent;