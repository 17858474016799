import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import TitleHC from "../common/title/titleHC";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";

import ActionHC from "../common/action/actionHC";
import AssignTokenElement from "./formComps/assignTokenElement";
import NameElement from "./formComps/nameElement";
import OrgElement from "./formComps/orgElement";
import PhoneNumberElementOutlined from "./formComps/phoneNumberElementOutlined";

const AssignToken = ({
    token,
    mobile,
    name,
    org,
    notification,
    tokenOnChange,
    setName,
    setOrg,
    setMobile,
    assignTokenHandler,
    clearDataHandler,
    key1,
    isTokenOccupied,
}) => {
    
    return (
        <Fragment>
            <ShowNoticeMessage noticeData={notification} key={key1} />
            <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                maxWidth={550}
                spacing={2}
                padding={2}>
                <Grid item>
                    <TitleHC title={"Assign Token"} />
                </Grid>
                <Grid item>
                    <Box sx={{ minHeight: "40vh" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography>Token</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AssignTokenElement
                                    token={token}
                                    tokenOnChange={tokenOnChange}
                                    isTokenOccupied={isTokenOccupied}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Name</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NameElement name={name} setName={setName} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Ph. Number</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PhoneNumberElementOutlined mob={mobile} setMob={setMobile} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Organization</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <OrgElement org={org} setOrg={setOrg} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={12} display="flex" justifyContent="center">
                    <ActionHC
                        saveAction={assignTokenHandler}
                        cancelAction={clearDataHandler}
                        buttonText1={"Assign"}
                        buttonText2={"Clear"}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default AssignToken;
