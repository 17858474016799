import { Typography } from "@mui/material";

export const CustomTypography = ({ children }) => (
  <Typography
    sx={{
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#6B6B6B",
    }}
  >
    {children}
  </Typography>
);

export const CustomTypographyBold = ({ children }) => (
  <Typography
    sx={{
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 14,
      color: "#6B6B6B",
    }}
  >
    {children}
  </Typography>
);
