import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL, zoomRooms } from "../../assets/constantsFile";

import { saveData } from "../../services/api/postApiCalls";

const SendAppointment = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [mobile, setMobile] = useState(data.mobile);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const shareLink = async () => {
    const url = BASE_URL + "clinic/sendAppointmentLink";
    const obj = {
      appointmentId: data.appointmentId,
      url: data.videoURL,
      patientMobile: mobile,
      doctorName: data.doctorName,
    };
    const data111 = await saveData(url, obj);

    if (data111.error) {
      console.log("failed");
    } else {
      console.log("success");
      handleClose();
    }
  };

  console.log(data.doctorName);
  return (
    <Fragment>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        startIcon={<ShareIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>

      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm mobile number.
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pt: 2 }}>
            <TextField
              size="small"
              fullWidth
              label="Mobile Number"
              variant="outlined"
              value={mobile || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length < 11) {
                  setMobile(e.target.value);
                }
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={mobile && mobile.length < 10 ? true : false}
            onClick={shareLink}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SendAppointment;
