import axios from "axios";
import { BASE_URL, BASE_URL_AUTH } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

export async function uploadVitals(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(BASE_URL + "patient/vitals", {
      method: "POST",
      data: obj,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function uploadReports(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };

  return await Resolver(
    axios
      .post(BASE_URL + "patient/reports", obj, { headers })
      .then((res) => res.data)
  );
}

export async function registerPatient(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };

  return await Resolver(
    axios.post(BASE_URL + "patient", obj, { headers }).then((res) => res.data)
  );
}

export async function getPatient(id) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.get(BASE_URL + "patient/" + id, { headers }).then((res) => res.data)
  );
}

export async function getUserToken(
  username,
  password,
  role,
  url = BASE_URL_AUTH + "authenticate"
) {
  return await Resolver(
    axios(url, {
      method: "POST",
      data: {
        username: username,
        password: password,
        role: role,
      },
    }).then((res) => res.data)
  );
}

export async function getUserTokenByMobile(url, data) {
  return await Resolver(
    axios(url, {
      method: "POST",
      data: data,
    }).then((res) => res.data)
  );
}
