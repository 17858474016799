import { TextField } from "@mui/material";
import { Fragment } from "react";
import { genderList } from "../../../assets/constantsFile";

const Gender = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <TextField
        size="small"
        select
        fullWidth
        label="Sex:"
        variant="outlined"
        value={formValues.gender || ""}
        onChange={(e) => {
          setFormValues({ ...formValues, gender: e.target.value });
        }}
        SelectProps={{
          native: true,
        }}
        helperText=""
      >
        {genderList.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Fragment>
  );
};

export default Gender;
