import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import PatCaseTableComp from "./patCaseTableComp";
import ClinicAppointmentSucess from "../clinicAppointmentSucess";
import ResumeConsultationregistrationForm from "./resumeConsultationRegistrationForm";
import PaymentComp from "../../paymentComp/paymentComp";

const ResumeConsltMainComp = (props) => {
  const selectedRowHandler = (d) => {
    setResConsltRegContent(
      <ResumeConsultationregistrationForm
        onResumeConsult={resumeConstHandler}
        patientId={props.patientId}
        patientData={d}
      />
    );
    console.log({ secletcedrow_resumeconsultation: d });
  };

  const resumeConstHandler = (d, doctor) => {
    if (d === "BACK") {
      setResConsltRegContent(
        <PatCaseTableComp
          patientId={props.patientId}
          onSelctedRow={selectedRowHandler}
        />
      );
    } else {
      if (d.error) {
      } else {
        setResConsltRegContent(
          <PaymentComp
            data={d.data}
            patientId={props.patientId}
            doctor={doctor}
            source="RESUMECLINIC"
          />
        );
      }
      console.log({ viewPatientHistoryHandler_data: d.data });
    }
  };

  const [resConsltRegContent, setResConsltRegContent] = useState(
    <PatCaseTableComp
      patientId={props.patientId}
      onSelctedRow={selectedRowHandler}
    />
  );

  return <Box>{resConsltRegContent}</Box>;
};
export default ResumeConsltMainComp;
