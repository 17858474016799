import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import Imge from "../../assets/images/logos/logo_print.svg"
const PrintFooter = props => {
  const docDetail = props.docDetail

  return (
    <Grid container>
      {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}> */}
      {/* <Grid item lg={3} md={3} sm={3} xs={3} display="flex" justifyContent={"flex-start"}>
                    <Stack direction="column" display="flex" alignItems="flex-start">
                        {docDetail.signatureURL && (
                            <img
                                src={docDetail.signatureURL}
                                alt="logo"
                                height={"80"}
                                width={"130"}
                                sx={{ pt: 2, flexGrow: 1 }}
                            />
                        )}
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {"Dr. "} {docDetail?.fullName}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {docDetail?.degree}
                        </Typography>
                    </Stack>
                </Grid> */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Stack direction={"row"} display="flex">
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: "13px",
              lineHeight: "9px",
              marginTop: "7px",
              color: "#6B6B6B",
              marginRight: "2px",
              textTransform: "uppercase",
            }}>
            Note:
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#F80000",
              mt: "3px",
            }}>
            This Document is NOT valid for legal purposes. It is generated under good faith
            solelyfor treatment of client, as per the verbal information provided by
            Informants/Clientself.
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default PrintFooter
