import axios from "axios";
import { BASE_URL } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

export async function saveData(url, obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.post(url, obj, { headers }, { timeout: 5000 }).then((res) => res.data)
  );
}

export async function saveDataWithoutToken(url, obj) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await Resolver(
    axios.post(url, obj, { headers }, { timeout: 5000 }).then((res) => res.data)
  );
}

export async function uploadFile1(url, formData) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.post(url, formData, { headers }).then((res) => res.data)
  );
}

export async function uploadFile(url, formData) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(url, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function registerPatient(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.post(BASE_URL + "patient", obj, { headers }).then((res) => res.data)
  );
}

export async function bookAppointment(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .post(BASE_URL + "clinic/appointment", obj, { headers })
      .then((res) => res.data)
  );
}
