import { Box, Button, Grid, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const PatCaseTableComp = (props) => {
  const [patCaseList, setPatCaseList] = useState([]);
  const fetchPatList = useCallback(async () => {
    const user = await getData(BASE_URL + "patient/cases/" + props.patientId);
    const data1 = user.data;
    if (data1) {
      setPatCaseList(data1);
    }
  }, []);

  useEffect(() => {
    fetchPatList();
  }, [fetchPatList]);

  const [selectionModel, setSelectionModel] = useState([]);
  const [selection, setSelection] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const rows = patCaseList;

  useEffect(() => {
    let obj = patCaseList.find((o) => o.caseId === selectionModel[0]);
    setSelection(obj);
    if (obj) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [selectionModel]);

  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      width: 140,
    },

    {
      field: "patientName",
      headerAlign: "center",
      align: "center",
      headerName: "PATIENT NAME",
      width: 180,
    },

    {
      field: "doctorName",
      headerAlign: "center",
      align: "center",
      headerName: "DOCTOR NAME",
      width: 180,
    },

    {
      field: "caseId",
      headerAlign: "center",
      align: "center",
      headerName: "CASE ID",
      width: 100,
    },

    {
      field: "symptom",
      headerAlign: "center",
      align: "center",
      headerName: "ISSUE",
      width: 200,
    },

    {
      field: "firstConsultationDate",
      headerAlign: "center",
      align: "center",
      headerName: "FIRST CONSULTATION DATE",
      width: 200,
    },

    {
      field: "lastConsultationDate",
      headerAlign: "center",
      align: "center",
      headerName: "LAST CONSULTATION DATE",
      width: 200,
    },
  ];

  console.log({ resume_patcaseList: patCaseList });
  console.log({ resume_selectionModel: selectionModel });
  console.log({ resume_selection: selection });

  const bookAppointmentHandler = (event) => {
    event.preventDefault();
    props.onSelctedRow(selection);
  };

  return (
    <Fragment>
      <Box
        sx={{
          minHeight: "47vh",
          background: "#fff",
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box sx={{ height: "47vh", background: "#fff" }}>
          <Box style={{ display: "flex", height: "100%" }}>
            <Box style={{ flexGrow: 1 }}>
              <DataGrid
                getRowId={(row) => row.caseId}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableMultipleSelection
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: "5vh",
          background: "#fff",
          p: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button variant="contained" href="/registration">
                Back
              </Button>
              <Button
                variant="contained"
                disabled={btnDisabled}
                onClick={bookAppointmentHandler}
              >
                book appointment
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatCaseTableComp;
