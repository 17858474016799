import React, { useEffect, useCallback, useState, useContext } from "react";
import { userContext } from "../../common/context/usercontext";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { useNavigate, useParams } from "react-router-dom";
import CaseUpload from "../caseUpload";

import { Grid, Card, CardContent, Typography, Box, CardActions, Button } from "@mui/material";

import ReportsView from "./reportsView";

// Individual Cards of  Case
const CardItem = ({
    doctor,
    patientName,
    reportsList,
    date,
    caseId,
    issue,
    patientId,
    patientData,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [showReports, setShowReports] = useState(false);

    return (
        <>
            <>
                <Card sx={{ textAlign: "center", m: 1, p: 0.1, minWidth: { lg: 975, xs: 300 } }}>
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {issue}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Dr {doctor}
                        </Typography>
                        <Typography sx={{ mb: 1 }} color="text.secondary">
                            Record for {patientName}
                        </Typography>
                        <Typography variant="body2">{date}</Typography>
                        <Typography variant="body2">
                            {reportsList?.length > 0 ? reportsList?.length : "NO "} reports
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => setIsEdit(true)}>
                            Edit
                        </Button>
                        <Button size="small" onClick={() => setShowReports(true)}>
                            Reports
                        </Button>
                        <Grid contianer>
                            <Grid lg={12}>
                                <CaseUpload
                                    patientName={patientName}
                                    patientId={patientId}
                                    caseId={caseId}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    location="EDIT"
                                    patientData={patientData}
                                />
                            </Grid>{" "}
                        </Grid>
                        <Grid container>
                            <Grid item sx={12} lg={10}>
                                <ReportsView
                                    reportsList={reportsList}
                                    showReports={showReports}
                                    setShowReports={setShowReports}
                                />
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </>

            {/* {modalOpen && <StandardImageList reportsList={reportsList} />} */}
        </>
    );
};

// List display of all cases

const CaseHistory = () => {
    let [caseList, setCaseList] = useState({});

    const { patientId, patientName } = useParams();
    const navigate = useNavigate();

    console.log({ caseHistory_PatientID: patientId });
    console.log({ caseHistory_PatientName: patientName });

    const fetchData = useCallback(async () => {
        const user = await getData(`${BASE_URL}healthcamp/digitalize/case/${patientId}`);
        console.log({ caseData: user });
        if (user.data) {
            setCaseList(user.data);
        } else {
            console.error(user.error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData, patientId]);

    const Title = (
        <>
            <Grid item sm={12} lg={12}>
                <Typography variant="h3" gutterBottom>
                    Cases of {patientName}
                </Typography>
            </Grid>
        </>
    );
    const NewCaseButton = (
        <>
            <Grid item sm={12} lg={12}>
                <CaseUpload patientId={patientId} patientName={patientName} location="ADD_CASE" />
            </Grid>
        </>
    );
    const Cases = (
        <>
            <Grid container spacing={2}>
                {caseList.length > 0 &&
                    caseList.map((e, index) => (
                        <>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <CardItem
                                    {...{
                                        caseId: e.id,
                                        doctor: e.doctorName,
                                        patientName: patientName,
                                        reportsList: e.reportsList,
                                        date: e.date,
                                        issue: e.issue,
                                        key: e.id,
                                        patientId: e.patientId,
                                        patientData: e,
                                    }}
                                    key={e.caseId}
                                />
                            </Grid>
                        </>
                    ))}
            </Grid>
        </>
    );
    const BackButton = (
        <>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                <Grid item>
                    <Button sx={{ p: 3, m: 3 }} variant="outlined" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </>
    );
    return (
        <>
            <Grid container sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                {Title}
                {NewCaseButton}
            </Grid>
            {Cases}
            {BackButton}
        </>
    );
};

export default CaseHistory;
