import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Portal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDate from "../../components/CustomDate";
import CustomTime from "../../components/CustomTime";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import { saveData } from "../../../services/api/postApiCalls";
import CustomButtonBlue from "../../components/customButton";
import { updateData } from "../../../services/api/patchApi";
import BackButton from "../../components/backButton";

const optionsMMBS_AFIH = ["MBBS", "AFIH"];
const optionsOPTO_OPTHAL = ["OPTO", "OPTHAL"];
const optionsMBBS_RADIOL = ["MBBS", "RADIOL"];

const AutocompleteComp = ({
  placeholderText,
  labelText,
  value,
  options,
  onChange,
}) => {
  return (
    <Autocomplete
      sx={{
        background: "#fff",
        color: "#000000",
        fontWeight: "300",
        fontSize: "13px",
        lineHeight: " 15px",
        "& input::placeholder": {
          color: "#777777",
          fontWeight: "300",
          fontSize: "13px",
          lineHeight: " 15px",
        },
      }}
      size="small"
      fullWidth
      placeholder={placeholderText}
      label={labelText}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={labelText} // Pass the label attribute
          placeholder={placeholderText} // Pass the placeholder attribute
        />
      )}
    />
  );
};

const CustomField = ({ formValues, setFormValues }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Official Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Name"
          value={formValues.corpName}
          onChange={(e) =>
            setFormValues({ ...formValues, corpName: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Address</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Address"
          value={formValues.address}
          onChange={(e) =>
            setFormValues({ ...formValues, address: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Unocare SPOC</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={formValues.unoSPOC !== "" ? true : false}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Unocare SPOC"
          value={formValues.unoSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, unoSPOC: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Name"
          value={formValues.corpSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, corpSPOC: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Mobile</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Mobile"
          value={formValues.corpSPOCMobile}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue.length <= 10) {
              setFormValues({ ...formValues, corpSPOCMobile: inputValue });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>GST No For Billing</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter GST No"
          value={formValues.gst}
          onChange={(e) =>
            setFormValues({ ...formValues, gst: e.target.value })
          }
        />
      </Grid>
    </Grid>
  );
};

const CustomWithDateAndTime = ({ setTime, setDate, date }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Execution Date Req</Typography>
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ color: "#000", marginRight: "10px" }}>
          From
        </Typography>
        <CustomDate setDate={setDate} initialDate={date} disablePast={true} />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ color: "#000", marginInline: "10px" }}>To</Typography>
        <CustomDate setDate={setDate} initialDate={date} disablePast={true} />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ color: "#000" }}>Reporting Time</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <CustomTime setDate={setTime} />
      </Grid>
    </Grid>
  );
};

const TestDetail = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.testDetails?.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={6} lg={3}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange("testDetails", index, "isIncluded")}
                  name="height"
                />
              }
              sx={{ fontSize: "14px" }}
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="#Employees"
              placeholder="#Employees"
              value={item.noOfEmp || ""}
              onChange={handleChange("testDetails", index, "noOfEmp")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Price"
              placeholder="Price"
              value={item.costPrice || ""}
              onChange={handleChange("testDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Remark"
              placeholder="Remark"
              value={item.remark || ""}
              onChange={handleChange("testDetails", index, "remark")}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
const Vaccination = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.vaccinationDetails?.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={6} lg={3}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange(
                    "vaccinationDetails",
                    index,
                    "isIncluded"
                  )}
                  name="height"
                />
              }
              sx={{ fontSize: "14px" }}
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="#Employees"
              placeholder="#Employees"
              value={item.noOfEmp}
              onChange={handleChange("vaccinationDetails", index, "noOfEmp")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Price"
              placeholder="Price"
              value={item.costPrice}
              onChange={handleChange("vaccinationDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Remark"
              placeholder="Remark"
              value={item.remark}
              onChange={handleChange("vaccinationDetails", index, "remark")}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
const Pathology = ({ formValues, setFormValues, handleChange }) => {
  const [newPathology, setNewPathology] = useState({
    name: "",
    isIncluded: false,
    noOfEmp: "",
    costPrice: "",
    remark: "",
  });
  const handleAddPathology = () => {
    if (
      newPathology.name !== ""
      // ||
      // newPathology.noOfEmp !== "" ||
      // newPathology.costPrice !== "" ||
      // newPathology.remark !== ""
    ) {
      setFormValues({
        ...formValues,
        pathologyDetails: [...formValues.pathologyDetails, newPathology],
      });
    }
    setNewPathology({
      name: "",
      isIncluded: false,
      noOfEmp: "",
      costPrice: "",
      remark: "",
    });
  };

  const handleDeleteField = (index) => {
    const updatedPathologyDetails = [...formValues.pathologyDetails];
    updatedPathologyDetails.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      pathologyDetails: updatedPathologyDetails,
    }));
  };

  return (
    <Fragment>
      {formValues?.pathologyDetails?.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={5} lg={2}>
            <Typography>{item.name}</Typography>
          </Grid>
          <Grid item xs={5} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange(
                    "pathologyDetails",
                    index,
                    "isIncluded"
                  )}
                />
              }
              sx={{ fontSize: "14px" }}
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={2} sx={{ display: { lg: "none" } }}>
            <IconButton onClick={() => handleDeleteField(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="#Employees"
              placeholder="#Employees"
              value={item.noOfEmp}
              onChange={handleChange("pathologyDetails", index, "noOfEmp")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Price"
              placeholder="Price"
              value={item.costPrice}
              onChange={handleChange("pathologyDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Remark"
              placeholder="Remark"
              value={item.remark}
              onChange={handleChange("pathologyDetails", index, "remark")}
            />
          </Grid>
          <Grid item lg={1} sx={{ display: { xs: "none", lg: "flex" } }}>
            <IconButton onClick={() => handleDeleteField(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={1}>
        <Grid item xs={6} lg={3}>
          <TextField
            sx={{
              background: "#fff",
              color: "#000000",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Enter Name"
            label="Name*"
            value={newPathology.name}
            onChange={(e) =>
              setNewPathology({
                ...newPathology,
                name: e.target.value,
                isIncluded: !!e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <FormControlLabel
            control={
              <Switch
                checked={!!newPathology.isIncluded}
                onChange={(e) =>
                  setNewPathology({
                    ...newPathology,
                    isIncluded: e.target.checked,
                  })
                }
              />
            }
            label="Yes/no"
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <TextField
            sx={{
              background: "#fff",
              color: "#000000",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="#Employees"
            label="#Employees"
            value={newPathology.noOfEmp}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setNewPathology({ ...newPathology, noOfEmp: e.target.value });
              }
            }}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <TextField
            sx={{
              background: "#fff",
              color: "#000000",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Price"
            label="Price"
            value={newPathology.costPrice}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setNewPathology({ ...newPathology, costPrice: e.target.value });
              }
            }}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <TextField
            sx={{
              background: "#fff",
              color: "#000000",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Remark"
            label="Remark"
            value={newPathology.remark}
            onChange={(e) =>
              setNewPathology({ ...newPathology, remark: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button onClick={handleAddPathology} variant="outlined">
            Add
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};
const FormCertificates = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.formDetails?.map((item, index) => (
        <Grid
          container
          spacing={1}
          key={index}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={6} lg={3}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange("formDetails", index, "isIncluded")}
                />
              }
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            {item.name === "Form32" ||
            item.name === "medicalFitnessCertificate" ||
            item.name === "physicalFitnessCerticate" ||
            item.name === "foodSafetyForm" ||
            item.name === "Form21_HealthRegister" ? (
              <AutocompleteComp
                placeholderText="Seal/Sign"
                labelText="Seal/Sign"
                onChange={handleChange("formDetails", index, "sealSign")}
                value={item?.sealSign}
                options={optionsMMBS_AFIH}
              />
            ) : item.name === "Form35" ? (
              <AutocompleteComp
                placeholderText="Seal/Sign"
                labelText="Seal/Sign"
                onChange={handleChange("formDetails", index, "sealSign")}
                value={item?.sealSign}
                options={optionsOPTO_OPTHAL}
              />
            ) : item.name === "xrayReport" ? (
              <AutocompleteComp
                placeholderText="Seal/Sign"
                labelText="Seal/Sign"
                onChange={handleChange("formDetails", index, "sealSign")}
                value={item?.sealSign}
                options={optionsMBBS_RADIOL}
              />
            ) : null}
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Price"
              label="Price"
              value={item.costPrice}
              onChange={handleChange("formDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Remark"
              label="Remark"
              value={item.remark}
              onChange={handleChange("formDetails", index, "remark")}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
const TechReq = ({ formValues, setFormValues, handleChange }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [additionalField, setAdditionalField] = useState("");
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const addField = () => {
    if (additionalField.trim() !== "") {
      const newField = {
        name: additionalField.toLowerCase().replace(/\s/g, ""),
        label: additionalField,
        isIncluded: false,
      };

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        techRequirements: [...prevFormValues.techRequirements, newField],
      }));

      setAdditionalField("");
      toggleModal();
    }
  };

  const handleDeleteField = (index) => {
    const updatedTechRequirement = [...formValues.techRequirements];
    updatedTechRequirement.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      techRequirements: updatedTechRequirement,
    }));
  };

  return (
    <Fragment>
      {formValues?.techRequirements?.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={6} lg={6}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={4} lg={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange(
                    "techRequirements",
                    index,
                    "isIncluded"
                  )}
                />
              }
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <IconButton onClick={() => handleDeleteField(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button variant="outlined" onClick={toggleModal}>
        Add More Fields
      </Button>

      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isModalOpen}
          onClose={toggleModal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.1)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              width: "365px",
            }}
          >
            {/* <Box sx={{ minHeight: "130px" }}> */}
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={toggleModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Add More Charts
            </Typography>
            <Box>
              <TextField
                sx={{
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter Chart Name"
                label="Chart Name"
                onChange={(e) => setAdditionalField(e.target.value)}
              />

              <Button
                onClick={addField}
                sx={{
                  textAlign: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Add Field
              </Button>
            </Box>
          </Box>
        </Modal>
      </Portal>
    </Fragment>
  );
};

const GatherInfo = ({
  corpId = localStorage.getItem("CORP_ID"),
  corpName = localStorage.getItem("CORP_NAME"),
  unoSPOCName = localStorage.getItem("USER_NAME_CORP"),
  unoSPOCId = localStorage.getItem("USER_ID_CORP"),
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [time, setTime] = useState(new Date());
  const [isSpocNameModal, setIsSpocNameModal] = useState(false);
  const toggleSpocNameModal = () => {
    setIsSpocNameModal(!isSpocNameModal);
  };

  const [newRemark, setNewRemarks] = useState("");
  const [newSpocName, setNewSpocname] = useState("");
  const [newSpocMobile, setNewSpocMobile] = useState("");

  const handleAddSpocName = () => {
    if (newSpocName) {
      setFormValues({
        ...formValues,
        spocsInfo: [
          ...formValues.spocsInfo,
          { spocname: newSpocName, spocmobile: newSpocMobile },
        ],
      });
      toggleSpocNameModal();
      setNewSpocname("");
      setNewSpocMobile("");
    }
  };
  const handleAddRemark = () => {
    if (newRemark) {
      setFormValues({
        ...formValues,
        remarksInfo: [...formValues.remarksInfo, { remarks: newRemark }],
      });
      toggleRemark();
      setNewRemarks("");
    }
  };

  const [isRemarkModal, setIsRemarkModal] = useState(false);
  const toggleRemark = () => {
    setIsRemarkModal(!isRemarkModal);
  };

  const [formValues, setFormValues] = useState({
    corpName: "",
    address: "",
    unoSPOC: "",
    unoSPOCId: "",
    corpSPOC: "",
    corpSPOCMobile: "",
    gst: "",
    testDetails: [
      {
        name: "height_weight",
        label: "Height/weight",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "bp",
        label: "BP",
        isIncluded: false,
        noOfEmp: "",
        remark: "",
        isActive: true,
      },
      {
        name: "rbs",
        label: "RBS",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "eyeVision",
        label: "Eye Vision",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "pft",
        label: "PFT",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "audio",
        label: "Audio",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "ecg",
        label: "ECG",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xray",
        label: "Xray",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "doctorVisit",
        label: "Doctor Visit",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    vaccinationDetails: [
      {
        name: "typhoidVaccine",
        label: "Typhoid Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "hepBVaccine",
        label: "Hep B Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "tetnusVaccine",
        label: "Tetnus Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    formDetails: [
      {
        name: "Form32",
        label: "Form 32",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "medicalFitnessCertificate",
        label: "Medical Fitness Certificate",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "physicalFitnessCerticate",
        label: "Physical Fitness Certificate",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form35",
        label: "Form 35",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "foodSafetyForm",
        label: "Food Safety Form",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form21_HealthRegister",
        label: "Form 21 Health Register",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xrayReport",
        label: "X-ray Report",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    techRequirements: [
      {
        name: "mobileApp",
        label: "Mobile App",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "OffRoll_OnRoll",
        label: "OffRoll/OnRoll",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Pending_Complete",
        label: "Pending/Complete",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Healthy_Unhealthy",
        label: "Healthy/Unhealthy",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Department_Wise",
        label: "Department Wise",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bpChart",
        label: "BP CharT",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "rbsChart",
        label: "RBS Chart",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bmiChart",
        label: "BMI Chart",
        isIncluded: false,
        isActive: true,
      },
    ],
    pathologyDetails: [],
    spocsInfo: [],
    remarksInfo: [],
  });

  const handleChange = (field, index, property) => (event) => {
    const updatedFormValues = [...formValues?.[field]];
    const value =
      property === "noOfEmp" || property === "costPrice"
        ? !isNaN(event.target.value)
          ? event.target.value
          : ""
        : property === "isIncluded"
        ? event.target.checked
        : property === "sealSign"
        ? event
        : event.target.value;

    updatedFormValues[index][property] =
      property === "isIncluded" ? !!value : value;

    setFormValues({
      ...formValues,
      [field]: updatedFormValues,
    });
  };
  console.log({ formValues });

  const obj = {
    id: corpId,
    corpName: corpName,
  };
  const handleCreateCorp = async () => {
    const url = BASE_URL + "org/corpConfigInfo/add";
    const result = await saveData(url, obj);
    if (result.data) {
      console.log("SUCCESS", result?.data);
      fetchCorpDetails();
    } else {
      console.log("ERROR", result.error);
    }
  };

  const convertStringToDate = (inputString) => {
    const currentDate = new Date();
    let hours = 0;
    let minutes = 0;
    if (inputString) {
      const splitString = inputString?.split(":");
      if (splitString?.length === 2) {
        hours = parseInt(splitString[0], 10);
        minutes = parseInt(splitString[1], 10);
      }
    }
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    return currentDate;
  };

  const [corpDetail, setCorpDetail] = useState("");
  const fetchCorpDetails = async () => {
    if (corpId !== null) {
      const url = BASE_URL + "org/corpConfigInfo/corp?corpId=" + corpId;
      const result = await getData(url);
      if (result.data) {
        console.log("SUCCESS", result?.data);
        setCorpDetail(result?.data);
        setTimeout(() => {
          setFromDate(
            result?.data?.requirementsDate === null ||
              result?.data?.requirementsDate?.fromDate === null ||
              result?.data?.requirementsDate?.fromDate === undefined
              ? null
              : new Date(result?.data?.requirementsDate?.fromDate)
                  ?.toISOString()
                  ?.split("T")[0]
          );
          setTime(
            result?.data?.requirementsDate === null ||
              result?.data?.requirementsDate.time === null ||
              result?.data?.requirementsDate.time === undefined
              ? new Date()
              : new Date(
                  convertStringToDate(result?.data?.requirementsDate?.time)
                )
          );
          setToDate(
            result?.data?.requirementsDate === null ||
              result?.data?.requirementsDate.toDate === null ||
              result?.data?.requirementsDate.toDate === undefined
              ? null
              : new Date(result?.data?.requirementsDate?.toDate)
                  ?.toISOString()
                  ?.split("T")[0]
          );
          setFormValues({
            id: result?.data?.id,
            corpName: result?.data?.corpName || "",
            address: result?.data?.address || "",
            unoSPOC: result?.data?.unoSPOC
              ? result?.data?.unoSPOC
              : unoSPOCName,
            unoSPOCId: result?.data?.unoSPOCId
              ? result?.data?.unoSPOCId
              : unoSPOCId,
            corpSPOC: result?.data?.corpSPOC || "",
            corpSPOCMobile: result?.data?.corpSPOCMobile || "",
            gst: result?.data?.gst || "",
            testDetails:
              result?.data?.testDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.testDetails[index]
                  ? formValues.testDetails[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues?.testDetails,
            vaccinationDetails:
              result?.data?.vaccinationDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.vaccinationDetails[index]
                  ? formValues.vaccinationDetails[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues?.vaccinationDetails,
            pathologyDetails:
              result?.data?.pathologyDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: item?.fieldName,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues.pathologyDetails,
            formDetails:
              result?.data?.formDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues?.formDetails[index].label,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                sealSign:
                  item?.seal === "" || item.seal === null ? null : item?.seal,
              })) || formValues.formDetails,
            spocsInfo: result?.data?.spocsInfo || [],
            remarksInfo: result?.data?.remarksInfo || [],
            techRequirements:
              result?.data?.techRequirements?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.techRequirements[index]
                  ? formValues.techRequirements[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
              })) || formValues?.techRequirements,
          });
        }, 500);
      } else {
        console.log("ERROR", result.error);
        setCorpDetail("");
        handleCreateCorp();
      }
    }
  };

  console.log({ formValues });

  useEffect(() => {
    fetchCorpDetails();
  }, []);

  const formatTime = (originalTime) => {
    const options = { hour: "numeric", minute: "2-digit", hour12: true };
    return originalTime.toLocaleString("en-US", options);
  };
  const Obj = {
    id: formValues.id || null,
    corpName: formValues.corpName,
    address: formValues.address,
    unoSPOC: formValues.unoSPOC,
    unoSPOCId: formValues.unoSPOCId,
    corpSPOC: formValues.corpSPOC,
    corpSPOCMobile: formValues.corpSPOCMobile,
    gst: formValues.gst,
    testDetails:
      formValues?.testDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,

    vaccinationDetails:
      formValues?.vaccinationDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    pathologyDetails:
      formValues?.pathologyDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    formDetails:
      formValues?.formDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
        seal: item.sealSign,
      })) || null,
    techRequirements:
      formValues?.techRequirements?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    requirementsDate:
      {
        fromDate: fromDate || null,
        toDate: toDate || null,
        time: formatTime(time) || null,
      } || null,
    spocsInfo: formValues.spocsInfo,
    remarksInfo: formValues.remarksInfo,
  };

  console.log({ Obj });

  const handleSave = async () => {
    const url =
      BASE_URL + "org/corpConfigInfo/update?corpConfigId=" + corpDetail?.id;
    const result = await updateData(url, Obj);
    if (result?.data) {
      console.log("SUCCESS", result.data);
      setSeverity("success");
      setMessage("Successfully Saved");
      setOpenNotice(true);
    } else {
      console.log("ERROR", result?.error);
      setSeverity("error");
      setMessage("An error occured");
      setOpenNotice(true);
    }
  };

  const handleDeleteSpoc = (index) => {
    const updatedSpocsInfo = [...formValues.spocsInfo];
    updatedSpocsInfo.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      spocsInfo: updatedSpocsInfo,
    }));
  };

  // Function to handle deletion of an item from remarksInfo array
  const handleDeleteRemark = (index) => {
    const updatedRemarksInfo = [...formValues.remarksInfo];
    updatedRemarksInfo.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      remarksInfo: updatedRemarksInfo,
    }));
  };

  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const handleChangeAccordion = (panel) => {
    setExpandedAccordion((prevExpanded) => {
      if (prevExpanded.includes(panel)) {
        // If the clicked panel is already expanded, collapse it
        return [];
      } else {
        // If a new panel is clicked, collapse the previous one and expand the new one
        return [panel];
      }
    });
  };

  const handleCollapseAll = () => {
    setExpandedAccordion([]);
  };

  console.log({ time });
  console.log({ fromDate, toDate });

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={3000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box>
        <BackButton url="/corp/home" />
        <Typography
          sx={{
            textAlign: "center",
            color: "#000",
            marginTop: "-30px",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Requirement Gathered
        </Typography>
        <Divider />

        <Box sx={{ textAlign: "center", marginBlock: 1 }}>
          <Button variant="outlined" onClick={handleCollapseAll}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Collapse All
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setExpandedAccordion([
                "panel1",
                "panel2",
                "panel3",
                "panel4",
                "panel5",
                "panel6",
                "panel7",
                "panel8",
                "panel9",
              ])
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Expand All
            </Typography>
          </Button>
        </Box>

        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel1")}
          onChange={() => handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Company Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomField
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel2")}
          onChange={() => handleChangeAccordion("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Execution Date Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                <Typography sx={{ color: "#000" }}>
                  Execution Date Req
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000", marginRight: "10px" }}>
                  From
                </Typography>
                <CustomDate
                  setDate={setFromDate}
                  initialDate={fromDate}
                  disablePast={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000", marginInline: "10px" }}>
                  To
                </Typography>
                <CustomDate
                  setDate={setToDate}
                  initialDate={toDate}
                  disablePast={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000" }}>Reporting Time</Typography>
              </Grid>
              <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                <CustomTime setTime={setTime} time={time} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel3")}
          onChange={() => handleChangeAccordion("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Test Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TestDetail
              formValues={formValues}
              setFormValues={setFormValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel4")}
          onChange={() => handleChangeAccordion("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Vacination
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Vaccination
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel5")}
          onChange={() => handleChangeAccordion("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Pathology
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Pathology
              formValues={formValues}
              setFormValues={setFormValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel6")}
          onChange={() => handleChangeAccordion("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Forms/Certificates
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormCertificates
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel7")}
          onChange={() => handleChangeAccordion("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Tech Requirements
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TechReq
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        {formValues?.spocsInfo.length > 0 ? (
          <Accordion
            sx={styles.accordionTitle}
            expanded={expandedAccordion.includes("panel8")}
            onChange={() => handleChangeAccordion("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                CORP SPOC Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {formValues?.spocsInfo?.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#000" }}>
                            {item.spocname}
                          </Typography>
                          <Typography
                            sx={{ color: "#000", marginLeft: "10px" }}
                          >
                            {item.spocmobile}
                          </Typography>
                        </Box>
                        <IconButton onClick={() => handleDeleteSpoc(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {formValues?.remarksInfo.length > 0 ? (
          <Accordion
            sx={styles.accordionTitle}
            expanded={expandedAccordion.includes("panel9")}
            onChange={() => handleChangeAccordion("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                Remark Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {formValues?.remarksInfo?.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#000" }}>
                            {item.remarks}
                          </Typography>
                        </Box>
                        <IconButton onClick={() => handleDeleteRemark(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>

      <Button
        variant="outlined"
        onClick={toggleSpocNameModal}
        sx={{ margin: "10px" }}
      >
        Add New Spoc Details
      </Button>
      <Button variant="outlined" onClick={toggleRemark} sx={{ margin: "10px" }}>
        Add Remark
      </Button>

      <CustomButtonBlue title={"Save"} onClick={() => handleSave()} />

      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isSpocNameModal}
          onClose={toggleSpocNameModal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.1)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              width: "365px",
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={toggleSpocNameModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Add SPOC Name
            </Typography>
            <Box>
              <TextField
                sx={{
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter SPOC Name"
                label="SPOC Name"
                value={newSpocName}
                onChange={(e) => setNewSpocname(e.target.value)}
              />
              <TextField
                sx={{
                  marginTop: "10px",
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter SPOC Mobile"
                label="SPOC Mobile"
                value={newSpocMobile}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!isNaN(inputValue) && inputValue.length <= 10) {
                    setNewSpocMobile(e.target.value);
                  }
                }}
              />

              <CustomButtonBlue
                title="Add SPOC Name"
                onClick={handleAddSpocName}
              />
            </Box>
          </Box>
        </Modal>
      </Portal>
      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isRemarkModal}
          onClose={toggleRemark}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.1)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              width: "365px",
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={toggleRemark}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Add Remarks
            </Typography>
            <Box>
              <TextField
                sx={{
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter Remarks"
                label="Remarks"
                value={newRemark}
                onChange={(e) => setNewRemarks(e.target.value)}
              />
              <CustomButtonBlue title="Add Remark" onClick={handleAddRemark} />
            </Box>
          </Box>
        </Modal>
      </Portal>
    </Fragment>
  );
};

export default GatherInfo;

const styles = {
  accordionTitle: {
    "& .MuiTypography-root": {
      fontSize: "16px",
    },
  },
};
