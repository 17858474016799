import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackButton from "../../components/backButton";
import BasicDetails from "./subComp/basicDetails";
import CustomDate from "../../components/CustomDate";
import CustomTime from "../../components/CustomTime";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import { saveData } from "../../../services/api/postApiCalls";
import CustomButtonBlue from "../../components/customButton";
import { updateData } from "../../../services/api/patchApi";
import ExecutionDetails from "./subComp/executionDetails";

const convertStringToDate = (inputString) => {
  const currentDate = new Date();
  let hours = 0;
  let minutes = 0;
  if (inputString) {
    const splitString = inputString?.split(":");
    if (splitString?.length === 2) {
      hours = parseInt(splitString[0], 10);
      minutes = parseInt(splitString[1], 10);
    }
  }
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  return currentDate;
};

const formatTime = (originalTime) => {
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  return originalTime.toLocaleString("en-US", options);
};

const GatherInfoMain = ({
  corpId = localStorage.getItem("CORP_ID"),
  corpName = localStorage.getItem("CORP_NAME"),
  unoSPOCName = localStorage.getItem("USER_NAME_CORP"),
  unoSPOCId = localStorage.getItem("USER_ID_CORP"),
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [time, setTime] = useState(new Date());
  const [isSpocNameModal, setIsSpocNameModal] = useState(false);
  const toggleSpocNameModal = () => {
    setIsSpocNameModal(!isSpocNameModal);
  };

  const [newRemark, setNewRemarks] = useState("");
  const [newSpocName, setNewSpocname] = useState("");
  const [newSpocMobile, setNewSpocMobile] = useState("");

  const handleAddSpocName = () => {
    if (newSpocName) {
      setFormValues({
        ...formValues,
        spocsInfo: [
          ...formValues.spocsInfo,
          { spocname: newSpocName, spocmobile: newSpocMobile },
        ],
      });
      toggleSpocNameModal();
      setNewSpocname("");
      setNewSpocMobile("");
    }
  };
  const handleAddRemark = () => {
    if (newRemark) {
      setFormValues({
        ...formValues,
        remarksInfo: [...formValues.remarksInfo, { remarks: newRemark }],
      });
      toggleRemark();
      setNewRemarks("");
    }
  };

  const [isRemarkModal, setIsRemarkModal] = useState(false);
  const toggleRemark = () => {
    setIsRemarkModal(!isRemarkModal);
  };

  const [formValues, setFormValues] = useState({
    corpName: "",
    address: "",
    unoSPOC: "",
    unoSPOCId: "",
    corpSPOC: "",
    corpSPOCMobile: "",
    gst: "",
    testDetails: [
      {
        name: "height_weight",
        label: "Height/weight",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "bp",
        label: "BP",
        isIncluded: false,
        noOfEmp: "",
        remark: "",
        isActive: true,
      },
      {
        name: "rbs",
        label: "RBS",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "eyeVision",
        label: "Eye Vision",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "pft",
        label: "PFT",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "audio",
        label: "Audio",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "ecg",
        label: "ECG",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xray",
        label: "Xray",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "doctorVisit",
        label: "Doctor Visit",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    vaccinationDetails: [
      {
        name: "typhoidVaccine",
        label: "Typhoid Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "hepBVaccine",
        label: "Hep B Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "tetnusVaccine",
        label: "Tetnus Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    formDetails: [
      {
        name: "Form32",
        label: "Form 32",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "medicalFitnessCertificate",
        label: "Medical Fitness Certificate",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "physicalFitnessCerticate",
        label: "Physical Fitness Certificate",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form35",
        label: "Form 35",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "foodSafetyForm",
        label: "Food Safety Form",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form21_HealthRegister",
        label: "Form 21 Health Register",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xrayReport",
        label: "X-ray Report",
        isIncluded: false,
        sealSign: null,
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    techRequirements: [
      {
        name: "mobileApp",
        label: "Mobile App",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "OffRoll_OnRoll",
        label: "OffRoll/OnRoll",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Pending_Complete",
        label: "Pending/Complete",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Healthy_Unhealthy",
        label: "Healthy/Unhealthy",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Department_Wise",
        label: "Department Wise",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bpChart",
        label: "BP CharT",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "rbsChart",
        label: "RBS Chart",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bmiChart",
        label: "BMI Chart",
        isIncluded: false,
        isActive: true,
      },
    ],
    pathologyDetails: [],
    spocsInfo: [],
    remarksInfo: [],
    requirementsDate: {
      fromDate: fromDate,
      toDate: toDate,
      time: formatTime(time) || null,
    },
  });

  const handleChange = (field, index, property) => (event) => {
    const updatedFormValues = [...formValues?.[field]];
    const value =
      property === "noOfEmp" || property === "costPrice"
        ? !isNaN(event.target.value)
          ? event.target.value
          : ""
        : property === "isIncluded"
        ? event.target.checked
        : property === "sealSign"
        ? event
        : event.target.value;

    updatedFormValues[index][property] =
      property === "isIncluded" ? !!value : value;

    setFormValues({
      ...formValues,
      [field]: updatedFormValues,
    });
  };
  console.log({ formValues });

  const obj = {
    id: corpId,
    corpName: corpName,
  };
  const handleCreateCorp = async () => {
    const url = BASE_URL + "org/corpConfigInfo/add";
    const result = await saveData(url, obj);
    if (result.data) {
      console.log("SUCCESS", result?.data);
      fetchCorpDetails();
    } else {
      console.log("ERROR", result.error);
    }
  };

  const [corpDetail, setCorpDetail] = useState("");
  const fetchCorpDetails = async () => {
    if (corpId !== null) {
      const url = BASE_URL + "org/corpConfigInfo/corp?corpId=" + corpId;
      const result = await getData(url);
      if (result.data) {
        console.log("SUCCESS", result?.data);
        setCorpDetail(result?.data);
        setTimeout(() => {
          setFromDate(
            result?.data?.requirementsDate &&
              (result?.data?.requirementsDate.toDate === null ||
                result?.data?.requirementsDate.toDate === undefined)
              ? null
              : new Date(result?.data?.requirementsDate?.fromDate)
                  .toISOString()
                  .split("T")[0]
          );
          setTime(
            result?.data?.requirementsDate &&
              (result?.data?.requirementsDate.time === null ||
                result?.data?.requirementsDate.time === undefined)
              ? new Date()
              : new Date(
                  convertStringToDate(result?.data?.requirementsDate?.time)
                )
          );
          setToDate(
            result?.data?.requirementsDate &&
              (result?.data?.requirementsDate.toDate === null ||
                result?.data?.requirementsDate.toDate === undefined)
              ? null
              : new Date(result?.data?.requirementsDate?.toDate)
                  .toISOString()
                  .split("T")[0]
          );
          setFormValues({
            id: result?.data?.id,
            corpName: result?.data?.corpName || "",
            address: result?.data?.address || "",
            unoSPOC: result?.data?.unoSPOC
              ? result?.data?.unoSPOC
              : unoSPOCName,
            unoSPOCId: result?.data?.unoSPOCId
              ? result?.data?.unoSPOCId
              : unoSPOCId,
            corpSPOC: result?.data?.corpSPOC || "",
            corpSPOCMobile: result?.data?.corpSPOCMobile || "",
            gst: result?.data?.gst || "",
            testDetails:
              result?.data?.testDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.testDetails[index]
                  ? formValues.testDetails[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues?.testDetails,
            vaccinationDetails:
              result?.data?.vaccinationDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.vaccinationDetails[index]
                  ? formValues.vaccinationDetails[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues?.vaccinationDetails,
            pathologyDetails:
              result?.data?.pathologyDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: item?.fieldName,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
              })) || formValues.pathologyDetails,
            formDetails:
              result?.data?.formDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues?.formDetails[index].label,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                sealSign:
                  item?.seal === "" || item.seal === null ? null : item?.seal,
              })) || formValues.formDetails,
            spocsInfo: result?.data?.spocsInfo || [],
            remarksInfo: result?.data?.remarksInfo || [],
            techRequirements:
              result?.data?.techRequirements?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.techRequirements[index]
                  ? formValues.techRequirements[index].label
                  : item.fieldName || "",
                isIncluded: item?.isIncluded || false,
              })) || formValues?.techRequirements,

            requirementsDate: {
              fromDate: fromDate || null,
              toDate: toDate || null,
              time: formatTime(time) || null,
            },
          });
        }, 500);
      } else {
        console.log("ERROR", result.error);
        setCorpDetail("");
        handleCreateCorp();
      }
    }
  };

  console.log({ formValues });

  useEffect(() => {
    fetchCorpDetails();
  }, []);

  const Obj = {
    id: formValues.id || null,
    corpName: formValues.corpName,
    address: formValues.address,
    unoSPOC: formValues.unoSPOC,
    unoSPOCId: formValues.unoSPOCId,
    corpSPOC: formValues.corpSPOC,
    corpSPOCMobile: formValues.corpSPOCMobile,
    gst: formValues.gst,
    testDetails:
      formValues?.testDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,

    vaccinationDetails:
      formValues?.vaccinationDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    pathologyDetails:
      formValues?.pathologyDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    formDetails:
      formValues?.formDetails?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
        seal: item.sealSign,
      })) || null,
    techRequirements:
      formValues?.techRequirements?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        isIncluded: item.isIncluded,
        employees: item.noOfEmp,
        costPrice: item.costPrice,
        remarks: item.remark,
        isActive: true,
      })) || null,
    requirementsDate:
      {
        fromDate: fromDate || null,
        toDate: toDate || null,
        time: formatTime(time) || null,
      } || null,
    spocsInfo: formValues.spocsInfo,
    remarksInfo: formValues.remarksInfo,
  };

  console.log({ Obj });

  const handleSave = async () => {
    const url =
      BASE_URL + "org/corpConfigInfo/update?corpConfigId=" + corpDetail?.id;
    const result = await updateData(url, Obj);
    if (result?.data) {
      console.log("SUCCESS", result.data);
      setSeverity("success");
      setMessage("Successfully Saved");
      setOpenNotice(true);
    } else {
      console.log("ERROR", result?.error);
      setSeverity("error");
      setMessage("An error occured");
      setOpenNotice(true);
    }
  };

  const handleDeleteSpoc = (index) => {
    const updatedSpocsInfo = [...formValues.spocsInfo];
    updatedSpocsInfo.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      spocsInfo: updatedSpocsInfo,
    }));
  };

  // Function to handle deletion of an item from remarksInfo array
  const handleDeleteRemark = (index) => {
    const updatedRemarksInfo = [...formValues.remarksInfo];
    updatedRemarksInfo.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      remarksInfo: updatedRemarksInfo,
    }));
  };

  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const handleChangeAccordion = (panel) => {
    setExpandedAccordion((prevExpanded) => {
      if (prevExpanded.includes(panel)) {
        // If the clicked panel is already expanded, collapse it
        return [];
      } else {
        // If a new panel is clicked, collapse the previous one and expand the new one
        return [panel];
      }
    });
  };

  const handleCollapseAll = () => {
    setExpandedAccordion([]);
  };

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={3000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box>
        <BackButton url="/corp/home" />
        <Typography
          sx={{
            textAlign: "center",
            color: "#000",
            marginTop: "-30px",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Requirement Gathered
        </Typography>
        <Divider />

        <Box sx={{ textAlign: "center", marginBlock: 1 }}>
          <Button variant="outlined" onClick={handleCollapseAll}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Collapse All
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setExpandedAccordion([
                "panel1",
                "panel2",
                "panel3",
                "panel4",
                "panel5",
                "panel6",
                "panel7",
                "panel8",
                "panel9",
              ])
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Expand All
            </Typography>
          </Button>
        </Box>

        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel1")}
          onChange={() => handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Company Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BasicDetails
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel2")}
          onChange={() => handleChangeAccordion("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Execution Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExecutionDetails
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setTime={setTime}
              time={time}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Fragment>
  );
};

export default GatherInfoMain;

const styles = {
  accordionTitle: {
    "& .MuiTypography-root": {
      fontSize: "16px",
    },
  },
};
