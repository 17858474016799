import { Box, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material"

import { BASE_URL } from "../../assets/constantsFile"
import { useEffect, useState } from "react"
import PrintHeader from "./printHeader"
import PrintPatientDetail from "./printPatientDetail"
import PrintVitals from "./printVitals"
import PrintIssue from "./printIssue"
import PrintDiagnosis from "./printDiagnosis"
import PrintPrescribedTests from "./printPrescribedTests"
import PrintPrescriptions from "./printPrescriptions"
import { getData } from "../../services/api/getApiCalls"
import PrintFooter from "./printFooter"
import PatientMedicalHistory from "./patientMedicalHistory"
import NextAppointmentDate from "./nextAppointmentDate"
import PersonalVisitRequired from "./personalVisitRequired"
import PrintChiefComplaint from "./printChiefComplaint"

const PrintReportMainComponent = props => {
  const [docDetail, setDocDetail] = useState("")

  let patientDetails = ""
  let doctorId = ""

  let patientInfo = ""
  let vitalInfo = ""
  let issueInfo = ""
  let observationInfo = ""
  let reportList = []
  let prescriptionList = []
  let patientMedicalHistoryInfo = ""
  let nextAppointmentDate = ""
  let personalVisitNeeded = ""
  let chiefComplaintInfo = ""

  if (props.patientDetails) {
    patientMedicalHistoryInfo = {
      patientName: props.patientDetails.patientName,
      habits: props.patientDetails.habits,
      preExistingConditions: props.patientDetails.preExistingConditions,
      drugHistory: props.patientDetails.drugHistory,
      drugAllergy: props.patientDetails.drugAllergy,
      parentalHistory: props.patientDetails.parentalHistory,
      occupation: props.patientDetails.occupation,
    }

    nextAppointmentDate = props.patientDetails.nextAppointmentDate
    personalVisitNeeded = props.patientDetails.personalVisitNeeded

    patientDetails = props.patientDetails
    doctorId = patientDetails.docId

    patientInfo = {
      patientId: patientDetails.patientId,
      name: patientDetails.patientName,
      age:
        (patientDetails.patientInfoVM.age ? patientDetails.patientInfoVM.age + "Y " : "") +
        (patientDetails.patientInfoVM.ageMonths
          ? patientDetails.patientInfoVM.ageMonths + "M"
          : ""),
      sex: patientDetails.gender,
      mobile: patientDetails.ContactNumber,
      issue: patientDetails.issue,
    }
    chiefComplaintInfo = patientDetails.chiefComplaints
    issueInfo = patientDetails.issue
    vitalInfo = patientDetails.vitals ? patientDetails.vitals : ""
    observationInfo = patientDetails.observation ? patientDetails.observation : ""
    reportList = patientDetails.reports ? patientDetails.reports : []
    let prescriptionVM = patientDetails.prescriptionVM

    if (prescriptionVM) {
      prescriptionList = prescriptionVM.medicines
    }
  }

  const getDocInfo = async dId => {
    const user = await getData(BASE_URL + "doctor/" + dId)

    if (user.error) {
    } else {
      const data = user.data
      setDocDetail(data)
    }
  }

  useEffect(() => {
    getDocInfo(doctorId)
  }, [doctorId])

  console.log(patientDetails)

  return (
    <Container maxWidth={false} sx={{ mr: 0, ml: 0, width: "100%" }}>
      <Box sx={{ minHeight: "72vh", width: "100%" }}>
        <Grid container rowSpacing={2}>
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <PrintHeader docDetail={docDetail} patientDetails={patientDetails} />
            {/* <Divider /> */}
          </Grid>

          <Grid item lg={12} xs={12} md={12} sm={12}>
            <PrintPatientDetail patientInfo={patientInfo} />
            {/* <Divider /> */}
          </Grid>

          {vitalInfo ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintVitals vitalInfo={vitalInfo} />
              {/* <Divider /> */}
            </Grid>
          ) : (
            ""
          )}

          {chiefComplaintInfo ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintChiefComplaint chiefComplaintInfo={chiefComplaintInfo} />
            </Grid>
          ) : (
            ""
          )}

          {issueInfo ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintIssue issueInfo={issueInfo} />
            </Grid>
          ) : (
            ""
          )}

          {observationInfo ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintDiagnosis diagnosisInfo={observationInfo} />
            </Grid>
          ) : (
            ""
          )}

          {patientMedicalHistoryInfo ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PatientMedicalHistory patientMedicalHistoryInfo={patientMedicalHistoryInfo} />
            </Grid>
          ) : (
            ""
          )}

          {reportList && reportList.length > 0 ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintPrescribedTests reportList={reportList} />
              {/* <Divider /> */}
            </Grid>
          ) : (
            ""
          )}

          {personalVisitNeeded ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PersonalVisitRequired personalVisitNeeded={personalVisitNeeded} />
              {/* <Divider /> */}
            </Grid>
          ) : (
            ""
          )}

          {nextAppointmentDate ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <NextAppointmentDate nextAppointmentDate={nextAppointmentDate} />
              {/* <Divider /> */}
            </Grid>
          ) : (
            ""
          )}

          {prescriptionList && prescriptionList.length > 0 ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintPrescriptions prescriptionList={prescriptionList} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>

      <Box>
        <Grid container>
          {docDetail ? (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <PrintFooter docDetail={docDetail} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default PrintReportMainComponent
