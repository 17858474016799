// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  /* remove the default header */
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  /* remove the default footer */
  body::after {
    content: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/marketing/ashaWoker/printCard/printCard.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B;IACE,aAAa;IACb,gBAAgB;EAClB;EACA,8BAA8B;EAC9B;IACE,aAAa;EACf;AACF","sourcesContent":["@media print {\n  /* remove the default header */\n  @page {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n  /* remove the default footer */\n  body::after {\n    content: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
