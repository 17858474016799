import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import ClinicAppointmentSucess from "../bookAppointment/clinicAppointmentSucess";
import BookLabTestSuccess from "../bookLab/bookLabTestSuccess";
import BookPharmacySuccess from "../bookPharmacy/bookPharmacySuccess";

const PaymentComp = (props) => {
  const [patient, setPatient] = useState("");

  const getPatient = async () => {
    const url = BASE_URL + "patient/" + props.patientId;
    const data = await getData(url);

    if (data.error) {
      setPatient("");
    } else {
      setPatient(data.data[0]);
    }
  };

  useEffect(() => {
    getPatient();
  }, []);
  console.log({ "props.doctor": props.doctor });
  console.log({ "props.patientId": props.patientId });
  console.log({ "props.data": props.data });

  const paymentVM = {
    paymentVM: props.data.paymentVM,
    appointmentId: props.data.appointmentId,
    patientId: props.data.patientId,
    docId: props.data.docId,
  };

  if (props.source === "CLINIC" || props.source === "RESUMECLINIC") {
    return (
      <Fragment>
        <Box>
          <ClinicAppointmentSucess
            data={props.data}
            patientId={props.patientId}
            patient={patient}
            doctor={props.doctor}
            paymentVM={paymentVM}
          />
        </Box>
      </Fragment>
    );
  }

  if (props.source === "LAB") {
    return (
      <Fragment>
        <Box>
          <BookLabTestSuccess
            data={props.data}
            patientId={props.patientId}
            patient={patient}
            paymentVM={paymentVM}
          />
        </Box>
      </Fragment>
    );
  }

  if (props.source === "PHARMACY") {
    return (
      <Fragment>
        <Box>
          <BookPharmacySuccess
            data={props.data}
            patientId={props.patientId}
            patient={patient}
            paymentVM={paymentVM}
          />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Box>
        <Typography>...</Typography>
      </Box>
    </Fragment>
  );
};

export default PaymentComp;
