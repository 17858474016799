import { Box, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material"

import { BASE_URL } from "../../assets/constantsFile"
import { useEffect, useState } from "react"
import { getData } from "../../services/api/getApiCalls"
import PrintHeader from "../../common/printComps/printHeader"
import PrintPatientDetail from "../../common/printComps/printPatientDetail"
import PrintVitals from "../../common/printComps/printVitals"
import PrintIssue from "../../common/printComps/printIssue"
import PrintDiagnosis from "../../common/printComps/printDiagnosis"
import PrintPrescribedTests from "../../common/printComps/printPrescribedTests"
import PrintPrescriptions from "../../common/printComps/printPrescriptions"
import PrintFooter from "../../common/printComps/printFooter"
import PatientMedicalHistory from "../../common/printComps/patientMedicalHistory"
import NextAppointmentDate from "../../common/printComps/nextAppointmentDate"
import PersonalVisitRequired from "../../common/printComps/personalVisitRequired"
import PrintChiefComplaint from "../../common/printComps/printChiefComplaint"
import companyLogo from "../../assets/images/svg/unoLogo.svg"
import printHeader from "../../assets/images/svg/printHeader.svg"
import printFooter from "../../assets/images/svg/printFooter.svg"
import telephone1 from "../../assets/images/telephone1.png"

const PrintReportComponent = props => {
  const [docDetail, setDocDetail] = useState("")
  console.log({ "props.patientDetails123": props.patientDetails })

  let patientDetails = ""
  let doctorId = ""

  let patientInfo = ""
  let vitalInfo = ""
  let issueInfo = ""
  let observationInfo = ""
  let reportList = []
  let prescriptionList = []
  let patientMedicalHistoryInfo = ""
  let nextAppointmentDate = ""
  let personalVisitNeeded = ""
  let advice = ""
  let chiefComplaintInfo = ""

  console.log({ printHere: props.patientDetails })

  if (props.patientDetails) {
    patientDetails = props.patientDetails
    doctorId = patientDetails.docId
    nextAppointmentDate = props.patientDetails.nextAppointmentDate
    personalVisitNeeded = props.patientDetails.personalVisitNeeded

    patientInfo = {
      patientId: patientDetails.patientId,
      name: patientDetails.patientName,
      age:
        (patientDetails.patientInfoVM && patientDetails.patientInfoVM.age
          ? patientDetails.patientInfoVM.age + "Y "
          : "") +
        (patientDetails.patientInfoVM && patientDetails.patientInfoVM.ageMonths
          ? patientDetails.patientInfoVM.ageMonths + "M"
          : ""),
      sex: patientDetails.gender,
      issue: patientDetails.issue,
      appointmentId: patientDetails.appointmentId,
      caseId: patientDetails.caseId,
    }
    chiefComplaintInfo = patientDetails.chiefComplaints
    issueInfo = patientDetails.issue
    vitalInfo = patientDetails.vitals ? patientDetails.vitals : ""
    observationInfo = patientDetails.observation ? patientDetails.observation : ""
    reportList = patientDetails.reports ? patientDetails.reports : []
    let prescriptionVM = patientDetails.prescriptionVM

    if (prescriptionVM) {
      prescriptionList = prescriptionVM.medicines
    }
    advice = patientDetails.advice
    const patientInfoVM = patientDetails.patientInfoVM
    patientMedicalHistoryInfo = {
      preExistingConditions: patientInfoVM.preExistingConditions,
      drugHistory: patientInfoVM.drugHistory,
      drugAllergy: patientInfoVM.drugAllergy,
      habits: patientInfoVM.habits,
      occupation: patientInfoVM.occupation,
      parentalHistory: patientInfoVM.parentalHistory,
    }
  }

  const getDocInfo = async dId => {
    if (dId) {
      const user = await getData(BASE_URL + "doctor/" + dId)

      if (user.error) {
      } else {
        const data = user.data
        setDocDetail(data)
      }
    }
  }

  useEffect(() => {
    getDocInfo(doctorId)
  }, [doctorId])

  console.log({ patientDetails: patientDetails })

  return (
    <Grid
      container
      sx={{
        display: "flex",
        m: 0,
      }}>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
        <img src={printHeader} style={{ width: "100%" }} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ paddingX: "15px" }}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ minHeight: "90.8vh" }}>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <img src={companyLogo} style={{ width: "200px", height: "65px", marginTop: 0 }} />
              <Grid item sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <img src={telephone1} alt="" style={{ width: 25, height: 25 }} />
                </Grid>
                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                  <Typography color="#6B6B6B"> {`+${docDetail.unoPhoneNumber}, `} </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography color="#6B6B6B"> {`${docDetail.unoEmail}`} </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <PrintHeader docDetail={docDetail} patientDetails={patientDetails} />
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ border: "0.5px solid #127DDD", width: "100%" }} />

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              height: "50px",
              width: "100%",
              backgroundColor: "#F4FBFF",
              marginY: "10px",
            }}>
            <Grid item lg={10} md={10} sm={10} xs={10} sx={{ marginX: "10px" }}>
              <PrintPatientDetail patientInfo={patientInfo} />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
              <PrintVitals vitalInfo={vitalInfo} />
            </Grid>
            {chiefComplaintInfo && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PrintChiefComplaint chiefComplaintInfo={chiefComplaintInfo} />
              </Grid>
            )}
            {issueInfo && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PrintIssue issueInfo={issueInfo} />
              </Grid>
            )}
            {observationInfo && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PrintDiagnosis diagnosisInfo={observationInfo} />
              </Grid>
            )}
            {advice && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <Stack direction="row">
                  <Typography sx={styles.Mainheading}>Advice:&nbsp;</Typography>
                  <Typography sx={styles.headingData}>{advice}</Typography>
                </Stack>
              </Grid>
            )}
            {patientMedicalHistoryInfo && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PatientMedicalHistory patientMedicalHistoryInfo={patientMedicalHistoryInfo} />
              </Grid>
            )}
            {reportList && reportList.length > 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PrintPrescribedTests reportList={reportList} />
              </Grid>
            )}
            {personalVisitNeeded && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginY: "5px" }}>
                <PersonalVisitRequired personalVisitNeeded={personalVisitNeeded} />
              </Grid>
            )}
            {prescriptionList && prescriptionList.length > 0 && (
              <Grid item lg={12} xs={12} md={12} sm={12} sx={{ marginY: "10px" }}>
                <PrintPrescriptions prescriptionList={prescriptionList} />
              </Grid>
            )}
          </Box>
        </Grid>
        {nextAppointmentDate && (
          <Grid item lg={12} xs={12} md={12} sm={12} sx={{ marginY: "5px" }}>
            <NextAppointmentDate nextAppointmentDate={nextAppointmentDate} />
          </Grid>
        )}
        <Grid item lg={12} xs={12} md={12} sm={12} sx={{ marginY: "5px" }}>
          {docDetail && <PrintFooter docDetail={docDetail} />}
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
        <img src={printFooter} style={{ width: "100%" }} />
      </Grid>
    </Grid>
  )
}

export default PrintReportComponent

const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    marginTop: "5px",
    color: "#127DDD",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
    mt: "5px",
  },
}
