import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import RegisterEyeSightHC from "../../healthcamp/eyeSight/registerEyeSightHC";

const EyeSightPageHC = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <RegisterEyeSightHC />
    </Fragment>
  );
};

export default EyeSightPageHC;
