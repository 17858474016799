import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import ActionWithFreeTokenHC from "../common/action/actionWithFreeTokenHC";
import FreeTokenHC from "../common/freeToken/freeTokenHC";
import BPElement from "./formComps/bpElement";
import SelectTokenElement from "./formComps/selectTokenElement";

const RegisterBPContentHC = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [formValues, setFormValues] = useState([
    { sNo: 1, name: "ABC" },
    { sNo: 1, name: "DEF" },
    { sNo: 1, name: "GHI" },
  ]);

  return (
    <Fragment>
      <FreeTokenHC open={open} handleClose={handleClose} />

      <Box sx={{ p: 1, mt: 5 }}>
        <Box
          sx={{
            p: 2,
            border: "1px solid #208F94",
            borderRadius: 2,
            minHeight: "63vh",
          }}
          border={1}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={12}
              display="flex"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              <SelectTokenElement />
            </Grid>

            <Grid item xs={12} lg={12}>
              <Box sx={{ minHeight: "40vh" }}>
                <Box sx={{ mb: 4 }}>
                  <Grid container>
                    <Grid item lg={2}>
                      <Typography>Name:</Typography>
                    </Grid>
                    <Grid item lg={4}>
                      <Typography>Test Singh</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography>Phone Number:</Typography>
                    </Grid>
                    <Grid item lg={4}>
                      <Typography>123124124</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container border={1} textAlign="center">
                    <Grid item lg={1} borderRight={1}>
                      <Typography>S. No.</Typography>
                    </Grid>
                    <Grid item lg={6} borderRight={1}>
                      <Typography>Name</Typography>
                    </Grid>
                    <Grid item lg={5}>
                      <Typography>BP</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  {formValues.map((element, index) => (
                    <Grid container border={1} borderTop={0} key={index}>
                      <Grid
                        item
                        lg={1}
                        borderRight={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>{index + 1}</Typography>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        borderRight={1}
                        display="flex"
                        alignItems="center"
                        sx={{ pl: 1 }}
                      >
                        <Typography> {element.name}</Typography>
                      </Grid>
                      <Grid item lg={5}>
                        <BPElement />
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={12} display="flex" justifyContent="center">
              <ActionWithFreeTokenHC handleClickOpen={handleClickOpen} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RegisterBPContentHC;
