import { Box } from "@mui/material"
import React, { useState } from "react"
import { Fragment } from "react"
import PatientRegister from "../patientRegister/patientRegister"
import WaitingCard from "../components/waiting/WaitingCard"
import { BASE_URL } from "../../../assets/constantsFile"
import { getData } from "../../../services/api/getApiCalls"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"
import HeaderAshaWoker from "../header/headerAshaWoker"

const DashboardAshaWorker = () => {
  const location = useLocation()
  const currentPath = location.pathname
  const [dateValue, setDateValue] = useState(new Date())

  const navigate = useNavigate()

  const handleShowForm = () => {
    navigate("/patientRegisterAshaWorker")
  }
  const [patientList, setPatientList] = useState([])
  const fetchPatientList = async () => {
    const url = BASE_URL + "healthcamp/marketing/patient/v2/" + dateValue.toISOString().slice(0, 10)
    const result = await getData(url)
    if (result && result.data) {
      console.log("Success GET", result.data)
      setPatientList(result.data)
    } else if (result && result.data) {
      console.log("Error GET", result.error)
    }
  }
  console.log({ LOCATION: currentPath })

  useEffect(() => {
    fetchPatientList()
  }, [dateValue, currentPath])

  // const formatDob = patient => {
  //   const options = { day: "numeric", month: "short", year: "numeric" }
  //   const date = new Date(patient.dob)
  //   return date.toLocaleDateString("en-GB", options)
  // }
  const formatDob = patient => {
    const options = { day: "numeric", month: "numeric", year: "numeric" }
    const date = new Date(patient.dob)
    return date.toLocaleDateString("en-GB", options)
  }

  const data = patientList.map(patient => ({
    allData: patient,
    userIcon: patient.imageUrl,
    name: patient.fullName,
    age: patient.age,
    gender: patient.gender,
    dob: formatDob(patient),
    mobile: patient.mobile,
  }))

  console.log(data)

  return (
    <Fragment>
      <Box sx={{ padding: "10px", marginTop: "10px" }}>
        <Fragment>
          <HeaderAshaWoker
            setDateValue={setDateValue}
            dateValue={dateValue}
            handleShowForm={handleShowForm}
          />
          {data.map((item, index) => (
            <WaitingCard item={item} key={index} />
          ))}
        </Fragment>
      </Box>
    </Fragment>
  )
}

export default DashboardAshaWorker
