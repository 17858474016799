import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import { Container, ThemeProvider } from "@mui/material";
import CorpTheme from "../common/themes/corpTheme";
import CorpManagePermissionsMain from "../corporate/corpManagePermissions/corpManagePermissionsMain";

const CorpPermissionDashboardIndex = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <Container maxWidth={false} sx={{ mt: 1 }} disableGutters>
        <ThemeProvider theme={CorpTheme}>
          <CorpManagePermissionsMain />
        </ThemeProvider>
      </Container>
    </Fragment>
  );
};

export default CorpPermissionDashboardIndex;
