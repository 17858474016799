import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { IconButton, InputAdornment, Snackbar, Stack } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Imge from "../assets/images/medical.png";
import BackImge from "../assets/images/ua_back.png";
import { ThemeProvider } from "@emotion/react";
import customTheme from "../assets/themes/customTheme";
import ClinicDashboard from "../pages/dashboards/clinicDashboard";
import {
  BASE_URL_AUTH,
  userList,
  userListDetailed,
} from "../assets/constantsFile";
import jwt_decode from "jwt-decode";
import { getUserToken, getUserTokenByMobile } from "../services/api/apiCalls";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginComponent = (props) => {
  const [severity, setSeverity] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [openNotice, setOpenNotice] = React.useState(false);

  localStorage.setItem("ROLE", "");
  localStorage.setItem("isAdminUser_local", "");
  localStorage.setItem("isClinicUser_local", "");
  localStorage.setItem("isLabUser_local", "");
  localStorage.setItem("isPharmacyUser_local", "");
  localStorage.setItem("authHeader_local", "");
  localStorage.setItem("loginUserData_local", "");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  let role = "";
  let fffSDa = null;
  let navigate = useNavigate();
  const fetchDoctorDataHandler = React.useCallback(
    async (username, password, role, url) => {
      const user = await getUserToken(username, password, role, url);
      if (user.error) {
        console.log({ "auth data error": "error" });
        //setState({ error: user.error });
      } else {
        //setState({ data: user.data });
        const data = await user.data;

        var token = await data.token;
        // contextData.authHeader = token;
        var dData = await jwt_decode(token);
        // contextData.val = dData;

        localStorage.setItem("authHeader_local", token);
        role = await dData.role;

        localStorage.setItem("ROLE", role);
        localStorage.setItem("BRANCH_ID", dData.userID);
        localStorage.setItem("BRANCH_ID_UNIQUE", dData.userID);
        localStorage.setItem("CLINIC_ID", dData.clinicId);
        localStorage.setItem("LAB_ID", dData.labId);
        localStorage.setItem("PHARMACY_ID", dData.pharmacyId);

        console.log({ "auth data success": dData });
      }
      return dData;
    },
    []
  );

  const myDta = async (a, b, c, d) => {
    fffSDa = await fetchDoctorDataHandler(a, b, c, d);
  };

  const [helperTxtUser, setHelperTxtUser] = React.useState("");
  const [helperTxtPass, setHelperTxtPass] = React.useState("");
  const [helperTxtLogin, setHelperTxtLogin] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userName = data.get("userName");
    const password = data.get("password");

    if (userName === "") {
      setHelperTxtUser("Enter user name.");
    } else {
      setHelperTxtUser("");
    }
    if (password === "") {
      setHelperTxtPass("Enter password.");
    } else {
      setHelperTxtPass("");
    }

    await myDta(userName, password);

    if (fffSDa && role !== "DOCTOR" && userName === fffSDa.sub) {
      role = fffSDa.role;
      switch (role) {
        case "ADMIN":
          localStorage.setItem("isAdminUser_local", "ADMIN");
          navigate("/clinicDashboard");
          break;
        case "CLINIC":
          localStorage.setItem("isClinicUser_local", "CLINIC");
          localStorage.setItem("isLabUser_local", "");
          localStorage.setItem("isPharmacyUser_local", "");

          navigate("/clinicDashboard");
          break;
        case "LAB":
          localStorage.setItem("isLabUser_local", "LAB");
          localStorage.setItem("isClinicUser_local", "");
          localStorage.setItem("isPharmacyUser_local", "");

          navigate("/labDashboard");
          break;
        case "PHARMACY":
          localStorage.setItem("isPharmacyUser_local", "PHARMACY");
          localStorage.setItem("isClinicUser_local", "");
          localStorage.setItem("isLabUser_local", "");

          navigate("/pharmacyDashboard");
          break;

        default:
          localStorage.setItem("isAdminUser_local", "ADMIN");
          navigate("/clinicDashboard");
      }

      localStorage.setItem("loginUserData_local", fffSDa);

      setSeverity("success");
      setMessage("Login successfull");
      setOpenNotice(true);
    } else {
      setHelperTxtUser("");
      setHelperTxtPass("");
      setHelperTxtLogin("Login Failed");
      setSeverity("error");
      setMessage("Login failed! Enter Correct Credentials!");
      setOpenNotice(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  const [isSendOTP, setIsSendOTP] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  const getOTPHandler = async () => {
    if (mobileNumber) {
      console.log({ mobileNumber });
      setIsSendOTP(true);
      const url = BASE_URL_AUTH + "authenticate?sendOtp=true";

      const data = {
        username: mobileNumber,
        role: "ADMIN",
      };

      const otpData = await getUserTokenByMobile(url, data);

      if (otpData.error) {
        console.log({ otpData_error_otp: otpData.error });
        setSeverity("error");
        setMessage("OTP generation failed.");
        setOpenNotice(true);
      } else {
        console.log({ otpData_data_otp: otpData.data });
        setSeverity("success");
        setMessage("OTP Send to your registered mobile number");
        setOpenNotice(true);
      }
    }
  };

  const handleSubmitOTP = async (event, m, o) => {
    event.preventDefault();

    const userName = mobileNumber;
    const password = otp;
    const role = "ADMIN";

    const url = BASE_URL_AUTH + "authenticate?authOnOTP=true";

    const dataObj = {
      username: mobileNumber,
      password: otp,
      role: "ADMIN",
    };
    console.log({ dataObj: dataObj });

    if (userName && password) {
      await myDta(mobileNumber, otp, role, url);

      console.log({ useruseruseruser: fffSDa });
      if (fffSDa && fffSDa.role === "ADMIN" && userName === fffSDa.sub) {
        setHelperTxtUser("");
        setHelperTxtPass("");
        setHelperTxtLogin("Login successfull");
        localStorage.setItem("userMobile", fffSDa.sub);
        console.log({ uzerdata: fffSDa });

        setSeverity("success");
        setMessage("Login successfull");
        setOpenNotice(true);
        navigate("/clinicDashboard");
      } else {
        setHelperTxtUser("");
        setHelperTxtPass("");
        setHelperTxtLogin("Login Failed");
        setSeverity("error");
        setMessage("Login failed! Enter Correct Credentials!");
        setOpenNotice(true);
      }
    }
  };
  const [showOTPForm, setShowOTPForm] = useState(true);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100vw",
        minHeight: "89vh",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          minHeight: "89vh",
          overflow: "auto",
          backgroundImage: `url(${BackImge})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          mixBlendMode: "lighten",
          opacity: 0.3,
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          maxHeight: "89vh",
          overflow: "auto",
        }}
      >
        <Container component="main">
          <Box
            sx={{
              width: "100%",
              height: "84vh",
              mt: 2,
            }}
          >
            <Snackbar
              open={openNotice}
              autoHideDuration={6000}
              onClose={handleCloseNotice}
            >
              <Alert
                onClose={handleCloseNotice}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
            <Grid container>
              <Grid item lg={2}></Grid>
              <Grid item lg={8} xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "56vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#fff",
                    boxShadow: "0px 1px 9px #208F94",
                    borderRadius: "22px",
                    mt: "5rem",
                    px: 3,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} lg={5}>
                      <Box sx={{ background: "#fff" }}>
                        <img alt="login" src={Imge} height={355} width={297} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={7}>
                      <Box sx={{ background: "#fff" }}>
                        {showOTPForm === true ? (
                          <Grid container>
                            <Typography variant="h6" sx={{ mt: 5 }}>
                              SIGN-IN VIA EMAIL
                            </Typography>
                            <Box
                              component="form"
                              onSubmit={handleSubmit}
                              noValidate
                              sx={{ mt: 1 }}
                            >
                              <TextField
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                id="userName"
                                label="User Name"
                                name="userName"
                                autoComplete="userName"
                                helperText={helperTxtUser}
                                autoFocus
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccountCircleIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                helperText={helperTxtPass}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockIcon />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowPassword(!showPassword);
                                        }}
                                        onMouseDown={() => {
                                          setShowPassword(!showPassword);
                                        }}
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          value="remember"
                                          color="primary"
                                        />
                                      }
                                      label="Remember me"
                                    />
                                  </Grid>
                                  <Grid item xs={6} sx={{ mt: 1 }}>
                                    <Link href="#" underline="none">
                                      Forgot password?
                                    </Link>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                              >
                                Sign-in
                              </Button>

                              <Stack direction={"row"}>
                                <Typography>
                                  Don't have an account?&nbsp;
                                </Typography>
                                <Link href="#" underline="none">
                                  Sign-up
                                </Link>
                              </Stack>
                            </Box>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Typography variant="h6" sx={{ mt: 5, mb: 1 }}>
                              SIGN-IN VIA MOBILE
                            </Typography>

                            <Box
                              component="form"
                              onSubmit={(e) =>
                                handleSubmitOTP(e, mobileNumber, otp)
                              }
                              noValidate
                              sx={{ mt: 1 }}
                            >
                              <Box sx={{}}>
                                <Grid container rowSpacing={3}>
                                  <Grid item xs={12} lg={12}>
                                    <TextField
                                      variant="standard"
                                      fullWidth
                                      size="small"
                                      required
                                      id="mobileNumber"
                                      autoComplete="mobileNumber"
                                      name="mobileNumber"
                                      label="MOBILE NUMBER"
                                      helperText={""}
                                      value={mobileNumber || ""}
                                      onChange={(e) => {
                                        if (
                                          !isNaN(e.target.value) &&
                                          e.target.value.length < 11
                                        ) {
                                          setMobileNumber(e.target.value);
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <LocalPhoneIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <Stack
                                      direction="row"
                                      sx={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Button
                                        fullWidth
                                        onClick={getOTPHandler}
                                        variant="contained"
                                      >
                                        Send OTP
                                      </Button>

                                      <Button
                                        fullWidth
                                        size="small"
                                        onClick={getOTPHandler}
                                      >
                                        Resend
                                      </Button>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} lg={12}>
                                    <TextField
                                      variant="standard"
                                      size="small"
                                      required
                                      fullWidth
                                      autoComplete="new-password"
                                      name="otp"
                                      label="ENTER OTP"
                                      type={showOTP ? "text" : "password"}
                                      id="otp"
                                      helperText={helperTxtPass}
                                      value={otp || ""}
                                      onChange={(e) => {
                                        if (
                                          !isNaN(e.target.value) &&
                                          e.target.value.length < 7
                                        ) {
                                          setOtp(e.target.value);
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() => {
                                                setShowOTP(!showOTP);
                                              }}
                                              onMouseDown={() => {
                                                setShowOTP(!showOTP);
                                              }}
                                            >
                                              {showOTP ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box>
                                <Grid container>
                                  <Grid item xs={12} lg={12}>
                                    <Button
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      sx={{ my: 2 }}
                                    >
                                      Sign-in
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                    <Stack direction={"row"} sx={{ mx: 5, mb: 2 }}>
                      <Typography sx={{ my: 1.2 }}>Sign-In Using:</Typography>
                      <Button
                        variant="text"
                        sx={{
                          color: "#127DDD",
                          fontWeight: 600,
                          height: 45,
                        }}
                        onClick={() => {
                          showOTPForm === true
                            ? setShowOTPForm(false)
                            : setShowOTPForm(true);
                        }}
                      >
                        {showOTPForm === true ? "Mobile" : "Email"}
                      </Button>
                    </Stack>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginComponent;
