import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import CardImg3 from "../assets/images/svg/homeCard3.svg";
import CardImg2 from "../assets/images/svg/homeCard2.svg";
import CardImg1 from "../assets/images/svg/homeCard1.svg";

const FacilitiesOfferedComponent = () => {
    return (
  <Box sx={{ width: "100%", height: 500, pt: 4 }}>
  <Typography variant="h3" sx={{mb:3}}>Facilities Offered</Typography>

  <Grid container columnSpacing={2}>

    <Grid item lg={4}>
    <Box component="img"
      alt="card1"
      src={CardImg1}
      sx={{ 
        height:288,
        width:323,
        background: 'linear-gradient(90deg, #9DE7EB 0%, #FAFCFC 100%)',
        boxShadow: '0px 2px 6px 2px rgba(32, 143, 148, 0.5)',
        borderRadius: '10px',
      }}  
    />
<Typography variant="body2">Video Consultation</Typography>
    </Grid>

    <Grid item lg={4}>

    <Box component="img"
      alt="card1"
      src={CardImg2}
      sx={{ 
        height:288,
        width:323,
        background: 'linear-gradient(90deg, #9DE7EB 0%, #FAFCFC 100%)',
        boxShadow: '0px 2px 6px 2px rgba(32, 143, 148, 0.5)',
        borderRadius: '10px',
      }}  
    />
   <Typography variant="body2">Lab Tests</Typography>
    </Grid>

    <Grid item lg={4}>

    <Box component="img"
      alt="card1"
      src={CardImg3}
      sx={{ 
        height:288,
        width:323,
        background: 'linear-gradient(90deg, #9DE7EB 0%, #FAFCFC 100%)',
        boxShadow: '0px 2px 6px 2px rgba(32, 143, 148, 0.5)',
        borderRadius: '10px',
      }}  
    />
<Typography variant="body2">Medicines</Typography>
    </Grid>

  </Grid>
</Box>


    );
}
 
export default FacilitiesOfferedComponent;