import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadIcon from "@mui/icons-material/Upload";
import VitalForm from "../uploadVitals/vitalForm";
import ReportForm from "../uploadReports/reportForm";
import UploadFile from "../uploadReports/uploadFile";
import CloseIcon from "@mui/icons-material/Close";

const ReportComponent = (props) => {
  console.log({ "props.pDetails": props.pDetails });

  const [reports, setReports] = useState("");

  useEffect(() => {
    if (props.pDetails.reports) {
      setReports(props.pDetails.reports);
    }
  }, []);

  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const saveReportHandler = (data) => {
    handleClose();

    if (data === "CANCEL") {
    } else {
      if (data.error) {
      } else {
        setReports(data.data);
      }
    }
    console.log({ savedvitalsdata_uploadVitals: data });
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<UploadIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>

      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="Reports"
        aria-describedby="report form"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" display="flex" justifyContent="space-between">
            <Fragment>
              {props.pDetails.patientName}
              {"'s Reports"}
            </Fragment>

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <ReportForm
            onAddReports={saveReportHandler}
            pDetails={props.pDetails}
            reports={reports}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ReportComponent;
