import axios from "axios"
import { Resolver } from "../resolver/resolver"

export async function updateData(URL, OBJ, TOKEN) {
  let authHeader_local = localStorage.getItem("authHeader_local")
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  }

  return await Resolver(axios.patch(URL, OBJ, { headers }).then(res => res.data))
}

export async function updateDataNew(URL, OBJ) {
  let authHeader_local = localStorage.getItem("authHeader_local")
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  }

  return await Resolver(axios.patch(URL, OBJ, { headers }).then(res => res.data))
}
