import {
  Box,
  Button,
  Grid,
  Paper,
  Portal,
  Stack,
  Typography,
  Modal,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import { saveData } from "../../services/api/postApiCalls";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import StethoIndex from "../../components/stethoStream/stethoIndex";

const DooctorInfoSection = ({
  docDetails,
  patient,
  toggleCaller,
  setToggleCaller,
  setOpenStetho,
}) => {
  let doctorDetails = docDetails;
  let doctorId = doctorDetails?.docId;
  let appointmentId = doctorDetails?.appointmentId;

  const [docDetail, setDocDetail] = useState("");
  const getDocInfo = async (dId) => {
    const user = await getData(BASE_URL + "doctor/" + dId);

    if (user.error) {
    } else {
      const data = user.data;
      setDocDetail(data);
    }
  };

  useEffect(() => {
    getDocInfo(doctorId);
  }, [docDetails]);

  const endCall = () => {
    handleClosePopUp();
    setTimeout(() => {
      navigate("/clinicDashboard");
    }, 2000);
  };

  const navigate = useNavigate();

  const markJoinedCall = async () => {
    const url = BASE_URL + "clinic/markPatientJoinedCall";
    const obj = {
      appointmentId: appointmentId,
      joinedCall: false,
    };
    const data = await saveData(url, obj);

    if (data.error) {
    } else {
      console.log("success");
      console.log(appointmentId);
    }
  };

  window.addEventListener("beforeunload", markJoinedCall);
  console.log({ appointmentId: appointmentId });

  const [formValues, setFormValues] = useState({
    admission: false,
    surgery: false,
    personalVisit: false,
    imaging: false,
    labTest: false,
    medicines: false,
    criticalityHigh: false,
  });

  console.log("formValues:", formValues);

  const fetchData = async () => {
    const url = BASE_URL + "clinic/getEndConsultationParams/" + appointmentId;
    const data = await getData(url);
    const fetchedData = data.data;
    if (data.error) {
      console.log("Error");
    } else {
      console.log("fetchedData:", fetchedData);
      console.log(fetchedData);

      //setFormValues(fetchedData);

      setFormValues({
        ...formValues,
        appointmentId: fetchedData.appointmentId,
        labTest: fetchedData.labTest,
        medicines: fetchedData.medicines,
      });
    }
  };

  const saveRequirementData = async (e) => {
    e.preventDefault();
    const url = BASE_URL + "clinic/markEndConsultationParams";

    const empData = await saveData(url, formValues);
    if (empData.error) {
      console.log({ error: empData.error });
      alert("Error Sending Data");
    } else {
      console.log({ success: empData.data });
      endCall();
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 40,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
      color: "#FFFFFF",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const CustomIOSSwitch = ({
    title,
    property,
    formValues,
    setFormValues,
    disabled,
    defaultChecked,
  }) => {
    return (
      <Stack direction="row">
        <FormControlLabel
          disabled={disabled}
          defaultChecked={defaultChecked}
          control={
            <IOSSwitch
              sx={{ ml: 2 }}
              checked={formValues[property] || false}
              onChange={(e) => {
                let newFormValues = { ...formValues };
                newFormValues[property] = e.target.checked;
                setFormValues(newFormValues);
              }}
            />
          }
        />
        <Typography>{title}</Typography>
      </Stack>
    );
  };

  const [openPopUp, setOpenPopUp] = useState(false);
  const handleOpenPopUp = () => {
    fetchData();
    setOpenPopUp(true);
  };
  const handleClosePopUp = () => setOpenPopUp(false);

  return (
    <Fragment>
      <Portal>
        <Modal
          open={openPopUp}
          onClose={handleClosePopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Grid container>
            <Grid
              item
              lg={12}
              md={3}
              sm={6}
              xs={10}
              mx={5}
              display="flex"
              justifyContent="flex-end">
              <Box
                sx={{
                  position: "absolute",
                  //top: "-40%",
                  //left: "85%",
                  transform: "translate(0%, 40%)",
                  width: 280,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 5,
                }}>
                <Grid item m={2}>
                  <form onSubmit={saveRequirementData}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2">
                      Addtional Requirements
                    </Typography>
                    <Stack direction="column" my={3} spacing={2}>
                      <CustomIOSSwitch
                        disabled={false}
                        title="Admission Required"
                        property="admission"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={false}
                        title="Surgery Required"
                        property="surgery"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={false}
                        title="Personal Visit Required"
                        property="personalVisit"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={false}
                        title="Imaging Required"
                        property="imaging"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={false}
                        title="Criticality High"
                        property="criticalityHigh"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={true}
                        title="Lab Test Required (checked by system)"
                        property="labTest"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                      <CustomIOSSwitch
                        disabled={true}
                        title={"Medicine Rquired (checked by system)"}
                        property="medicines"
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />
                    </Stack>

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: 3,
                        ml: 10,
                      }}>
                      Submit
                    </Button>
                  </form>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      </Portal>
      <Box sx={{ minHeight: "5vh", background: "#208F94" }}>
        <Paper
          sx={{ minHeight: "5vh", p: 1, background: "#208F94" }}
          elevation={6}>
          <Grid container>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Stack direction={"row"}>
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  {docDetail.fullName}
                </Typography>
                <Typography sx={{ color: "#fff" }}>
                  ,&nbsp;{docDetail.specialization}
                </Typography>
                <Typography sx={{ color: "#fff" }}>
                  ,&nbsp;{docDetail.rmpNo}
                </Typography>
              </Stack>
            </Grid>

            <Grid
              item
              lg={1}
              md={1}
              sm={1}
              xs={12}
              sx={{ borderLeft: "1px solid white" }}></Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
              display="flex"
              justifyContent={"flex-start"}>
              <Stack direction="row">
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  Patient Name:&nbsp;
                </Typography>
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  {patient.patientName + ","}&nbsp;
                </Typography>
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  {patient.age + ","}&nbsp;
                </Typography>
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  {patient.gender}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
              display="flex"
              justifyContent={"flex-end"}>
              <Stack direction={"row"} spacing={2}>
                <StethoIndex
                  setOpenStetho={setOpenStetho}
                  appointmentId={appointmentId}
                />

                <Button
                  onClick={markJoinedCall}
                  variant="outlined"
                  startIcon={<NotificationsActiveIcon />}
                  sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}></Button>
                <Button
                  color="success"
                  variant="contained"
                  href="/clinicDashboard">
                  Dashboard
                </Button>
                <Button
                  sx={{ width: 200 }}
                  color="error"
                  variant="contained"
                  //href="/clinicDashboard"
                  onClick={handleOpenPopUp}>
                  mark as attended
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default DooctorInfoSection;
