import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import SearchIcon from "@mui/icons-material/Search";

const SearchPatientByNameComp = (props) => {
  const [pName, setPName] = useState("");
  const getPatientDataByName = async (e) => {
    e.preventDefault();
    if (pName) {
      const user = await getData(BASE_URL + "patient/search?query=" + pName);

      const data1 = await user.data;
      data1 ? props.onSearchPatient(data1) : props.onSearchPatient([]);
    } else {
      props.onSearchPatient([]);
    }
  };
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ minHeight: "5vh", background: "#D9D9D9" }}>
          <form onSubmit={getPatientDataByName}>
            <Stack direction={"row"}>
              <TextField
                size="small"
                fullWidth
                label="Search patient..."
                id="fullWidth2"
                value={pName}
                onChange={(e) => setPName(e.target.value)}
              />
              <Button
                variant="outlined"
                type="submit"
                startIcon={<SearchIcon />}
                sx={{ minWidth: 32, p: 0, pl: 1, minHeight: 32 }}
              ></Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchPatientByNameComp;
