import { Box, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { getCurrentDateFormatted } from "../../assets/utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';

const PrintPrescriptionComponent = (props) => {

const [docDetail, setDocDetail] = useState("");
  let patientDetails = ""; 
  let doctorId = "";
  let doctorName = "";
  let patientInfo = "";
  let vitalInfo = {bp:""};
  let observationInfo = "";
  let reportList = [];
  let prescriptionList = [];

if(props.patientDetails){

  patientDetails = props.patientDetails;
  doctorId = patientDetails.prescriptionVM.docId;
 
  patientInfo = {
    
    patientId:patientDetails.patientId,
    name:patientDetails.patientName,
    age:patientDetails.age,
    sex:patientDetails.gender,

  }

  let prescriptionVM = patientDetails.prescriptionVM;

  if(prescriptionVM) {
    prescriptionList = prescriptionVM.medicines;
  }
}

const getDocInfo = async(dId) => {

const user = await getData(BASE_URL+ "doctor/"+dId);

if(user.error){

} else {
  const data = user.data;
  setDocDetail(data);

}

} 

useEffect(() => {
  getDocInfo(doctorId);

}, [doctorId])


console.log({"docDetail":docDetail})


  return (
    <Container maxWidth={false} sx={{mr:.5, ml:1}}>
      <Box
        sx={{
          width:"100%",
          mt: 2,
          filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
        }}
      >
        <Grid container>
        <Grid item lg={12} >
            <Box
              sx={{  minHeight: "20vh" }}
            >


              <Grid container rowSpacing={4}>


              <Grid item lg={12} xs={12} md={12} sm={12}>
                  <Paper
                    component={Stack}
                    direction="row"
                    elevation={0}
                    sx={{ width: 200, p:2 }}
                  >
                    <CalendarMonthIcon />
                    <Typography variant={"body2"} sx={{ mt: .5, ml:1}}>
                      {" "}
                      {getCurrentDateFormatted(" ")}{" "}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12}>
                <Paper sx={{ p:2, minHeight:"10vh" }}>
                <Grid container>

                <Grid item lg={4} xs={4} md={4} sm={4}>
                <Stack >
                <Typography variant="body2" sx={{fontWeight:500}}> Dr. { (docDetail.firstName ? docDetail.firstName : "") + (docDetail.middleName ? docDetail.middleName : "") + (docDetail.lastName ? docDetail.lastName : "") } ,{ docDetail.degree}</Typography>
                <Typography variant="caption" sx={{fontSize:'.75rem'}}>{docDetail.department}</Typography>
                <Typography variant="caption" sx={{fontSize:'.75rem'}}>Specialized in {docDetail.specialization} </Typography>
                </Stack>
                </Grid>


                <Grid item lg={4} xs={4} md={4} sm={4} display="flex" justifyContent={"center"} >
                <Stack >
                        <img src="clinic/EC_Logo11.png" alt="logo" height={'80'} width={'130'} sx={{pt:2,flexGrow: 1}}/>
                        </Stack>
                </Grid>

                <Grid item lg={4} xs={4} md={4} sm={4} display="flex" justifyContent={"flex-end"} >
                <Stack>
                        <Typography variant="caption" sx={{fontSize:'.75rem'}}> {docDetail.mobile} </Typography>
                        <Typography variant="caption" sx={{fontSize:'.75rem'}}> {docDetail.email} </Typography>
                        <Typography variant="caption" sx={{fontSize:'.75rem'}}> {docDetail.unit} </Typography>
                        </Stack>
                </Grid>


               

                </Grid>
                <Grid container>
                <Grid item lg={12} xs={12} md={12} sm={12} display="flex" justifyContent={"center"} >

                <Typography variant="h6" sx={{ fontWeight:500, m:3 }}>PATIENT INFORMATION</Typography>


                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12}>

                <Stack direction={"row"}>
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}>NAME: </Typography>
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}> {patientInfo.name} </Typography>
                </Stack>

                </Grid>
                </Grid>
                <Grid container>

               
                <Grid item lg={12} xs={12} md={12} sm={12}>

                <Stack direction={"row"} justifyContent={"space-between"}>

                <Stack direction={"row"} >

                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}>AGE: </Typography>
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}> {patientInfo.age} </Typography>

                </Stack>
                <Stack direction={"row"} >

                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}>SEX: </Typography>
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}> {patientInfo.sex} </Typography>

                </Stack>
                <Stack direction={"row"} >

                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}>ISSUE: </Typography>  
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', }}> {patientInfo.issue} </Typography>

                </Stack>



                </Stack>

                <Divider />
                
                </Grid>


                </Grid>
                <Grid container>
                <Grid item lg={12} xs={12} md={12} sm={12} display="flex" justifyContent={"center"} >


                <Typography variant="h6" sx={{ fontWeight:500, m:3 }}>VITALS</Typography>
                </Grid>
                </Grid>
                <Grid container>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>BP: </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.bp} mmHg</Paper>
                      </Stack>
                </Grid>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>WEIGHT: </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.weight} kg</Paper>
                      </Stack>
                </Grid>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>HEIGHT: </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.height} cm</Paper>
                      </Stack>
                </Grid>

                </Grid>
                <Grid container sx={{mt:2}}>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>HR: </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.heartRate} bpm</Paper>
                      </Stack>
                </Grid>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>SpO2:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.spo2} %</Paper>
                      </Stack>
                </Grid>

                <Grid item lg={4} xs={4} md={4} sm={4} >

                <Stack direction={'row'} alignItems="center"> <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', mr:2}}>TEMP:&nbsp;&nbsp;&nbsp; </Typography><Paper sx={{border: '1px solid #000000', height:30, width:120, display:"flex", justifyContent:"center" }}>{vitalInfo.temperature} F</Paper>
                      </Stack>
                </Grid>

                </Grid>



                </Paper>

                </Grid>



                <Grid item lg={12} xs={12} md={12} sm={12}>

                <Grid container>
        <Grid item lg={12} xs={12} md={12} sm={12} display="flex" justifyContent={"center"} >


                <Typography variant="h6" sx={{ fontWeight:500, m:3 }}>OBSERVATIONS</Typography>
                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12} >

                <Paper sx={{ minHeight:50,p:2 ,}}>
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem', whiteSpace:"pre-line" }}>  {observationInfo} </Typography>
                </Paper>
                </Grid>
        </Grid>
                </Grid>


                <Grid item lg={12} xs={12} md={12} sm={12}>

                <Grid container>
        <Grid item lg={12} xs={12} md={12} sm={12} display="flex" justifyContent={"center"} >


                <Typography variant="h6" sx={{ fontWeight:500, m:3 }}>PRESCRIBED TESTS</Typography>
                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12} >

                <Paper sx={{ minHeight:200,p:2 ,}}>

                <Grid container>

                <Grid item lg={6} xs={6} md={6} sm={6} >
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>TEST NAME</Typography>

                </Grid>
            
                <Grid item lg={6} xs={6} md={6} sm={6} >
                <Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>TEST PRESCRIBED DATE</Typography>

                </Grid>
        

                </Grid>


{reportList.map((report, index) => (

  <Grid container key={index}>

<Grid item lg={6} xs={6} md={6} sm={6} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>{report.reportName}</Typography>

</Grid>

<Grid item lg={6} xs={6} md={6} sm={6} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>{new Date(report.prescribedDate).toISOString().split("T")[0]}</Typography>

</Grid>



</Grid>
))}



                </Paper>
                </Grid>
        </Grid>
                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12}>

                <Grid container>
        <Grid item lg={12} xs={12} md={12} sm={12} display="flex" justifyContent={"center"} >


                <Typography variant="h6" sx={{ fontWeight:500, m:3 }}>PRESCRIBED MEDICINES</Typography>
                </Grid>

                <Grid item lg={12} xs={12} md={12} sm={12} >

                <Paper sx={{ minHeight:200,p:2 ,}}>
              
                <Grid container sx={{mb:2}}>

                <Grid item lg={7} xs={7} md={7} sm={7} display="flex" justifyContent={"flex-start"} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>MEDICINE NAME</Typography>

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>DOSE</Typography>

</Grid>
<Grid item lg={2} xs={2} md={2} sm={2} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>FREQUENCY</Typography>

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>SOS</Typography>

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>DURATION</Typography>

</Grid>


</Grid>


         {prescriptionList.map( (pres, index) => (
          <Grid container key={index}>

          <Grid item lg={7} xs={7} md={7} sm={7} display="flex" justifyContent={"flex-start"} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}> {pres.medicineName} </Typography>

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}> {pres.dosage} </Typography>

</Grid>
<Grid item lg={2} xs={2} md={2} sm={2} >

{pres.m ? "1-" : "X-"}
{pres.a ? "1-" : "X-"}
{pres.e ? "1-" : "X-"}
{pres.n ? "1" : "X"}

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}>  {pres.isSOS ? <CheckIcon color="primary" fontSize="small" /> : ""}  </Typography>

</Grid>
<Grid item lg={1} xs={1} md={1} sm={1} >
<Typography variant="body2" sx={{ fontWeight:400,fontSize:'.85rem'}}> {pres.duration} </Typography>

</Grid>


</Grid>

         ))}     






                </Paper>
                </Grid>
        </Grid>
                </Grid>


              </Grid>



            </Box>
          </Grid>
        </Grid>

       
        

      </Box>
    </Container>
  );
};

export default PrintPrescriptionComponent;


