import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import customTheme from "../../assets/themes/customTheme";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApiCalls";
import VideoCallMain from "../videoCall/videoCallMain";
import { useNavigate } from "react-router-dom";

const GoToVideoCallComponent = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const agreementHandler = () => {
    setIsLoading(true);
    startVideoCall();
  };
  const navigate = useNavigate();
  let startVideoCall = () => {
    markJoinedCall();
  };

  const markJoinedCall = async () => {
    const url = BASE_URL + "clinic/markPatientJoinedCall";
    const obj = {
      appointmentId: props.calls.appointmentId,
      joinedCall: true,
    };
    const data = await saveData(url, obj);

    if (data.error) {
    } else {
      console.log("success");
      console.log(props.calls.appointmentId);
      handleClose();
      setIsLoading(false);
      navigate("/call", {
        state: {
          calls: props.calls,
        },
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        startIcon={<VideoCallIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please get consent letter signed."}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <DialogContentText id="alert-dialog-description">
              If already signed click agree.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={agreementHandler} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default GoToVideoCallComponent;
