import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

const CorpPackageReadonly = ({ formValues, setFormValues, setOpen }) => {
  return (
    <Box>
      <Stack direction="row">
        <TextField
          value={formValues?.packageName || ""}
          //disabled
          size="small"
          fullWidth
          label="Package"
          onChange={(e) => {
            setFormValues({ ...formValues, packageName: e.target.value });
          }}
        />
        {/* <Button onClick={() => setOpen(true)}>
          <EditIcon />
        </Button> */}
      </Stack>
    </Box>
  );
};

export default CorpPackageReadonly;
