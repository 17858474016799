import { Box, Grid, Stack, Typography } from "@mui/material"
import LogoMain from "../logo/logoMain"
import phoneLogo from "../../../assets/images/telephone.png"

const PrintHeader = () => {
 return (
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={12}>
          <LogoMain />
          <Stack direction="row" spacing={2} my={2}>
            <img src={phoneLogo} sx={{ width: "25px", height: "25px" }} />
            <Typography sx={{ color: "#404040" }}>+91-7869873802</Typography>
            <Typography sx={{ color: "#404040" }}>support@uno.care</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PrintHeader
