import { IconButton } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Fragment, useState } from "react";
import axios from 'axios';
import React from 'react';
import { BASE_URL } from "../../assets/constantsFile";

const UploadFile = (props) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append('fileName', selectedFile.name);

        uploadFile(formData);

        console.log({"formData_upload":formData });
        
      }

      const uploadFile = async(formData) => {

        try {
            const response = await axios({
              method: "post",
              url: BASE_URL+"lab/tests/upload?reportId=721",
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            });
            console.log({"formData_upload_2":response.data });
  
          } catch(error) {
            console.log(error)
          }
      }


    const handleFileSelect = (event) => {
        console.log({"event":event});
        setSelectedFile(event.target.files[0])
      }

      console.log({"selectedFile_upload":selectedFile });
    return (
        <Fragment>

        <form onSubmit={handleSubmit}>
        <IconButton color="primary" component="label" onChange={handleFileSelect}>
            <input type="file" accept="/*" hidden />
            <AttachFileIcon fontSize="medium" />
        </IconButton>
            <input type="file" onChange={handleFileSelect}/>
            <input type="submit" value="Upload File" />
            </form>

        <IconButton color="primary" component="label">
            <input type="file" accept="/*" hidden />
            <AttachFileIcon fontSize="medium" />
        </IconButton>
        </Fragment>
    
    );
}
 
export default UploadFile;