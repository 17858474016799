import React, { Fragment } from "react";

import HomeCorpMain from "../module/home/homeCorpMain";
import NavbarCorp from "../components/NavbarCorp";

const CorpHomeIndex = () => {
  return (
    <Fragment>
      <NavbarCorp />
      <HomeCorpMain />
    </Fragment>
  );
};

export default CorpHomeIndex;
