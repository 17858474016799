import {
  Avatar,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback, useEffect, useState } from "react";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getCurrentDateFormatted, getDelay } from "../assets/utils";
import PatientListClinicUpcoming from "./patientListClinicUpcoming";
import PatientListClinicAttended from "./patientListClinicAttended";
import { getData } from "../services/api/getApiCalls";
import {
  BASE_URL,
  PRIMARY_CUSTOM,
  WHITE_CUSTOM,
} from "../assets/constantsFile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import PatientListClinicCancelled from "./patientListClinicCancelled";

const TableContentClinic = (props) => {
  const [value, setValue] = useState("1");
  const [dateValue, setDateValue] = useState(new Date());
  const [flag, setFlag] = useState(true);

  const tabClick = (event) => {
    setFlag(!flag);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [patients, setPatients] = useState([]);
  const [state, setState] = useState();

  const fetchPataientsHandler = useCallback(async (date) => {
    const user = await getData(
      BASE_URL +
        "clinic/appointment/" +
        localStorage.getItem("CLINIC_ID") +
        "?date=" +
        date
    );
    if (user.error) {
      setState({ error: user.error });
    } else {
      setState({ data: user.data });

      const data = user.data;
      console.log({ clinic_dashborad_patientList_data: data });
      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          appointmentStatus: patientData.appointmentStatus,
          edit: {
            docId: patientData.docId,
            startTime: patientData.startTime,
            endTime: patientData.endTime,
            date: patientData.date,
            appointmentId: patientData.appointmentId,
            slotNo: patientData.slotNo,
            patientId: patientData.patientId,
            patientName: patientData.patientName,
            caseId: patientData.caseId,
            symptom: patientData.symptom,
            doctorSpeciality: patientData.doctorSpeciality,
            doctorFullName: patientData.doctorFullName,
          },
          cancel: {
            docId: patientData.docId,
            startTime: patientData.startTime,
            endTime: patientData.endTime,
            date: patientData.date,
            appointmentId: patientData.appointmentId,
            slotNo: patientData.slotNo,
            patientId: patientData.patientId,
            patientName: patientData.patientName,
            caseId: patientData.caseId,
          },
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,
          patientName: patientData.patientName,
          mobile: patientData.mobile,

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age:
              (patientData.patientInfoVM.age
                ? patientData.patientInfoVM.age + "Y "
                : "") +
              (patientData.patientInfoVM.ageMonths
                ? patientData.patientInfoVM.ageMonths + "M"
                : ""),
          },
          startTime: patientData.startTime,
          endTime: patientData.endTime,
          delayTime: getDelay(patientData.startTime),

          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,

          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,
          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,
          gender: patientData.gender,
          email: patientData.email,
          doctorFullName: patientData.doctorFullName,
          countryCode: patientData.countryCode,
          age:
            (patientData.patientInfoVM.age
              ? patientData.patientInfoVM.age + "Y "
              : "") +
            (patientData.patientInfoVM.ageMonths
              ? patientData.patientInfoVM.ageMonths + "M"
              : ""),
          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,
          vitals: {
            patientId: patientData.patientId,
            appointmentId: patientData.appointmentId,
            vitals: patientData.vitals,
            patientName: patientData.patientName,
            caseId: patientData.caseId,
          },
          medicalHistory: {
            patientId: patientData.patientId,
            appointmentId: patientData.appointmentId,
            patientInfoVM: patientData.patientInfoVM,
            patientName: patientData.patientName,
            caseId: patientData.caseId,
          },
          call: {
            patientId: patientData.patientId,
            appointmentId: patientData.appointmentId,
            videoURL: patientData.videoURL,
            patientName: patientData.patientName,
            guestURLToken: patientData.guestURLToken,
            hostURLToken: patientData.hostURLToken,

            age:
              (patientData.patientInfoVM.age
                ? patientData.patientInfoVM.age + "Y "
                : "") +
              (patientData.patientInfoVM.ageMonths
                ? patientData.patientInfoVM.ageMonths + "M"
                : ""),
            gender: patientData.gender,
            docDetails: {
              docId: patientData.docId,
              doctorName: patientData.doctorName,
              doctorSpeciality: patientData.doctorSpeciality,
              appointmentId: patientData.appointmentId,
            },
          },
          remind: {
            appointmentId: patientData.appointmentId,
          },
          sendLink: {
            appointmentId: patientData.appointmentId,
            videoURL: patientData.videoURL,
            mobile: patientData.mobile,
            doctorName: patientData.doctorFullName,
          },
          prescriptionVM: patientData.prescriptionVM,
          printPrescription: patientData,
          reports: {
            patientId: patientData.patientId,
            appointmentId: patientData.appointmentId,
            reports: patientData.reports,
            docId: patientData.docId,
            patientName: patientData.patientName,
            caseId: patientData.caseId,
          },
          paymentVM: {
            paymentVM: patientData.paymentVM,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            docId: patientData.docId,
          },
          attended: patientData.attended,
          videoURL: patientData.videoURL,
          prescriptionUrl: patientData.prescriptionUrl,
        };
      });
      setPatients(transformedPatients);
    }
  }, []);

  useEffect(() => {
    fetchPataientsHandler(dateValue.toISOString().split("T")[0]);
  }, [dateValue, flag]);

  console.log({ clinic_dashborad_patientList: patients });

  //console.log(getCurrentDate())
  return (
    <Grid container spacing={0}>
      <Grid item lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                minHeight: "8vh",
                width: "100%",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} lg={10}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      onClick={tabClick}
                      label="Upcoming Patients"
                      value="1"
                      sx={{
                        width: 250,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                    <Tab
                      onClick={tabClick}
                      label="Attended Patients"
                      value="2"
                      sx={{
                        width: 250,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        ml: 2,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                    <Tab
                      onClick={tabClick}
                      label="Cancelled Appointments"
                      value="3"
                      sx={{
                        width: 250,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        ml: 2,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                  </TabList>
                </Grid>

                <Grid xs={12} item lg={2}>
                  <Paper
                    elevation={0}
                    component={Stack}
                    justifyContent="flex-end"
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        openTo="day"
                        views={["year", "month", "day"]}
                        value={dateValue}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: "100%",
                background: "#fff",
                height: "75vh",
                borderRadius: 2,
              }}
            >
              <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
                <PatientListClinicUpcoming
                  patients={patients}
                  root={props.root}
                  tabClick={tabClick}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
                <PatientListClinicAttended
                  patients={patients}
                  root={props.root}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ p: 0, pt: 1 }}>
                <PatientListClinicCancelled
                  patients={patients}
                  root={props.root}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableContentClinic;
