import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const Issue = ({ symptom, setSymptoms }) => {
  console.log({ symptom });
  const [sympt, setSympt] = useState(symptom);

  return (
    <Fragment>
      <TextField
        size={"small"}
        fullWidth
        label="Issue Reported*"
        variant="outlined"
        value={sympt || ""}
        onChange={(e) => {
          setSymptoms(e.target.value);
          setSympt(e.target.value);
        }}
      />
    </Fragment>
  );
};

export default Issue;
