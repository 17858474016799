import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
import axios from "axios";
import { Fragment } from "react";
import { useFileUpload } from "use-file-upload";

const UploadDocuments = ({
  url,
  handleClose,
  formValues,
  setFormValues,
  property,
}) => {
  const handleSubmitNew = (name, size, source, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", name);
    uploadFile(formData);
  };

  const uploadFile = async (formData) => {
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authHeader_local"),
        },
      });

      const data = await response.data;
      //setFormValues({ id: 0 });
      //setFormValues(response.data);

      let newFormValues = { ...formValues };
      newFormValues[property] = response.data[property];
      setFormValues(newFormValues);

      console.log({ formData_upload_2: response.data });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const [files, selectFiles] = useFileUpload();
  return (
    <Fragment>
      <Button
        onClick={() =>
          selectFiles({ accept: "*" }, ({ name, size, source, file }) => {
            handleSubmitNew(name, size, source, file);
            console.log("Files Selected", {
              name,
              size,
              source,
              file,
            });
          })
        }
        variant="contained"
        startIcon={<AttachFileIcon sx={{ color: "#fff" }} />}
      >
        Upload from system
      </Button>
    </Fragment>
  );
};
export default UploadDocuments;
