import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import { Container, ThemeProvider } from "@mui/material";
import CorpRegistrationMain from "../corporate/corpRegistration/corpRegistrationMain";
import CorpTheme from "../common/themes/corpTheme";

const CorpRegistrationIndex = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <Container maxWidth={false} sx={{ mt: 1 }} disableGutters>
        <ThemeProvider theme={CorpTheme}>
          <CorpRegistrationMain />
        </ThemeProvider>
      </Container>
    </Fragment>
  );
};

export default CorpRegistrationIndex;
