import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import React, { Fragment } from "react"
import UploadIcon from "../../../../assets/images/UploadIcon.png"
import { useFileUpload } from "use-file-upload"
import { BASE_URL } from "../../../../assets/constantsFile"
import { uploadFile } from "../../../../services/api/postApiCalls"

const CustomTextFieldUpload = ({
  setFormValues,
  formValues,
  setMarketingPatientId,
  setImageUrl,
  item,
  patientId,
  date,
  caseId,
  appointmentId,
}) => {
  const handleUploadPhotograph = async (name, size, source, file) => {
    const formData = new FormData()
    formData.append("file", file)
    const url = BASE_URL + "healthcamp/marketing/upload/image/v2"
    const result = await uploadFile(url, formData)
    if (result && result.data) {
      console.log("SUCCESS UPLOADED FILE", result.data)
      setMarketingPatientId(result.data.id)
      setImageUrl(result.data.imageUrl)
    } else if (result && result.error) {
      console.log("ERROR UPLOAD DATA", result.error)
    }
  }
  const handleSubmit = async (name, size, source, file) => {
    if (item.page === "patientPhotograph") {
      await handleUploadPhotograph(name, size, source, file)
    }
  }

  const [files, selectFiles] = useFileUpload()

  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#6B6B6B",
          marginBlock: "5px",
        }}>
        {item.label}
      </Typography>

      <TextField
        // disabled={item.disabled}
        sx={{
          background: "#fff",
          color: "#000000",
          fontWeight: "300",
          fontSize: "13px",
          lineHeight: " 15px",
          "& input::placeholder": {
            color: "#777777",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
          },
        }}
        size="small"
        fullWidth
        placeholder={item.placeHolder}
        value={
          item.type === "upload" && formValues[item.property]
            ? formValues[item.property].filename
            : formValues[item.property] || ""
        }
        onChange={e => {
          let newFormValues = { ...formValues }
          newFormValues[item.property] = e.target.value
          setFormValues(newFormValues)
        }}
        onInput={e => {
          e.target.style.color = "#000000"
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() =>
                  selectFiles({ accept: "*" }, ({ name, size, source, file }) => {
                    handleSubmit(name, size, source, file)
                    console.log("Files Selected", {
                      name,
                      size,
                      source,
                      file,
                    })
                    const reader = new FileReader()
                    reader.onload = () => {
                      let newFormValues = { ...formValues }
                      newFormValues[item.property] = {
                        filename: file.name,
                        file: reader.result,
                      }
                      setFormValues(newFormValues)
                    }
                    reader.readAsDataURL(file)
                  })
                }>
                <Box
                  component="img"
                  src={UploadIcon}
                  style={{ width: 20, height: 20 }}
                  alt="Icon"
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Fragment>
  )
}

export default CustomTextFieldUpload
