import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const RespondentMobile = ({ formValues, setFormValues }) => {
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [userExists, setUserExists] = useState(false);
  useEffect(() => {
    const getData = setTimeout(() => {
      if (mobile?.length === 10) {
        getExistingUser(mobile);
      } else {
        setUserExists(false);
      }
    }, 1);

    return () => clearTimeout(getData);
  }, [mobile]);

  const getExistingUser = async (number) => {
    const url = BASE_URL + `healthcamp/marketing/mobile/${number}`;

    const userData = await getData(url);
    if (userData?.data) {
      console.log({ success: userData?.data });
      setUserExists(true);
      setName(userData?.data?.fullName);
    } else {
      console.log({ error: userData?.error });
      setUserExists(false);
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={2} display="flex" alignItems="center">
          <Typography>Mobile:</Typography>
        </Grid>
        <Grid item lg={8} xs={10}>
          <TextField
            size="small"
            fullWidth
            placeholder={"Contact Number"}
            value={formValues["mobile"] || ""}
            error={formValues["mobile"]?.length !== 10 || userExists}
            // helperText={
            //   formValues["mobile"]?.length !== 10 ? "Should be 10 digits" : ""
            // }
            helperText={
              userExists ? `Mobile already regisred for user ${name}` : ""
            }
            onChange={(e) => {
              if (!isNaN(e.target.value) && e.target.value.length < 11) {
                setUserExists(false);
                setFormValues({ ...formValues, mobile: e.target.value });
                setMobile(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default RespondentMobile;
