import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import PatientRegistrationMainComp from "../../patientRegistration/patientRegistrationMainComp";
import { registerNewPatientContext } from "../../common/context/usercontext";
import PatientRegistrationMain from "../../patientRegistrationNew/patientRegistrationMain";

const PatientRegistration = (props) => {
  return (
    <Fragment>
      <registerNewPatientContext.Provider
        value={{ root: props.root, patientList: [], caseList: [] }}
      >
        <MyAppBarAfterLogin />
        <PatientRegistrationMain />
        {/* <PatientRegistrationMainComp root={props.root} /> */}
      </registerNewPatientContext.Provider>
    </Fragment>
  );
};

export default PatientRegistration;
