import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { getFormattedDDMonthYYYY } from "../../../assets/utils";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const VitalAnalyticsMain = ({
  orgName = localStorage.getItem("ORGNAME"),
  corpId = localStorage.getItem("CORPID"),
}) => {
  const [vitalCount, setVitalCount] = useState({});

  const _fetchCount = async () => {
    const url = BASE_URL + `org/ahcCountOfImportantParams/${corpId}`;

    const counts = await getData(url);

    if (counts.error) {
      setVitalCount({});
    } else {
      setVitalCount(counts.data);
    }
  };

  useEffect(() => {
    _fetchCount();
  }, []);

  console.log({ vitalCount });
  return (
    <Fragment>
      {/* <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 1 }}>
        <Typography variant="h5">{orgName}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 1 }}>
        <Typography variant="h6">{`Total Employess: ${vitalCount?.totalEmployees}`}</Typography>
      </Box>

      <Box sx={{ pt: 5 }}>
        <Grid container display="flex" justifyContent="center" alignItems="center">
          <Grid item lg={2}>
            <Box>
              <Grid container display="flex" justifyContent="center" alignItems="center">
                <Grid item lg={12} height={50}>
                  <Typography>&nbsp;</Typography>
                </Grid>
                {[1].map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={50}>
                    <Typography variant="h6">{getFormattedDDMonthYYYY(new Date())}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          {[
            {
              title: "Height",
              value: vitalCount?.height,
            },
            {
              title: "Weight",
              value: vitalCount?.weight,
            },
            {
              title: "BP",
              value: vitalCount?.bp,
            },
            {
              title: "Sugar",
              value: vitalCount?.sugar,
            },
            {
              title: "Photo",
              value: vitalCount?.photo,
            },
            {
              title: "Blood Test",
              value: vitalCount?.bloodTestToggle,
            },
            {
              title: "ECG",
              value: vitalCount?.ecgToggle,
            },
          ].map((item, index) => (
            <Grid item xs key={index}>
              <Box>
                <Grid
                  container
                  columns={1}
                  spacing={0}
                  border={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  <Grid
                    item
                    lg={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderBottom={1}
                    height={50}>
                    <Typography variant="h6">{item?.title}</Typography>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={50}>
                    <Typography>{item?.value}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box> */}

      <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" mt={5}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {orgName}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
            }}>{`Total Employess: ${vitalCount?.totalEmployees}`}</Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
            }}>
            {getFormattedDDMonthYYYY(new Date())}
          </Typography>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="center" alignItems="center" mt={3}>
        {[
          {
            title: "Height",
            value: vitalCount?.height,
          },
          {
            title: "Weight",
            value: vitalCount?.weight,
          },
          {
            title: "BP",
            value: vitalCount?.bp,
          },
          {
            title: "Sugar",
            value: vitalCount?.sugar,
          },
          {
            title: "Photo",
            value: vitalCount?.photo,
          },
          {
            title: "Blood Test",
            value: vitalCount?.bloodTestToggle,
          },
          {
            title: "ECG",
            value: vitalCount?.ecgToggle,
          },
        ].map((item, index) => (
          <Grid item lg={1} xs={4} key={index}>
            <Box>
              <Grid
                container
                columns={1}
                spacing={0}
                border={1}
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Grid
                  item
                  lg={1}
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderBottom={1}
                  height={50}>
                  <Typography variant="h6">{item?.title}</Typography>
                </Grid>
                <Grid
                  item
                  lg={1}
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={50}>
                  <Typography>{item?.value}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default VitalAnalyticsMain;
