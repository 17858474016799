import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import TitleHC from "../common/title/titleHC";
import RegisterEyeSightContentHC from "./registerEyeSightContentHC";

const RegisterEyeSightHC = () => {
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TitleHC title={"Register Eye Sight"} />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={1}></Grid>
          <Grid item xs={12} sm={8} md={8} lg={10}>
            <RegisterEyeSightContentHC />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={1}></Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RegisterEyeSightHC;
