import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import SearchIcon from "@mui/icons-material/Search";

const SearchPatientByPhoneComp = (props) => {
  const [pMobileNo, setPMobileNo] = useState("");
  const getPatientDataByMobileNo = async (e) => {
    e.preventDefault();
    if (pMobileNo) {
      const user = await getData(
        BASE_URL + "patient/search?phoneNo=" + pMobileNo
      );

      const data1 = await user.data;
      data1 ? props.onSearchPatient(data1) : props.onSearchPatient([]);
    } else {
      props.onSearchPatient([]);
    }
  };

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ minHeight: "5vh", background: "#D9D9D9" }}>
          <form onSubmit={getPatientDataByMobileNo}>
            <Stack direction={"row"}>
              <TextField
                size="small"
                fullWidth
                label="Search by mobile number..."
                id="fullWidth3"
                value={pMobileNo}
                onChange={(e) => setPMobileNo(e.target.value)}
              />
              <Button
                variant="outlined"
                type="submit"
                startIcon={<SearchIcon />}
                sx={{ minWidth: 32, p: 0, pl: 1, minHeight: 32 }}
              ></Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchPatientByPhoneComp;
