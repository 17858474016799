import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const PhoneNumberElementOutlined = ({ mob, setMob }) => {
  const [focus, setFocus] = useState(false);
  return (
    <Fragment>
      <TextField
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label="Enter Phone Number"
        value={mob || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length < 11) {
            setMob(e.target.value);
          }
        }}
        onFocus={() => setFocus(true)}
        helperText={
          mob
            ? mob.length < 10
              ? "Phone must be 10 digits"
              : ""
            : "Phone no can not be empty."
        }
        error={focus && (mob ? (mob.length < 10 ? true : false) : true)}
      />
    </Fragment>
  );
};

export default PhoneNumberElementOutlined;
