import { Fragment, useState, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import HCTheme from "../HCTheme";
import { debounce } from "../../utils/debounce";
import { useLocation } from "react-router";

import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import { saveData } from "../../services/api/postApiCalls";
import AssignToken from "../assignToken/assignToken";
import AddFamily from "./AddFamilyHC";
import HeaderHC from "../common/components/headersHC";

const RegistrationHC = () => {
  const [token, setToken] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [org, setOrg] = useState(localStorage.getItem("ORGANIZATION"));
  const [key, setKey] = useState("");

  const [searchToken, setSearchToken] = useState("");
  const [patient, setPatient] = useState(null);
  const [isPatient, setisPatient] = useState(false);
  const [isTokenOccupied, setIsTokenOccupied] = useState(false);

  const [notification, setNotification] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });

  let location = useLocation();
  const isAssignToken = location.pathname === "/assignTokenHC";
  const isAddFamily = location.pathname === "/addFamilyHC";

  const debounceToken = useCallback(
    debounce((e) => getPatientByToken(e.target.value), 500),
    []
  );

  const tokenOnChange = (e) => {
    setToken(e.target.value);
    debounceToken(e);
  };

  const assignTokenHandler = () => {
    const payload = {
      name: name,
      mobile: mobile,
      token: token,
      organization: org,
      familyMembers: [
        {
          name: name,
          relation: "self",
          mobile: mobile,
          sequence: 0,
        },
      ],
    };
    if (token && name) {
      saveToken(payload);
    }
  };

  const clearDataHandler = () => {
    setToken("");
    setName("");
    //setOrg("");
    setMobile("");
    setIsTokenOccupied(false);
  };

  const saveToken = async (payload) => {
    const url = BASE_URL + "healthcamp/registration";
    const result = await saveData(url, payload);
    if (result.error) {
      setIsTokenOccupied(false);
      console.log({ error: result?.error?.response?.data?.message });
      setKey(crypto.randomUUID());
      setNotification({
        severity: "error",
        message: token?.error?.response?.data?.message || "Failed.",
        openNotice: true,
      });
    } else {
      console.log({ success: result.data });
      setNotification({
        severity: "success",
        message: "Assigned Successfully.",
        openNotice: true,
      });
      setKey(crypto.randomUUID());
      getPatientByToken(result.data.token);
      setSearchToken(result.data.token);
      clearDataHandler();
      console.log({ isTokenOccupied });
    }
  };

  const searchTokenHandler = (e, flag = true) => {
    flag && e.preventDefault();
    getPatientByToken(searchToken);
  };
  const getPatientByToken = async (token) => {
    const url = BASE_URL + "healthcamp/getToken/" + token;

    const patientData = await getData(url);

    if (patientData.error) {
      console.error(patientData.error);
      setisPatient(false);
      setIsTokenOccupied(false);
    } else {
      setPatient(patientData.data);
      console.log({ success: patientData.data });
      setisPatient(true);
      setIsTokenOccupied(true);
    }
  };
  return (
    <ThemeProvider theme={HCTheme}>
      <Fragment>
        <Grid container display={"flex"} justifyContent={"center"}>
          {isAssignToken ? (
            <Grid item>
              <HeaderHC />
              <AssignToken
                {...{
                  token,
                  mobile,
                  name,
                  org,
                  notification,
                  tokenOnChange,
                  setName,
                  setOrg,
                  setMobile,
                  assignTokenHandler,
                  clearDataHandler,
                  key,
                  setisPatient,
                  isPatient,
                  isTokenOccupied,
                }}
                key1={key}
                title="Assign Token"
              />
            </Grid>
          ) : (
            ""
          )}

          {isAddFamily ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AddFamily
                searchToken={searchToken}
                setSearchToken={setSearchToken}
                searchTokenHandler={searchTokenHandler}
                isPatient={isPatient}
                setisPatient={setisPatient}
                patient={patient}
                title="Add Family"
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Fragment>
    </ThemeProvider>
  );
};

export default RegistrationHC;
