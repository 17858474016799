import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Fragment } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PaymentComp from "../paymentComp/paymentComp";
import { saveData } from "../../services/api/postApiCalls";

const BookLabDialog = ({ open, handleClose, patientId, patientData }) => {
  const [listOfTests, setListOfTests] = useState([]);
  const [currentTest, setCurrentTest] = useState("");
  const [toggle, setToggle] = useState(true);
  const [payableaAmt, setPayableaAmt] = useState(0);
  const [selectedTestIDs, setSlectedTestIDs] = useState([]);
  const [selectedTests, setSlectedTests] = useState([]);
  const [formDataLab, setFormDataLab] = useState({
    reports: [],
    lAmmountPayable: 0,
    lAmmountReceived: 0,
    lPayMode: "CASH",
  });

  const fetchTestListHandler = useCallback(async () => {
    const user = await getData(BASE_URL + "lab/tests/all");
    const data1 = user.data;
    setListOfTests(data1);
  }, []);

  useEffect(() => {
    fetchTestListHandler();
  }, [fetchTestListHandler]);

  const [formValues, setFormValues] = useState([
    { lTest: { id: 0, reportName: "", reportPrice: 0, labId: "" } },
  ]);

  let addFormFields = () => {
    setToggle(!toggle);
    setFormValues([
      ...formValues,
      { lTest: { id: 0, reportName: "", reportPrice: 0, labId: "" } },
    ]);
  };

  let removeFormFields = (i) => {
    setToggle(!toggle);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    let amt = 0;
    let selectedIDs = [];
    let selectedTestList = [];
    if (formValues.length > 0) {
      formValues.map((fv, index) => {
        formDataLab.reports[index] = fv.lTest;
        amt = amt + parseInt(fv.lTest.reportPrice);
        if (fv.lTest.id && fv.lTest.reportName) {
          selectedTestList.push(fv);
        }
        selectedIDs.push(fv.lTest.id);
        console.log({ fv: fv.lTest });
      });
    }

    setSlectedTestIDs(selectedIDs);
    setPayableaAmt(amt);
    setSlectedTests(selectedTestList);
  }, [formValues, currentTest]);

  const submitHandlerL = (event) => {
    event.preventDefault();

    const pObj = {
      reports: selectedTests,
      patientId: patientId,
      paymentVM: {
        amountPayable: payableaAmt,
        amountCollected: 0,
        finalAmountPayable: payableaAmt,
        branchId: localStorage.getItem("BRANCH_ID"),
        appointmentSource: "LAB",
      },
      appointmentSource: "LAB",
      labId: localStorage.getItem("LAB_ID"),
    };

    console.log({ formDataLab_datatobesaved: pObj });
    if (patientId) {
      bookLab(pObj);
    }
  };

  const bookLab = async (obj) => {
    const bApp = await saveData(BASE_URL + "lab/tests/register", obj);

    if (bApp.error) {
      console.log({ error: bApp.error });
    } else {
      console.log({ formDataLab_afterSubmit: bApp.data });
      setLabData(bApp.data);
      setIsBooked(true);
    }
  };

  const [labData, setLabData] = useState();
  const [isBooked, setIsBooked] = useState(false);
  console.log({ formDataLab_formvalue: formValues });

  console.log({ formDataLab_formDataLab: formDataLab });
  console.log({ selectedTestIDs });
  console.log({ selectedTests });

  const checkDuplicate = (test) => {
    return findDuplicate(test.id);
  };

  const findDuplicate = (id) => {
    let flag = true;

    if (selectedTestIDs && selectedTestIDs.length > 0) {
      selectedTestIDs.map((item, index) => {
        if (item === id) {
          flag = false;
        }
      });
    }

    return flag;
  };

  console.log({ labData: labData });
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" align="center">
          Book Lab
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            {isBooked ? (
              <PaymentComp data={labData} patientId={patientId} source="LAB" />
            ) : (
              <Grid container>
                <Grid item lg={12}>
                  <Box>
                    <form id="consultReg" onSubmit={submitHandlerL}>
                      <Box
                        sx={{
                          minHeight: "47vh",
                          background: "#fff",
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        <Grid container spacing={2}>
                          {formValues.map((element, index) => (
                            <Grid item xs={12} lg={12} key={index}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                  <Autocomplete
                                    size={"small"}
                                    freeSolo
                                    name="lTest"
                                    value={element.lTest}
                                    disableClearable
                                    options={listOfTests.filter(checkDuplicate)}
                                    getOptionLabel={(option) =>
                                      option.reportName ? option.reportName : ""
                                    }
                                    onChange={(event, value) => {
                                      element.lTest = value;
                                      setCurrentTest(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...params}
                                        label="Select a test"
                                        InputProps={{
                                          ...params.InputProps,
                                          type: "search",
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                  <TextField
                                    disabled
                                    label="Price"
                                    value={element.lTest.reportPrice || 0}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  {formValues.length > 1 ? (
                                    <Stack
                                      direction="row"
                                      divider={
                                        <Divider
                                          orientation="vertical"
                                          flexItem
                                        />
                                      }
                                      spacing={0}
                                    >
                                      <IconButton
                                        color="primary"
                                        aria-label="add"
                                        onClick={() => addFormFields()}
                                      >
                                        <AddCircleIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="remove"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Stack>
                                  ) : (
                                    <IconButton
                                      color="primary"
                                      aria-label="add"
                                      onClick={() => addFormFields()}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>

                      <Box
                        sx={{
                          height: "5vh",
                          background: "#fff",
                          p: 2,
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Stack direction="row" spacing={2}>
                              <Button variant="contained" onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button variant="contained" type="submit">
                                Book Lab Tests
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default BookLabDialog;
