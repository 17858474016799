import  { Fragment } from "react";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import CaseHistory from "../../marketing/caseHistory"

const CaseHistoryMK = () => {
  return (
    <Fragment>
      <NavigationBarMarketing />
      <CaseHistory />
    </Fragment>
  );
};

export default CaseHistoryMK;
