import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useCallback, useState } from "react";
import jwt_decode from "jwt-decode";
import MobileField from "./components/MobileField";
import OTPField from "./components/OTPField";

import { useNavigate } from "react-router";
import { saveDataWithoutToken } from "../../../services/api/postApiCalls";
import { BASE_URL_AUTH } from "../../../assets/constantsFile";

const CorpOtpLoginForm = ({
  setSeverity,
  setMessage,
  setOpenNotice,
  handleCloseNotice,
}) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const navigate = useNavigate();

  const getOTPHandler = async () => {
    if (mobile) {
      setIsSendOTP(true);
      const url = BASE_URL_AUTH + "authenticate?sendOtp=true";

      const payload = {
        username: mobile,
        role: "CLINIC",
        portal: "CAMP",
      };

      const otpData = await saveDataWithoutToken(url, payload);
      if (otpData?.data) {
        console.log({ otpData: otpData?.data });
        setSeverity("success");
        setMessage("OTP Send to your registered mobile number");
        setOpenNotice(true);
      } else {
        console.log({ otpData_error_otp: otpData.error });
        setSeverity("error");
        setMessage("OTP generation failed.");
        setOpenNotice(true);
      }
    }
  };

  const _fetchLoginDetails = useCallback(async (mobile, otp) => {
    const url = BASE_URL_AUTH + "authenticate?authOnOTP=true";
    const payload = {
      username: mobile,
      password: otp,
      portal: "SNOP",
    };
    const user = await saveDataWithoutToken(url, payload);

    if (user.error) {
      setSeverity("error");
      setMessage("Login failed! Enter Correct Credentials!");
      setOpenNotice(true);
      console.log({ error: user.error });
    } else {
      setSeverity("success");
      setMessage("Login successful");
      setOpenNotice(true);

      const data = user.data;
      const token = data.token;

      const userData = await jwt_decode(token);
      localStorage.setItem("authHeader_local", token);
      localStorage.setItem("ROLE_CORP", userData?.role);
      localStorage.setItem("BRANCH_ID_CORP", userData?.userID);
      localStorage.setItem("BRANCH_ID_UNIQUE_CORP", userData?.userID);
      localStorage.setItem("CLINIC_ID_CORP", userData?.clinicId);
      localStorage.setItem("BRANCH_NAME_CORP", userData?.branchName);
      localStorage.setItem("USER_NAME_CORP", userData?.name);
      localStorage.setItem("USER_MOBILE_CORP", userData?.mobile);
      localStorage.setItem("userMobile_CORP", userData?.sub);
      localStorage.setItem("USER_ID_CORP", userData?.id);
      localStorage.setItem("CASHIER_ID_CORP", userData?.id);
      localStorage.setItem("PORTAL_CORP", userData?.portal);
      localStorage.setItem("LAB_ID_CORP", userData?.labId);
      localStorage.setItem(
        "PERMISSION_CORP",
        userData?.permissions ? JSON.stringify(userData?.permissions) : null
      );

      console.log({ SUCCESSLOGIN: userData });
      if (userData !== null) {
        localStorage.setItem("isAdminUser_local", "ADMIN");
        navigate("/corp/select-corp");
      }
    }
  }, []);

  const handleSubmitOTP = async (event) => {
    event.preventDefault();
    _fetchLoginDetails(mobile, otp);
    console.log({ mobile, otp });
  };
  return (
    <Fragment>
      <Box
        component="form"
        onSubmit={(e) => handleSubmitOTP(e)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container>
          <MobileField mobile={mobile} setMobile={setMobile} />
          <Grid item xs={6} sx={{ textAlign: "center", marginBlock: "10px" }}>
            <Button
              onClick={getOTPHandler}
              style={{
                borderRadius: "15px",
                padding: "10px",
                textTransform: "none",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#8A8A8A",
                }}
              >
                Resend
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center", marginBlock: "10px" }}>
            <Button
              disabled={mobile?.length !== 10}
              onClick={getOTPHandler}
              variant="contained"
              style={{
                borderRadius: "15px",
                height: "40px",
                width: "100%",
                textTransform: "none",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#FFFFFF",
                }}
              >
                Send OTP
              </Typography>
            </Button>
          </Grid>
          <OTPField
            otp={otp}
            setOtp={setOtp}
            setShowOTP={setShowOTP}
            showOTP={showOTP}
          />

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{
                borderRadius: "15px",
                height: "40px",
                width: "200px",
                textTransform: "none",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#FFFFFF",
                }}
              >
                Login
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#404040",
              }}
            >
              Don’t Have An Account?
            </Typography>
            <Button
              sx={{
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#127DDD",
              }}
            >
              SIGN-UP
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CorpOtpLoginForm;
