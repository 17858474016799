import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';  
import MyAppBarBeforeLogin from '../header/myAppBarBeforeLogin';
import HomeComponentMain from '../homeNew/homeComponentMain';

const Home = (props) => {

    const commonHeader = {
        root : props.root,
    };
   // console.log({'props.commonHeader':props})
   return (

    <React.Fragment>
     <CssBaseline />
        <MyAppBarBeforeLogin/>
        <HomeComponentMain root = {props.root} />      
    </React.Fragment>
);

}
 
export default Home;