import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getFormattedDayAndDate } from "../../assets/utils";
import { getData } from "../../services/api/getApiCalls";
import HandIcon from "../../assets/images/svg/handIcon.svg";
import HeaderHC from "../common/components/headersHC";
import { ThemeProvider } from "@mui/material";
import HCTheme from "../HCTheme";

const HomeContentHC = () => {
  const [healthCampData, setHealthCampData] = useState("");
  const getHealthCampDetails = async (date) => {
    const url = BASE_URL + "healthcamp/healthCamp/" + date;

    const healthCamp = await getData(url);

    if (healthCamp.error) {
      console.error(healthCamp.error);
    } else {
      setHealthCampData(healthCamp.data);
      console.log({ success: healthCamp.data });
      localStorage.setItem("ORGANIZATION", healthCamp.data.organization);
    }
  };

  useEffect(() => {
    getHealthCampDetails(new Date().toISOString().split("T")[0]);
  }, []);

  return (
    <ThemeProvider theme={HCTheme}>
      <Fragment>
        <Grid
          container
          lg={8}
          md={6}
          sm={8}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          rowSpacing={2}
        >
          <Grid item>
            <HeaderHC />
          </Grid>

          <Grid item sx={{ mt: 3 }}>
            <Typography fontWeight={600}>
              Welcome to UNO.care Healthcamp
            </Typography>
          </Grid>
          <Grid item>
            <Box component="img" src={HandIcon} />
          </Grid>
          <Grid item>
            <Box sx={{}}>
              <Box
                sx={{
                  p: 2,
                  mx: 3,
                  border: "1px solid #208F94",
                  borderRadius: 2,
                }}
                border={1}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    display="flex"
                    justifyContent="center"
                    sx={{ p: 1 }}
                  >
                    <Typography variant="h6">
                      Healthcamp is scheduled on&nbsp;
                      {healthCampData && healthCampData.date
                        ? getFormattedDayAndDate(healthCampData.date)
                        : ""}
                      .
                    </Typography>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Box>
                      <Grid container spacing={2} sx={{ mt: 4 }}>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          display="flex"
                          justifyContent="flex-start"
                        >
                          <Typography variant="h6">
                            Date:&nbsp;
                            {healthCampData && healthCampData.date
                              ? getFormattedDayAndDate(healthCampData.date)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          display="flex"
                          justifyContent="flex-start"
                        >
                          <Typography
                            variant="h6"
                            sx={{ textTransform: "capitalize" }}
                          >
                            Organization: {healthCampData.organization}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={12}
                    display="flex"
                    justifyContent="center"
                  ></Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    </ThemeProvider>
  );
};

export default HomeContentHC;
