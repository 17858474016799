import { Box, IconButton, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { format, isValid, subDays } from "date-fns";

const CustomDate = ({
  setCheckDate,
  setDate,
  initialDate,
  disablePast,
  sevenDaysBack,
  disableFuture,
  numberOfDaysBack,
  disabled,
}) => {
  const [dateValue, setDateValue] = useState(null);
  useEffect(() => {
    if (initialDate === null) {
      setDateValue(null);
      setDate(null);
    } else if (initialDate) {
      setDateValue(new Date(initialDate));
    } else if (numberOfDaysBack ? numberOfDaysBack : sevenDaysBack) {
      setDateValue(
        subDays(new Date(), numberOfDaysBack ? numberOfDaysBack : 7)
      );
    } else {
      setDateValue(new Date());
    }
  }, [initialDate]);

  const handleDateChange = (newValue) => {
    setDateValue(newValue);

    if (newValue === null) {
      setDateValue(null);
      setDate(null);
    }
    if (isValid(newValue)) {
      const formattedDate = format(newValue, "yyyy-MM-dd");
      setDate(formattedDate);
      if (setCheckDate) {
        setCheckDate(true);
      }
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        label=""
        openTo="day"
        views={["year", "month", "day"]}
        value={dateValue}
        onChange={handleDateChange}
        format="MMMM dd, yyyy"
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </LocalizationProvider>
  );
};

export default CustomDate;
