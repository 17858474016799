import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Fragment } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { getFormattedDDMonthYYYY } from "../../assets/utils";
import PrintVitals from "../../common/printComps/printVitals";
import PrintPrescriptions from "../../common/printComps/printPrescriptions";
import NextAppointmentDate from "../../common/printComps/nextAppointmentDate";
import PersonalVisitRequired from "../../common/printComps/personalVisitRequired";
import PrintPrescribedTests from "../../common/printComps/printPrescribedTests";
import PrintDiagnosis from "../../common/printComps/printDiagnosis";
import PrintIssue from "../../common/printComps/printIssue";

const ViewPatientHistoryComp = (props) => {
  const pHistory = props.pHistory;
  const appointmentList = pHistory ? pHistory.appointmentList : [];
  const reportList = pHistory ? pHistory.reportVMs : [];

  console.log({ appointmentList });
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Timeline position="right">
              {appointmentList.map((appointment, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent style={{ flex: 0.1 }}>
                    <Typography>
                      {getFormattedDDMonthYYYY(appointment.date)}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box>
                      <Grid container rowSpacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box>
                            <Grid container rowSpacing={2}>
                              {appointment.doctorName ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 600 }}
                                    >
                                      DOCTOR NAME:&nbsp;
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{ mt: "1px" }}
                                    >
                                      {appointment.doctorName}
                                    </Typography>
                                  </Stack>
                                  <Divider />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.vitals ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PrintVitals vitalInfo={appointment.vitals} />
                                  <Divider />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.symptom ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PrintIssue issueInfo={appointment.symptom} />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.observation ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PrintDiagnosis
                                    diagnosisInfo={appointment.observation}
                                  />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {reportList && reportList.length > 0 ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PrintPrescribedTests
                                    reportList={reportList}
                                  />
                                  <Divider />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.personalVisitNeeded ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PersonalVisitRequired
                                    personalVisitNeeded={
                                      appointment.personalVisitNeeded
                                    }
                                  />
                                  <Divider />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.nextAppointmentDate ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <NextAppointmentDate
                                    nextAppointmentDate={
                                      appointment.nextAppointmentDate
                                    }
                                  />
                                  <Divider />
                                </Grid>
                              ) : (
                                ""
                              )}

                              {appointment.prescription &&
                              appointment.prescription.medicines &&
                              appointment.prescription.medicines.length > 0 ? (
                                <Grid item lg={12} xs={12} md={12} sm={12}>
                                  <PrintPrescriptions
                                    prescriptionList={
                                      appointment.prescription.medicines
                                    }
                                  />
                                </Grid>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                      </Grid>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ViewPatientHistoryComp;
