import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Fragment } from "react";
import {
  CustomTypography,
  CustomTypographyBold,
} from "../../comps/customTypography";
import { IOSSwitch } from "../../comps/customSwitch";

const EmployeeEyeTestFormfields = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack
              direction={"row"}
              display={"flex"}
              //justifyContent={"center"}
              alignItems={"center"}
              spacing={3}
            >
              <CustomTypography>{"EYETEST DONE"}</CustomTypography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 2 }}
                    checked={formValues["eyeTest"] || false}
                    onChange={(e) => {
                      let newFormValues = { ...formValues };
                      newFormValues["eyeTest"] = e.target.checked;
                      setFormValues(newFormValues);
                    }}
                  />
                }
                label=""
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                boxSizing: "border-box",
                background: "#FFFFFF",
                border: "1px solid #A6A6A6",
                borderRadius: 5,
                px: 2,
                pt: 1,
                pb: 2,
              }}
            >
              <Stack>
                <Stack textAlign="center" sx={{ mb: 2 }}>
                  <CustomTypographyBold>Distant</CustomTypographyBold>
                </Stack>

                <Stack
                  direction="row"
                  spacing={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CustomTypographyBold>R</CustomTypographyBold>

                  <Autocomplete
                    fullWidth
                    value={formValues.farRightEyeSight || null}
                    onChange={(event, newValue) => {
                      let newFormValues = { ...formValues };
                      newFormValues["farRightEyeSight"] = newValue;
                      setFormValues(newFormValues);
                    }}
                    // sx={{ width: 200, mt: 0 }}
                    size="small"
                    disablePortal
                    options={[
                      "6/6",
                      "No PL",
                      "PL+",
                      "HM+",
                      "C.F.C.F",
                      "1/60",
                      "2/60",
                      "3/60",
                      "4/60",
                      "5/60",
                      "6/60",
                      "6/36",
                      "6/24",
                      "6/18",
                      "6/12",
                      "6/9",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Far Right Eyesight"
                        fullWidth
                      />
                    )}
                  />
                  <CustomTypographyBold>L</CustomTypographyBold>

                  <Autocomplete
                    fullWidth
                    value={formValues.farLeftEyeSight || null}
                    onChange={(event, newValue) => {
                      let newFormValues = { ...formValues };
                      newFormValues["farLeftEyeSight"] = newValue;
                      setFormValues(newFormValues);
                    }}
                    // sx={{ width: 200, mt: 0 }}
                    size="small"
                    disablePortal
                    options={[
                      "6/6",
                      "No PL",
                      "PL+",
                      "HM+",
                      "C.F.C.F",
                      "1/60",
                      "2/60",
                      "3/60",
                      "4/60",
                      "5/60",
                      "6/60",
                      "6/36",
                      "6/24",
                      "6/18",
                      "6/12",
                      "6/9",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Far Left Eyesight"
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                boxSizing: "border-box",
                background: "#FFFFFF",
                border: "1px solid #A6A6A6",
                borderRadius: 5,
                px: 2,
                pt: 1,
                pb: 2,
              }}
            >
              <Stack>
                <Stack textAlign="center" sx={{ mb: 2 }}>
                  <CustomTypographyBold>Near</CustomTypographyBold>
                </Stack>

                <Stack
                  direction="row"
                  spacing={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CustomTypographyBold>R</CustomTypographyBold>

                  <Autocomplete
                    fullWidth
                    value={formValues.nearRightEyeSight || null}
                    onChange={(event, newValue) => {
                      let newFormValues = { ...formValues };
                      newFormValues["nearRightEyeSight"] = newValue;
                      setFormValues(newFormValues);
                    }}
                    // sx={{ width: 200, mt: 0 }}
                    size="small"
                    disablePortal
                    options={[
                      "N/6",
                      "N/36",
                      "N/24",
                      "N/18",
                      "N/12",
                      "N/10",
                      "N/8",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Near Right Eyesight"
                        fullWidth
                      />
                    )}
                  />
                  <CustomTypographyBold>L</CustomTypographyBold>

                  <Autocomplete
                    fullWidth
                    value={formValues.nearLeftEyeSight || null}
                    onChange={(event, newValue) => {
                      let newFormValues = { ...formValues };
                      newFormValues["nearLeftEyeSight"] = newValue;
                      setFormValues(newFormValues);
                    }}
                    // sx={{ width: 200, mt: 0 }}
                    size="small"
                    disablePortal
                    options={[
                      "N/6",
                      "N/36",
                      "N/24",
                      "N/18",
                      "N/12",
                      "N/10",
                      "N/8",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Near Left Eyesight"
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeEyeTestFormfields;
