import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const RespondentName = ({ formValues, setFormValues }) => {
  // const [name, setName] = useState(formValues["fullName" || ""]);
  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     setFormValues({ ...formValues, fullName: name });
  //   }, 1000);

  //   return () => clearTimeout(getData);
  // }, [name]);

  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={2} display="flex" alignItems="center">
          <Typography>Name:</Typography>
        </Grid>
        <Grid item lg={8} xs={10}>
          <TextField
            required
            size="small"
            fullWidth
            placeholder={"Full Name"}
            value={formValues["fullName"]}
            onChange={(e) =>
              setFormValues({ ...formValues, fullName: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default RespondentName;
