import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import { Container } from "@mui/material";
import VitalAnalyticsMain from "../corporate/vitalAnalytics/vitalAnalyticsMain";

const VitalAnalyticsIndex = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <Container maxWidth={false} sx={{ mt: 1 }}>
        <VitalAnalyticsMain />
      </Container>
    </Fragment>
  );
};

export default VitalAnalyticsIndex;
