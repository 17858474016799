import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import CorporateMainComp from "../corporateHome/corporateMainComp";

const ConsolodatedReportMainNew = ({
  orgName = localStorage.getItem("ORGNAME"),
}) => {
  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Typography variant="h6">{orgName}</Typography>
      </Box>

      <CorporateMainComp consolidatedReport={"CONSOLIDATEDREPORT"} />
    </Fragment>
  );
};
export default ConsolodatedReportMainNew;
