import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { getData } from "../services/api/getApiCalls";
import { BASE_URL } from "../assets/constantsFile";

const PaymentContent = (props) => {
  var tomorrow = new Date();
  var yesterday = new Date();
  tomorrow.setDate(tomorrow.getDate() + 30);
  yesterday.setDate(tomorrow.getDate() - 30);
  const [startDateValue, setStartDateValue] = useState(yesterday);
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [paymentData, setPaymentData] = useState("");
  const [paymentDataClinic, setPaymentDataClinic] = useState("");
  const [paymentDataLab, setPaymentDataLab] = useState("");
  const [paymentDataPharmacy, setPaymentDataPharmacy] = useState("");
  const [paymentDataList, setPaymentDataList] = useState([]);

  const viewPendingDetails = (event, data) => {
    event.preventDefault();
    console.log({ data });
    getPaymentDetailList(data);
  };

  const getPaymentDetails = async () => {
    const query =
      "?branchId=" +
      localStorage.getItem("BRANCH_ID") +
      (startDateValue && endDateValue
        ? "&startDate=" +
          startDateValue.toISOString().split("T")[0] +
          "&endDate=" +
          endDateValue.toISOString().split("T")[0]
        : "");

    const url = BASE_URL + "payment/all" + query;
    const paymentData = await getData(url);
    if (paymentData.error) {
      console.log({ paymentData_error: paymentData.error });
    } else {
      const data = paymentData.data;
      setPaymentData(data);
      setPaymentDataClinic(data.CLINIC ? data.CLINIC : "");
      setPaymentDataLab(data.LAB ? data.LAB : "");
      setPaymentDataPharmacy(data.PHARMACY ? data.PHARMACY : "");
      console.log({ paymentData_data: paymentData.data });
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const getPaymentDataInRange = () => {
    getPaymentDetails();
  };

  const getPaymentDetailList = async (src) => {
    const query =
      "?branchId=" +
      localStorage.getItem("BRANCH_ID") +
      (startDateValue && endDateValue
        ? "&startDate=" +
          startDateValue.toISOString().split("T")[0] +
          "&endDate=" +
          endDateValue.toISOString().split("T")[0]
        : "");
    const url = BASE_URL + "payment/all/" + src + query;
    const paymentDataList = await getData(url);
    if (paymentDataList.error) {
      console.log({ paymentDataList_error: paymentDataList.error });
    } else {
      const data = paymentDataList.data;
      setPaymentDataList(data);
      console.log({ paymentDataList_data: paymentDataList.data });
      props.onViewPendingDetails("DATA", data);
    }
  };

  console.log({ paymentData });
  console.log({ paymentDataClinic });
  console.log({ paymentDataLab });
  console.log({ paymentDataPharmacy });
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ p: 1 }}>
          <Stack
            direction="row"
            display="flex"
            justifyContent="center"
            spacing={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                openTo="day"
                views={["year", "month", "day"]}
                disableMaskedInput={true}
                inputFormat="dd MMM yyyy"
                value={startDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="End Date"
                openTo="day"
                views={["year", "month", "day"]}
                disableMaskedInput={true}
                inputFormat="dd MMM yyyy"
                value={endDateValue}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>

            <Button variant="outlined" onClick={getPaymentDataInRange}>
              {" "}
              submit
            </Button>
          </Stack>
        </Box>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ borderTop: "3px solid #208F94", p: 4 }}>
          <Container maxWidth={"lg"}>
            <Box
              sx={{
                background: "#D9D9D9",
                minHeight: "7vh",
                p: 2,
              }}
            >
              <Grid container>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Type</Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Amount Payable</Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Paid</Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Pending</Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}></Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                height: "52vh",
                background: "#fff",
                p: 2,
                border: "1px solid gray",
              }}
            >
              <Grid container rowSpacing={3}>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Clinic</Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataClinic.totalPayable
                      ? paymentDataClinic.totalPayable
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {parseInt(
                      paymentDataClinic.totalPayable
                        ? paymentDataClinic.totalPayable
                        : 0
                    ) -
                      parseInt(
                        paymentDataClinic.totalPending
                          ? paymentDataClinic.totalPending
                          : 0
                      )}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataClinic.totalPending
                      ? paymentDataClinic.totalPending
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Button
                    variant="outlined"
                    onClick={(e) => viewPendingDetails(e, "CLINIC")}
                  >
                    show details
                  </Button>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Lab</Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataLab.totalPayable
                      ? paymentDataLab.totalPayable
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {parseInt(
                      paymentDataLab.totalPayable
                        ? paymentDataLab.totalPayable
                        : 0
                    ) -
                      parseInt(
                        paymentDataLab.totalPending
                          ? paymentDataLab.totalPending
                          : 0
                      )}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataLab.totalPending
                      ? paymentDataLab.totalPending
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Button
                    variant="outlined"
                    onClick={(e) => viewPendingDetails(e, "LAB")}
                  >
                    show details
                  </Button>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>

                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Pharmacy</Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataPharmacy.totalPayable
                      ? paymentDataPharmacy.totalPayable
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;{" "}
                    {parseInt(
                      paymentDataPharmacy.totalPayable
                        ? paymentDataPharmacy.totalPayable
                        : 0
                    ) -
                      (paymentDataPharmacy.totalPending
                        ? paymentDataPharmacy.totalPending
                        : 0)}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    &#8377;
                    {paymentDataPharmacy.totalPending
                      ? paymentDataPharmacy.totalPending
                      : 0}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Button
                    variant="outlined"
                    onClick={(e) => viewPendingDetails(e, "PHARMACY")}
                  >
                    show details
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaymentContent;
