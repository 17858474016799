import { Box, Button, Drawer, Portal, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import {
  saveData,
  saveDataWithoutToken,
} from "../../services/api/postApiCalls";

const StethoIndex = ({ setOpenStetho, appointmentId }) => {
  const [streamUrl, setStreamUrl] = useState(
    "https://stream.ayudevicestech.com/listen/unocareiframerefresh"
  );
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenStetho(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenStetho(false);
    setStreamUrl(
      "https://stream.ayudevicestech.com/listen/unocareiframerefresh"
    );
  };

  const createLiveStream = async () => {
    handleDrawerOpen();
    const url = "http://localhost:8000/online_stream/";

    const obj = {
      stream_token: "unocare" + appointmentId,
    };
    console.log({ obj: obj });

    const data = await saveDataWithoutToken(url, obj);

    if (data.error) {
      console.log({ failed: data.error });
    } else {
      console.log({ success: data.data });
      createStethoStream(data.data);
      setStreamUrl(data.data.url_streamer);
    }
  };

  const createStethoStream = async (data) => {
    const url = BASE_URL + "clinic/stethoURL";
    const obj = {
      appointmentId: appointmentId,
      stethoURL: data.url_listner,
      stethoStreamerURL: data.url_streamer,
    };

    const stream = await saveData(url, obj);

    if (stream.error) {
      console.log({ "falied stream": stream.error });
    } else {
      console.log({ "success stream": stream.data });
    }
  };

  const closeStream = async () => {
    handleDrawerClose();
    const url = "http://localhost:8000/online_stream/end";

    const obj = {
      stream_token: "unocare" + appointmentId,
    };

    const data = await saveDataWithoutToken(url, obj);

    if (data.error) {
      //alert("error");
    } else {
      //alert("success");
    }
  };
  return (
    <Fragment>
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createLiveStream();
        }}
      >
        Stetho
      </Button>
      <Portal>
        <Drawer
          sx={{
            width: 600,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 600,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          {streamUrl ? (
            <iframe
              id="LiveStreaming"
              title="Sample"
              height="100%"
              width="98%"
              allow="camera; microphone; clipboard-read; clipboard-write"
              src={streamUrl}
              samesite="none"
            />
          ) : (
            <Box
              sx={{
                height: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No stream available.</Typography>
            </Box>
          )}
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              height: "6vh",
              width: "100%",
              backgroundColor: "#127DDD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button fullWidth style={{ color: "#fff" }} onClick={closeStream}>
              close
            </Button>
          </Box>
        </Drawer>
      </Portal>
    </Fragment>
  );
};
export default StethoIndex;
