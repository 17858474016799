import { Box, Divider, Grid, Stack } from "@mui/material";
import { Fragment } from "react";
import SwitchComps from "../../comps/switchComps";
import {
  SwitchFieldList,
  UploadFieldList,
} from "../../dataandpermissions/corpFields";
import ImageUploadComp from "../../comps/imageUploadComp";
import { CustomTypographyBold } from "../../comps/customTypography";

const EmployeeToggleFormfields = ({
  formValues,
  setFormValues,
  permissions,
  corpId = localStorage.getItem("CORPID"),
}) => {
  return (
    <Fragment>
      <Box
        sx={{
          boxSizing: "border-box",
          background: "#FFFFFF",
          border: "0.5px solid #A6A6A6",
          borderRadius: 5,
          minHeight: "10vh",
          p: 2,
        }}
      >
        <Stack display="flex" justifySelf="center" alignItems="center">
          <Box
            sx={{
              position: "relative",
              top: -28,
              //left: "37vw",
              background: "#fff",
              width: 240,
              display: "flex",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CustomTypographyBold>
              Various Toggles and Reports
            </CustomTypographyBold>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SwitchComps
              formValues={formValues}
              setFormValues={setFormValues}
              SwitchFieldList={SwitchFieldList(permissions)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              {UploadFieldList(formValues, corpId, permissions)
                .filter((item) => item.display)
                .map((item, index) => (
                  <Grid
                    key={index}
                    item
                    lg={3}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ImageUploadComp
                      formValues={formValues}
                      setFormValues={setFormValues}
                      url={item.url}
                      label={item.title}
                      property={item.property}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeToggleFormfields;
