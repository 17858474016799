import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Password = ({ password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show);
  };

  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginBlock: "10px" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#6B6B6B",
          }}>
          Enter Password
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  onMouseDown={() => {
                    setShowPassword(!showPassword);
                  }}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: {
              marginTop: "3px",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#777777",
            },
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default Password;
