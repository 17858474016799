import { Grid, Stack, Typography } from "@mui/material"

const NextAppointmentDate = props => {
  return (
    <Grid container>
      <Grid item lg={8} md={8} sm={8} xs={8}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography sx={styles.Mainheading}>Next Appoinment Date:&nbsp;</Typography>
          <Typography sx={styles.headingData}>{props.nextAppointmentDate}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default NextAppointmentDate
const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    marginTop: "5px",
    color: "#6B6B6B",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
    marginTop: "5px",
  },
}
