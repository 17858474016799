import { Box, Button, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { getCurrentDateFormatted } from "../../assets/utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintPrescriptionComponent from "../printComponents/printPrescriptionComponent";
import CheckIcon from '@mui/icons-material/Check';
import PrintReportMainComponent from "../../common/printComps/printReportMainComponent";


const ViewPrescriptionComponent = (props) => {

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });





  const [docDetail, setDocDetail] = useState("");
  let patientDetails = ""; 
  let doctorId = "";
  let doctorName = "";
  let patientInfo = "";
  let vitalInfo = {bp:""};
  let observationInfo = "";
  let reportList = [];
  let prescriptionList = [];

if(props.patientDetails){

  patientDetails = props.patientDetails;
  doctorId = patientDetails.prescriptionVM.docId;
 
  patientInfo = {
    
    patientId:patientDetails.patientId,
    name:patientDetails.patientName,
    age:patientDetails.age,
    sex:patientDetails.gender,
  }

  let prescriptionVM = patientDetails.prescriptionVM;

  if(prescriptionVM) {
    prescriptionList = prescriptionVM.medicines;
  }
}

const getDocInfo = async(dId) => {

const user = await getData(BASE_URL+ "doctor/"+dId);

if(user.error){

} else {
  const data = user.data;
  setDocDetail(data);

}

} 

useEffect(() => {
  getDocInfo(doctorId);

}, [doctorId])


const goBAck = () => {

  props.onGoBack();
}


console.log({"docDetail":docDetail})


  return (

    <Fragment>

<Box component={"iframe"} sx={{ width:0, height:0, display: 'none' }} >
    <Box ref={componentRef} >
    <PrintReportMainComponent patientDetails = {patientDetails} />
    
        </Box>
    </Box>
   
    <Container maxWidth={false} sx={{mr:.5, ml:1, mb:5}} disableGutters>
      <Box
        sx={{
          width:"100%",
        }}
      >
        <Grid container>
        <Grid item lg={12} >
            <Box
              sx={{  minHeight: "20vh" }}
            >


              <Grid container rowSpacing={4}>


              <Grid item lg={6} xs={6} md={6} sm={6} display="flex" justifyContent={"flex-start"}>
                 
                </Grid>
                <Grid item lg={6} xs={6} md={6} sm={6} display="flex" justifyContent={"flex-end"}>
                  <Paper
                    component={Stack}
                    direction="row"
                    display={"flex"}
                    justifyContent={"space-between"}
                    elevation={0}
                    sx={{ width: 200, p:1 , height:30 }}
                  >
                    <Button variant="contained" sx={{width:90}} onClick={handlePrint}>Print!</Button>
                    <Button variant="contained" sx={{width:90}} onClick={goBAck}>Back</Button>
                  </Paper>
                </Grid>

         
                <Box sx={{border:"1px solid grey", width:"100%"}}>
                <PrintReportMainComponent patientDetails = {props.patientDetails} />

                </Box>

             

              </Grid>



            </Box>
          </Grid>
        </Grid>

       
        

      </Box>
    </Container>

    </Fragment>
  );
};

export default ViewPrescriptionComponent;
