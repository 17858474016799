import { Box, Button, Grid, Typography } from "@mui/material";
import TitleHC from "../../../healthcamp/common/title/titleHC";
import RespondentName from "../components/respondentName";
import RespondentMobile from "../components/respondentMobile";
import RespondentAge from "../components/respondentAge";
import RespondentGender from "../components/respondentGender";
import RespondentDisease from "../components/respondentDisease";
import RespondentComments from "../components/respondentComments";
import SOS from "../components/SOS";
import SOSReason from "../components/sosReason";
import MarketingLeadType from "../components/marketingLeadType";
import DiseaseGroup from "../components/diseaseGroup";

const OnboardingIndex = ({
  formValues,
  setFormValues,
  diseases,
  setDiseases,
  count,
  target,
  leadType,
  setLeadType,
}) => {
  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{`Patients Identified ${count || 0}/${
            target || 0
          }`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <MarketingLeadType
            formValues={formValues}
            setFormValues={setFormValues}
            leadType={leadType}
            setLeadType={setLeadType}
          />
        </Grid>
        {formValues.sos && (
          <Grid item xs={12}>
            <SOSReason formValues={formValues} setFormValues={setFormValues} />
          </Grid>
        )}
        <Grid item xs={12}>
          <RespondentName
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>

        <Grid item xs={12}>
          <RespondentMobile
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
        <Grid item xs={12}>
          <RespondentAge
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
        <Grid item xs={12}>
          <RespondentGender
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
        <Grid item xs={12}>
          <DiseaseGroup
            formValues={formValues}
            setFormValues={setFormValues}
            leadType={leadType}
          />
          {formValues.diseaseGroup === "MD" && (
            <RespondentDisease
              formValues={formValues}
              setFormValues={setFormValues}
              diseases={diseases}
              setDiseases={setDiseases}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <RespondentComments
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            type="submit"
            size="small"
            disabled={formValues["mobile"]?.length !== 10 ? true : false}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
};

export default OnboardingIndex;
