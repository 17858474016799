import { Box, TextField } from "@mui/material";
import { Fragment } from "react";
import SearchIcon from "../../../assets/images/svg/search.svg";

const SelectTokenElement = ({ token, setToken, searchTokenHandler }) => {
    // const [token, setToken] = useState("");
    return (
        <Fragment>
            <Box
                sx={{
                    display: "flex",
                    borderRadius: "25px",
                    "& .MuiFormControl-root": { display: "flex", justifyContent: "center" },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#127DDD66",
                        padding: 0.5,
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                        px: 1,
                    },
                }}>
                <TextField
                    size="small"
                    fullWidth
                    label="Search Token"
                    value={token || ""}
                    onChange={(e) => {
                        setToken(e.target.value);
                    }}
                    sx={{ background: "#F4FBFF", borderTopLeftRadius: "25px", borderBottomLeftRadius: "25px" }}
                />
                <Box
                    onClick={() => searchTokenHandler(token, false)}
                    component="img"
                    src={SearchIcon}
                    sx={{
                        padding: "12px 24px",
                        background: "#127DDD",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                    }}></Box>
            </Box>
        </Fragment>
    );
};

export default SelectTokenElement;
