import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  let navigate = useNavigate();

  localStorage.setItem("isAdminUser_local", "");
  localStorage.setItem("isClinicUser_local", "");
  localStorage.setItem("isLabUser_local", "");
  localStorage.setItem("isPharmacyUser_local", "");
  localStorage.setItem("authHeader_local", "");
  localStorage.setItem("loginUserData_local", "");
  localStorage.setItem("ROLE", "");

  useEffect(() => {
    localStorage.clear();
    navigate("/");
    localStorage.setItem("ROLE", "");
    localStorage.setItem("BRANCH_ID", "");
  }, []);

  return "you have succesfully logged out.";
};

export default Logout;
