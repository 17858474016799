import React from "react"
import { Box, Grid, Menu, MenuItem } from "@mui/material"
import hambergerHC from "../../../../assets/images/hambergerHC.png"
import notificationsHC from "../../../../assets/images/notificationsHC.png"
import { Fragment } from "react"
import EChokitsalayaLogo from "../../../../logoNew.svg"
import { Link } from "@mui/material"

const NavigationBarAshaWorkers = () => {
  const pages = [
    { name: "Dashboard", url: "/marketing/asha-worker" },
    { name: "Logout", url: "/logoutHC" },
  ]
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <Fragment>
      <Grid
        container
        sx={{ justifyContent: "space-between", boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.25)" }}>
        <Grid item xs={3} lg={3}>
          <Box
            onClick={handleOpenNavMenu}
            component="img"
            src={hambergerHC}
            style={{ height: "70px", width: "70px", marginLeft: "10px", cursor: "pointer" }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", lg: "block" },
            }}>
            {pages.map(page => (
              <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                <Link
                  underline="none"
                  variant="button"
                  color="text.primary"
                  href={page.url}
                  sx={{ my: 1, mx: 1.5 }}>
                  {page.name}
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={6} lg={6} sx={{ textAlign: "center" }}>
          <Box component="img" src={EChokitsalayaLogo} style={{ height: "60px" , marginTop:"10px"}} />
        </Grid>
        <Grid item xs={3} lg={3} sx={{ textAlign: "right" }}>
          <Box
            component="img"
            src={notificationsHC}
            style={{ height: "70px", width: "70px", marginRight: "10px" }}
          />
        </Grid>
      </Grid>

      {/*FORM */}
    </Fragment>
  )
}

export default NavigationBarAshaWorkers
