import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { Fragment } from "react";
import PatientHistoryContent from "./patientHistoryContent";
import { patientHistoryContext } from "../common/context/usercontext";

const PatientHistoryMainComp = (props) => {
  return (
    <Fragment>
      <patientHistoryContext.Provider
        value={{
          patientList: [],
          caseList: [],
          patientId: null,
          patient: null,
        }}
      >
        <Container maxWidth={false} sx={{ mt: 2 }}>
          <Box sx={{ minHeight: "85vh", background: "fff" }}>
            <PatientHistoryContent />
          </Box>
        </Container>
      </patientHistoryContext.Provider>
    </Fragment>
  );
};

export default PatientHistoryMainComp;
