import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

const _ageGroup = ["0-15", "16-30", "31-45", "46-60", "61-75", "75+"];
const RespondentAge = ({ formValues, setFormValues }) => {
  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={2} lg={4}>
          <Typography>Age:</Typography>
        </Grid>
        <Grid item xs={10} lg={8}>
          <RadioGroup
            row
            value={formValues["age"] || ""}
            label={"Select Clinic Location"}
            onChange={(e) => {
              setFormValues({ ...formValues, age: e.target.value });
            }}
          >
            {_ageGroup.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                control={<Radio required />}
                label={item}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RespondentAge;
