import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { saveData } from "../../../services/api/postApiCalls";
import ImageUploadComp from "../comps/imageUploadComp";
import ReactInputMask from "react-input-mask";
import CorporateHomeDemo from "../corporateHome/demo/corporateHomeDemo";
import CorporateHomeDemoReg from "../corporateHome/demoUnderRegistration/corporateHomeDemoReg";
import CorporateHomeMainRegister from "../corporateHomeNew/corporateHomeMainRegister";

const calulateAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const CorpRegistrationMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [formValues, setFormValues] = useState({});
  const [formValuesVitals, setFormValuesVitals] = useState({});

  const _submitHandler = async (e) => {
    e.preventDefault();
    const url = BASE_URL + "org/register";
    const payload = {
      //id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //orgId: "string",
      corpId: corpId,
      ...formValues,
    };

    const employee = await saveData(url, payload);

    if (employee.error) {
      console.log({ "falied to save": employee.error });
      setSeverity("error");
      setMessage("Employee registration failed!");
      setOpenNotice(true);
      setTempEmpId(null);
    } else {
      console.log({ "successfully saved": employee.data });
      setSeverity("success");
      setMessage("Employee registered successfully");
      setOpenNotice(true);
      setTempEmpId(employee.data.empId);
    }
  };
  console.log({ formValues });

  const [tempEmpId, setTempEmpId] = useState(null);

  const _clearFields = () => {
    setTempEmpId(null);
    setFormValues({});
    setFormValuesVitals({});
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  console.log({ formValuesVitals });

  const _sumbitHandler = async () => {
    const url = BASE_URL + "org/detailed";

    const dataOBJ = {
      corpId: corpId,
      empId: tempEmpId,
      ...formValuesVitals,
    };
    const empData = await saveData(url, dataOBJ);

    if (empData.error) {
      console.log({ "empData.error": empData.error });
      setSeverity("error");
      setMessage("Failed to save!");
      setOpenNotice(true);
    } else {
      console.log({ "empData.data": empData.data });
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
      _clearFields();
    }
  };
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Typography variant="h6">{"Register"}</Typography>
      </Box>

      {!tempEmpId && (
        <Container
          maxWidth={false}
          sx={{ backgroundColor: "#F5F5F5", minHeight: "80vh" }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              background: "#F5F5F5",
              border: "0.5px solid #A6A6A6",
              borderRadius: 5,
              minHeight: "40vh",
              p: 2,
            }}
          >
            <form onSubmit={_submitHandler}>
              <Box sx={{ pt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Employee ID"
                      value={formValues.empId || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, empId: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Name"
                      value={formValues.name || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, name: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Autocomplete
                      fullWidth
                      value={formValues.gender || null}
                      onChange={(event, newValue) => {
                        let newFormValues = { ...formValues };
                        newFormValues["gender"] = newValue;
                        setFormValues(newFormValues);
                      }}
                      size="small"
                      disablePortal
                      options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Gender" fullWidth />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Department"
                      value={formValues.department || ""}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          department: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Mobile"
                      value={formValues.mobile || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length < 11
                        ) {
                          setFormValues({
                            ...formValues,
                            mobile: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth"
                        openTo="day"
                        views={["year", "month", "day"]}
                        value={formValues.dateOfBirth || null}
                        onChange={(newValue) => {
                          setFormValues({
                            ...formValues,
                            dateOfBirth: newValue.toISOString().split("T")[0],
                            age: calulateAge(newValue),
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <InsertInvitationIcon />
                            </IconButton>
                          ),
                        }}
                        renderInput={(params) => (
                          <Box
                            sx={{
                              border: "0.5px solid #D4D4D4",
                              borderRadius: "15px",
                              width: "160px",
                              paddingTop: "5px",

                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                              " .MuiIconButton-root": {
                                color: "#127DDD",
                              },
                            }}
                          >
                            <TextField
                              {...params}
                              size="small"
                              style={{ borderRadius: "15px" }}
                            />
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item lg={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Age"
                      value={formValues.age || ""}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          age: e.target.value,
                          dateOfBirth: null,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Designation"
                      value={formValues.designation || ""}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          designation: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="City"
                      value={formValues.city || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, city: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Plant"
                      value={formValues.plant || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, plant: e.target.value });
                      }}
                    />
                  </Grid>

                  {/* {tempEmpId && (
                <Fragment>
                  <Grid item lg={12} xs={12}>
                    <Divider>Vitals</Divider>
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Height(cm)"
                      value={formValuesVitals.height || ""}
                      onChange={(e) => {
                        setFormValuesVitals({
                          ...formValuesVitals,
                          height: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Weight(kg)"
                      value={formValuesVitals.weight || ""}
                      onChange={(e) => {
                        setFormValuesVitals({
                          ...formValuesVitals,
                          weight: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <ReactInputMask
                      mask="999/999"
                      value={formValuesVitals.bp || ""}
                      onChange={(e) => {
                        setFormValuesVitals({
                          ...formValuesVitals,
                          bp: e.target.value,
                        });
                      }}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label="BP(mmHg)"
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="RBS(mg/dL)"
                      value={formValuesVitals.sugar || ""}
                      onChange={(e) => {
                        setFormValuesVitals({
                          ...formValuesVitals,
                          sugar: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <ImageUploadComp
                      formValues={formValues}
                      setFormValues={setFormValues}
                      url={
                        BASE_URL +
                        `org/profilePic/upload?empId=${formValues.empId}&corpId=${corpId}`
                      }
                      label={"Take photo"}
                      property={"imageUrl"}
                    />
                  </Grid>
                </Fragment>
              )} */}

                  <Grid item lg={12} xs={12}>
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      spacing={3}
                    >
                      <Button variant="contained" onClick={_clearFields}>
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={
                          formValues.empId && formValues.name ? false : true
                        }
                      >
                        Submit
                      </Button>
                    </Stack>
                    {/* {tempEmpId ? (
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button variant="contained" onClick={_clearFields}>
                      Clear
                    </Button>
                    <Button variant="contained" onClick={_sumbitHandler}>
                      Save
                    </Button>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button variant="contained" onClick={_clearFields}>
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={
                        formValues.empId && formValues.name ? false : true
                      }
                    >
                      Submit
                    </Button>
                  </Stack>
                )} */}
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Container>
      )}
      <Box>
        {tempEmpId && (
          <CorporateHomeMainRegister
            tempEmpId={tempEmpId}
            setTempEmpId={setTempEmpId}
            _clearFields={_clearFields}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default CorpRegistrationMain;
