import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Portal,
  Stack,
} from "@mui/material";
import { Camera } from "react-camera-pro";
import React, { useState, useRef, Fragment } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { uploadFile } from "../../../services/api/postApiCalls";
import UploadDocuments from "./uploadDocuments";

const TakePicture = ({
  empId,
  url,
  formValues,
  setFormValues,
  label,
  property,
}) => {
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImage(null);
  };

  const getImageFileObject = async (imageFile) => {
    fetch(imageFile)
      .then((res) => res.blob())
      .then((val) => {
        var file = new File([val], property + "my_image.png", {
          type: "image/png",
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        saveImage(formData);
        console.log({ filesdasdasda: file });
      });
  };

  const saveImage = async (data) => {
    const image = await uploadFile(url, data);

    if (image.error) {
      console.log("error");
    } else {
      const data = image.data;
      console.log({ success: data });
      //setFormValues({ id: 0 });
      //setFormValues(data);

      let newFormValues = { ...formValues };
      newFormValues[property] = data[property];
      setFormValues(newFormValues);
      handleClose();
    }
  };

  console.log({ imagesdfas: camera.current });
  return (
    <Fragment>
      <Button
        startIcon={<PhotoCameraIcon sx={{ color: "#fff" }} />}
        size="small"
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
      >
        {label}
      </Button>

      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <Box sx={{ height: "90vh" }}>
              {image ? (
                <Fragment>
                  <img src={image} alt="Taken photo" width="100%" />
                </Fragment>
              ) : (
                <Camera ref={camera} facingMode={"environment"} />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              {image ? (
                <Button
                  variant="contained"
                  onClick={() => getImageFileObject(image)}
                >
                  Upload
                </Button>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => camera.current.switchCamera()}
                  >
                    Switch Camera
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setImage(camera.current.takePhoto())}
                  >
                    Capture
                  </Button>
                </Stack>
              )}
              <UploadDocuments
                url={url}
                handleClose={handleClose}
                formValues={formValues}
                setFormValues={setFormValues}
                property={property}
              />
              <Button onClick={handleClose}>Close</Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default TakePicture;
