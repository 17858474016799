import axios from "axios";
import { Resolver } from "../resolver/resolver";

export async function deleteData(URL, TOKEN) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}
