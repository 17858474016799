import {
  Box,
  Divider,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment } from "react";
import TextFieldComps from "../../comps/textFieldComps";
import { TextFieldList } from "../../dataandpermissions/corpFields";
import TextFieldCompsNew from "../../comps/textFieldCompsNew";
import {
  CustomTypography,
  CustomTypographyBold,
} from "../../comps/customTypography";
import { TextFieldItem } from "../../comps/customTextField";

const EmployeeVitalFormfields = ({
  formValues,
  setFormValues,
  permissions,
  gridSize,
  minHeightVal = "38.5vh",
}) => {
  return (
    <Fragment>
      {/* <Divider textAlign="center">VITALS</Divider> */}

      <Box
        sx={{
          boxSizing: "border-box",
          background: "#FFFFFF",
          border: "0.5px solid #A6A6A6",
          borderRadius: 5,
          minHeight: minHeightVal,
          p: 2,
        }}
      >
        <Stack display="flex" justifySelf="center" alignItems="center">
          <Box
            sx={{
              position: "relative",
              top: -28,
              // left: "10vw",
              background: "#fff",
              width: 140,
              display: "flex",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CustomTypographyBold>Body Stats</CustomTypographyBold>
          </Box>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextFieldCompsNew
              TextFieldList={TextFieldList(permissions)}
              formValues={formValues}
              setFormValues={setFormValues}
              gridSize={gridSize}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack>
              <CustomTypography>Hearing</CustomTypography>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={formValues.hearing}
                exclusive
                onChange={(event, newAlignment) => {
                  let newFormValues = { ...formValues };
                  newFormValues["hearing"] = newAlignment;
                  setFormValues(newFormValues);
                }}
              >
                <ToggleButton
                  sx={{
                    "&.Mui-selected, &.Mui-selected:hover": {
                      color: "white",
                    },
                  }}
                  value="normal"
                >
                  Normal
                </ToggleButton>
                <ToggleButton
                  sx={{
                    "&.Mui-selected, &.Mui-selected:hover": {
                      color: "white",
                    },
                  }}
                  value="impaired"
                >
                  Impaired
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextFieldItem
              title="Doctor's Remark "
              property="doctorsRemark"
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid> */}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeVitalFormfields;
