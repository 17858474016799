import { useDevices, DeviceType } from "@100mslive/react-sdk";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Fragment, useState } from "react";

const DeviceSettings = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const { videoInput, audioInput, audioOutput } = allDevices;
  return (
    <Fragment>
      <Box>
        <Button onClick={handleClickOpen}>
          <SettingsIcon fontSize="large" />
        </Button>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <CusatomSelect
                  title="Camera"
                  value={selectedDeviceIDs.videoInput}
                  list={videoInput}
                  onChange={(e) =>
                    updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.videoInput,
                    })
                  }
                />
              </Grid>
              <Grid item lg={12}>
                <CusatomSelect
                  title="Microphone"
                  value={selectedDeviceIDs.audioInput}
                  list={audioInput}
                  onChange={(e) =>
                    updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.audioInput,
                    })
                  }
                />
              </Grid>
              <Grid item lg={12}>
                <CusatomSelect
                  title="Speaker"
                  value={selectedDeviceIDs.audioOutput}
                  list={audioOutput}
                  onChange={(e) =>
                    updateDevice({
                      deviceId: e.target.value,
                      deviceType: DeviceType.audioOutput,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const CusatomSelect = ({ list, value, onChange, title }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={3}>
          <Typography>{title}:</Typography>
        </Grid>
        <Grid item lg={9}>
          {list?.length ? (
            <Select onChange={onChange} value={value} fullWidth>
              {list.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceSettings;
