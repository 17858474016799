import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";

import { labContext } from "../../common/context/usercontext";
import LabDashborardMainComponent from "../../labDashboardNew/labDashborardMainComponent";
const LabDashboard = (props) => {
  return (
    <Fragment>
      <labContext.Provider value={{ root: props.root }}>
        <MyAppBarAfterLogin />
        <LabDashborardMainComponent root={props.root} />
      </labContext.Provider>
    </Fragment>
  );
};

export default LabDashboard;
