import React, { Fragment } from "react";
import GatherInfo from "../module/gatherInfo/GatherInfo";
import NavbarCorp from "../components/NavbarCorp";
import { Container } from "@mui/material";
import GatherInfoMain from "../module/gatherInfo/gatherInfoMain";

const GatherInfoIndex = () => {
  return (
    <Fragment>
      <NavbarCorp />
      <Container maxWidth={false}>
        <GatherInfo />
        {/* <GatherInfoMain /> */}
      </Container>
    </Fragment>
  );
};

export default GatherInfoIndex;
