import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import PaymentMainComponent from "../../payment/paymentMainComponent";

const Payment = (props) => {
    return (

       
        <Fragment>

            <MyAppBarAfterLogin />
            <PaymentMainComponent root={props.root} />

        </Fragment>
    );
}
 
export default Payment;