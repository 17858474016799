import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL, genderList, SOURCELIST } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApiCalls";
import PatientHistoryMainComp from "../patientRegistrationFormElements/patientHistoryFormComps/patientHistoryMainComp";

const NewPatientRegistrationForm = (props) => {
  const [isOptionalData, setIsOptionalData] = useState(true);
  const [formValues, setFormValues] = useState({
    pFirstName: "",
    pMiddleName: "",
    pLastName: "",
    pContactNumber: "",
    pAge: 0,
    pMonth: 0,
    pSex: "MALE",
    pEmailId: "",
    gFirstName: "",
    gLastName: "",
    gContactNumber: "",
    source: "",
    patientId: 0,
    pHabit: "",
    pPreExcond: "",
    pDrugHistory: "",
    pDrugAllergy: "",
    pOccupation: "",
    pParentalHistory: "",
  });

  let handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const submitHandlerPR = (event) => {
    event.preventDefault();

    const pObj = {
      firstName: formValues.pFirstName,
      middleName: formValues.pMiddleName,
      lastName: formValues.pLastName,
      fatherName: "",
      dateOfBirth: null,
      gender: formValues.pSex,
      email: formValues.pEmailId,
      mobile: formValues.pContactNumber,
      countryCode: "91",
      patientId: 0,
      age: formValues.pAge,
      ageMonths: formValues.pMonth,
      guardianFirstName: formValues.gFirstName,
      guardianLastName: formValues.gLastName,
      guardianContactNumber: formValues.gContactNumber,
      source: formValues.source,
      patientType: "ONLINE",
      habits: formValues.pHabit,
      preExistingConditions: formValues.pPreExcond,
      drugHistory: formValues.pDrugHistory,
      drugAllergy: formValues.pDrugAllergy,
      parentalHistory: formValues.pParentalHistory,
      occupation: formValues.pOccupation,
      addressVM: {
        city: "",
        state: "",
        country: "",
        address: formValues.pAddress,
      },
      pinCode: formValues.pPincode,
      branchId: localStorage.getItem("BRANCH_ID"),
    };
    console.log({ preg_formValues: pObj });
    if (formValues.pFirstName) {
      savePatient(pObj);
    }
  };

  const savePatient = async (obj) => {
    //saveData
    const regPatient = await saveData(BASE_URL + "patient", obj);
    console.log({ "jhsfg adgfakdsgf": regPatient.data });
    props.onAddRegistrationData("REGISTERNEWPATIENT", regPatient);
  };

  const goBackToHome = () => {
    props.onAddRegistrationData("BACK", "");
  };

  const goToNextPage = (event) => {
    event.preventDefault();
    setIsOptionalData(false);
  };

  const goBack = () => {
    setIsOptionalData(true);
  };
  console.log({ formValues: formValues });

  const validateFields = (data) => {
    let isValidated = false;

    if (
      data.pFirstName &&
      data.pContactNumber &&
      data.pContactNumber.length > 9 &&
      data.source &&
      data.pAddress
    ) {
      isValidated = true;
    }

    return isValidated;
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              minHeight: "64vh",
              borderRadius: 2,
              pl: 3,
              pr: 3,
              pt: 2,
            }}
          >
            <form id="patientData" onSubmit={submitHandlerPR}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: isOptionalData ? "flex" : "none",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Typography variant="h6">Patient Information</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="pFirstName"
                      size={"small"}
                      fullWidth
                      label="First Name*:"
                      variant="outlined"
                      value={formValues.pFirstName || ""}
                      onChange={(e) => handleChange(e)}
                      helperText={
                        formValues.pFirstName ? "" : "name can not be empty."
                      }
                      error={formValues.pFirstName ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="pMiddleName"
                      size={"small"}
                      fullWidth
                      label="Middle Name:"
                      variant="outlined"
                      value={formValues.pMiddleName || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="pLastName"
                      size={"small"}
                      fullWidth
                      label="Last Name:"
                      variant="outlined"
                      value={formValues.pLastName || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    <Typography sx={{ pt: 1 }}>Age:</Typography>
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    <TextField
                      fullWidth
                      name="pAge"
                      size={"small"}
                      label="Year"
                      variant="outlined"
                      value={formValues.pAge || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value < 120 &&
                          e.target.value >= 0
                        ) {
                          handleChange(e);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    <TextField
                      fullWidth
                      name="pMonth"
                      size={"small"}
                      label="Month"
                      variant="outlined"
                      value={formValues.pMonth || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value < 12 &&
                          e.target.value >= 0
                        ) {
                          handleChange(e);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    <TextField
                      fullWidth
                      size={"small"}
                      select
                      label="Sex"
                      name="pSex"
                      value={formValues.pSex || ""}
                      onChange={(e) => handleChange(e)}
                      SelectProps={{
                        native: true,
                      }}
                      helperText=""
                    >
                      {genderList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <TextField
                      fullWidth
                      name="pContactNumber"
                      size={"small"}
                      label="Phone Number*:"
                      variant="outlined"
                      value={formValues.pContactNumber || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value < 10000000000 &&
                          e.target.value.length < 11
                        )
                          handleChange(e);
                      }}
                      helperText={
                        formValues.pContactNumber
                          ? formValues.pContactNumber.length < 10
                            ? "Phone must be 10 digits"
                            : ""
                          : "Phone no can not be empty."
                      }
                      error={
                        formValues.pContactNumber
                          ? formValues.pContactNumber.length < 10
                            ? true
                            : false
                          : true
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="pEmailId"
                      size={"small"}
                      fullWidth
                      label="Email ID:"
                      variant="outlined"
                      value={formValues.pEmailId || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Autocomplete
                      freeSolo
                      name="source"
                      size={"small"}
                      fullWidth
                      disableClearable
                      value={formValues.source || ""}
                      onChange={(e, value) => {
                        console.log(value);
                      }}
                      onInputChange={(e, value) => {
                        setFormValues({ ...formValues, source: value });
                      }}
                      options={SOURCELIST.map((option) => option)}
                      renderInput={(params) => (
                        <TextField
                          size={"small"}
                          variant="outlined"
                          {...params}
                          label="Marketing Source:"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          helperText={
                            formValues.source ? "" : "Source can not be empty."
                          }
                          error={formValues.source ? false : true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} lg={10}>
                    <TextField
                      name="pAddress"
                      size={"small"}
                      fullWidth
                      label="Address*:"
                      variant="outlined"
                      value={formValues.pAddress || ""}
                      onChange={(e) => handleChange(e)}
                      helperText={
                        formValues.pAddress ? "" : "address can not be empty."
                      }
                      error={formValues.pAddress ? false : true}
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <TextField
                      name="pPincode"
                      size={"small"}
                      fullWidth
                      label="Pincode*:"
                      variant="outlined"
                      value={formValues.pPincode || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length < 7
                        ) {
                          handleChange(e);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="gFirstName"
                      size={"small"}
                      fullWidth
                      label="Guardian First Name:"
                      variant="outlined"
                      value={formValues.gFirstName || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="gLastName"
                      size={"small"}
                      fullWidth
                      label="Guardian Last Name:"
                      variant="outlined"
                      value={formValues.gLastName || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      type="number"
                      name="gContactNumber"
                      size={"small"}
                      label="Guardian's Contact Number"
                      variant="outlined"
                      value={formValues.gContactNumber || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <PatientHistoryMainComp
                  isOptionalData={isOptionalData}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <Box
            sx={{ minHeight: "5vh", p: 2, background: "#fff" }}
            component={Stack}
            direction="row"
            spacing={2}
            justifyContent={"center"}
          >
            {isOptionalData ? (
              <Button fullWidth variant="contained" onClick={goBackToHome}>
                Back
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={goBack}>
                Back
              </Button>
            )}

            {isOptionalData ? (
              <Button
                fullWidth
                variant="contained"
                onClick={goToNextPage}
                disabled={!validateFields(formValues)}
              >
                Save
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                type="submit"
                form="patientData"
              >
                Next
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NewPatientRegistrationForm;
