import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import React, { Fragment } from "react"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

const OTPField = ({ otp, setOtp, showOTP, setShowOTP }) => {
  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginBottom: "5px" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#6B6B6B",
          }}>
          Enter OTP
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          autoComplete="new-password"
          required
          value={otp}
          type={showOTP ? "text" : "password"}
          onChange={e => {
            if (!isNaN(e.target.value) && e.target.value.length < 7) {
              setOtp(e.target.value)
            }
          }}
          InputLabelProps={{
            style: {
              marginTop: "3px",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#777777",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowOTP(!showOTP)
                  }}
                  onMouseDown={() => {
                    setShowOTP(!showOTP)
                  }}>
                  {showOTP ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Fragment>
  )
}

export default OTPField
