import { useCallback, useState } from "react";
import { getData } from "../../services/api/getApiCalls";

const useHttpGet = (requestConfig, applyData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchDataHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const url = requestConfig.url;
    const user = await getData(url);
    if (user.error) {
      setError(user.error);
      console.log("error");
    } else {
      console.log("success");
      const data = user.data;
      applyData(data);
    }
    setIsLoading(false);
  }, []);

  return { isLoading, error, fetchDataHandler };
};
export default useHttpGet;
