import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import { registerNewPatientContext } from "../../common/context/usercontext";
import PatientRegistrationMain from "../../patientRegistrationNew/patientRegistrationMain";

const PatientRegistrationBeta = (props) => {
  return (
    <Fragment>
      <registerNewPatientContext.Provider
        value={{ root: props.root, patientList: [], caseList: [] }}
      >
        <MyAppBarAfterLogin />
        <PatientRegistrationMain />
      </registerNewPatientContext.Provider>
    </Fragment>
  );
};

export default PatientRegistrationBeta;
