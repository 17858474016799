import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { updateData } from "../../../services/api/patchApi";
import DrugAllergyFormComp from "./drugAllergyFormComp";
import DrugHistoryFormComp from "./drugHistoryFormComp";
import LifeStyleChoicesFormComp from "./lifeStyleChoicesFormComp";
import OccupationFormComp from "./occupationFormComp";
import ParentalHistoryFormComp from "./parentalHistoryFormComp";
import PreExCondFormComp from "./preExCondFormComp";
import Autocomplete from "../../../common/forms/autocomplete";
const PatientHistoryMainComp = ({
  patientId,
  patientData,
  handleClose,
  setIsPatientInfo,
}) => {
  console.log({ patientInfo0123: patientData });
  const [formValues, setFormValues] = useState({
    patientId: patientId,
    pHabit: patientData.habits,
    pPreExcond: patientData.preExistingConditions,
    pDrugHistory: patientData.drugHistory,
    pDrugAllergy: patientData.drugAllergy,
    pParentalHistory: patientData.parentalHistory,
    pOccupation: patientData.occupation,
    menstualHistory: patientData.menstualHistory,
    obstraticHistory: patientData.obstraticHistory,
    maritalHistory: patientData.maritalHistory,
    surgicalHistory: patientData.surgicalHistory,
    personalHistory: patientData.personalHistory,
  });

  const updateValue = (e) => {
    e.preventDefault();
    const obj = {
      patientId: patientId,
      habits: formValues.pHabit,
      preExistingConditions: formValues.pPreExcond,
      drugHistory: formValues.pDrugHistory,
      drugAllergy: formValues.pDrugAllergy,
      parentalHistory: formValues.pParentalHistory,
      occupation: formValues.pOccupation,
      menstualHistory: formValues.menstualHistory,
      obstraticHistory: formValues.obstraticHistory,
      maritalHistory: formValues.maritalHistory,
      surgicalHistory: formValues.surgicalHistory,
      personalHistory: formValues.personalHistory,
    };

    updateFieldData(obj);
  };
  const updateFieldData = async (data) => {
    const url = BASE_URL + "patient/medicalHistory";
    const response = await updateData(url, data, "");

    if (response.error) {
      console.log("error");
    } else {
      console.log("success");
      setIsPatientInfo(false);
    }
  };

  const bookAppointment = () => {
    setIsPatientInfo(false);
  };
  console.log({ patientInfo: formValues });
  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
          pt: 0.5,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h6">Patient Optional Information</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PreExCondFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DrugHistoryFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DrugAllergyFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LifeStyleChoicesFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <OccupationFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ParentalHistoryFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          {patientData.gender === "FEMALE" ? (<Fragment><Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Menstual History"
              keyValue={"menstualHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Obstratic History"
              keyValue={"obstraticHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Marital History"
              keyValue={"maritalHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Surgical History"
              keyValue={"surgicalHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Personal History"
              keyValue={"personalHistory"}
            />
          </Grid></Fragment>) : false}
          <Grid item xs={12} lg={12}>
            <Stack
              direction="row"
              spacing={2}
              display="flex"
              justifyContent="center"
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outlined" onClick={updateValue}>
                save
              </Button>
              <Button variant="outlined" onClick={bookAppointment}>
                Book Appointment
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientHistoryMainComp;
