import { Divider, Grid, Stack, Typography } from "@mui/material"
import { Fragment } from "react"

const PatientMedicalHistory = props => {
  const patientMedicalHistoryInfo = props.patientMedicalHistoryInfo
    ? props.patientMedicalHistoryInfo
    : ""

  let preExistingConditions = ""
  let drugHistory = ""
  let drugAllergy = ""
  let habits = ""
  let occupation = ""
  let parentalHistory = ""

  if (patientMedicalHistoryInfo) {
    preExistingConditions = patientMedicalHistoryInfo.preExistingConditions
    drugHistory = patientMedicalHistoryInfo.drugHistory
    drugAllergy = patientMedicalHistoryInfo.drugAllergy
    habits = patientMedicalHistoryInfo.habits
    occupation = patientMedicalHistoryInfo.occupation
    parentalHistory = patientMedicalHistoryInfo.parentalHistory
  }

  return (
    <Fragment>
      <Grid container>
        <Stack direction={"row"} spacing={2}>
          {preExistingConditions ? (
            <Stack direction={"row"} display="flex" alignItems={"center"} marginTop={"3px"}>
              <Typography sx={styles.Mainheading}>Pre-Existing Conditions:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {preExistingConditions} </Typography>
            </Stack>
          ) : (
            ""
          )}

          {drugHistory ? (
            <Stack
              direction={"row"}
              display="flex"
              alignItems={"center"}
              marginTop={"3px"}
              marginLeft="20px">
              <Typography sx={styles.Mainheading}>Drug History:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {drugHistory} </Typography>
            </Stack>
          ) : (
            ""
          )}

          {drugAllergy ? (
            <Stack direction={"row"} display="flex" alignItems={"center"} marginTop={"3px"}>
              <Typography sx={styles.Mainheading}>Drug Allergy:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {drugAllergy} </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
        <Stack direction={"row"} spacing={2}>
          {habits ? (
            <Stack direction={"row"} display="flex" alignItems={"center"} marginTop={"3px"}>
              <Typography sx={styles.Mainheading}>Habits:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {habits} </Typography>
            </Stack>
          ) : (
            ""
          )}

          {occupation ? (
            <Stack direction={"row"} display="flex" alignItems={"center"} marginTop={"3px"}>
              <Typography sx={styles.Mainheading}>Occupation:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {occupation} </Typography>
            </Stack>
          ) : (
            ""
          )}

          {parentalHistory ? (
            <Stack direction={"row"} display="flex" alignItems={"center"} marginTop={"3px"}>
              <Typography sx={styles.Mainheading}>Parental History:&nbsp;</Typography>
              <Typography sx={styles.headingData}> {parentalHistory} </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </Grid>
    </Fragment>
  )
}

export default PatientMedicalHistory

const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    marginTop: "5px",
    color: "#127DDD",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
    mt: "5px",
  },
}
