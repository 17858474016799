import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import LogoutHC from "../../healthcamp/logout/logoutHC";

const LogoutPageHC = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <LogoutHC />
    </Fragment>
  );
};

export default LogoutPageHC;
