import { Box, Divider, Grid, Typography, Stack, Tooltip } from "@mui/material";
import LogoMain from "../logo/logoMain";

const PrintPatientHeader = ({ patient }) => {
    return (
        <Box>
            {patient ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                width: { lg: "820px", md: "820px", sm: "820px", xs: "250px" },
                                height: "90px",
                                mb: 2,
                                background: "#F4FBFF",
                                pl: 2,
                                pr: 2,
                            }}>
                            <Grid container>
                                <Grid item lg={6} xs={6} pt={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography
                                            sx={{
                                                color: "#6B6B6B",
                                                fontSize: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                                            }}>
                                            Name:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                textTransform: "capitalize",
                                                fontSize: { xs: "7px", sm: "15px", md: "15px", lg: "15px" },
                                            }}>
                                            {patient.name}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Typography
                                            sx={{
                                                color: "#6B6B6B",
                                                fontSize: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                                            }}>
                                            Phone Number:
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: { xs: "7px", sm: "15px", md: "15px", lg: "15px" } }}>
                                            {patient.mobile}
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid item lg={6} xs={6}>
                                    <Stack direction="row" spacing={2}>
                                        {/* <Typography>Phone Number:</Typography>
                    <Typography>{patient.mobile}</Typography> */}
                                    </Stack>

                                    <Grid
                                        item
                                        lg={6}
                                        xs={6}
                                        sx={{ ml: { lg: 50, md: 0, sm: 50, xs: 9 } }}
                                        display="flex"
                                        justifyContent="flex-end">
                                        <Tooltip title="Click to Print">
                                            <a onClick={() => window.print()}>
                                                <img
                                                    src={`data:image/png;base64,${patient.qrCode}`}
                                                    alt="example"
                                                    height="90px"
                                                    width="85px"
                                                />
                                            </a>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                ""
            )}
        </Box>
    );
};

export default PrintPatientHeader;
