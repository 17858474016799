import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import RegisterVitalsHC from "../../healthcamp/vitals/registerVitalsHC";

const VitalsPageHC = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <RegisterVitalsHC />
    </Fragment>
  );
};

export default VitalsPageHC;
