import { Box, Grid, Stack, Typography } from "@mui/material"

const PrintPrescribedTests = props => {
  const reportList = props.reportList
  return (
    <Grid container display="flex">
      <Stack direction={"row"} display="flex" alignItems={"center"}>
        <Typography sx={styles.Mainheading}>Prescribed Tests:&nbsp;</Typography>
      </Stack>

      <Stack direction={"row"} display="flex" alignItems={"center"} wrap="wrap">
        <Typography sx={styles.headingData}>
          {reportList
            .filter((report, index) => !report.broughtByPatient)
            .map((report, index) => (
              <span key={index}>
                {report.reportName} {index < reportList.length - 1 ? "," : ""}
                &nbsp;
              </span>
            ))}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default PrintPrescribedTests
const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    marginTop: "5px",
    color: "#127DDD",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "15px",
    color: "#000000",
    marginTop: "5px",
  },
}
