import { Fragment } from "react";
import BranchNameMainComponent from "../../branchName/branchNameMainComponent";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";


const BranchName = (props) => {
    return (

       
        <Fragment>

            <MyAppBarAfterLogin />
            <BranchNameMainComponent root={props.root} />

        </Fragment>
    );
}
 
export default BranchName;