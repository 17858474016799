import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  MenuItem,
  Stack,
} from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import { useEffect } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Item = ({ item }) => {
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
          <Grid container>
            <Grid item lg={3} xs={3}>
              <Typography> {item?.staffFullName} </Typography>
            </Grid>
            <Grid item lg={3} xs={3}>
              <Typography> {item?.mobile} </Typography>
            </Grid>
            <Grid item lg={2} xs={2} display="flex">
              <Typography> {item?.count} </Typography>
            </Grid>
            <Grid item lg={2} xs={2}>
              <Typography> {item?.target} </Typography>
            </Grid>
            <Grid item lg={2} xs={2}>
              <Typography> {(item?.count * 100) / item?.target} </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: { xs: "flex", lg: "none" } }}>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <Typography> {item?.staffFullName} </Typography>
            </Grid>
            <Grid item lg={2} xs={3} display="flex" justifyContent="center">
              <Typography>
                {item?.count}/{item?.target}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={3} display="flex" justifyContent="flex-end">
              <Typography>
                {(item?.count * 100) / item?.target}
                {"%"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

const Index = () => {
  const [associateList, setAssociateList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (date) {
      getDashboardData(
        date ? date.toISOString().split("T")[0] : "",
        startDate ? startDate.toISOString().split("T")[0] : ""
      );
    }
  }, [date, startDate]);

  const getDashboardData = async (d, startDate) => {
    const URL =
      BASE_URL +
      "healthcamp/marketing/dashboard/" +
      d +
      "?startDate=" +
      startDate;

    const associateHeads = await getData(URL);

    if (associateHeads.error) {
      setAssociateList([]);
    } else {
      setAssociateList(associateHeads.data);
    }
  };

  console.log({ associateList });
  const [filter, setFilter] = useState("daily");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    if (e.target.value === "daily") {
      setStartDate(new Date());
      setDate(new Date());
    } else if (e.target.value === "weekly") {
      const sevenDaysBefore = new Date();
      sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);
      setStartDate(sevenDaysBefore);
      setDate(new Date());
    } else if (e.target.value === "monthly") {
      const thirtyDaysBefore = new Date();
      thirtyDaysBefore.setDate(thirtyDaysBefore.getDate() - 30);
      setStartDate(thirtyDaysBefore);
      setDate(new Date());
    } else {
      setStartDate(null);
      setDate(null);
    }
  };

  return (
    <Fragment>
      <Container disableGutters maxWidth={false}>
        <Box
          sx={{ minHeight: "9vh", mt: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container>
            <Grid
              item
              lg={12}
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{ borderBottom: "3px solid #127DDD" }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 3, textTransform: "uppercase" }}
              >
                Dashboard
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{ mx: 1, mt: 3 }}
            >
              <FormControl sx={{ width: 150, mr: 3, mb: 1 }} size="small">
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  value={filter}
                  label="Filter"
                  onChange={handleFilterChange}
                  sx={{
                    width: "140px",
                  }}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Last Week</MenuItem>
                  <MenuItem value="monthly">Last Month</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>
              <Stack spacing={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {filter !== "daily" && (
                    <DatePicker
                      label="Start Date"
                      disableMaskedInput={true}
                      inputFormat="dd/MMM/yy"
                      disabled={filter !== "custom"}
                      views={["year", "month", "day"]}
                      openTo="day"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      disableFuture={true}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            mr: 1,
                            width: "150px",
                          }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  )}

                  <DatePicker
                    label={filter == "daily" ? "Today" : "End Date"}
                    inputFormat="dd/MMM/yy"
                    disableMaskedInput={true}
                    disabled={filter !== "custom" && filter !== "daily"}
                    views={["year", "month", "day"]}
                    openTo="day"
                    value={date}
                    onChange={(newValue) => {
                      if (filter == "daily") {
                        setDate(newValue);
                        setStartDate(newValue);
                      } else {
                        setDate(newValue);
                      }
                    }}
                    disableFuture={true}
                    minDate={filter == "custom" ? startDate : false}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        sx={{
                          width: "150px",
                        }}
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Container>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Box sx={{ pt: 5, display: { xs: "none", lg: "flex" } }}>
                  <Grid container>
                    <Grid item lg={3} xs={3}>
                      <Typography variant="h6"> Staff Name </Typography>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                      <Typography variant="h6"> Mobile </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <Typography variant="h6"> Count </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <Typography variant="h6"> Target </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <Typography variant="h6"> % Achieved </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ pt: 5, display: { xs: "flex", lg: "none" } }}>
                  <Grid container>
                    <Grid item lg={3} xs={6}>
                      <Typography variant="h6"> Staff Name </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      xs={3}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="h6"> Count </Typography>
                    </Grid>

                    <Grid item lg={2} xs={3}>
                      <Typography
                        variant="h6"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        Achieved
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {associateList.map((item, index) => (
                <Grid item lg={12} xs={12} key={index}>
                  <Item item={item} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Index;
