export const getOrgPermissions = (corpId) => {
  let permissions = {};
  switch (corpId) {
    case "bbb269e5-b020-4257-ad8f-4da8c811801a": //case construction
      permissions = caseconstruction_permissions;
      break;

    case "2751db43-138d-4be9-8720-30e3b9130548": //coromandel
      permissions = coromandel_permissions;
      break;

    case "8047e6d8-e51b-4d6d-b3e2-bc0ccd13be25": //daawat
      permissions = daawat_permissions;
      break;

    case "e78bd9d1-06f0-4701-9c0d-8e1d1a79fe69": //jalpa
      permissions = jalpa_permissions;
      break;

    case "76cfdb38-722a-4f77-8f67-09d6318e2667": //kachmotors
      permissions = kachmotors_permissions;
      break;

    case "d56ab78a-98ab-4bb1-81de-73a235b12c64": //liugong
      permissions = liugong_permissions;
      break;

    case "b10d9fce-34a9-4a5b-b728-0550bb9014ac": //maraloverseas
      permissions = maraloverseas_permissions;
      break;

    case "1ab48481-e6f5-4dd6-8b50-983c94d763a1": //nationalsteel
      permissions = nationalsteel_permissions;
      break;

    case "1f7b5e98-04ee-47e5-b6cd-300f0dc98281": //nouvelle
      permissions = nouvelle_permissions;
      break;

    case "556fa0e4-d7e9-4c4e-ad55-6f041d49c700": //syntho
      permissions = syntho_permissions;
      break;

    default:
      permissions = default_permissions;
  }

  return permissions;
};

const default_permissions = {
  organization: "Default",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: true,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};

const caseconstruction_permissions = {
  organization: "Case Construction",
  isEnabled: true,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: false,
  xrayDone: false,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: false,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: false,
  xrayUrl: false,
  xrayFilmUrl: false,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: false,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const coromandel_permissions = {
  organization: "Coromandel",
  isEnabled: true,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: true,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const daawat_permissions = {
  organization: "Daaawat",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: true,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const jalpa_permissions = {
  organization: "Jalpa",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: true,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const kachmotors_permissions = {
  organization: "Kach Motors",
  isEnabled: true,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: false,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: false,
  xrayFilmUrl: false,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const liugong_permissions = {
  organization: "Liugong",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: false,
  eyeTest: false,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: false,
  eyeTestUrl: false,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const maraloverseas_permissions = {
  organization: "Maral Overseas",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: false,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: false,
  xrayDone: false,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: false,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: false,
  xrayUrl: false,
  xrayFilmUrl: false,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: false,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const nationalsteel_permissions = {
  organization: "National Steel",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: false,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: true,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: false,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: true,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: false,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const nouvelle_permissions = {
  organization: "Nouvelle",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: false,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: true,
  stoolSampleCollected: false,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: true,
  audiometryUrl: true,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};
const syntho_permissions = {
  organization: "Syntho",
  isEnabled: false,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: false,
  hairs: false,
  nails: false,
  bmi: false,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,
  glass: false,
  cataract: false,
  eyeOperation: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,

  ecg: false,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: false,
  stoolSampleCollected: false,
  audiometryDone: false,
  pft: false,
  eyeTest: false,
  cbc: false,
  serumBilirubin: false,
  fitnessCertificate: false,
  hbsAgPFSampleCollected: false,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: false,
  miscellaneousUrl: false,
  ecgUrl: false,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: false,
  audiometryUrl: false,
  fitnessCertificateUrl: false,
  serumBilirubinUrl: false,
  pftUrl: false,
  eyeTestUrl: false,
  cbcUrl: false,
  stoolTestUrl: false,
  hbsAgPFTestUrl: false,
  physicalFitnessCertificateUrl: false,
  form35Url: false,
  physicalFitnessFormUrl: false,

  /////uploads end
};

const default_permissions_static = {
  organization: "Default",
  isEnabled: true,
  ////vitals start
  height: true,
  weight: true,
  bp: true,
  sugar: true,
  bloodGroup: true,
  bodyBuilt: true,
  hairs: true,
  nails: true,
  bmi: true,

  //////vitals end

  /////toggles start
  fitToWork: true,
  eyeSightOk: true,
  farSighted: true,
  nearSighted: true,
  glass: true,
  cataract: true,
  smoking: true,
  alchohol: true,
  panChewing: true,
  gambling: true,
  drugAddiction: true,
  eyeOperation: true,

  ecg: true,
  xrayDone: true,
  bloodSampleCollected: true,
  urineSampleCollected: true,
  stoolSampleCollected: true,
  audiometryDone: true,
  pft: true,
  eyeTest: true,
  cbc: true,
  serumBilirubin: true,
  fitnessCertificate: true,
  hbsAgPFSampleCollected: true,
  /////toggles end

  /////uploads start

  imageUrl: true,
  prescriptionUrl: true,
  miscellaneousUrl: true,
  ecgUrl: true,
  xrayUrl: true,
  xrayFilmUrl: true,
  bloodTestUrl: true,
  urineTestUrl: true,
  audiometryUrl: true,
  fitnessCertificateUrl: true,
  serumBilirubinUrl: true,
  pftUrl: true,
  eyeTestUrl: true,
  cbcUrl: true,
  stoolTestUrl: true,
  hbsAgPFTestUrl: true,
  physicalFitnessCertificateUrl: true,
  form35Url: true,
  physicalFitnessFormUrl: true,

  /////uploads end

  // empId: "string",
  // gender: "MALE",
  // name: "string",
  // mobile: "string",
  // dateOfBirth: "string",
  // age: "string",
  // department: "string",
  // packageName: "string",

  // hearing: "string",
  // doctorsRemark: "string",

  // nearLeftEyeSight: "string",
  // nearRightEyeSight: "string",
  // farLeftEyeSight: "string",
  // farRightEyeSight: "string",
};
