import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import prescription2 from "../../assets/images/prescription2.png"

const PrintPrescriptions = props => {
  const medicines = props.prescriptionList
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          sx={{ justifyContent: "center", alignItems: "center", marginY: "10px" }}>
          <img
            src={prescription2}
            alt=""
            style={{ width: "16px", height: "16px", marginRight: "10px" }}
          />
          <Typography
            sx={{
              color: "#127DDD",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "14px",
              textAlign: "center",
              textTransform: "uppercase",
            }}>
            Prescription
          </Typography>
        </Grid>
        <Box
          sx={{
            minHeight: "1vh",
            background: "#C0E5FF",
            p: 1,
            borderRadius: "5px",
          }}>
          <Grid container sx={{ mb: 1 }} columns={36}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Medicine
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Dose
              </Typography>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Frequency
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Timings
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Days
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#127DDD", textTransform: "uppercase" }}>
                Instructions
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* -------------------MEDICINE PRESCRIPTION-------------------*/}

        {medicines.map((medicine, index) => (
          <Box
            sx={{
              minHeight: "3vh",
              background: "#F1F8FF",
              borderRadius: "5px",
              paddingX: 1,
              my: "5px",
            }}
            key={index}>
            <Grid container columns={36}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption">{medicine.medicineName}</Typography>
              </Grid>

              <Grid item lg={3} md={3} sm={3} xs={3}>
                {<Typography variant="caption">{medicine.dosage}</Typography>}
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Stack>
                  <Typography variant="caption">{medicine.frequencyString}</Typography>
                  <Typography variant="caption">
                    {medicine.frequencyStringVernacular
                      ? "(" + medicine.frequencyStringVernacular + ")"
                      : ""}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Stack>
                  <Typography variant="caption"> {medicine.timing} </Typography>

                  <Typography variant="caption">
                    {" "}
                    {(medicine.m ? "सुबह" : "") +
                      (medicine.m && (medicine.a || medicine.e || medicine.n) ? "-" : "") +
                      (medicine.a ? "दोपहर बाद" : "") +
                      (medicine.a && (medicine.e || medicine.n) ? "-" : "") +
                      (medicine.e ? "शाम" : "") +
                      (medicine.e && medicine.n ? "-" : "") +
                      (medicine.n ? "रात" : "")}{" "}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography variant="caption">{medicine.durationString}</Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Stack>
                  <Typography variant="caption">{medicine.comment}</Typography>
                  <Typography variant="caption">
                    {medicine.commentVernacular ? "(" + medicine.commentVernacular + ")" : ""}
                  </Typography>
                </Stack>
              </Grid>
              {/* <Grid item lg={36} md={36} sm={36} xs={36}>
                                <Divider />
                            </Grid> */}
            </Grid>
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}

export default PrintPrescriptions
