import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { getFormattedDayAndDate } from "../../../assets/utils";

const ShowCurrentDate = () => {
  const [date, setDate] = useState(getFormattedDayAndDate(new Date()));
  return (
    <Box>
      <Typography>{date}</Typography>
    </Box>
  );
};

export default ShowCurrentDate;
