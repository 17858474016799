import axios from "axios";
import { BASE_URL } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

///////...GET APIs...///////

export async function getData(url) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(axios.get(url, { headers }).then((res) => res.data));
}

export async function getDataWithoutToken(url) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await Resolver(axios.get(url, { headers }).then((res) => res.data));
}

export async function getPatientListByDocId(docId) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "doctor/basic/" + docId, { headers })
      .then((res) => res.data)
  );
}

export async function getSpecialization() {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "clinic/getSpecializations", { headers })
      .then((res) => res.data)
  );
}

export async function getDoctorsBySpecialization(specialization) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "clinic/searchDoctor/" + specialization, { headers })
      .then((res) => res.data)
  );
}

export async function getDoctorsBySpAndDate(specialization, date) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(
        BASE_URL + "clinic/searchDoctor/" + specialization + "?date=" + date,
        { headers }
      )
      .then((res) => res.data)
  );
}

export async function getUserToken(url, data) {
  return await Resolver(
    axios(url, {
      method: "POST",
      data: data,
    }).then((res) => res.data)
  );
}
