export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

export function getCurrentDateFormatted(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let monthStr = m[month-1];
        
    return `${date}${separator}${monthStr}${separator}${year}`
    }


    export function getDelay(time){
    
        let str = "";
    
        if( time !== null && typeof(time) !== "undefined" ){
            
       
        let delay = 0;
        let newDate_current = new Date()
        let hour_current = newDate_current.getHours();
        let minutes_current = newDate_current.getMinutes();
    
        let d = time.split(":");
        let d1 = d[1].split(" ");
    
        let th = parseInt(d[0]);
        let tm = parseInt(d1[0]);
        let tp = d1[1];
    
        if(tp==="PM"){
            th = th + 12;
        }
    
    
        delay =    (hour_current-th)*60 +  (minutes_current-tm);
    
        if(delay<0){
            delay =    (th-hour_current)*60 +  (tm-minutes_current);
            const h = Math.floor(delay/60);
            const m = delay % 60;
            str = (h + "h " + m + "m to start");
        } else {
            delay =    (hour_current-th)*60 +  (minutes_current-tm);
            const h = Math.floor(delay/60);
            const m = delay % 60;
            str = (h + "h " + m + "m overdue");
        }
    
    }
     
        return str;
        
        }


        export const getFormattedDayAndDate = (date) => {

            const d = new Date(date).toUTCString(  ).split(' ');
            const nd = d[0]+' '+d[1]+' '+d[2]+' '+d[3];
            return nd;
        }
    
    
        export const getFormattedDDMonthYYYY = (date) => {
    
            const d = new Date(date).toUTCString(  ).split(' ');
            const nd = d[1]+' '+d[2]+' '+d[3];
            return nd;
        }