export const BASE_URL = "https://apibackend.uno.care/api/";
//export const BASE_URL = "https://apitest.uno.care/api/";
export const BASE_URL_ATOZ = "https://apitest.uno.care/api/";

export const BASE_URL_AUTH = "https://auth.uno.care/";

export const PRIMARY_CUSTOM = "#127DDD";

export const WHITE_CUSTOM = "#FFFFFF";

export const PATAIENT_TYPE = "ONLINE";

export const paymentMode = [
  {
    value: "CASH",
    label: "CASH",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "CARD",
    label: "CARD",
  },
];

export const CASHIER = [
  {
    value: "RIYA",
    label: "RIYA",
  },
  {
    value: "VARDHAN",
    label: "VARDHAN",
  },
  {
    value: "VIPUL",
    label: "VIPUL",
  },
];

export const genderList = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
  {
    value: "DONOTDISCLOSE",
    label: "DONOTDISCLOSE",
  },
];

export const REPORTTYPE = [
  {
    value: "REPORT",
    label: "REPORT",
  },
  {
    value: "PHOTO",
    label: "PHOTO",
  },
];

export const userList = ["admin", "abhisheksingh", "vardhan", "vipul"];
export const userListDetailed = {
  admin: ["admin", "password"],
  abhisheksingh: ["abhisheksingh", "password1"],
  vardhan: ["vardhan", "password2"],
  vipul: ["vipul", "password3"],
};

export const ZoomRoom_Params = "?timer=on&floatSelf";
export const zoomRooms = {
  697: ["Vijay kumar", "https://ehospit.whereby.com/patient1"],
  19: ["Sushma Saxena", "https://ehospit.whereby.com/patient2"],
  23: ["Varun Mishra", "https://ehospit.whereby.com/patient3"],
  11: ["admin", "https://apna-clinic.whereby.com/echikitsalaya"],
  695: ["adbhut", "https://ehospit.whereby.com/patient1"],
};

export const SOURCELISTOLD = [
  "Relative/Friend",
  "Newspaper",
  "Pamphlet",
  "Healthcard",
  "Health Booklet",
  "Self",
  "Sms",
  "Healthcamp",
  "Whatsapp",
  "Local visit",
  "Other",
];

export const SOURCELIST = ["Relative/Friend", "Self", "Marketing Team"];
