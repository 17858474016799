import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { BASE_URL, paymentMode } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApiCalls";

const PharmacyRegistrationForm = (props) => {
  let patientId = props.patientId;

  const [formDataPharmacy, setFormDataPharmacy] = useState({
    pAmmountPayable: 0,
    pAmmountReceived: 0,
    pPayMode: "CASH",
  });
  let handleChangePharmacy = (e) => {
    setFormDataPharmacy({
      ...formDataPharmacy,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandlerP = (event) => {
    event.preventDefault();

    const pObj = {
      pharmacyId: localStorage.getItem("PHARMACY_ID"),
      patientId: patientId,
      paymentVM: {
        amountPayable: formDataPharmacy.pAmmountPayable,
        amountCollected: 0,
        finalAmountPayable: formDataPharmacy.pAmmountPayable,
        branchId: localStorage.getItem("BRANCH_ID"),
        appointmentSource: "PHARMACY",
      },
      appointmentSource: "PHARMACY",
    };
    console.log({ "formDataPharmacy_to be saved": pObj });
    if (patientId) {
      bookPres(pObj);
    }
  };

  const bookPres = async (obj) => {
    const bApp = await saveData(BASE_URL + "pharmacy/register", obj);

    props.onBookPharmacy(bApp);

    console.log({ formDataPharmacy_afterSubmit: bApp.data });
  };

  return (
    <form id="consultReg" onSubmit={submitHandlerP}>
      <Box
        sx={{
          minHeight: "47vh",
          background: "#fff",
          borderRadius: 2,
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              size={"small"}
              fullWidth
              name="pAmmountPayable"
              label="Total Amount"
              variant="outlined"
              value={formDataPharmacy.pAmmountPayable || ""}
              onChange={(e) => handleChangePharmacy(e)}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: "5vh",
          background: "#fff",
          p: 2,
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button variant="contained" href="/registration">
                Back
              </Button>
              <Button variant="contained" type="submit">
                Book Prescription
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default PharmacyRegistrationForm;
