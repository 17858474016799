import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback, useEffect, useState } from "react";
import PatientListPharmacyUpcoming from "./patientListPharmacyUpcoming";
import PatientListPharmacyAttended from "./patientListPharmacyAttended";
import { getData } from "../services/api/getApiCalls";
import {
  BASE_URL,
  PRIMARY_CUSTOM,
  WHITE_CUSTOM,
} from "../assets/constantsFile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

const TableContentPharmacy = (props) => {
  const [value, setValue] = useState("1");
  const [dateValue, setDateValue] = useState(new Date());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [flag, setFlag] = useState(true);

  const tabClick = (event, newValue) => {
    setFlag(!flag);
  };

  const [patients, setPatients] = useState([]);
  const [state, setState] = useState();

  const fetchPataientsHandler = useCallback(async (date) => {
    const user = await getData(
      BASE_URL +
        "pharmacy/basic/" +
        localStorage.getItem("PHARMACY_ID") +
        "?date=" +
        date
    );
    if (user.error) {
      setState({ error: user.error });
    } else {
      setState({ data: user.data });

      const data = user.data;
      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          patientId: patientData.patientId,
          patientName: patientData.patientName,
          age: patientData.age,
          gender: patientData.gender,
          mobileNo: patientData.mobileNo,
          printed: patientData.printed,
          paid: patientData.paid,
          prescriptions: patientData.prescriptionVM
            ? patientData.prescriptionVM.medicines
            : [],
          prescriptionVM: {
            patientId: patientData.prescriptionVM,
            patientName: patientData.patientName,
            age: patientData.age,
            gender: patientData.gender,
            mobileNo: patientData.mobileNo,
            prescriptions: patientData.prescriptionVM,
            directBooking: patientData.directBooking,
          },
          attended: patientData.paid,
          billAmmount: patientData.paymentVM.amountCollected,
          paymentVM: {
            paymentVM: patientData.paymentVM,
            patientId: patientData.patientId,
            directBooking: patientData.directBooking,
          },
          directBooking: patientData.directBooking,
          printPrescription: patientData,
        };
      });
      setPatients(transformedPatients);
    }
  }, []);

  useEffect(() => {
    fetchPataientsHandler(dateValue.toISOString().split("T")[0]);
  }, [dateValue, flag]);

  console.log({ prescriptions_pharmacy: patients });

  return (
    <Grid container spacing={0}>
      <Grid item lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                minHeight: "8vh",
                width: "100%",
              }}
            >
              <Grid container spacing={0}>
                <Grid xs={12} item lg={10}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      onClick={tabClick}
                      label="Upcoming Patients"
                      value="1"
                      sx={{
                        width: 300,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                    <Tab
                      onClick={tabClick}
                      label="Attended Patients"
                      value="2"
                      sx={{
                        width: 300,
                        height: 51,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        ml: 2,
                        color: WHITE_CUSTOM,
                        backgroundColor: PRIMARY_CUSTOM,
                      }}
                    />
                  </TabList>
                </Grid>
                <Grid xs={12} item lg={2}>
                  <Paper
                    elevation={0}
                    component={Stack}
                    justifyContent="flex-end"
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        openTo="day"
                        views={["year", "month", "day"]}
                        value={dateValue}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: "100%",
                background: "#fff",
                height: "75vh",
                borderRadius: 2,
              }}
            >
              <TabPanel value="1">
                <PatientListPharmacyUpcoming
                  patients={patients}
                  root={props.root}
                />
              </TabPanel>
              <TabPanel value="2">
                <PatientListPharmacyAttended
                  patients={patients}
                  root={props.root}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableContentPharmacy;
