import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Popover, Typography, Button } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { VITALS_SUGAR } from "../../assets/constantsText";
import { getData } from "../../services/api/getApiCalls";
import TitleHC from "../common/title/titleHC";
import AddVitalsHC from "./AddVitalsHC";

const RegisterVitalsHC = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [isHeight, setIsHeight] = useState(true);
    const [isWeight, setIsWeight] = useState(true);
    const [isEyeSight, setIsEyeSight] = useState(true);
    const [isBP, setIsBP] = useState(true);

    const [isSugar, setIsSugar] = useState(true);

    const [patient, setPatient] = useState(null);
    const [isPatient, setisPatient] = useState(false);
    const [searchToken, setSearchToken] = useState("");

    const searchTokenHandler = (e, flag = true) => {
        flag && e.preventDefault();
        getPatientByToken(searchToken);
    };
    const getPatientByToken = async (token) => {
        const url = BASE_URL + "healthcamp/getToken/" + token;

        const patientData = await getData(url);

        if (patientData.error) {
            console.log("error");
            setisPatient(false);
        } else {
            setPatient(patientData.data);
            console.log({ success: patientData.data });
            setisPatient(true);
        }
    };

    return (
        <Fragment>
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}>
                    <Box sx={{ width: 150 }}>
                        <FormGroup>
                            <Grid container>
                                <Grid item lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isHeight}
                                                onChange={(e) => {
                                                    setIsHeight(e.target.checked);
                                                    localStorage.setItem("HEIGHT", e.target.checked);
                                                }}
                                            />
                                        }
                                        label="Height&nbsp;&nbsp;&nbsp;&nbsp;"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isWeight}
                                                onChange={(e) => {
                                                    setIsWeight(e.target.checked);
                                                    localStorage.setItem("WEIGHT", e.target.checked);
                                                }}
                                            />
                                        }
                                        label="Weight&nbsp;&nbsp;&nbsp;&nbsp;"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isEyeSight}
                                                onChange={(e) => {
                                                    setIsEyeSight(e.target.checked);
                                                    localStorage.setItem("EYESIGHT", e.target.checked);
                                                }}
                                            />
                                        }
                                        label="Eye Sight"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isBP}
                                                onChange={(e) => {
                                                    setIsBP(e.target.checked);
                                                    localStorage.setItem("BP", e.target.checked);
                                                }}
                                            />
                                        }
                                        label="BP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        labelPlacement="start"
                                    />
                                </Grid>

                                <Grid item lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSugar}
                                                onChange={(e) => {
                                                    setIsSugar(e.target.checked);
                                                    localStorage.setItem("SUGAR", e.target.checked);
                                                }}
                                            />
                                        }
                                        // label={
                                        //   VITALS_SUGAR +
                                        //   "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        // }
                                        label="RBS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        labelPlacement="start"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Box>
                </Popover>
            </div>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AddVitalsHC
                            isHeight={isHeight}
                            isWeight={isWeight}
                            isEyeSight={isEyeSight}
                            isBP={isBP}
                            isSugar={isSugar}
                            isPatient={isPatient}
                            setisPatient={setisPatient}
                            patient={patient}
                            openConfiguration={handleClick}
                            title={"Vitals"}
                            token={searchToken}
                            searchToken={searchToken}
                            setSearchToken={setSearchToken}
                            searchTokenHandler={searchTokenHandler}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
};

export default RegisterVitalsHC;
