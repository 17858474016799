import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { BASE_URL } from "../../assets/constantsFile";
import { deleteData } from "../../services/api/deleteApi";

const CancelAppointmentForm = (props) => {
  const appointmentId = props.pDetails.appointmentId;

  const cancelActionHandeler = () => {
    props.onCancelAppointment("CANCEL");
  };

  const cancelAppointmentHandeler = () => {
    props.onCancelAppointment("CANCELAPPOINTMENT");
    deleteAppointment(appointmentId);

    setTimeout(() => {
      props.setValue("3");
    }, 2000);
  };

  const deleteAppointment = async (apId) => {
    const user = await deleteData(BASE_URL + "clinic/appointment/" + apId, "");

    if (user.error) {
    } else {
    }
  };

  return (
    <Box>
      <Grid container rowSpacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>Do you want to cancel this appointment?</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack
            direction={"row"}
            display="flex"
            justifyContent={"center"}
            spacing={2}
          >
            <Button variant="outlined" onClick={cancelAppointmentHandeler}>
              Yes
            </Button>
            <Button variant="outlined" onClick={cancelActionHandeler}>
              No
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CancelAppointmentForm;
