import { Fragment } from "react";
import Index from "../../marketing/dashboard";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";

const DashboardMK = () => {
  return (
    <Fragment>
      <NavigationBarMarketing />
      <Index />
    </Fragment>
  );
};

export default DashboardMK;
