import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import RegisterBPHC from "../../healthcamp/bp/registerBPHC";

const BPPageHC = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <RegisterBPHC />
    </Fragment>
  );
};

export default BPPageHC;
