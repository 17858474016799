import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { genderList } from "../../../assets/constantsFile";
import DownArrow from "../../../assets/images/svg/DownArrowBlack.svg";
import { SvgIcon } from "@mui/material";

const GenderElement = ({ element, variant }) => {
    const [gender, setGender] = useState("");

    return (
        <Fragment>
            <FormControl fullWidth size="small" sx={{ p: 0.3 }}>
                {variant === "small" ? (
                    <InputLabel id="demo-simple-select-label" sx={{ fontSize: 12 }}>
                        Gender
                    </InputLabel>
                ) : (
                    ""
                )}

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={element.gender || ""}
                    IconComponent={() => <Box component="img" src={DownArrow} sx={{ pr: 2 }}></Box>}
                    label={variant === "small" ? "Gender" : ""}
                    onChange={(e) => {
                        element["gender"] = e.target.value;
                        setGender(e.target.value);
                    }}>
                    {genderList.map((item, index) => (
                        <MenuItem value={item.value} key={index}>
                            <Typography variant="body2">{item.label}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>
    );
};

export default GenderElement;
