import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import PharmacyDashborardMainComponent from "../../pharmacyDashboard/pharmacyDashborardMainComponent";


const PharmacyDashboard = (props) => {
    return (

        <Fragment>

            <MyAppBarAfterLogin />
            <PharmacyDashborardMainComponent root={props.root} />

        </Fragment>
    );
}
 
export default PharmacyDashboard;