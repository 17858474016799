import { Box, Button, Container, Grid, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const TableContent = (props) => {
  const rows = props.listData ? props.listData : [];

  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      type: "number",
      width: 100,
    },
    {
      field: "fullName",
      headerAlign: "center",
      align: "center",
      headerName: "NAME",
      width: 300,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "mobile",
      headerAlign: "center",
      align: "center",
      headerName: "CONTACT",
      width: 140,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "appointmentDate",
      headerAlign: "center",
      align: "center",
      headerName: "DATE OF APPOINTMENT",
      width: 160,
      editable: false,
    },

    {
      field: "amountPending",
      headerAlign: "center",
      align: "center",
      headerName: "AMMOUNT PENDING",
      width: 160,
      editable: false,
    },
  ];

  const goBackHandler = () => {
    props.onClickBack("BACK");
  };
  return (
    <Container maxWidth={false}>
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box style={{ height: "82vh", width: "100%" }}>
              <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>
                  <DataGrid
                    getRowId={(row) => row.index}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Stack>
                <Button variant="contained" onClick={goBackHandler}>
                  Back
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TableContent;
