import { TextField } from "@mui/material";
import { Fragment } from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useCallback } from "react";
// import { getData } from "../../../services/api/getApiCalls";

const PinCode = ({ formValues, setFormValues }) => {

  return (
    <Fragment>
      <TextField
        size={"small"}
        fullWidth
        label="Pincode:"
        variant="outlined"
        value={formValues.pinCode || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length < 7) {
            setFormValues({ ...formValues, pinCode: e.target.value });
          }
        }}
      />
    </Fragment>
  );
};

export default PinCode;
