import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

const _genderGroup = [
  { title: "पुरूष", value: "MALE" },
  { title: "महिला", value: "FEMALE" },
  { title: "अन्य", value: "OTHER" },
];
const RespondentGender = ({ formValues, setFormValues }) => {
  return (
    <Box>
      <Grid container display={"flex"} alignItems={"center"}>
        <Grid item xs={2} lg={4}>
          <Typography>Gender:</Typography>
        </Grid>
        <Grid
          item
          xs={10}
          lg={8}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <RadioGroup
            row
            value={formValues["gender"] || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, gender: e.target.value });
            }}
          >
            {_genderGroup.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item?.value}
                control={<Radio required />}
                label={item?.title}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RespondentGender;
