import React, { useEffect, useState } from "react";

const PrivateRouter = ({ Page, Default, root }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authHeader_local");
    setIsLoggedIn(token ? true : false);
  }, [localStorage.getItem("authHeader_local")]);

  if (isLoggedIn) {
    return <Page root={root} />;
  }

  return <Default root={root} />;
};

export default PrivateRouter;
