import { TextField, Typography, InputAdornment, IconButton, Box } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import RupeeIcon from "../../../../assets/images/RupeeIcon.png"
import flag from "../../../../assets/images/flag.png"

const CustomTextField = ({ item, formValues, setFormValues }) => {
  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#6B6B6B",
          marginBlock: "5px",
        }}>
        {item.label}
      </Typography>

      <TextField
        sx={{
          background: "#fff",
          color: "#000000",
          fontWeight: "300",
          fontSize: "13px",
          lineHeight: " 15px",
          "& input::placeholder": {
            color: "#777777",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
          },
        }}
        size="small"
        fullWidth
        placeholder={item.placeHolder}
        value={formValues[item.property] || ""}
        onChange={e => {
          let newFormValues = { ...formValues }
          newFormValues[item.property] = e.target.value
          setFormValues(newFormValues)
        }}
        onInput={e => {
          e.target.style.color = "#000000"
        }}
        inputProps={{
          ...(item.property === "mobile"
            ? {
                type: "tel",
                pattern: "[0-9]{10}",
                maxLength: 10,
              }
            : {}),
        }}
        InputProps={{
          startAdornment:
            item.placeHolder === "Enter Amount" ? (
              <InputAdornment position="start">
                <Box component="img" src={RupeeIcon} style={{ width: 22, height: 22 }} alt="Icon" />
              </InputAdornment>
            ) : item.property === "mobile" ? (
              <InputAdornment position="start">
                <Box component="img" src={flag} style={{ width: 22, height: 22 }} alt="Icon" />
              </InputAdornment>
            ) : null,

          endAdornment:
            item.property === "height" ? (
              <InputAdornment position="end">
                <Box sx={{ borderLeft: " 0.5px solid rgba(0, 0, 0, 0.15)", paddingLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#6B6B6B",
                    }}>
                    cm
                  </Typography>
                </Box>
              </InputAdornment>
            ) : item.property === "weight" ? (
              <InputAdornment position="end">
                <Box sx={{ borderLeft: " 0.5px solid rgba(0, 0, 0, 0.15)", paddingLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#6B6B6B",
                    }}>
                    Kg
                  </Typography>
                </Box>
              </InputAdornment>
            ) : item.property === "bp" ? (
              <InputAdornment position="end">
                <Box sx={{ borderLeft: " 0.5px solid rgba(0, 0, 0, 0.15)", paddingLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#6B6B6B",
                    }}>
                    mmHg
                  </Typography>
                </Box>
              </InputAdornment>
            ) : item.property === "sugar" ? (
              <InputAdornment position="end">
                <Box sx={{ borderLeft: " 0.5px solid rgba(0, 0, 0, 0.15)", paddingLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#6B6B6B",
                    }}>
                    mg/dL
                  </Typography>
                </Box>
              </InputAdornment>
            ) : null,
        }}
      />
    </Fragment>
  )
}

export default CustomTextField
