import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import executionIcon from "../../assets/executionIcon.png";
import gatherInfoIcon from "../../assets/gatherInfoIcon.png";
import { NavLink } from "react-router-dom";

export const Card = ({ title, img }) => (
  <Box
    sx={{
      padding: "5px",
      background: "#FFFFFF",
      boxShadow: "0px 1px 6px 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 3,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#4998FF",
        color: "#FFFFFF",
        "& .MuiTypography-root": {
          color: "#FFFFFF",
        },
      },
      height: "137px",
    }}
  >
    <Grid container marginTop="12px" marginRight="7px">
      <Grid item lg={6} xs={6}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            // textAlign: "left",
            color: "#127DDD",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        xs={6}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: "#FAFAFA",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            src={img}
            style={{ height: "35px", width: "35px", objectFit: "contain" }}
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const HomeCorpMain = () => {
  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={6} lg={6}>
            <NavLink
              style={{ textDecoration: "none" }}
              to={"/corp/gather-info"}
            >
              <Card title={"GatherInfo"} img={gatherInfoIcon} />
            </NavLink>
          </Grid>
          <Grid item xs={6} lg={6}>
            <NavLink
              style={{ textDecoration: "none" }}
              to={"/corp/execution-info"}
            >
              <Card title={"ExecutionInfo"} img={executionIcon} />
            </NavLink>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default HomeCorpMain;
