import { Box, Container } from "@mui/material";
import { Fragment } from "react";
import HomeHC from "./home/homeHC";

const LandingHC = () => {
  return (
    <Fragment>
      <Box>
        <Container maxWidth={false} disableGutters>
          <HomeHC />
        </Container>
      </Box>
    </Fragment>
  );
};

export default LandingHC;
