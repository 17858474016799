import { Fragment } from "react";
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC";
import { Container } from "@mui/material";
import CorpPackagesMain from "../corporate/corpPackages/corpPackagesMain";

const ManagePackageIndex = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <Container maxWidth={false} sx={{ mt: 1 }}>
        <CorpPackagesMain />
      </Container>
    </Fragment>
  );
};

export default ManagePackageIndex;
