import { Box, Divider, Grid, Stack, Typography } from "@mui/material"

const PrintIssue = props => {
  const issueInfo = props.issueInfo
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography sx={styles.Mainheading}> Symptoms:&nbsp;</Typography>
          <Typography sx={styles.headingData}>{issueInfo}</Typography>
        </Stack>
        {/* <Divider /> */}
      </Grid>
    </Grid>
  )
}

export default PrintIssue
const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    marginTop: "5px",
    color: "#127DDD",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
    marginTop: "5px",
  },
}
