import { Box, Container, Typography } from "@mui/material";
import { Fragment } from "react";
import FileUploadMultiple from "../../../common/fileUpload/fileUploadMultiple";

const GalleryUploadMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Typography variant="h6">{"Gallery Upload"}</Typography>
      </Box>

      <Container
        maxWidth={false}
        sx={{ backgroundColor: "#F5F5F5", minHeight: "80vh" }}
      >
        <FileUploadMultiple title="" id={corpId} />
      </Container>
    </Fragment>
  );
};

export default GalleryUploadMain;
