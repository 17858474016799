import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApiCalls";

const PackageAutocomplete = ({
  corpId = localStorage.getItem("CORPID"),
  setSelectedPackage,
  flag,
}) => {
  const [listOfPackage, setListOfPackage] = useState([]);

  const fetchPackages = async () => {
    const url = BASE_URL + `org/package/${corpId}`;

    const packages = await getData(url);

    if (packages.error) {
      console.log("error");
      setListOfPackage([]);
    } else {
      console.log("success");
      setListOfPackage(
        packages.data.map((item) => ({ ...item, label: item.packageName }))
      );
    }
  };

  useEffect(() => {
    fetchPackages();
  }, [flag]);

  console.log({ listOfPackage });
  const [value, setValue] = useState(null);
  //const [inputValue, setInputValue] = useState("");

  console.log({ value });

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <Autocomplete
            fullWidth
            size="small"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setSelectedPackage(newValue);
            }}
            //inputValue={inputValue}
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            options={listOfPackage}
            renderInput={(params) => (
              <TextField {...params} label="Select Package" />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageAutocomplete;
