import { Box, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import GoToVideoCallComponent from "./dataGridComponents/goToVideoCallComponent";
import ReportComponent from "./dataGridComponents/reportComponent";
import VitalComponent from "./dataGridComponents/vitalComponent";
import EditAppointmentComponent from "./dataGridComponents/editAppointmentComponent";
import UploadMedicalHistory from "./dataGridComponents/uploadMedicalHistory";
import RemindDoctor from "./dataGridComponents/remindDoctor";
import SendAppointment from "./dataGridComponents/sendAppointment";

const PatientListClinicUpcoming = ({ patients, root, tabClick, setValue }) => {
  const rows = patients.filter(
    (patient, index) =>
      patient.appointmentStatus === "UPCOMING" ||
      patient.appointmentStatus === "LIVE"
  );

  const columns = [
    {
      field: "appointmentStatus",
      headerAlign: "center",
      align: "center",
      headerName: "STATUS",
      type: "number",
      width: 100,
    },
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      type: "number",
      width: 100,
    },
    {
      field: "patient",
      headerAlign: "center",
      align: "center",
      headerName: "PATIENT",
      width: 170,
      editable: false,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      renderCell: (cellValues) => {
        return (
          <Grid container spacing={0} alignItems="center">
            <Grid item lg={12}>
              <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontWeight="fontWeightMedium"
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {cellValues.value.name}
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: ".65rem" }}>
                    {cellValues.value.gender?.charAt(0).toUpperCase()},{" "}
                    {cellValues.value.age}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "doctorFullName",
      headerAlign: "center",
      align: "center",
      headerName: "DOCTOR",
      width: 120,
    },
    {
      field: "mobile",
      headerAlign: "center",
      align: "center",
      headerName: "CONTACT NO.",
      width: 120,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "startTime",
      headerAlign: "center",
      align: "center",
      headerName: "APPOINTMENT",
      width: 100,
      editable: false,
    },

    {
      field: "reports",
      headerAlign: "center",
      align: "center",
      headerName: "REPORTS",
      width: 80,
      editable: false,
      renderCell: (cellValues) => {
        return <ReportComponent root={root} pDetails={cellValues.value} />;
      },
    },
    {
      field: "vitals",
      headerAlign: "center",
      align: "center",
      headerName: "VITALS",
      width: 80,
      editable: false,
      renderCell: (cellValues) => {
        return <VitalComponent root={root} pDetails={cellValues.value} />;
      },
    },
    {
      field: "medicalHistory",
      headerAlign: "center",
      align: "center",
      headerName: "MEDICAL HISTORY",
      width: 140,
      editable: false,
      renderCell: (cellValues) => {
        return <UploadMedicalHistory root={root} pDetails={cellValues.value} />;
      },
    },
    {
      field: "call",
      headerAlign: "center",
      align: "center",
      headerName: "CALLS",
      width: 70,
      editable: false,
      renderCell: (cellValues) => {
        return <GoToVideoCallComponent root={root} calls={cellValues.value} />;
      },
    },
    {
      field: "remind",
      headerAlign: "center",
      align: "center",
      headerName: "REMIND",
      width: 70,
      editable: false,
      renderCell: (cellValues) => {
        return <RemindDoctor calls={cellValues.value} />;
      },
    },
    {
      field: "edit",
      headerAlign: "center",
      align: "center",
      headerName: "MODIFY",
      width: 100,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <EditAppointmentComponent
            root={root}
            pDetails={cellValues.value}
            tabClick={tabClick}
            setValue={setValue}
          />
        );
      },
    },
    {
      field: "sendLink",
      headerAlign: "center",
      align: "center",
      headerName: "SHARE LINK",
      width: 70,
      editable: false,
      renderCell: (cellValues) => {
        return <SendAppointment data={cellValues.value} />;
      },
    },
  ];

  return (
    <Container maxWidth={false}>
      <Box>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Box style={{ height: "72vh", width: "100%" }}>
              <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>
                  <DataGrid
                    getRowId={(row) => row.appointmentId}
                    rows={rows}
                    columns={columns}
                    //rowHeight={24}
                    disableSelectionOnClick
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PatientListClinicUpcoming;
