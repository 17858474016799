import { CssBaseline } from "@mui/material";
import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import PatientHistoryMainComp from "../../patientHistory/patientHistoryMainComp";

const PatientHistory = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />
      <PatientHistoryMainComp />
    </Fragment>
  );
};

export default PatientHistory;
