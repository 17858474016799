import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const NameElement = ({ name, setName }) => {
  //const [name, setName] = useState("");
  return (
    <Fragment>
      <TextField
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label="Enter Name"
        value={name || ""}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </Fragment>
  );
};

export default NameElement;
