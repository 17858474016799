import { Autocomplete, TextField } from "@mui/material"
import React, { Fragment } from "react"

const CustomAutoComplete = ({
  size,
  fullWidth,
  freeSolo,
  id,
  name,
  value,
  disableClearable,
  options,
  onChange,
  getOptionLabel,
  label,
}) => {
  return (
    <Fragment>
      <Autocomplete
        size={size}
        fullWidth={fullWidth}
        freeSolo={freeSolo}
        id={id}
        name={name}
        value={value}
        disableClearable={disableClearable}
        options={options.filter(ele => {
          return ele !== null
        })}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderInput={params => <TextField {...params} label={label} />}
      />
    </Fragment>
  )
}

export default CustomAutoComplete
