import { Box, Container, Grid, Typography } from "@mui/material";
import AboutUsComponent from "./aboutUsComponent";
import AvailableDoctorsComponent from "./availableDoctorsComponent";
import BannerComponent from "./bannerComponent";
import BookAppointmentComponent from "./bookAppointmentComponent";
import FacilitiesOfferedComponent from "./facilitiesOfferedComponent";
import FooterComponent from "./footerComponent";
import PrimaryHealthComponent from "./primaryHealthComponent";


const HomeComponentMain = () => {

    return (

        <Container maxWidth={false} disableGutters component="main">

        

        <Grid container>


        <Grid item lg={12}>
            <BannerComponent />
        </Grid>

        <Grid item lg={12}>
            <Container sx={{ width:'100%', background:"white" }}>
            <Grid container>

            <Grid item lg={12} align="center" >
            <FacilitiesOfferedComponent /> 
            </Grid>

            <Grid item lg={12} align="center" >
            <AvailableDoctorsComponent />
            </Grid>

            <Grid item lg={12} align="center" >
            <PrimaryHealthComponent />
            </Grid>

            <Grid item lg={12} align="center" >
            <AboutUsComponent />
            </Grid>

            </Grid>

            </Container>

        </Grid>


        <Grid item lg={12} align="center" >
            <FooterComponent />
        </Grid>


        </Grid>


        </Container>
    );
}
 
export default HomeComponentMain;