import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { IconButton, InputAdornment, Snackbar, Stack } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Imge from "../../assets/images/medical.png";
import jwt_decode from "jwt-decode";
import { getUserToken } from "../../services/api/apiCalls";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Fragment } from "react";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginHC = (props) => {
  const [severity, setSeverity] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [openNotice, setOpenNotice] = React.useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  let fffSDa = null;
  let navigate = useNavigate();
  const fetchDoctorDataHandler = React.useCallback(
    async (username, password) => {
      const user = await getUserToken(username, password);
      if (user.error) {
        console.log({ "auth data error": "error" });
      } else {
        const data = user.data;
        var token = await data.token;
        var dData = await jwt_decode(token);
        localStorage.setItem("authHeader_local", token);
        console.log({ "auth data success": dData });
      }
      return dData;
    },
    []
  );

  const myDta = async (a, b) => {
    fffSDa = await fetchDoctorDataHandler(a, b);
  };

  const [helperTxtUser, setHelperTxtUser] = React.useState("");
  const [helperTxtPass, setHelperTxtPass] = React.useState("");
  const [helperTxtLogin, setHelperTxtLogin] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userName = data.get("userName");
    const password = data.get("password");

    if (userName === "") {
      setHelperTxtUser("Enter user name.");
    } else {
      setHelperTxtUser("");
    }
    if (password === "") {
      setHelperTxtPass("Enter password.");
    } else {
      setHelperTxtPass("");
    }

    await myDta(userName, password);

    if (fffSDa && fffSDa.role === "HEALTHCAMP" && userName === fffSDa.sub) {
      localStorage.setItem("loginUserData_local", fffSDa);
      localStorage.setItem("CORPUSERID", fffSDa.userID);
      setSeverity("success");
      setMessage("Login successfull");
      setOpenNotice(true);
      getCorpData(fffSDa.userID);
    } else {
      setSeverity("error");
      setMessage("Login failed! Enter Correct Credentials!");
      setOpenNotice(true);
    }
  };

  const getCorpData = async (userId) => {
    const url = BASE_URL + `org/corp/user/${userId}`;
    const corp = await getData(url);
    if (corp.error) {
      console.log("error");
    } else {
      console.log({ successCorpId: corp.data });
      localStorage.setItem("CORPID", corp.data.corpId);
      localStorage.setItem("ORGNAME", corp.data.orgName);
    }
    navigate("/corporatehome");
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Fragment>
      <Box>
        <Container component="main">
          <Box
            sx={{
              width: "100%",
              height: "84vh",
              mt: 2,
            }}
          >
            <Snackbar
              open={openNotice}
              autoHideDuration={6000}
              onClose={handleCloseNotice}
            >
              <Alert
                onClose={handleCloseNotice}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
            <Grid container>
              <Grid item lg={2}></Grid>
              <Grid item lg={8} xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "56vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#fff",
                    boxShadow: "0px 1px 9px #208F94",
                    borderRadius: "22px",
                    mt: "5rem",
                    p: 1,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} lg={5}>
                      <Box sx={{ background: "#fff" }}>
                        <img alt="login" src={Imge} height={355} width={297} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={7}>
                      <Box sx={{ background: "#fff" }}>
                        <Grid container>
                          <Typography variant="h6" sx={{ mt: 5 }}>
                            HEALTH CAMP SIGN-IN
                          </Typography>

                          <Box
                            component="form"
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{ mt: 1 }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              required
                              fullWidth
                              id="userName"
                              label="User Name"
                              name="userName"
                              autoComplete="userName"
                              helperText={helperTxtUser}
                              autoFocus
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AccountCircleIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              size="small"
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="current-password"
                              helperText={helperTxtPass}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setShowPassword(!showPassword);
                                      }}
                                      onMouseDown={() => {
                                        setShowPassword(!showPassword);
                                      }}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value="remember"
                                        color="primary"
                                      />
                                    }
                                    label="Remember me"
                                  />
                                </Grid>
                                <Grid item xs={6} sx={{ mt: 1 }}>
                                  <Link href="#" underline="none">
                                    Forgot password?
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                            >
                              Sign-in
                            </Button>

                            <Stack direction={"row"}>
                              <Typography>
                                Don't have an account?&nbsp;
                              </Typography>
                              <Link href="#" underline="none">
                                Sign-up
                              </Link>
                            </Stack>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default LoginHC;
