import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
const ViewReportComponent = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <Container component="main" maxWidth={false}>
      <Grid container spacing={0}>
        <Grid item lg={12}>
          <Box sx={{ background: "#fff", height: "75vh", borderRadius: 2 }}>
            <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewReportComponent;
