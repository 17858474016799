import { Box, Button, Grid, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import SearchComponent from "../searchBar/searchComponent";
import SearchResults from "../searchResults/searchResults";

const OldPatientRegistrationContent = (props) => {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [pIdForSceduling, setPIdForSceduling] = useState(0);
  const goBackToHome = () => {
    props.onAddRegistrationData("BACK", "");
  };
  const goToAppointmentBooking = () => {
    props.onAddRegistrationData("APPOINTMENTBOOKING", pIdForSceduling);
  };

  const goToEditPatientDetails = () => {
    props.onAddRegistrationData("EDITPATIENTDETAILS", pIdForSceduling);
  };

  const serachDataHandler = (pData) => {
    if (pData !== null) {
      setResultContent(
        <SearchResults pData={pData} onRowSelect={rowSelectHandler} />
      );
    }
  };

  const rowSelectHandler = (rData) => {
    if (rData && rData.length > 0) {
      setIsRowSelected(true);
      setPIdForSceduling(rData[0]);
    } else {
      setIsRowSelected(false);
    }
    console.log({ handler_rData: rData });
  };

  const [resultContent, setResultContent] = useState(
    <SearchResults pData={[]} onRowSelect={rowSelectHandler} />
  );
  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              minHeight: "64vh",
              borderRadius: 2,
              pl: 3,
              pr: 3,
              pt: 2,
            }}
          >
            <SearchComponent onSearchPatient={serachDataHandler} />
            {resultContent}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <Box
            sx={{ minHeight: "5vh", p: 2, background: "#fff" }}
            component={Stack}
            direction="row"
            spacing={2}
            justifyContent={"center"}
          >
            <Button fullWidth variant="contained" onClick={goBackToHome}>
              Back
            </Button>
            <Button
              disabled={!isRowSelected}
              fullWidth
              variant="contained"
              onClick={goToEditPatientDetails}
            >
              Edit patient Details
            </Button>
            <Button
              disabled={!isRowSelected}
              fullWidth
              variant="contained"
              onClick={goToAppointmentBooking}
            >
              Book Appointment
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OldPatientRegistrationContent;
