import { Box, Button, Grid, TextField } from "@mui/material";
import TitleHC from "../../../healthcamp/common/title/titleHC";
import ShowCurrentDate from "../components/showCurrentDate";
import BranchLocation from "../components/branchLocation";
import SurveyLocation from "../components/surveyLocation";
import { CustomIOSSwitch } from "../../../healthcamp/corporateForm/comps/customIOSSwitch";
import SOSReason from "../components/sosReason";
import SOS from "../components/SOS";
import OnBehalfOf from "../components/onBehalfOf";
import OnBehalfOfPerson from "../components/onBehalfOfPerson";

const SetupIndex = ({ handleSetupVisibility, formValues, setFormValues }) => {
  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <BranchLocation
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
        <Grid item xs={12}>
          <SurveyLocation
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>

        <Grid item xs={12}>
          <OnBehalfOf formValues={formValues} setFormValues={setFormValues} />
        </Grid>

        {formValues.onBehalfOf && (
          <Grid item xs={12}>
            <OnBehalfOfPerson
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={handleSetupVisibility}
            disabled={
              formValues.clinicLocation && formValues.surveyLocation
                ? false
                : true
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SetupIndex;
