import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

export const _fetchEmployeeById = async (
  corpId,
  setSeverity,
  setMessage,
  setOpenNotice,
  setSearchedEmployeeId,
  setFormValues,
  employeeId,
  setIsTokenNumber
) => {
  const url = BASE_URL + `org/detailed/${employeeId}?corpId=${corpId}`;

  const empData = await getData(url);
  if (empData.error) {
    setSeverity("error");
    setMessage(empData.error?.response?.data?.message);
    setOpenNotice(true);
    setSearchedEmployeeId(null);
    setIsTokenNumber(false);
  } else {
    setFormValues(empData.data);
    setSearchedEmployeeId(empData.data.empId);
    setIsTokenNumber(empData.data?.tokenNumber ? true : false);
  }
};
