import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment } from "react";
import CorpPackageReadonly from "../../comps/corpPackageReadonly";
import {
  CustomTypography,
  CustomTypographyBold,
} from "../../comps/customTypography";
import { TextFieldItem } from "../../comps/customTextField";

const EmployeeMiscFormfields = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Box
        sx={{
          boxSizing: "border-box",
          background: "#FFFFFF",
          border: "0.5px solid #A6A6A6",
          borderRadius: 5,
          minHeight: "10vh",
          p: 2,
        }}
      >
        <Stack display="flex" justifySelf="center" alignItems="center">
          <Box
            sx={{
              position: "relative",
              top: -28,
              background: "#fff",
              width: 200,
              textAlign: "center",
              borderRadius: 5,
            }}
          >
            <CustomTypographyBold>Misc Details</CustomTypographyBold>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack>
              <CustomTypography>Hearing</CustomTypography>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={formValues.hearing}
                exclusive
                onChange={(event, newAlignment) => {
                  let newFormValues = { ...formValues };
                  newFormValues["hearing"] = newAlignment;
                  setFormValues(newFormValues);
                }}
              >
                <ToggleButton
                  sx={{
                    "&.Mui-selected, &.Mui-selected:hover": {
                      color: "white",
                    },
                  }}
                  value="normal"
                >
                  Normal
                </ToggleButton>
                <ToggleButton
                  sx={{
                    "&.Mui-selected, &.Mui-selected:hover": {
                      color: "white",
                    },
                  }}
                  value="impaired"
                >
                  Impaired
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <TextFieldItem
              title="Doctor's Remark "
              property="doctorsRemark"
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeMiscFormfields;
