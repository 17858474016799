import { Box, Button, Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { PhotoViewer } from "../../../common/imageModel/photoViewer";
import printJS from "print-js";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TakePicture from "./takePicture";

function getUrlExtension(url) {
  try {
    return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1];
  } catch (ignored) {
    return false;
  }
}

const ImageUploadComp = ({
  formValues,
  setFormValues,
  url,
  label,
  property,
}) => {
  const [imageURL, setImageURL] = useState(formValues[property]);

  useEffect(() => {
    setImageURL(formValues[property]);
  }, [formValues]);

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <PhotoViewer url={imageURL} open={open} setOpen={setOpen} />
      <Stack
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Box sx={{ height: 90 }}>
          {imageURL ? (
            getUrlExtension(imageURL).toLowerCase() === "pdf" ? (
              <Button onClick={() => printJS(imageURL)}>
                <PictureAsPdfIcon sx={{ fontSize: 100 }} />
              </Button>
            ) : (
              <Box sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                <img
                  src={`${imageURL}?${Date.now()}`}
                  height="90"
                  width="120"
                  alt="profile"
                />
              </Box>
            )
          ) : (
            <img
              src={require("../../../assets/images/profile.png")}
              height="80"
              width="80"
            />
          )}
        </Box>

        <TakePicture
          empId={formValues?.empId}
          formValues={formValues}
          setFormValues={setFormValues}
          url={url}
          label={label}
          property={property}
        />
      </Stack>
    </Fragment>
  );
};

export default ImageUploadComp;
