export const init = {
  empId: 0,
  height: "",
  weight: "",
  bp: "",
  sugar: "",
  bmi: "",
  bodyBuilt: "",
  nails: "",
  hairs: "",
  bloodGroup: "",

  glass: false,
  cataract: false,
  smoking: false,
  alchohol: false,
  panChewing: false,
  gambling: false,
  drugAddiction: false,
  eyeOperation: false,

  gender: null,
  hearing: "normal",

  doctorsRemark: "",
  bloodSampleCollected: true,
  urineSampleCollected: false,
  audiometryDone: false,
  pft: false,
  xrayDone: false,
  stoolSampleCollected: false,
  eyeTest: true,
  eyeSightOk: false,
  farSighted: false,
  nearSighted: false,

  //cbc: false,
  //serumBilirubin: false,
  //fitnessCertificate: false,
};

export const initNew = (isFlag) => {
  return {
    empId: 0,
    height: "",
    weight: "",
    bp: "",
    sugar: "",
    bmi: "",
    bodyBuilt: "",
    nails: "",
    hairs: "",
    bloodGroup: "",

    glass: false,
    cataract: false,
    smoking: false,
    alchohol: false,
    panChewing: false,
    gambling: false,
    drugAddiction: false,
    eyeOperation: false,

    gender: null,
    hearing: "normal",

    doctorsRemark: "",
    bloodSampleCollected: isFlag || false,
    urineSampleCollected: false,
    audiometryDone: isFlag || false,
    pft: isFlag || false,
    xrayDone: isFlag || false,
    stoolSampleCollected: false,
    eyeTest: isFlag || false,
    ecg: isFlag || false,
    eyeSightOk: false,
    farSighted: false,
    nearSighted: false,
    //cbc: false,
    //serumBilirubin: false,
    //fitnessCertificate: false,
  };
};

export const payload = (corpId, formValues) => {
  const p = {
    corpId: corpId,
    id: formValues.id,
    empId: formValues.empId,
    name: formValues.name,
    mobile: formValues.mobile,
    age: formValues.age,
    gender: formValues.gender,
    bp: formValues.bp,
    sugar: formValues.sugar,
    height: formValues.height,
    weight: formValues.weight,
    bmi: formValues.bmi,
    glass: formValues.glass || false,
    cataract: formValues.cataract || false,
    hearing: formValues.hearing || "normal",
    //date: formValues.date,
    smoking: formValues.smoking || false,
    alchohol: formValues.alchohol || false,
    panChewing: formValues.panChewing || false,
    gambling: formValues.gambling || false,
    drugAddiction: formValues.drugAddiction || false,
    eyeOperation: formValues.eyeOperation || false,
    bodyBuilt: formValues.bodyBuilt,
    nails: formValues.nails,
    hairs: formValues.hairs,
    doctorsRemark: formValues.doctorsRemark,
    bloodSampleCollected: formValues.bloodSampleCollected,
    urineSampleCollected: formValues.urineSampleCollected,
    audiometryDone: formValues.audiometryDone,
    pft: formValues.pft,
    xrayDone: formValues.xrayDone,
    stoolSampleCollected: formValues.stoolSampleCollected,
    eyeTest: formValues.eyeTest,
    fitToWork: formValues.fitToWork,
    department: formValues.department,
    ecg: formValues.ecg,
    eyeSightOk: formValues.eyeSightOk,
    farSighted: formValues.farSighted,
    nearSighted: formValues.nearSighted,
    bloodGroup: formValues.bloodGroup,
    //cbc: formValues.cbc,
    //serumBilirubin: formValues.serumBilirubin,
    //fitnessCertificate: formValues.fitnessCertificate,
    nearLeftEyeSight: formValues.nearLeftEyeSight,
    nearRightEyeSight: formValues.nearRightEyeSight,
    farLeftEyeSight: formValues.farLeftEyeSight,
    farRightEyeSight: formValues.farRightEyeSight,
    packageName: formValues.packageName,
    tokenNumber: formValues.tokenNumber,
  };

  return p;
};
