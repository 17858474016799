import {
  Button,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  Input,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { saveData } from "../../services/api/postApiCalls";
import { BASE_URL, REPORTTYPE } from "../../assets/constantsFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import { getFormattedDDMonthYYYY } from "../../assets/utils";
import { userContext } from "../../common/context/usercontext";
import { useFileUpload } from "use-file-upload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const ReportForm = (props) => {
  const contextData = useContext(userContext);
  console.log({ "props.pDetails": props.pDetails });
  const patientId = props.pDetails.patientId;
  const appointmentId = props.pDetails.appointmentId;
  const docId = props.pDetails.docId;
  const caesId = props.pDetails.caseId;

  const [addNewReport, setAddNewReport] = useState(true);
  const [addNewReportName, setAddNewReportName] = useState("");
  const [addNewReportType, setAddNewReportType] = useState("REPORT");
  const [addNewReportPrescribedBy, setAddNewReportPrescribedBy] = useState("");
  const [currentAddedReport, setCurrentAddedReport] = useState(null);

  const [reportsUploadFlag, setReportsUploadFlag] = useState(false);
  const [reports, setReports] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  let myReoprtList = [];
  if (props.reports) {
    myReoprtList = props.reports;
  }

  useEffect(() => {
    setReports(myReoprtList);
  }, [reportsUploadFlag]);

  console.log({ reportsreports_reports: reports });

  const selectForm = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [reportId, setReportId] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);

    uploadFile(formData);

    console.log({ formData_upload: formData });
  };

  const handleSubmitNew = (name, size, source, file, reportId, index) => {
    console.log({ file });
    console.log({ reportId });
    console.log({ index });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", name);

    uploadFile(formData, reportId, index);

    console.log({ formData_upload: formData });
  };

  const uploadFile = async (formData, reportId, index) => {
    try {
      const response = await axios({
        method: "post",
        url: BASE_URL + "lab/tests/upload?reportId=" + reportId,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authHeader_local"),
        },
      });

      const data = await response.data;
      console.log({ formData_upload_2: response.data });

      myReoprtList[index] = data;
      setReportsUploadFlag(!reportsUploadFlag);

      console.log({ reports_upload_new: reports });
    } catch (error) {
      console.log(error);
    }
  };
  console.log({ reports_upload: reports });

  const closePopOver = () => {
    props.onAddReports("CANCEL");
  };

  const handleFileSelect = (i, event) => {
    console.log({ event_reports: reports[i] });
    setReportId(reports[i].reportId);
    setCurrentIndex(i);

    setSelectedFile(event.target.files[0]);
  };

  const addNewReportHandler = (event) => {
    setAddNewReportName("");
    setAddNewReportType("REPORT");
    setAddNewReportPrescribedBy("");

    setAddNewReport(false);

    console.log("kl");
  };

  const saveNewReportHandler = () => {
    if (addNewReportName) {
      const o = {
        reportName: addNewReportName,
        reportType: addNewReportType,
        prescribedBy: addNewReportPrescribedBy,
        patientId: patientId,
        docId: docId,
        appointmentId: appointmentId,
        caseId: caesId,
      };
      console.log({ " to be saved object": o });
      console.log({ addNewReportName: addNewReportName });
      console.log({ addNewReportType: addNewReportType });
      console.log({ addNewReportPrescribedBy: addNewReportPrescribedBy });

      saveNewReport(o);

      setAddNewReport(true);
    }
  };

  const saveNewReport = async (obj) => {
    const report = await saveData(BASE_URL + "lab/saveSingleTest", obj);
    if (report.error) {
    } else {
      const rData = await report.data;
      reports.push(rData);
      setCurrentAddedReport(rData);
      console.log({ "report.data": rData });
    }
  };

  const cancelNewReportHandler = () => {
    setAddNewReportName("");
    setAddNewReportType("REPORT");
    setAddNewReportPrescribedBy("");

    setAddNewReport(true);
  };

  useEffect(() => {
    if (currentAddedReport) {
      setReports(reports);
    }
  }, [currentAddedReport, addNewReport]);

  const [files, selectFiles] = useFileUpload();
  return (
    <Container sx={{ width: "62vw" }}>
      <Box sx={{ width: "100%" }}>
        <Grid container>
          <Grid item lg={4} md={12} xs={12} sm={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Name
            </Typography>
          </Grid>

          <Grid item lg={2} md={12} xs={12} sm={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Type
            </Typography>
          </Grid>

          <Grid item lg={2} md={12} xs={12} sm={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Prescribed By
            </Typography>
          </Grid>

          <Grid item lg={2} md={12} xs={12} sm={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Date
            </Typography>
          </Grid>

          <Grid item lg={2} md={12} xs={12} sm={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              URL
            </Typography>
          </Grid>
          <Grid item lg={2} md={12} xs={12} sm={12}></Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {reports.length > 0
            ? reports.map((element, index) => (
                <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                  <Grid container>
                    <Grid item lg={4} md={12}>
                      <Typography variant="body2">
                        {element.reportName}
                      </Typography>
                    </Grid>

                    <Grid item lg={2} md={12}>
                      <Typography variant="body2">
                        {element.reportFileType}
                      </Typography>
                    </Grid>

                    <Grid item lg={2} md={12}>
                      <Typography variant="body2">
                        {element.prescribedBy}
                      </Typography>
                    </Grid>

                    <Grid item lg={2} md={12}>
                      <Typography variant="body2">
                        {getFormattedDDMonthYYYY(
                          new Date(
                            element.prescribedDate
                              ? element.prescribedDate
                              : new Date()
                          )
                        )}
                      </Typography>
                    </Grid>

                    <Grid item lg={1} md={2}>
                      <Fragment>
                        {element.reportURL ? (
                          <div>
                            {element.reportFileType === "PDF" ? (
                              <Link
                                href={
                                  "http://docs.google.com/gview?url=" +
                                  element.reportURL +
                                  "&embedded=true"
                                }
                                target="_blank"
                                underline="none"
                              >
                                <PictureAsPdfIcon />
                              </Link>
                            ) : (
                              <Link
                                href={"/myimage?ImageUrl=" + element.reportURL}
                                target="_blank"
                                underline="none"
                              >
                                <img
                                  src={element.reportURL}
                                  alt="preview"
                                  height="25"
                                  width="25"
                                />
                              </Link>
                            )}
                          </div>
                        ) : (
                          <Typography variant="caption">No file</Typography>
                        )}
                      </Fragment>
                    </Grid>

                    <Grid item lg={1} md={6}>
                      <Stack direction="row">
                        <Fragment>
                          <Button
                            onClick={() =>
                              selectFiles(
                                { accept: "*" },
                                ({ name, size, source, file }) => {
                                  handleSubmitNew(
                                    name,
                                    size,
                                    source,
                                    file,
                                    element.reportId,
                                    index
                                  );
                                  console.log("Files Selected", {
                                    name,
                                    size,
                                    source,
                                    file,
                                  });
                                  console.log({
                                    "element.reportId": element.reportId,
                                  });
                                }
                              )
                            }
                            variant="outlined"
                            startIcon={<AttachFileIcon />}
                            sx={{
                              minWidth: 32,
                              p: 0,
                              pl: 1.5,
                              minHeight: 32,
                            }}
                          ></Button>
                        </Fragment>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : ""}

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <Box>
              {addNewReport ? (
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  size="small"
                  onClick={addNewReportHandler}
                >
                  Add New Report
                </Button>
              ) : (
                <Grid container spacing={1}>
                  <Grid item lg={2} md={12} xs={12} sm={12}>
                    <TextField
                      name="addNewReportName"
                      size={"small"}
                      fullWidth
                      label=""
                      variant="outlined"
                      value={addNewReportName || ""}
                      onChange={(e) => {
                        setAddNewReportName(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item lg={2} md={12} xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label=""
                      variant="outlined"
                      size={"small"}
                      select
                      name="addNewReportType"
                      value={addNewReportType || "REPORT"}
                      onChange={(e) => {
                        setAddNewReportType(e.target.value);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      helperText=""
                    >
                      {REPORTTYPE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} md={12} xs={12} sm={12}>
                    <TextField
                      name="addNewReportPrescribedBy"
                      size={"small"}
                      fullWidth
                      label=""
                      variant="outlined"
                      value={addNewReportPrescribedBy || ""}
                      onChange={(e) => {
                        setAddNewReportPrescribedBy(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item lg={2} md={12} xs={12} sm={12}></Grid>

                  <Grid item lg={2} md={12} xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      size="small"
                      onClick={cancelNewReportHandler}
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid item lg={2} md={12} xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      size="small"
                      onClick={saveNewReportHandler}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Divider sx={{ mt: 1 }} />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Button onClick={closePopOver} variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ReportForm;
