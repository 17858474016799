import React from "react";
import CorpLogout from "../module/corpLogout/corpLogout";
import { Box } from "@mui/material";

const LogoutCorp = () => {
  return (
    <Box>
      <CorpLogout />
    </Box>
  );
};

export default LogoutCorp;
