import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Portal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFileUpload } from "use-file-upload";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { BASE_URL, BASE_URL_ATOZ } from "../../assets/constantsFile";
import { saveData, uploadFile } from "../../services/api/postApiCalls";
import { Axios } from "axios";
import { getData } from "../../services/api/getApiCalls";
import { updateDataNew } from "../../services/api/patchApi";

const FileUploadMultiple = ({ title, id }) => {
  const [files, selectFiles] = useFileUpload();
  const [formValues, setFormValues] = useState({ files: [] });

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      const propertyName = "files";
      const currentFiles = formValues[propertyName] || [];

      const updatedSelectedFiles = [...currentFiles];

      //let formData = new FormData();
      files.forEach(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
        //formData.append("files", file);
      });
      setFormValues({ ...formValues, [propertyName]: updatedSelectedFiles });

      //formData.append("fileName", files.name);
      // console.log({ formData: formData.getAll("files") });
    });
  };

  const handleRemove = async (index, id) => {
    console.log("Removing file at index:", index);
    const updatedFiles = formValues?.files?.filter((_, i) => i !== index);
    setFormValues({ ...formValues, files: updatedFiles });

    const url = BASE_URL + `org/photos?Id=${id}`;

    const response = await updateDataNew(url, "");

    if (response.error) {
      console.log({ error: response.error });
      setSeverity("error");
      setMessage("Failed to remove!");
      setOpenNotice(true);
    } else {
      console.log({ success: response.data });
      setSeverity("success");
      setMessage("removed successfully");
      setOpenNotice(true);
      handleClose();
    }
  };

  console.log({ files, formValues });

  const _saveImages = async () => {
    let formData = new FormData();

    formValues?.files.forEach((file, index) => {
      formData.append("files", file.file);
    });

    const url = BASE_URL + `org/uploadPhotos?corpId=${id}`;

    const result = await uploadFile(url, formData);

    if (result.error) {
      setSeverity("error");
      setMessage("Failed to save!");
      setOpenNotice(true);
      console.log("error");
    } else {
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
      console.log({ success: result.data });
      _getPhotos();
    }
  };

  const _getPhotos = async () => {
    const url = BASE_URL + `org/photos?corpId=${id}`;

    const images = await getData(url);

    if (images.error) {
      console.log({ error: images.error });
    } else {
      console.log({ "successfullt get images": images.data });
      setFormValues({ files: images.data });
    }
  };

  useEffect(() => {
    _getPhotos();
  }, []);

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: 13,
                lineHeightt: 17,
                color: "#8A8A8A",
                textTransform: "capitalize",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction={"row"} spacing={4}>
              <Button
                size="large"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                }}
                variant="contained"
                startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
                onClick={handleUpload}
              >
                Upload
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                }}
                size="large"
                fullWidth
                onClick={_saveImages}
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ maxHeight: "60vh", overflow: "auto" }}>
              <Grid container spacing={3}>
                {formValues?.files?.length
                  ? formValues?.files.map((file, index) => (
                      <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        <Paper
                          elevation={3}
                          style={{
                            border: "1px solid gray",
                            //width: "100%",
                            //marginInline: "10px",
                            //marginBlock: "5px",
                          }}
                        >
                          <Box position="relative">
                            <img
                              src={file.source || file.reportURL}
                              alt={file.source}
                              style={{ maxWidth: "130px", height: "130px" }}
                            />
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => {
                                handleClickOpen();
                                setSelectedIndex(index);
                                setSelectedId(file.id);
                              }}
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                color: "red",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Typography variant="body2" align="center">
                              {file.name}
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box>
        <ImageList
          sx={{ width: "100%", height: "60vh" }}
          cols={6}
          rowHeight={130}
          //gap={7}
        >
          {formValues?.files.map((item, index) => (
            <ImageListItem key={index} sx={{ p: 1, border: 1 }}>
              <img
                style={{ height: 100, width: "100%" }}
                // srcSet={`${item.reportURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${
                  item.reportURL || item.source
                }?w=164&h=164&fit=crop&auto=format`}
                alt={item.reportName}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box> */}

      <Portal>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"Remove Image from gallery."}</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleRemove(selectedIndex, selectedId)}
              autoFocus
            >
              Confirm
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default FileUploadMultiple;
