import { Fragment } from "react";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import ClinicDashborardMainComponent from "../../clinicDashboard/clinicDashborardMainComponent";

const ClinicDashboard = (props) => {
    return (

        <Fragment>

            <MyAppBarAfterLogin />
            <ClinicDashborardMainComponent root={props.root} />

        </Fragment>
    );
}
 
export default ClinicDashboard;