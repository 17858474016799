import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const TitleHC = ({ title }) => {
  return (
    <Fragment>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <Typography variant="h6" sx={{ pt: 2 }}>
          {title}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default TitleHC;
