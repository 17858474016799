import { Box } from "@mui/material";
import { useEffect } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const LogoutHC = () => {
  let navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    navigate("/");
    localStorage.setItem("ROLE", "");
    localStorage.setItem("BRANCH_ID", "");
    localStorage.setItem("ORGANIZATION", "");
  }, []);

  return (
    <Fragment>
      <Box></Box>
    </Fragment>
  );
};

export default LogoutHC;
