import React, { useState, useEffect } from "react";
import { Paper, Stack, Box, Chip, Tooltip, Grid } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserInformation = () => {
  const { userId } = useParams();
  console.log({ outside: userId });
  const [data, setData] = useState({});

  const Image = () => {
    return (
      <>
        <Tooltip title="Click to Print">
          <a onClick={() => window.print()}>
            <img src={`data:image/png;base64,${data.qrCode}`} alt="example" />
          </a>
        </Tooltip>
      </>
    );
  };
  const fetchData = async () => {
    console.log({ id: userId });
    try {
      const response = await getData(
        `${BASE_URL}healthcamp/marketing/${userId}`
      );
      console.log({ response: response });
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <>
      <Box sx={{ width: "100%", mt: 10, p: 5, boxSizing: "border-box" }}>
        <Grid container>
          <Grid item sm={12} xs={12} lg={3} md={3}></Grid>
          <Grid item sm={12} xs={12} lg={6} md={6}>
            <Stack spacing={2} display="flex" justifyContent="center">
              {data.fullName && (
                <Item>
                  {" "}
                  Name : <Chip label={data.fullName} />
                </Item>
              )}
              {data.city && (
                <Item>
                  {" "}
                  City : <Chip label={data.city} />
                </Item>
              )}
              {data.age && (
                <Item>
                  {" "}
                  Age : <Chip label={data.age} />{" "}
                </Item>
              )}
              {data.mobile && (
                <Item>
                  {" "}
                  Mobile : <Chip label={data.mobile} />{" "}
                </Item>
              )}
            </Stack>
          </Grid>
          <Grid item sm={12} xs={12} lg={3} md={3}></Grid>
        </Grid>

        <Grid container>
          <Grid item sx={2} sm={5} md={5} lg={5}></Grid>
          <Grid
            container
            xs={8}
            sm={2}
            md={2}
            lg={2}
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>{data.qrURL && <Image />}</Grid>

            <Grid item>
              <Tooltip title="Click to Print" onClick={() => window.print()}>
                <PrintIcon className={{ height: "50px", width: "1rem" }} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item sx={2} sm={5} md={5} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserInformation;
