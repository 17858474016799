import { TextField } from "@mui/material";
import { Fragment } from "react";

const AssignTokenElement = ({ token, tokenOnChange, isTokenOccupied }) => {
    const showError = token.length === 0 ? false : isTokenOccupied;
    return (
        <Fragment>
            <TextField
                InputLabelProps={{ style: { fontSize: 12 } }}
                size="small"
                fullWidth
                label="Enter Token"
                value={token || ""}
                onChange={tokenOnChange}
                helperText={showError ? "Token occupied" : ""}
                error={showError}
            />
        </Fragment>
    );
};

export default AssignTokenElement;
