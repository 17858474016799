import { Typography, colors } from "@mui/material"

export const TypographySubTitle = ({ children }) => (
  <Typography
    sx={{
      fontWeight: "300",
      fontSize: 10,
      lineHeight: "12px",
      textAlign: "center",
      color: "#404040",
      marginBlock: "5px",
    }}>
    {children}
  </Typography>
)
