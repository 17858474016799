import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Fragment, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import PrintHistoryComp from "../print/printHistoryComp";
import PatientInfo from "../patientInfo/patientInfo";
import { patientHistoryContext } from "../../common/context/usercontext";

const PatientCaseListMainComp = (props) => {
  const patientId = props.patientId;
  const patientHistoryContextData = useContext(patientHistoryContext);

  let pList = [];

  if (
    patientHistoryContextData.patientList &&
    patientHistoryContextData.patientList.length > 0 &&
    patientId
  ) {
    pList = patientHistoryContextData.patientList.filter(
      (patient, index) => patient.patientId === patientId
    );
    patientHistoryContextData.patient = pList[0];
  }

  console.log({ "pList[0]": pList[0] });

  const componentRef = useRef();
  const [pCaseList, setPCaseList] = useState(
    patientHistoryContextData.caseList
  );
  const [pHistory, setPHistory] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);

  useEffect(() => {
    getPatientHistoryData(selectionModel[0]);
  }, [selectionModel]);

  const getPatientHistoryData = async (caseId) => {
    if (caseId) {
      const user = await getData(BASE_URL + "patient/detailed/case/" + caseId);
      if (user.error) {
        setPHistory([]);
      } else {
        const data1 = user.data;
        setPHistory(data1);
      }
    }
  };

  useEffect(() => {
    getPatientCaseList(patientId);
  }, [patientId]);

  const getPatientCaseList = async (pId) => {
    if (pId) {
      const user = await getData(BASE_URL + "patient/cases/" + pId);

      if (user.error) {
        patientHistoryContextData.caseList = [];
      } else {
        const data1 = user.data;
        setPCaseList(data1);
        patientHistoryContextData.caseList = data1;
      }
    }
  };
  const viewPatientHistory = (event, value) => {
    event.preventDefault();
    props.onViewCases(pHistory, value, pList[0]);
  };

  const rows = pCaseList;
  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      width: 50,
    },

    {
      field: "patientName",
      headerAlign: "center",
      align: "center",
      headerName: "PATIENT NAME",
      width: 300,
    },

    {
      field: "doctorName",
      headerAlign: "center",
      align: "center",
      headerName: "DOCTOR NAME",
      width: 200,
    },

    {
      field: "caseId",
      headerAlign: "center",
      align: "center",
      headerName: "CASE ID",
      width: 100,
    },

    {
      field: "type",
      headerAlign: "center",
      align: "center",
      headerName: "TYPE",
      width: 100,
    },

    {
      field: "firstConsultationDate",
      headerAlign: "center",
      align: "center",
      headerName: "FIRST CONSULTATION DATE",
      width: 240,
    },

    {
      field: "lastConsultationDate",
      headerAlign: "center",
      align: "center",
      headerName: "LAST CONSULTATION DATE",
      width: 200,
    },
  ];

  console.log({ pCaseList: pCaseList });
  console.log({ pHistory_hhhhh: pHistory });
  console.log({ selectionModel: selectionModel });

  return (
    <Fragment>
      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box ref={componentRef}>
          <PrintHistoryComp pHistory={pHistory} />
        </Box>
      </Box>

      <Box sx={{ minHeight: "80vh" }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "8vh", background: "fff" }}>
              <Stack direction={"row"} spacing={2}>
                <PatientInfo
                  patientId={patientId}
                  patient={patientHistoryContextData.patient}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "72vh", background: "fff" }}>
              <Box sx={{ height: "72vh" }}>
                <Box style={{ display: "flex", height: "100%" }}>
                  <Box style={{ flexGrow: 1 }}>
                    <DataGrid
                      getRowId={(row) => row.caseId}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                        newSelectionModel && newSelectionModel.length > 0
                          ? setIsRowSelected(true)
                          : setIsRowSelected(false);
                      }}
                      selectionModel={selectionModel}
                      rows={rows}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableMultipleSelection
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "5vh" }}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={(e) => viewPatientHistory(e, "VIEWSEARCHPAGE")}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={!isRowSelected}
                      variant="contained"
                      fullWidth
                      onClick={(e) => viewPatientHistory(e, "VIEWHISTORYPAGE")}
                    >
                      View
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientCaseListMainComp;
