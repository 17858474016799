import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TitleHC from "../../../healthcamp/common/title/titleHC";
import ShowCurrentDate from "./showCurrentDate";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";

const MarketingTitle = ({ showSetup, handleSetupVisibility }) => {
  return (
    <Box sx={{ borderBottom: "1px solid #127ddd" }}>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Stack
            //direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6">MARKETING</Typography>
            <Stack
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ShowCurrentDate />
              <Typography noWrap>
                {localStorage.getItem("CLINIC_LOCATION")
                  ? `, ${localStorage.getItem("CLINIC_LOCATION")}`
                  : ""}
              </Typography>
              <Typography noWrap>
                {localStorage.getItem("SURVEY_LOCATION")
                  ? `, ${localStorage.getItem("SURVEY_LOCATION")}`
                  : ""}
              </Typography>
              {!showSetup && (
                <Tooltip title="Change Clinic Location or Survey Location">
                  <IconButton
                    aria-label="delete"
                    onClick={handleSetupVisibility}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingTitle;
