import { Box, Snackbar } from "@mui/material";
import { forwardRef, Fragment, useState } from "react";
import BookingComp from "../booking/bookingComp";
import EditPatientRegistrationForm from "../editPatientDetails/editPatientRegistrationForm";
import OldPatientRegistrationContent from "./oldPatientRegistrationContent";

import MuiAlert from "@mui/material/Alert";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterOldPatientMainComp = (props) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const bookingHandler = (bookingData) => {
    console.log({ handler_bookingData: bookingData });
  };

  const saveDataHandler = (saveData) => {
    if (saveData === "back") {
      setContent(
        <OldPatientRegistrationContent
          onAddRegistrationData={registrationHandler}
        />
      );
    } else {
      if (saveData.error) {
        setSeverity("error");
        setMessage("Patient updation failed!");
        setOpenNotice(true);
      } else {
        setSeverity("success");
        setMessage("Patient updated successfully!");
        setOpenNotice(true);
        setContent(
          <BookingComp
            patientId={saveData.data.patientId}
            onBooking={bookingHandler}
            tabValue={"2"}
          />
        );
      }
    }

    console.log({ handler_saveData: saveData });
  };

  const registrationHandler = (page, data) => {
    if (page === "BACK") {
      props.onChangeContent("BACK", "");
    } else if (page === "APPOINTMENTBOOKING") {
      setContent(
        <BookingComp
          patientId={data}
          onBooking={bookingHandler}
          tabValue={"2"}
        />
      );
    } else if (page === "EDITPATIENTDETAILS") {
      setContent(
        <EditPatientRegistrationForm
          patientId={data}
          onAddRegistrationData={saveDataHandler}
        />
      );
    }
  };
  const [content, setContent] = useState(
    <OldPatientRegistrationContent
      onAddRegistrationData={registrationHandler}
    />
  );
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box>{content}</Box>
    </Fragment>
  );
};
export default RegisterOldPatientMainComp;
