import { Box, Button, Grid, Stack } from "@mui/material";
import { Fragment } from "react";
import { initNew, payload } from "../../dataandpermissions/data";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApiCalls";

const EmployeeSubmitComp = ({
  formValues,
  setFormValues,
  permissions,
  corpId,
  setSeverity,
  setMessage,
  setOpenNotice,
  setEmployeeId,
  setSearchedEmployeeId,
  tempEmpId,
  setTempEmpId,
  _clearFields,
}) => {
  const saveDataHandler = async () => {
    const url = BASE_URL + "org/detailed";

    const dataOBJ = payload(corpId, formValues);

    const empData = await saveData(url, dataOBJ);

    if (empData.error) {
      console.log({ "empData.error": empData.error.response.data?.message });
      setSeverity("error");
      setMessage(empData?.error?.response?.data?.message);
      setOpenNotice(true);
    } else {
      console.log({ "empData.data": empData.data });
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
      clearFields();
    }
  };

  const clearFields = () => {
    setEmployeeId("");
    setFormValues(initNew(permissions?.isEnabled));
    setSearchedEmployeeId(null);
    if (tempEmpId) {
      setTempEmpId(null);
      _clearFields();
    }
  };
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" justifyContent="center" spacing={4}>
              <Button
                disabled={formValues.empId ? false : true}
                variant="contained"
                sx={{ width: 200 }}
                onClick={saveDataHandler}
              >
                Save
              </Button>
              <Button
                variant="contained"
                sx={{ width: 200 }}
                onClick={clearFields}
              >
                Clear
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeSubmitComp;
