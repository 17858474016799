import { Button } from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL, zoomRooms } from "../../assets/constantsFile";

import { saveData } from "../../services/api/postApiCalls";

const RemindDoctor = (props) => {
  const markJoinedCall = async () => {
    const url = BASE_URL + "clinic/markPatientJoinedCall";
    const obj = {
      appointmentId: props.calls.appointmentId,
      joinedCall: true,
    };
    const data = await saveData(url, obj);

    if (data.error) {
    } else {
      console.log("success");
      console.log(props.calls.appointmentId);
    }
  };
  return (
    <Fragment>
      <Button
        onClick={markJoinedCall}
        variant="outlined"
        startIcon={<NotificationsActiveIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>
    </Fragment>
  );
};

export default RemindDoctor;
