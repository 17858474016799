import { Grid } from "@mui/material";
import { TextFieldItem } from "./customTextField";

const TextFieldComps = ({ formValues, setFormValues, TextFieldList }) => {
  return (
    <Grid
      container
      columns={14}
      columnSpacing={3}
      display="flex"
      alignItems="center"
      rowSpacing={1}
    >
      {TextFieldList.filter((val) => val.visibility).map((item, index) => (
        <Grid item lg={2} xs={7} key={index}>
          <TextFieldItem
            title={item.title}
            property={item.property}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TextFieldComps;
