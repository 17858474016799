import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { Fragment, useState } from "react";
import LogoutCorp from "../pages/logoutCorp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const NavbarCorp = ({
  cropName = localStorage.getItem("CORP_NAME"),
  corpId = localStorage.getItem("CORP_ID"),
}) => {
  const [pages, setPages] = useState([
    {
      name: "Home",
      url: "/corp/home",
      visibility: corpId !== null ? true : false,
    },
    {
      name: "Gather Info",
      url: "/corp/gather-info",
      visibility: corpId !== null ? true : false,
    },
    {
      name: "Execution Info",
      url: "/corp/execution-info",
      visibility: corpId !== null ? true : false,
    },
    { name: "Create New", url: "/corp/create-corp", visibility: true },
    { name: "Select Corp", url: "/corp/select-corp", visibility: true },
    { name: "Logout", url: "/corp/logout", visibility: true },
  ]);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <Fragment>
      <AppBar position="static" color="default" sx={{ mb: 2 }}>
        <Container>
          <Toolbar disableGutters>
            <Typography variant="h6">
              Uno care {cropName ? ` | ${cropName}` : null}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
                mr: 2,
              }}
            >
              {pages.map(
                (page) =>
                  page.visibility && (
                    <Button
                      href={page.url}
                      key={page.name}
                      sx={{ my: 2, color: "black", display: "block" }}
                    >
                      {page.name}
                    </Button>
                  )
              )}
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", lg: "none" },
                justifyContent: "flex-end",
                alignItems: "center",
                mr: 2,
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
              >
                {pages.map(
                  (page) =>
                    page.visibility && (
                      <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                        <NavLink
                          style={{ textDecoration: "none", color: "#000" }}
                          underline="none"
                          variant="button"
                          color="text.primary"
                          to={page.url}
                          sx={{ my: 1, mx: 1.5 }}
                        >
                          {page.name}
                        </NavLink>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Fragment>
  );
};
export default NavbarCorp;
