import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const RespondentComments = ({ formValues, setFormValues }) => {
  // const [comments, setComments] = useState(formValues["comments"] || "");

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     setFormValues({ ...formValues, comments: comments });
  //   }, 1000);

  //   return () => clearTimeout(getData);
  // }, [comments]);

  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={12}>
          <Typography>Comments:</Typography>
        </Grid>
        <Grid item lg={8} xs={12}>
          <TextField
            multiline
            rows={3}
            size="small"
            fullWidth
            placeholder={"Comments"}
            value={formValues["comments"] || ""}
            onChange={(e) =>
              setFormValues({ ...formValues, comments: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default RespondentComments;
