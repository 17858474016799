import { Box, Button, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GoToRegistration = (props) => {
  let navigate = useNavigate();

  const toRegHome = () => {
    navigate("/registration");
  };

  const toClinicHome = () => {
    navigate("/clinicDashboard");
  };

  const toLabHome = () => {
    navigate("/labDashboard");
  };

  const toPharmacyHome = () => {
    navigate("/pharmacyDashboard");
  };

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Stack direction={"row"} spacing={2}>
            <Button variant="contained" href="/registration">
              Go To Registration Home
            </Button>

            {props.tabValue === "CLINIC" ? (
              <Button variant="contained" onClick={toClinicHome}>
                Go To Clinic Dashboard
              </Button>
            ) : (
              ""
            )}
            {props.tabValue === "LAB" ? (
              <Button variant="contained" onClick={toLabHome}>
                Go To Lab Dashboard
              </Button>
            ) : (
              ""
            )}

            {props.tabValue === "PHARMACY" ? (
              <Button variant="contained" onClick={toPharmacyHome}>
                Go To Pharmacy Dashboard
              </Button>
            ) : (
              ""
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GoToRegistration;
