import { Fragment } from "react"
import MyAppBarAfterLoginHC from "../../header/myAppBarAfterLoginHC"
import LandingHC from "../../healthcamp/landingHC"
import MyAppBarAfterLoginNewHC from "../../header/myAppBarAfterLoginNewHC"

const LandingPageHC = () => {
  return (
    <Fragment>
      <MyAppBarAfterLoginHC />
      <LandingHC />
    </Fragment>
  )
}

export default LandingPageHC
