import { Box, Container, Grid } from "@mui/material";
import ShowCurrentDate from "./components/showCurrentDate";
import TitleHC from "../../healthcamp/common/title/titleHC";
import SetupIndex from "./setup/setupIndex";
import OnboardingIndex from "./onboarding/onboardingIndex";
import { Fragment, useEffect, useState } from "react";
import MarketingTitle from "./components/marketingTitle";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApiCalls";
import { getData } from "../../services/api/getApiCalls";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";

const UserOnBoardingNew = () => {
  const init = {
    fullName: "",
    clinicLocation: localStorage.getItem("CLINIC_LOCATION") || "",
    surveyLocation: localStorage.getItem("SURVEY_LOCATION") || "",
    internalStaffPhoneNumber: localStorage.getItem("userMobile") || "",
    marketingLeadType: "PATIENT",
    diseaseGroup: "MD",
  };
  const [noticeData, setNoticeData] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });
  const [formValues, setFormValues] = useState(init);
  const [diseases, setDiseases] = useState({});
  const [leadType, setLeadType] = useState("PATIENT");

  const [showSetup, setShowSetup] = useState(
    localStorage.getItem("CLINIC_LOCATION") &&
      localStorage.getItem("SURVEY_LOCATION")
      ? false
      : true
  );

  const _handleSetupVisibility = () => {
    setShowSetup(!showSetup);
  };

  const _submitHandler = async (e) => {
    e.preventDefault();
    const url = BASE_URL + "healthcamp/marketing";
    const result = await saveData(url, formValues);

    if (result.error) {
      console.log("error");
      setNoticeData({
        severity: "error",
        message: "Failed.",
        openNotice: true,
      });
    } else {
      console.log("success");
      setNoticeData({
        severity: "success",
        message: "Assigned Successfully.",
        openNotice: true,
      });
      getCount();
      setFormValues(init);
      setDiseases({});
      setLeadType("PATIENT");
    }
  };

  const [count, setCount] = useState(0);
  const [target, setTarget] = useState(0);
  const [associateName, setAssociateName] = useState("");
  const [appreciationText, setAppreciationText] = useState("");

  useEffect(() => {
    getCount();
  }, []);

  const getCount = async () => {
    const url =
      BASE_URL +
      "healthcamp/marketing/internalStaff/info/" +
      localStorage.getItem("userMobile");

    const data = await getData(url);

    if (data.error) {
    } else {
      setCount(data.data.count);
      setTarget(data.data.target);
      setAssociateName(data.data.staffFullName);
      setAppreciationText(data.data.appreciationText);
      console.log({ data: data.data });
    }
  };
  console.log({ formValues });

  console.log({ CLINIC_LOCATION: localStorage.getItem("CLINIC_LOCATION") });
  console.log({ SURVEY_LOCATION: localStorage.getItem("SURVEY_LOCATION") });
  return (
    <Fragment>
      <ShowNoticeMessage
        noticeData={noticeData}
        key={JSON.stringify(noticeData)}
      />

      <Container component={"main"}>
        <form onSubmit={_submitHandler}>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <MarketingTitle
                  showSetup={showSetup}
                  handleSetupVisibility={_handleSetupVisibility}
                />
              </Grid>
              <Grid item xs={12}>
                {showSetup ? (
                  <SetupIndex
                    handleSetupVisibility={_handleSetupVisibility}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                ) : (
                  <OnboardingIndex
                    formValues={formValues}
                    setFormValues={setFormValues}
                    diseases={diseases}
                    setDiseases={setDiseases}
                    count={count}
                    target={target}
                    leadType={leadType}
                    setLeadType={setLeadType}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </Fragment>
  );
};

export default UserOnBoardingNew;
