import { Fragment } from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router";

const CustomButton = ({ label, url }) => {
    let navigate = useNavigate();
    let location = useLocation();
    const isActive = location.pathname === url;
    return (
        <Button
            variant={isActive ? "contained" : "outlined"}
            sx={{ px: 0.5, py: 0.7, mx: 0.3, mt: 2, mb: -2, borderRadius: "20px" }}
            onClick={() => navigate(url)}>
            {label}
        </Button>
    );
};

const HeaderHC = () => {
    return (
        <Fragment>
            <Grid container display={"flex"} justifyContent="center" wrap="nowrap">
                <Grid item >
                    <CustomButton label="Assign Token" url="/assignTokenHC" />
                    <CustomButton label="Add Family" url="/addFamilyHC" />
                    <CustomButton label="Vitals" url="/vitalsHC" />
                    <CustomButton label="Free Token" url="/freeTokenHC" />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default HeaderHC;
