import { TextField } from "@mui/material";
import { Fragment } from "react";

const GuradianPhoneNumber = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <TextField
        size="small"
        fullWidth
        label="Guradian Phone Number:"
        variant="outlined"
        value={formValues.guardianContactNumber || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length < 11)
            setFormValues({
              ...formValues,
              guardianContactNumber: e.target.value,
            });
        }}
      />
    </Fragment>
  );
};

export default GuradianPhoneNumber;
