import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import DrugAllergyFormComp from "./drugAllergyFormComp";
import DrugHistoryFormComp from "./drugHistoryFormComp";
import LifeStyleChoicesFormComp from "./lifeStyleChoicesFormComp";
import OccupationFormComp from "./occupationFormComp";
import ParentalHistoryFormComp from "./parentalHistoryFormComp";
import PreExCondFormComp from "./preExCondFormComp";
import Autocomplete from "../../../common/forms/autocomplete";

const PatientHistoryMainComp = ({
  isOptionalData,
  formValues,
  setFormValues,
  isDialog,
  patientDetails,
}) => {
  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
          pt: 3,
          display: !isOptionalData ? "flex" : "none",
        }}
      >
        <Grid container spacing={2}>
          {isDialog ? (
            ""
          ) : (
            <Grid item xs={12} lg={12}>
              <Typography variant="h6">Patient Optional Information</Typography>
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <PreExCondFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DrugHistoryFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DrugAllergyFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LifeStyleChoicesFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <OccupationFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ParentalHistoryFormComp
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          {patientDetails.patientInfoVM.gender === "FEMALE" ? (
          <Fragment><Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Menstual History"
              keyValue={"menstualHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Obstratic History"
              keyValue={"obstraticHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Marital History"
              keyValue={"maritalHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Surgical History"
              keyValue={"surgicalHistory"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              formValues={formValues}
              setFormValues={setFormValues}
              label="Personal History"
              keyValue={"personalHistory"}
            />
          </Grid>
          </Fragment>) : false}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientHistoryMainComp;
