import { Button, Container, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import PatientHistoryMainComp from "../../patientRegistration/patientRegistrationFormElements/patientHistoryFormComps/patientHistoryMainComp";
import { updateData } from "../../services/api/patchApi";

const MedicalHistoryForm = ({
  patientId,
  formValues,
  setFormValues,
  handleClose,
  pDetails,
}) => {
  const submitVitalHandler = (event) => {
    event.preventDefault();

    console.log({ formValues_uploadMedicalHistory: formValues });

    const medicalHistoryObj = {
      patientId: patientId,
      preExistingConditions: formValues.pPreExcond,
      drugHistory: formValues.pDrugHistory,
      drugAllergy: formValues.pDrugAllergy,
      habits: formValues.pHabit,
      occupation: formValues.pOccupation,
      parentalHistory: formValues.pParentalHistory,
      menstualHistory: formValues.menstualHistory,
      obstraticHistory: formValues.obstraticHistory,
      maritalHistory: formValues.maritalHistory,
      surgicalHistory: formValues.surgicalHistory,
      personalHistory: formValues.personalHistory,
    };

    console.log({ objtobesaved_MedicalHistory: medicalHistoryObj });

    saveVitals(BASE_URL + "patient/medicalHistory", medicalHistoryObj);
  };

  const saveVitals = async (url, obj) => {
    const user = await updateData(url, obj, "");

    if (user.error) {
      console.log({ medicalHistory_Error: user.error });
    } else {
      console.log({ medicalHistory_Success: user.data });
      pDetails.patientInfoVM = user.data;
      handleClose();
    }
  };

  const closeDialog = () => {
    const patientInfoVM = pDetails.patientInfoVM;

    setFormValues({
      pPreExcond: patientInfoVM
        ? patientInfoVM.preExistingConditions
        : "Not Available",
      pDrugHistory: patientInfoVM
        ? patientInfoVM.drugHistory
        : "Not Available1",
      pDrugAllergy: patientInfoVM ? patientInfoVM.drugAllergy : "Not Available",
      pHabit: patientInfoVM ? patientInfoVM.habits : "Not Available",
      pOccupation: patientInfoVM ? patientInfoVM.occupation : "Not Available",
      pParentalHistory: patientInfoVM
        ? patientInfoVM.parentalHistory
        : "Not Available",
    });
    handleClose();
  };

  return (
    <React.Fragment>
      <Container sx={{ width: "60vw" }}>
        <Box sx={{ p: 2 }}>
          <form id="myVitalForm" onSubmit={submitVitalHandler}>
            <Box>
              <PatientHistoryMainComp
                isOptionalData={false}
                formValues={formValues}
                setFormValues={setFormValues}
                isDialog={true}
                patientDetails={pDetails}
              />
            </Box>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} lg={12}>
                <Stack direction={"row"}>
                  <Button onClick={closeDialog}>Close</Button>
                  <Button type="submit">save</Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default MedicalHistoryForm;
