import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { getData } from "../../../../services/api/getApiCalls";
import { saveData } from "../../../../services/api/postApiCalls";
import printJS from "print-js";
import CorpPackageReadonly from "../../comps/corpPackageReadonly";
import TextFieldComps from "../../comps/textFieldComps";
import { SwitchFieldList, TextFieldList, UploadFieldList } from "./jalpaData";
import SwitchComps from "../../comps/switchComps";
import { CustomTypography } from "../../comps/customTypography";
import { TextFieldItem } from "../../comps/customTextField";
import ImageUploadComp from "../../comps/imageUploadComp";
import { BASE_URL } from "../../../../assets/constantsFile";
import ChangePackageDialog from "../../comps/changePackageDialog";
import { init, payload } from "../../dataandpermissions/data";

const CorporateHomeJalpa = ({
  corpId = localStorage.getItem("CORPID"),
  consolidatedReport,
}) => {
  console.log({ corpId });
  const [employeeId, setEmployeeId] = useState("");
  const [formValues, setFormValues] = useState(init);
  const [selctedPackage, setSelctedPackage] = useState(null);
  const [searchedEmployeeId, setSearchedEmployeeId] = useState(null);

  const fetchEmployee = async (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/detailed/${employeeId}?corpId=${corpId}`;

    const empData = await getData(url);
    if (empData.error) {
      setSeverity("error");
      setMessage(empData.error?.response?.data?.message);
      setOpenNotice(true);
      setSearchedEmployeeId(null);
      // console.log({ display: empData.error?.response?.data?.message });
    } else {
      setFormValues(empData.data);
      setSearchedEmployeeId(empData.data.empId);
      console.log({ display: empData.data.display });
    }
  };

  console.log({ formValues });
  console.log({ searchedEmployeeId });

  const saveDataHandler = async () => {
    const url = BASE_URL + "org/detailed";

    const dataOBJ = payload(corpId, formValues);

    const empData = await saveData(url, dataOBJ);

    if (empData.error) {
      console.log({ "empData.error": empData.error });
      setSeverity("error");
      setMessage("Failed to save!");
      setOpenNotice(true);
    } else {
      console.log({ "empData.data": empData.data });
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
      clearFields();
    }
  };

  const clearFields = () => {
    setEmployeeId("");
    setFormValues(init);
    setSearchedEmployeeId(null);
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const _handleSubmit = async () => {
    const url = BASE_URL + `org/print/tests`;
    setIsLoading(true);

    const payload = {
      corpId: corpId,
      empId: searchedEmployeeId,
    };

    const printData = await saveData(url, payload);

    if (printData.error) {
      console.log({ error: printData.error });
    } else {
      console.log({ success: printData.data });
      printJS(printData.data);
      setIsLoading(false);
    }
  };
  const [isLoadng, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  console.log({ payload: payload(corpId, formValues) });

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <ChangePackageDialog
        open={open}
        setOpen={setOpen}
        setFormValues={setFormValues}
        formValues={formValues}
      />

      <Container
        maxWidth={false}
        sx={{ backgroundColor: "#F5F5F5", minHeight: "90vh" }}
      >
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={9}>
                  <form onSubmit={fetchEmployee}>
                    <Box>
                      <Stack direction="row">
                        <TextField
                          value={employeeId}
                          onChange={(e) => setEmployeeId(e.target.value)}
                          variant="outlined"
                          label=""
                          fullWidth
                          size="small"
                          sx={{
                            backgroundColor: "#fff",
                            "& fieldset": {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          }}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            px: 5,
                          }}
                        >
                          Search
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </Grid>

                <Grid
                  item
                  lg={6}
                  xs={3}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {/* <Autocomplete
                    sx={{ width: 200 }}
                    size="small"
                    disablePortal
                    options={[]}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Configurations" />
                    )}
                  /> */}

                  {consolidatedReport === "CONSOLIDATEDREPORT" && (
                    <Stack direction="row">
                      <Button
                        disabled={searchedEmployeeId ? false : true}
                        variant="contained"
                        sx={{ width: 100 }}
                        onClick={_handleSubmit}
                      >
                        Print
                        {isLoadng && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                            }}
                          >
                            <CircularProgress size={30} />
                          </Box>
                        )}
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} xs={12}>
              <Stack direction="row">
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#6B6B6B",
                  }}
                >
                  Name:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {formValues?.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} xs={8}>
              <Stack direction="row">
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#6B6B6B",
                  }}
                >
                  Phone No:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {formValues?.mobile}
                </Typography>
              </Stack>
            </Grid>

            <Grid item lg={2} xs={4}>
              <Stack direction="row">
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#6B6B6B",
                  }}
                >
                  Age:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {formValues?.age}
                </Typography>
              </Stack>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Box
                sx={{
                  boxSizing: "border-box",
                  background: "#FFFFFF",
                  border: "0.5px solid #A6A6A6",
                  borderRadius: 5,
                  minHeight: "40vh",
                  p: 2,
                }}
              >
                <Grid
                  container
                  columns={14}
                  columnSpacing={3}
                  display="flex"
                  alignItems="center"
                  rowSpacing={1}
                >
                  <Grid item lg={2} xs={5}>
                    <TextField
                      fullWidth
                      label="Emp ID*"
                      size="small"
                      value={formValues.empId || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, empId: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={8} xs={9}>
                    <TextField
                      fullWidth
                      label="Department"
                      size="small"
                      value={formValues.department || ""}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          department: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item lg={4} xs={14}>
                    <CorpPackageReadonly
                      formValues={formValues}
                      setFormValues={setFormValues}
                      setOpen={setOpen}
                    />
                  </Grid>
                  <Grid item lg={8} xs={14}>
                    <TextField
                      fullWidth
                      label="Name"
                      size="small"
                      value={formValues.name || ""}
                      onChange={(e) => {
                        setFormValues({ ...formValues, name: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={2} xs={8}>
                    <TextField
                      fullWidth
                      label="Phone No."
                      size="small"
                      value={formValues.mobile || ""}
                      error={
                        !formValues.mobile || formValues.mobile?.length === 10
                          ? false
                          : true
                      }
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length <= 10
                        ) {
                          setFormValues({
                            ...formValues,
                            mobile: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item lg={1} xs={6}>
                    <TextField
                      fullWidth
                      label="Age"
                      size="small"
                      value={formValues.age || ""}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length <= 2
                        ) {
                          setFormValues({ ...formValues, age: e.target.value });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} xs={14}>
                    <Autocomplete
                      fullWidth
                      value={formValues.gender}
                      onChange={(event, newValue) => {
                        let newFormValues = { ...formValues };
                        newFormValues["gender"] = newValue;
                        setFormValues(newFormValues);
                      }}
                      // sx={{ width: 200, mt: 0 }}
                      size="small"
                      disablePortal
                      options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Gender" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item lg={14} xs={14}>
                    <TextFieldComps
                      TextFieldList={TextFieldList}
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                  </Grid>
                  <Grid item lg={14} xs={14}>
                    <SwitchComps
                      formValues={formValues}
                      setFormValues={setFormValues}
                      SwitchFieldList={SwitchFieldList(formValues)}
                    />
                  </Grid>

                  <Grid item lg={4} xs={14}>
                    <Stack>
                      <CustomTypography>Hearing</CustomTypography>
                      <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={formValues.hearing}
                        exclusive
                        onChange={(event, newAlignment) => {
                          let newFormValues = { ...formValues };
                          newFormValues["hearing"] = newAlignment;
                          setFormValues(newFormValues);
                        }}
                      >
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                            },
                          }}
                          value="normal"
                        >
                          Normal
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                            },
                          }}
                          value="impaired"
                        >
                          Impaired
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </Grid>

                  {/* <Grid item lg={5} xs={14}>
                    <Autocomplete
                      value={formValues.gender}
                      onChange={(event, newValue) => {
                        let newFormValues = { ...formValues };
                        newFormValues["gender"] = newValue;
                        setFormValues(newFormValues);
                      }}
                      sx={{ width: 200, mt: 2 }}
                      size="small"
                      disablePortal
                      options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Gender" />
                      )}
                    />
                  </Grid> */}

                  <Grid item lg={10} xs={14}>
                    <TextFieldItem
                      title="Doctor's Remark "
                      property="doctorsRemark"
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                  </Grid>

                  <Grid item lg={14} xs={14}>
                    <Box sx={{ mt: 5 }}>
                      <Grid container columnSpacing={12} rowSpacing={2}>
                        {UploadFieldList(formValues, corpId)
                          .filter((item) => item.display)
                          .map((item, index) => (
                            <Grid
                              key={index}
                              item
                              lg={3}
                              xs={12}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <ImageUploadComp
                                formValues={formValues}
                                setFormValues={setFormValues}
                                url={item.url}
                                label={item.title}
                                property={item.property}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Stack direction="row" justifyContent="center" spacing={4}>
                <Button
                  disabled={formValues.empId ? false : true}
                  variant="contained"
                  sx={{ width: 200 }}
                  onClick={saveDataHandler}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: 200 }}
                  onClick={clearFields}
                >
                  Clear
                </Button>
              </Stack>
            </Grid>

            <Grid item lg={12} xs={12}></Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default CorporateHomeJalpa;
