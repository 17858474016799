import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Portal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDate from "../../components/CustomDate";

import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import { updateData } from "../../../services/api/patchApi";
import CustomButtonBlue from "../../components/customButton";
import GatherInfoView from "./gatherInfoView";
import BackButton from "../../components/backButton";

const optionsCompleted = ["COMPLETED", "NOT_COMPLETED"];
const optionsUploaded = ["UPLOADED", "NOT_UPLOADED"];
const optionsPrinted = ["PRINTED", "NOT_PRINTED"];

const AutocompleteComp = ({
  placeholderText,
  labelText,
  value,
  options,
  onChange,
}) => {
  return (
    <Autocomplete
      sx={{
        background: "#fff",
        color: "#000000",
        fontWeight: "300",
        fontSize: "13px",
        lineHeight: " 15px",
        "& input::placeholder": {
          color: "#777777",
          fontWeight: "300",
          fontSize: "13px",
          lineHeight: " 15px",
        },
      }}
      size="small"
      fullWidth
      placeholder={placeholderText}
      label={labelText}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={labelText} // Pass the label attribute
          placeholder={placeholderText} // Pass the placeholder attribute
        />
      )}
    />
  );
};

const CustomField = ({ formValues, setFormValues }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Official Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Name"
          value={formValues.corpName}
          onChange={(e) =>
            setFormValues({ ...formValues, corpName: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Address</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Address"
          value={formValues.address}
          onChange={(e) =>
            setFormValues({ ...formValues, address: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Unocare SPOC</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Unocare SPOC"
          value={formValues.unoSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, unoSPOC: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Name"
          value={formValues.corpSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, corpSPOC: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Mobile</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Mobile"
          value={formValues.corpSPOCMobile}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue.length <= 10) {
              setFormValues({ ...formValues, corpSPOCMobile: inputValue });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>GST No For Billing</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter GST No"
          value={formValues.gst}
          onChange={(e) =>
            setFormValues({ ...formValues, gst: e.target.value })
          }
        />
        {/* <IconButton>
          <EditIcon />
        </IconButton>
        <IconButton>
          <SaveIcon />
        </IconButton> */}
      </Grid>
    </Grid>
  );
};

const PhaseDetails = ({ formValues, setFormValues, handleChange }) => {
  const [isAddPhaseModal, setIsAddPhaseModal] = useState(false);
  const togglePhaseModal = () => {
    setIsAddPhaseModal(!isAddPhaseModal);
  };
  const [newExecutionDetail, setnewExecutionDetail] = useState({
    name: "",
    fromDate: null,
    toDate: null,
    noOfEmp: "",
  });
  const handleAddPhaseDetail = () => {
    if (newExecutionDetail.name !== "") {
      setFormValues({
        ...formValues,
        executionDate: [...formValues.executionDate, newExecutionDetail],
      });
    }
    togglePhaseModal();
    setnewExecutionDetail({
      name: "",
      fromDate: new Date(),
      toDate: new Date(),
      noOfEmp: "",
    });
  };

  const handleDeletePhase = (index) => {
    const updatedPhaseDetails = [...formValues.executionDate];
    updatedPhaseDetails.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      executionDate: updatedPhaseDetails,
    }));
  };

  return (
    <Box>
      {formValues?.executionDate &&
        formValues?.executionDate?.map((item, index) => (
          <Grid
            container
            spacing={1}
            key={index}
            sx={{
              border: "0.5px solid #000",
              borderRadius: "10px",
              marginBlock: "10px",
              padding: "5px",
            }}
          >
            <Grid item xs={10} lg={2}>
              <Typography sx={{ textTransform: "capitalize" }}>
                {item?.name?.toUpperCase()?.toLowerCase()}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: { xs: "block", lg: "none" } }}>
              <IconButton onClick={() => handleDeletePhase(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ color: "#000", marginRight: "10px" }}>
                From
              </Typography>
              <CustomDate
                setDate={(value) =>
                  handleChange("executionDate", index, "fromDate")(value)
                }
                initialDate={item.fromDate}
                disablePast={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ color: "#000", marginInline: "10px" }}>
                To
              </Typography>
              <CustomDate
                setDate={(value) =>
                  handleChange("executionDate", index, "toDate")(value)
                }
                initialDate={item.toDate}
                disablePast={true}
              />
            </Grid>
            <Grid item lg={2} sx={{ display: { xs: "none", lg: "block" } }}>
              <IconButton onClick={() => handleDeletePhase(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                sx={{
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="#Employees"
                label="#Employees"
                value={item.noOfEmp}
                onChange={handleChange("executionDate", index, "noOfEmp")}
              />
            </Grid>
          </Grid>
        ))}

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button variant="outlined" onClick={togglePhaseModal}>
          Add Phase
        </Button>
      </Box>

      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isAddPhaseModal}
          onClose={togglePhaseModal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.5)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={togglePhaseModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Add Phase
            </Typography>
            <Box>
              <Grid
                container
                spacing={1}
                sx={{
                  border: "0.5px solid #000",
                  borderRadius: "10px",
                  marginBlock: "10px",
                  padding: "5px",
                }}
              >
                <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                  <TextField
                    sx={{
                      background: "#fff",
                      color: "#000000",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    size="small"
                    fullWidth
                    placeholder="Enter Phase Name"
                    label="Enter Phase Name*"
                    value={newExecutionDetail.name}
                    onChange={(e) =>
                      setnewExecutionDetail({
                        ...newExecutionDetail,
                        name: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ color: "#000", marginRight: "10px" }}>
                    From
                  </Typography>
                  <CustomDate
                    setDate={(value) =>
                      setnewExecutionDetail({
                        ...newExecutionDetail,
                        fromDate: value,
                      })
                    }
                    initialDate={newExecutionDetail.fromDate}
                    disablePast={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ color: "#000", marginInline: "10px" }}>
                    To
                  </Typography>
                  <CustomDate
                    setDate={(value) =>
                      setnewExecutionDetail({
                        ...newExecutionDetail,
                        toDate: value,
                      })
                    }
                    initialDate={newExecutionDetail.toDate}
                    disablePast={true}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    sx={{
                      background: "#fff",
                      color: "#000000",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    size="small"
                    fullWidth
                    placeholder="#Employees"
                    label="#Employees"
                    value={newExecutionDetail.noOfEmp}
                    onChange={(e) =>
                      setnewExecutionDetail({
                        ...newExecutionDetail,
                        noOfEmp: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <CustomButtonBlue title="Save" onClick={handleAddPhaseDetail} />
            </Box>
          </Box>
        </Modal>
      </Portal>
    </Box>
  );
};
const TestDetail = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.testDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography sx={{ textTransform: "capitalize" }}>
                  {item?.label?.toUpperCase()?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Completed"
                  labelText="Completed"
                  onChange={handleChange("testDetails", index, "completed")}
                  value={item?.completed}
                  options={optionsCompleted}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  labelText="Soft Copy"
                  placeholderText="Soft Copy"
                  value={item.softCopy}
                  onChange={handleChange("testDetails", index, "softCopy")}
                  options={optionsUploaded}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Hard Copy"
                  labelText="Hard Copy"
                  value={item.hardCopy}
                  onChange={handleChange("testDetails", index, "hardCopy")}
                  options={optionsPrinted}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};

const Vaccination = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.vaccinationDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography sx={{ textTransform: "capitalize" }}>
                  {item?.label?.toUpperCase()?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Completed"
                  labelText="Completed"
                  value={item.completed}
                  onChange={handleChange(
                    "vaccinationDetails",
                    index,
                    "completed"
                  )}
                  options={optionsCompleted}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Soft Copy"
                  labelText="Soft Copy"
                  value={item.softCopy}
                  onChange={handleChange(
                    "vaccinationDetails",
                    index,
                    "softCopy"
                  )}
                  options={optionsUploaded}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Hard Copy"
                  labelText="Hard Copy"
                  value={item.hardCopy}
                  onChange={handleChange(
                    "vaccinationDetails",
                    index,
                    "hardCopy"
                  )}
                  options={optionsPrinted}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};
const Pathology = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.pathologyDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography sx={{ textTransform: "capitalize" }}>
                  {item?.label?.toUpperCase()?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Completed"
                  labelText="Completed"
                  value={item.completed}
                  onChange={handleChange(
                    "pathologyDetails",
                    index,
                    "completed"
                  )}
                  options={optionsCompleted}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Soft Copy"
                  labelText="Soft Copy"
                  value={item.softCopy}
                  onChange={handleChange("pathologyDetails", index, "softCopy")}
                  options={optionsUploaded}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Hard Copy"
                  labelText="Hard Copy"
                  value={item.hardCopy}
                  onChange={handleChange("pathologyDetails", index, "hardCopy")}
                  options={optionsPrinted}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};
const FormCertificates = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.formDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography sx={{ textTransform: "capitalize" }}>
                  {item?.label?.toUpperCase()?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Completed"
                  labelText="Completed"
                  value={item.completed}
                  onChange={handleChange("formDetails", index, "completed")}
                  options={optionsCompleted}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Soft Copy"
                  labelText="Soft Copy"
                  value={item.softCopy}
                  onChange={handleChange("formDetails", index, "softCopy")}
                  options={optionsUploaded}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <AutocompleteComp
                  placeholderText="Hard Copy"
                  labelText="Hard Copy"
                  value={item.hardCopy}
                  onChange={handleChange("formDetails", index, "hardCopy")}
                  options={optionsPrinted}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};
const TechReq = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.techRequirements?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={6}>
                <Typography sx={{ textTransform: "capitalize" }}>
                  {item?.label?.toUpperCase()?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <AutocompleteComp
                  placeholderText="Completed"
                  labelText="Completed"
                  value={item.completed}
                  onChange={handleChange(
                    "techRequirements",
                    index,
                    "completed"
                  )}
                  options={optionsCompleted}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};

const ExecutionInfo = ({
  corpId = localStorage.getItem("CORP_ID"),
  unoSPOCName = localStorage.getItem("USER_NAME_CORP"),
  unoSPOCId = localStorage.getItem("USER_ID_CORP"),
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [formValues, setFormValues] = useState({
    corpName: "",
    address: "",
    unoSPOC: "",
    corpSPOC: "",
    corpSPOCMobile: "",
    gst: "",
    testDetails: [
      {
        name: "height_weight",
        label: "Height/Weight",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },

      {
        name: "bp",
        label: "BP",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "rbs",
        label: "RBS",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "eyeVision",
        label: "Eye Vision",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "pft",
        label: "PFT",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "audio",
        label: "Audio",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "ecg",
        label: "ECG",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "xray",
        label: "Xray",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "doctorVisit",
        label: "Doctor Visit",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
    ],
    vaccinationDetails: [
      {
        name: "typhoidVaccine",
        label: "Typhoid Vaccine",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "hepBVaccine",
        label: "Hep B Vaccine",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "tetnusVaccine",
        label: "Tetnus Vaccine",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
    ],
    pathologyDetails: [],
    formDetails: [
      {
        name: "Form32",
        label: "Form 32",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "medicalFitnessCertificate",
        label: "Medical Fitness Certificate",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "physicalFitnessCerticate",
        label: "Physical Fitness Certificate",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "Form35",
        label: "Form 35",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "foodSafetyForm",
        label: "Food Safety Form",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "Form21_HealthRegister",
        label: "Form 21 Health Register",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
      {
        name: "xrayReport",
        label: "X-ray Report",
        completed: "",
        softCopy: "",
        hardCopy: "",
      },
    ],
    techRequirements: [
      {
        name: "mobileApp",
        label: "Mobile App",
        completed: "",
      },
      {
        name: "OffRoll_OnRoll",
        label: "OffRoll/OnRoll",
        completed: "",
      },
      {
        name: "Pending_Complete",
        label: "Pending/Complete",
        completed: "",
      },
      {
        name: "Healthy_Unhealthy",
        label: "Healthy/Unhealthy",
        completed: "",
      },
      {
        name: "Department_Wise",
        label: "Department Wise",
        completed: "",
      },
      {
        name: "bpChart",
        label: "BP CharT",
        completed: "",
      },
      {
        name: "rbsChart",
        label: "RBS Chart",
        completed: "",
      },
      {
        name: "bmiChart",
        label: "BMI Chart",
        completed: "",
      },
    ],
    executionDate: [
      {
        name: "Phase 1",
        label: "Phase 1",
        fromDate: null,
        toDate: null,
        noOfEmp: "",
      },
    ],
    pathologyDetails: [],
    spocsInfo: [],
    remarksInfo: [],
  });

  const [isSpocNameModal, setIsSpocNameModal] = useState(false);
  const toggleSpocNameModal = () => {
    setIsSpocNameModal(!isSpocNameModal);
  };

  const [newSpocName, setNewSpocname] = useState("");
  const [newSpocMobile, setNewSpocMobile] = useState("");

  const handleAddSpocName = () => {
    if (newSpocName) {
      setFormValues({
        ...formValues,
        spocsInfo: [
          ...formValues.spocsInfo,
          { spocname: newSpocName, spocmobile: newSpocMobile },
        ],
      });
      toggleSpocNameModal();
      setNewSpocname("");
    }
  };

  const handleChange = (field, index, property) => (event) => {
    const updatedFormValues = [...formValues?.[field]];

    const value =
      property === "isIncluded"
        ? event.target.checked
        : property === "noOfEmp"
        ? !isNaN(event.target.value)
          ? event.target.value
          : ""
        : event;

    // Convert the value to a boolean if property is "isIncluded"
    updatedFormValues[index][property] =
      property === "isIncluded" ? !!value : value;

    setFormValues({
      ...formValues,
      [field]: updatedFormValues,
    });
  };

  const [corpDetail, setCorpDetail] = useState("");
  const fetchCorpDetails = async () => {
    if (corpId !== null) {
      const url = BASE_URL + "org/corpConfigInfo/corp?corpId=" + corpId;
      const result = await getData(url);
      if (result.data) {
        console.log("SUCCESS", result?.data);
        setCorpDetail(result?.data);
        setTimeout(() => {
          setFormValues({
            id: result?.data?.id,
            corpName: result?.data?.corpName || "",
            address: result?.data?.address || "",
            unoSPOC: result?.data?.unoSPOC
              ? result?.data?.unoSPOC
              : unoSPOCName,
            unoSPOCId: result?.data?.unoSPOCId
              ? result?.data?.unoSPOCId
              : unoSPOCId,
            corpSPOC: result?.data?.corpSPOC || "",
            corpSPOCMobile: result?.data?.corpSPOCMobile || "",
            gst: result?.data?.gst || "",
            testDetails:
              result?.data?.testDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.testDetails[index]?.label,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                completed: item?.finalStatusVM?.isCompleted || null,
                softCopy: item?.finalStatusVM?.isSoftCopy || null,
                hardCopy: item?.finalStatusVM?.isHardCopy || null,
              })) || formValues?.testDetails,
            vaccinationDetails:
              result?.data?.vaccinationDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.vaccinationDetails[index]?.label,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                completed: item?.finalStatusVM?.isCompleted || null,
                softCopy: item?.finalStatusVM?.isSoftCopy || null,
                hardCopy: item?.finalStatusVM?.isHardCopy || null,
              })) || formValues?.vaccinationDetails,
            pathologyDetails:
              result?.data?.pathologyDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: item?.fieldName,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                completed: item?.finalStatusVM?.isCompleted || null,
                softCopy: item?.finalStatusVM?.isSoftCopy || null,
                hardCopy: item?.finalStatusVM?.isHardCopy || null,
              })) || formValues.pathologyDetails,
            formDetails:
              result?.data?.formDetails?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: formValues.formDetails[index]?.label,
                isIncluded: item?.isIncluded || false,
                noOfEmp: item?.employees || "",
                costPrice: item?.costPrice || "",
                remark: item?.remarks || "",
                completed: item?.finalStatusVM?.isCompleted || null,
                softCopy: item?.finalStatusVM?.isSoftCopy || null,
                hardCopy: item?.finalStatusVM?.isHardCopy || null,
              })) || formValues.formDetails,
            techRequirements:
              result?.data?.techRequirements?.map((item, index) => ({
                id: item?.id || "",
                name: item?.fieldName || "",
                label: item?.fieldName,
                isIncluded: item?.isIncluded || false,
                completed: item?.finalStatusVM?.isCompleted || null,
              })) || formValues?.techRequirements,
            requirementsDate: {
              fromDate: result?.data?.requirementsDate?.fromDate,
              toDate: result?.data?.requirementsDate?.toDate,
              time: result?.data?.requirementsDate?.time,
            },
            spocsInfo: result?.data?.spocsInfo || [],
            remarksInfo: result?.data?.remarksInfo || [],
            executionDate:
              result?.data?.executionDate?.map((item, index) => ({
                name: item.fieldName,
                fromDate: item.fromDate,
                toDate: item.toDate,
                noOfEmp: item.employees,
              })) || formValues?.executionDate,
          });
        }, 500);
      } else {
        console.log("ERROR", result.error);
        setCorpDetail("");
      }
    }
  };

  console.log({ LLLL: formValues.executionDate });

  useEffect(() => {
    fetchCorpDetails();
  }, []);

  const Obj = {
    id: formValues.id || null,
    corpName: formValues.corpName,
    address: formValues.address,
    unoSPOC: formValues.unoSPOC,
    corpSPOC: formValues.corpSPOC,
    corpSPOCMobile: formValues.corpSPOCMobile,
    gst: formValues.gst,
    testDetails: formValues?.testDetails?.map((item, index) => ({
      id: index + 1,
      fieldName: item.name,
      isIncluded: item.isIncluded,
      employees: item.noOfEmp,
      costPrice: item.costPrice,
      remarks: item.remark,
      isActive: true,
      finalStatusVM: {
        isCompleted: item.completed,
        isSoftCopy: item.softCopy,
        isHardCopy: item.hardCopy,
      },
    })),

    vaccinationDetails: formValues?.vaccinationDetails?.map((item, index) => ({
      id: index + 1,
      fieldName: item.name,
      isIncluded: item.isIncluded,
      employees: item.noOfEmp,
      costPrice: item.costPrice,
      remarks: item.remark,
      isActive: true,
      finalStatusVM: {
        isCompleted: item.completed,
        isSoftCopy: item.softCopy,
        isHardCopy: item.hardCopy,
      },
    })),
    pathologyDetails: formValues?.pathologyDetails?.map((item, index) => ({
      id: index + 1,
      fieldName: item.name,
      isIncluded: item.isIncluded,
      employees: item.noOfEmp,
      costPrice: item.costPrice,
      remarks: item.remark,
      isActive: true,
      finalStatusVM: {
        isCompleted: item.completed,
        isSoftCopy: item.softCopy,
        isHardCopy: item.hardCopy,
      },
    })),
    formDetails: formValues?.formDetails?.map((item, index) => ({
      id: index + 1,
      fieldName: item.name,
      isIncluded: item.isIncluded,
      employees: item.noOfEmp,
      costPrice: item.costPrice,
      remarks: item.remark,
      isActive: true,
      finalStatusVM: {
        isCompleted: item.completed,
        isSoftCopy: item.softCopy,
        isHardCopy: item.hardCopy,
      },
    })),
    techRequirements: formValues?.techRequirements?.map((item, index) => ({
      id: index + 1,
      fieldName: item.name,
      isIncluded: item.isIncluded,
      employees: item.noOfEmp,
      costPrice: item.costPrice,
      remarks: item.remark,
      isActive: true,
      finalStatusVM: {
        isCompleted: item.completed,
      },
    })),
    executionDate:
      formValues?.executionDate?.map((item, index) => ({
        id: index + 1,
        fieldName: item.name,
        employees: item.noOfEmp,
        fromDate: item.fromDate,
        toDate: item.toDate,
      })) || null,
    requirementsDate: formValues.requirementsDate,
    spocsInfo: formValues.spocsInfo,
    remarksInfo: formValues.remarksInfo,
  };

  const handleUpdate = async () => {
    const url =
      BASE_URL + "org/corpConfigInfo/update?corpConfigId=" + corpDetail?.id;
    const result = await updateData(url, Obj);
    if (result.data) {
      console.log("SUCCESS", result.data);
      setSeverity("success");
      setMessage("Successfully Saved");
      setOpenNotice(true);
    } else {
      console.log("ERROR", result?.error);
      setSeverity("error");
      setMessage("An error occured");
      setOpenNotice(true);
    }
  };

  const handleDeleteSpoc = (index) => {
    const updatedSpocsInfo = [...formValues.spocsInfo];
    updatedSpocsInfo.splice(index, 1);
    setFormValues((prevValues) => ({
      ...prevValues,
      spocsInfo: updatedSpocsInfo,
    }));
  };

  console.log({ Obj });

  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const handleChangeAccordion = (panel) => {
    setExpandedAccordion((prevExpanded) => {
      if (prevExpanded.includes(panel)) {
        return [];
      } else {
        return [panel];
      }
    });
  };

  const handleCollapseAll = () => {
    setExpandedAccordion([]);
  };

  console.log({ formValues });

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={3000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <BackButton url="/corp/home" />
      <Typography
        sx={{
          textAlign: "center",
          color: "#000",
          marginTop: "-30px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        Execution Information
      </Typography>
      <Box>
        <Divider />
        <Box sx={{ textAlign: "center", marginBlock: 1 }}>
          <Button variant="outlined" onClick={handleCollapseAll}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Collapse All
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setExpandedAccordion([
                "panel1",
                "panel2",
                "panel3",
                "panel4",
                "panel5",
                "panel6",
                "panel7",
                "panel8",
                "panel9",
              ])
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Expand All
            </Typography>
          </Button>
        </Box>

        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel1")}
          onChange={() => handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography
              sx={{ textAlign: "center", color: "#000", fontSize: "20px" }}
            >
              Company Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>
                      Company Official Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Company Name"
                      value={formValues.corpName}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          corpName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>
                      Company Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Company Address"
                      value={formValues.address}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>Unocare SPOC</Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Unocare SPOC"
                      value={formValues.unoSPOC}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          unoSPOC: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>
                      CORP SPOC Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter SPOC Name"
                      value={formValues.corpSPOC}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          corpSPOC: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>
                      CORP SPOC Mobile
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter SPOC Mobile"
                      value={formValues.corpSPOCMobile}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!isNaN(inputValue) && inputValue.length <= 10) {
                          setFormValues({
                            ...formValues,
                            corpSPOCMobile: inputValue,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "#000" }}>
                      GST No For Billing
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                    <TextField
                      disabled={true}
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter GST No"
                      value={formValues.gst}
                      onChange={(e) =>
                        setFormValues({ ...formValues, gst: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomField
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel2")}
          onChange={() => handleChangeAccordion("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Execution Date Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{}}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    border: "0.5px solid #000",
                    padding: "10px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#000" }}>From</Typography>
                    <Box
                      sx={{
                        border: "1px solid lightgrey",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>
                        {formValues?.requirementsDate?.fromDate}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#000" }}>To</Typography>
                    <Box
                      sx={{
                        border: "1px solid lightgrey",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>
                        {formValues?.requirementsDate?.toDate}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#000" }}>
                      Reporting Time
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid lightgrey",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography>
                        {formValues?.requirementsDate?.time}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{
                  // border: "1px solid lightgrey",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <PhaseDetails
                  setFormValues={setFormValues}
                  formValues={formValues}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel3")}
          onChange={() => handleChangeAccordion("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Test Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                {formValues?.testDetails?.map(
                  (item, index) =>
                    item.isIncluded && (
                      <Grid
                        key={index}
                        container
                        spacing={1}
                        sx={{
                          border: "0.5px solid #000",
                          borderRadius: "10px",
                          marginBlock: "10px",
                          padding: "5px",
                        }}
                      >
                        <Grid item xs={6} lg={3}>
                          <Typography>{item.label}</Typography>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!item.isIncluded}
                                onChange={handleChange(
                                  "testDetails",
                                  index,
                                  "isIncluded"
                                )}
                                name="height"
                              />
                            }
                            sx={{ fontSize: "14px" }}
                            label="Yes/no"
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="#Employees"
                            placeholder="#Employees"
                            value={item.noOfEmp}
                            onChange={handleChange(
                              "testDetails",
                              index,
                              "noOfEmp"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="Price"
                            placeholder="Price"
                            value={item.costPrice}
                            onChange={handleChange(
                              "testDetails",
                              index,
                              "costPrice"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="Remark"
                            placeholder="Remark"
                            value={item.remark}
                            onChange={handleChange(
                              "testDetails",
                              index,
                              "remark"
                            )}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <TestDetail
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel4")}
          onChange={() => handleChangeAccordion("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Vaccination
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                {formValues?.vaccinationDetails?.map(
                  (item, index) =>
                    item.isIncluded && (
                      <Grid
                        key={index}
                        container
                        spacing={1}
                        sx={{
                          border: "0.5px solid #000",
                          borderRadius: "10px",
                          marginBlock: "10px",
                          padding: "5px",
                        }}
                      >
                        <Grid item xs={6} lg={3}>
                          <Typography>{item.label}</Typography>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!item.isIncluded}
                                onChange={handleChange(
                                  "vaccinationDetails",
                                  index,
                                  "isIncluded"
                                )}
                                name="height"
                              />
                            }
                            sx={{ fontSize: "14px" }}
                            label="Yes/no"
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="#Employees"
                            placeholder="#Employees"
                            value={item.noOfEmp}
                            onChange={handleChange(
                              "vaccinationDetails",
                              index,
                              "noOfEmp"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="Price"
                            placeholder="Price"
                            value={item.costPrice}
                            onChange={handleChange(
                              "vaccinationDetails",
                              index,
                              "costPrice"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            label="Remark"
                            placeholder="Remark"
                            value={item.remark}
                            onChange={handleChange(
                              "vaccinationDetails",
                              index,
                              "remark"
                            )}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Vaccination
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {formValues.pathologyDetails.length > 0 ? (
          <Accordion
            sx={styles.accordionTitle}
            expanded={expandedAccordion.includes("panel5")}
            onChange={() => handleChangeAccordion("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                Pathology
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Gathered Information
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Execution Information
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  {" "}
                  {formValues?.pathologyDetails?.map((item, index) => (
                    <Grid
                      key={index}
                      container
                      spacing={1}
                      sx={{
                        border: "0.5px solid #000",
                        borderRadius: "10px",
                        marginBlock: "10px",
                        padding: "5px",
                      }}
                    >
                      <Grid item xs={6} lg={3}>
                        <Typography>{item.name}</Typography>
                      </Grid>
                      <Grid item xs={6} lg={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!item.isIncluded}
                              onChange={handleChange(
                                "testDetails",
                                index,
                                "isIncluded"
                              )}
                            />
                          }
                          sx={{ fontSize: "14px" }}
                          label="Yes/no"
                        />
                      </Grid>
                      <Grid item xs={4} lg={2}>
                        <TextField
                          disabled={true}
                          sx={{
                            background: "#fff",
                            color: "#000000",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: " 15px",
                            "& input::placeholder": {
                              color: "#777777",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                            },
                          }}
                          size="small"
                          fullWidth
                          label="#Employees"
                          placeholder="#Employees"
                          value={item.noOfEmp}
                          onChange={handleChange(
                            "pathologyDetails",
                            index,
                            "noOfEmp"
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} lg={2}>
                        <TextField
                          disabled={true}
                          sx={{
                            background: "#fff",
                            color: "#000000",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: " 15px",
                            "& input::placeholder": {
                              color: "#777777",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                            },
                          }}
                          size="small"
                          fullWidth
                          label="Price"
                          placeholder="Price"
                          value={item.costPrice}
                          onChange={handleChange(
                            "pathologyDetails",
                            index,
                            "costPrice"
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} lg={2}>
                        <TextField
                          disabled={true}
                          sx={{
                            background: "#fff",
                            color: "#000000",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: " 15px",
                            "& input::placeholder": {
                              color: "#777777",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                            },
                          }}
                          size="small"
                          fullWidth
                          label="Remark"
                          placeholder="Remark"
                          value={item.remark}
                          onChange={handleChange(
                            "pathologyDetails",
                            index,
                            "remark"
                          )}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Pathology
                    formValues={formValues}
                    setFormValues={setFormValues}
                    handleChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel6")}
          onChange={() => handleChangeAccordion("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Forms/Certificates
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                {formValues?.formDetails?.map(
                  (item, index) =>
                    item.isIncluded && (
                      <Grid
                        container
                        spacing={1}
                        key={index}
                        sx={{
                          border: "0.5px solid #000",
                          borderRadius: "10px",
                          marginBlock: "10px",
                          padding: "5px",
                        }}
                      >
                        <Grid item xs={6} lg={3}>
                          <Typography>{item.label}</Typography>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!item.isIncluded}
                                onChange={handleChange(
                                  "formDetails",
                                  index,
                                  "isIncluded"
                                )}
                              />
                            }
                            label="Yes/no"
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            placeholder="Seal/Sign"
                            label="Seal/Sign"
                            value={item.sealSign}
                            onChange={handleChange(
                              "formDetails",
                              index,
                              "sealSign"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            placeholder="Price"
                            label="Price"
                            value={item.costPrice}
                            onChange={handleChange(
                              "formDetails",
                              index,
                              "costPrice"
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                          <TextField
                            disabled={true}
                            sx={{
                              background: "#fff",
                              color: "#000000",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                              "& input::placeholder": {
                                color: "#777777",
                                fontWeight: "300",
                                fontSize: "13px",
                                lineHeight: " 15px",
                              },
                            }}
                            size="small"
                            fullWidth
                            placeholder="Remark"
                            label="Remark"
                            value={item.remark}
                            onChange={handleChange(
                              "formDetails",
                              index,
                              "remark"
                            )}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormCertificates
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={styles.accordionTitle}
          expanded={expandedAccordion.includes("panel7")}
          onChange={() => handleChangeAccordion("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Tech Requirements
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ marginBottom: "15px" }}>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Gathered Information
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography sx={{ textAlign: "center", color: "#000" }}>
                  Execution Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                {formValues?.techRequirements?.map(
                  (item, index) =>
                    item.isIncluded && (
                      <Grid
                        container
                        key={index}
                        sx={{
                          marginTop: "10px",
                          border: "0.5px solid #000",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <Grid item xs={6} lg={6}>
                          <Typography>{item.label}</Typography>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!item.isIncluded}
                                onChange={handleChange(
                                  "techRequirements",
                                  index,
                                  "isIncluded"
                                )}
                              />
                            }
                            label="Yes/no"
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <TechReq
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {formValues?.spocsInfo?.length > 0 ? (
          <Accordion
            sx={styles.accordionTitle}
            expanded={expandedAccordion.includes("panel8")}
            onChange={() => handleChangeAccordion("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                CORP SPOC Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Gathered Information
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Execution Information
                  </Typography>
                </Grid>
              </Grid>
              {formValues?.spocsInfo?.map((item, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography sx={{ color: "#000" }}>
                          {item.spocname}
                        </Typography>
                        <Typography sx={{ color: "#000", marginLeft: "10px" }}>
                          {item.spocmobile}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => handleDeleteSpoc(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {formValues?.remarksInfo?.length > 0 ? (
          <Accordion
            sx={styles.accordionTitle}
            expanded={expandedAccordion.includes("panel9")}
            onChange={() => handleChangeAccordion("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                Remark Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Gathered Information
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography sx={{ textAlign: "center", color: "#000" }}>
                    Execution Information
                  </Typography>
                </Grid>
              </Grid>
              {formValues?.remarksInfo?.map((item, index) => (
                <Typography key={index} sx={{ color: "#000" }}>
                  {item.remarks}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
      <Button
        variant="outlined"
        onClick={toggleSpocNameModal}
        sx={{ margin: "10px" }}
      >
        Add New Spoc Details
      </Button>
      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isSpocNameModal}
          onClose={toggleSpocNameModal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.1)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              width: "365px",
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={toggleSpocNameModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Add SPOC Name
            </Typography>
            <Box>
              <TextField
                sx={{
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter SPOC Name"
                label="SPOC Name"
                value={newSpocName}
                onChange={(e) => setNewSpocname(e.target.value)}
              />

              <TextField
                sx={{
                  marginTop: "10px",
                  background: "#fff",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter SPOC Mobile"
                label="SPOC Mobile"
                value={newSpocMobile}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!isNaN(inputValue) && inputValue.length <= 10) {
                    setNewSpocMobile(e.target.value);
                  }
                }}
              />

              <CustomButtonBlue
                title="Add SPOC Name"
                onClick={handleAddSpocName}
              />
            </Box>
          </Box>
        </Modal>
      </Portal>

      <CustomButtonBlue title="Save" onClick={() => handleUpdate()} />
    </Fragment>
  );
};

export default ExecutionInfo;

const styles = {
  accordionTitle: {
    "& .MuiTypography-root": {
      fontSize: "16px",
    },
  },
};
