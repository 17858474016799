import {
  Alert,
  Autocomplete,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomButtonBlue from "../../components/customButton";
import { BASE_URL } from "../../../assets/constantsFile";
import { saveData } from "../../../services/api/postApiCalls";
import { useNavigate } from "react-router";
import { getData } from "../../../services/api/getApiCalls";
import BackButton from "../../components/backButton";

const CreateCorpMain = ({
  unoSPOCName = localStorage.getItem("USER_NAME_CORP"),
  unoSPOCId = localStorage.getItem("USER_ID_CORP"),
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };
  const navigate = useNavigate();
  const [corpName, setCorpname] = useState("");
  const [selectedCorp, setSelectedCorp] = useState(null);

  const [corpListOption, setCorpListOptions] = useState([]);

  const fetchCorpList = async () => {
    const url = BASE_URL + "org/corpConfigInfo/all";
    const response = await getData(url);
    if (response.data) {
      const temp = response?.data?.map((item) => ({
        corpId: item.corpId,
        corpName: item.corpName,
      }));
      setCorpListOptions(temp);
    } else {
      console.error({ ERROR: response.error });
      setCorpListOptions([]);
    }
  };

  useEffect(() => {
    fetchCorpList();
  }, []);

  console.log({ corpListOption });

  const obj = {
    corpName: corpName,
    unoSPOC: unoSPOCName,
    unoSPOCId: unoSPOCId,
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleSave = async () => {
    const isCorpExists = corpListOption.filter(
      (item) => item.corpName === corpName
    );
    console.log({ KK: isCorpExists.length });
    if (isCorpExists.length > 0) {
      setSeverity("error");
      setMessage("Corp Already Exist");
      setOpenNotice(true);
    } else {
      setIsClicked(true);
      const url = BASE_URL + "org/corpConfigInfo/add";
      const result = await saveData(url, obj);
      if (result.data) {
        setIsClicked(true);
        console.log("SUCCESS", result?.data);
        localStorage.setItem("CORP_ID", result?.data?.id);
        localStorage.setItem("CORP_NAME", result?.data?.corpName);
        navigate("/corp/home");
      } else {
        setIsClicked(false);
        console.log("ERROR", result.error);
      }
    }
  };
  const uniqueCorpListOptions = Array.from(
    new Set(corpListOption.map((option) => option?.corpName))
  ).map((corpName) =>
    corpListOption.find((option) => option?.corpName === corpName)
  );

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={3000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <BackButton url="/corp/home" />
      <Typography
        sx={{
          textAlign: "center",
          color: "#000",
          marginTop: "-30px",
          fontSize: "16px",
          fontWeight: "600",
          marginBottom: "20px",
        }}
      >
        Create Corp
      </Typography>
      <Autocomplete
        value={selectedCorp}
        onChange={(event, value) => {
          setSelectedCorp(value);
        }}
        onInputChange={(event, newInputValue) => {
          setCorpname(newInputValue);
        }}
        fullWidth
        freeSolo
        disableClearable
        size="small"
        getOptionLabel={(option) => option?.corpName || ""}
        options={uniqueCorpListOptions}
        selectOnFocus
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              background: "#fff",
              color: "#127DDD",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            variant="outlined"
            placeholder="Enter Corp Name"
            label="Corp Name"
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <CustomButtonBlue
        disabled={isClicked ? true : false}
        title="Save"
        onClick={handleSave}
      />
    </Fragment>
  );
};

export default CreateCorpMain;
