import { useCallback } from "react";
import { getData } from "../../services/api/getApiCalls";

const useDiscount = (requestConfig, applyData) => {
  const fetchDataHandler = useCallback(async () => {
    const url = requestConfig.url;
    const user = await getData(url);
    if (user.error) {
      console.log("error");
    } else {
      console.log("success");
      const data = user.data;
      applyData(data);
    }
  }, []);

  return { fetchDataHandler };
};
export default useDiscount;
