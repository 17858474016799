import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

const _branches = [
  "Pithampur",
  "Ghatabillod",
  "Depalpur",
  "Banedia",
  "Gautampura",
];
const BranchLocation = ({ formValues, setFormValues }) => {
  const [branch, setBranch] = useState(
    localStorage.getItem("CLINIC_LOCATION")
      ? localStorage.getItem("CLINIC_LOCATION")
      : ""
  );

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} lg={4}>
          <Typography>Clinic Location:</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <RadioGroup
            row
            value={branch}
            label={"Select Clinic Location"}
            onChange={(e) => {
              setBranch(e.target.value);
              setFormValues({ ...formValues, clinicLocation: e.target.value });
              localStorage.setItem("CLINIC_LOCATION", e.target.value);
            }}
          >
            {_branches.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BranchLocation;
