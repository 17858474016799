import React, { Fragment, useEffect, useState } from "react";
import CorpLoginMain from "../module/corpLogin/corpLoginMain";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CorpHomeIndex from "./corpHomeIndex";

const CorpLoginIndex = ({ children }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [accessToken, setAccessToken] = useState(false);

  const _accessToken =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("authHeader_local")
      : null;

  useEffect(() => {
    setAccessToken(_accessToken ? true : false);
  }, [_accessToken]);

  useEffect(() => {
    if (accessToken) {
      const currentRoute = location.pathname;
      if (
        currentRoute === "/corp/login" &&
        localStorage.getItem("authHeader_local")
      ) {
        navigate("/corp/home");
      }
    }
  }, [accessToken, location, navigate]);

  if (accessToken) {
    return (
      <>
        <CorpHomeIndex />
      </>
    );
  }
  return (
    <Container maxWidth={false}>
      <CorpLoginMain />
    </Container>
  );
};

export default CorpLoginIndex;
