import  { Fragment } from "react";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import Reports from "../../marketing/reports"

const ReportsMK = () => {
  return (
    <Fragment>
      <NavigationBarMarketing />
      <Reports />
    </Fragment>
  );
};

export default ReportsMK;
