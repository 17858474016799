import React, { useEffect } from "react";
import { Fragment } from "react";
import { TypographyTitle } from "../components/headings/TypogyraphyTitle";
import { TypographySubTitle } from "../components/headings/TypographySubTitle";
import DynamicForm from "../components/customTextField/DynamicForm";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { upload } from "@testing-library/user-event/dist/upload";
import { saveData, uploadFile } from "../../../services/api/postApiCalls";
import { BASE_URL } from "../../../assets/constantsFile";
import { useNavigate } from "react-router";

const PatientRegisterAshaWorker = () =>
  // { setShowForm, genderData, setShowPatientList, fetchPatientList }
  {
    const navigate = useNavigate();

    const [severity, setSeverity] = useState("info");
    const [message, setMessage] = useState("");
    const [openNotice, setOpenNotice] = useState(false);

    const handleClickNotice = () => {
      setOpenNotice(true);
    };

    const handleCloseNotice = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpenNotice(false);
    };

    const [date, setDate] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(new Date());
    const [formValues, setFormValues] = useState({ id: 0 });
    const [uploadDisable, setUploadDisable] = useState(true);
    const [marketingPatientId, setMarketingPatientId] = useState();
    const [imageUrl, setImageUrl] = useState();
    const formattedDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    const formattedCurrentDate =
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      currentDate.getDate().toString().padStart(2, "0");

    const formData = [
      {
        id: 1,
        label: "मरीज का नाम",
        placeHolder: "पूरा नाम दर्ज करें",
        property: "fullName",
        type: "text",
        disabled: "false",
        gridWidth: 12,
      },
      {
        id: 2,
        label: "जन्म की तारीख",
        placeHolder: "जन्म तारीख दर्ज करें",
        property: "dob",
        type: "date",
        disabled: "false",
        gridWidth: 12,
      },
      {
        id: 3,
        label: "लिंग",
        placeHolder: "लिंग दर्ज करें",
        property: "gender",
        type: "select",
        disabled: "false",
        gridWidth: 12,
      },
      {
        id: 4,
        label: "फ़ोन नंबर",
        placeHolder: "नंबर डालें",
        property: "mobile",
        type: "text",
        disabled: "false",
        gridWidth: 12,
      },
      {
        id: 5,
        label: "पता पंक्ति ",
        placeHolder: "पंक्ति दर्ज करें",
        property: "address",
        type: "text",
        disabled: "false",
        gridWidth: 12,
      },
      {
        id: 6,
        label: "फोटो *",
        placeHolder: "तस्विर अपलोड करें",
        property: "imageUrl",
        page: "patientPhotograph",
        type: "upload",
        // disabled: uploadDisable,
        marketingPatientId: marketingPatientId,
        gridWidth: 12,
      },

      {
        id: 7,
        label: "कद",
        placeHolder: "कद दर्ज करें",
        property: "height",
        type: "text",
        disabled: "false",
        gridWidth: 6,
      },
      {
        id: 8,
        label: "वज़न",
        placeHolder: "वज़न दर्ज करें",
        property: "weight",
        type: "text",
        disabled: "false",
        gridWidth: 6,
      },
      {
        id: 9,
        label: "बी.पी.",
        placeHolder: "मूल्य दर्ज करें",
        property: "bp",
        type: "text",
        disabled: "false",
        gridWidth: 6,
      },
      {
        id: 10,
        label: "शुगर",
        placeHolder: "मूल्य दर्ज करें",
        property: "sugar",
        type: "text",
        disabled: "false",
        gridWidth: 6,
      },
    ];

    const [isLocationEnabled, setIsLocationEnabled] = useState(true);

    const getLocation = () => {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
              setIsLocationEnabled(true);
            },
            (error) => {
              resolve({ latitude: null, longitude: null });
              setIsLocationEnabled(false);
            }
          );
        } else {
          console.log("GEOLOCATION NOT SUPPORTED");
        }
      });
    };

    useEffect(() => {
      getLocation();
    }, [isLocationEnabled]);

    console.log("MarketingId", marketingPatientId);
    const handleRegisterPatient = async () => {
      if (
        !formValues.fullName ||
        !formValues.mobile ||
        !formValues.address ||
        formValues.mobile.length < 10
      ) {
        setSeverity("error");
        setMessage("Please enter all required fields.");
        setOpenNotice(true);
        return;
      } else {
        const location = await getLocation();

        const OBJ = {
          id: marketingPatientId,
          fullName: formValues.fullName,
          gender: formValues.gender,
          address: formValues.address,
          mobile: formValues.mobile,
          imageUrl: imageUrl,
          height: formValues.height,
          weight: formValues.weight,
          bp: formValues.bp,
          sugar: formValues.sugar,
          date: formattedCurrentDate,
          dob: formattedDate,
          location: {
            latitude: location?.latitude,
            longitude: location?.longitude,
          },
        };
        console.log({ OBJ });
        const url = BASE_URL + "healthcamp/marketing/v2";

        const result = await saveData(url, OBJ);
        if (result && result.data) {
          console.log("SUCCESS UPLOADED FILE", result.data);
          setSeverity("success");
          setMessage("Patient Registered Successfully");
          setOpenNotice(true);
          // setTimeout(() => {
          navigate("/marketing/asha-worker");
          // }, 200)
        } else if (result && result.error) {
          console.log("ERROR UPLOAD DATA", result.error);
          setSeverity("error");
          setMessage("Patient Registered Failed");
          setOpenNotice(true);
        }
      }
    };

    const genderList = [
      { label: "पुरुष", value: "MALE" },
      { label: "महिला", value: "FEMALE" },
      { label: "अन्य", value: "DONOTDISCLOSE" },
    ];

    return (
      <Fragment>
        <TypographyTitle>New Patient Entry</TypographyTitle>
        <TypographySubTitle>
          Kindly Fill The Information Below
        </TypographySubTitle>
        {isLocationEnabled === false ? (
          <Box
            sx={{ backgroundColor: "red", textAlign: "center", color: "white" }}
          >
            Location not enabled
          </Box>
        ) : null}

        <Box
          sx={{
            width: "100%",
            mt: 2,
          }}
        >
          <Snackbar
            open={openNotice}
            autoHideDuration={6000}
            onClose={handleCloseNotice}
          >
            <Alert
              onClose={handleCloseNotice}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <DynamicForm
            fields={formData}
            formValues={formValues}
            setFormValues={setFormValues}
            genderData={genderList}
            date={date}
            setImageUrl={setImageUrl}
            setDate={setDate}
            setMarketingPatientId={setMarketingPatientId}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBlock: "40px",
          }}
        >
          <Button
            onClick={() => {
              navigate("/marketing/asha-worker");
            }}
            variant="contained"
            sx={{
              paddingInline: "43px",
              paddingBlock: "10px",
              textTransform: "capitalize",
              borderRadius: "10px",
              marginInline: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                textAlign: "center",
                color: "#FFFFFF",
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={handleRegisterPatient}
            variant="contained"
            sx={{
              paddingInline: "43px",
              paddingBlock: "10px",
              textTransform: "capitalize",
              borderRadius: "10px",
              marginInline: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                textAlign: "center",
                color: "#FFFFFF",
              }}
            >
              Register
            </Typography>
          </Button>
        </Box>
      </Fragment>
    );
  };

export default PatientRegisterAshaWorker;
