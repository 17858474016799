import { Container } from "@mui/material";
import { Fragment } from "react";
import VideoMain from "../videoModule/videoMain";
import PatientRegistrationContent from "./patientRegistrationContent";

const PatientRegistrationMain = () => {
  return (
    <Fragment>
      <Container maxWidth={false} disableGutters>
        {/* <VideoMain
          name={"User Name"}
          token={
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3Nfa2V5IjoiNjM0ZDU2NjI0MjA4NzgwYmY2NjYyMjU5Iiwicm9vbV9pZCI6IjYzNGQ1NzMzZTA4ODYzYTNmMmY5MWUxZiIsInVzZXJfaWQiOiJ4aHpqdHJzcCIsInJvbGUiOiJndWVzdCIsImp0aSI6IjM3MmY4Nzc0LTVjZTgtNDI0MC05YmU0LTEzNDA4NDdmZDZiNCIsInR5cGUiOiJhcHAiLCJ2ZXJzaW9uIjoyLCJleHAiOjE2NzA2NTY3OTN9.61ucpOs340Rbm5QFqH2sLimjwKzKS2Sl3CW_eG9CXS4"
          }
        /> */}
        <PatientRegistrationContent />
      </Container>
    </Fragment>
  );
};

export default PatientRegistrationMain;
