import { Box, Grid, Paper, Typography } from "@mui/material";
import FooterLogoImg from "../assets/images/svg/footerLogo.svg";

const FooterComponent = () => {
    return (
        <Box sx={{ 
            width:'100%',
            p:4, 
            background:'linear-gradient(90deg, #9AE7EB 0.73%, rgba(0, 241, 252, 0.46) 100%)',
            backdropFilter:'blur(60px)',
            }} 
             >
        <Grid container columnSpacing={2} >

        <Grid item lg={4}>
        <Box component="img"
            alt="card1"
            src={FooterLogoImg}
            sx={{ 
                height:'163px',
                width:'163px',
            }} 
            />
            <Paper sx={{
                width: '240px',
                height: '50px',
                border: '2px solid #000000',
                borderRadius: '5px',
                background:'#9AE7EB',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

            }}>
            <Typography variant="h5">eChikitsalaya</Typography>
            </Paper>
        </Grid>

        <Grid item lg={8}>
        <Grid container columns={10}>

        <Grid item lg={2}>
        <Typography variant="h6">ABOUT US</Typography>
        <Typography variant="body2">About Us</Typography>
        <Typography variant="body2">About Us</Typography>
        <Typography variant="body2">About Us</Typography>
        <Typography variant="body2">About Us</Typography>
        <Typography variant="body2">About Us</Typography>
        </Grid>

        <Grid item lg={2}>
        <Typography variant="h6">CAREER</Typography>
        <Typography variant="body2">Career</Typography>
        <Typography variant="body2">Career</Typography>
        <Typography variant="body2">Career</Typography>
        <Typography variant="body2">Career</Typography>
        <Typography variant="body2">Career</Typography>
        </Grid>


        <Grid item lg={2}>
        <Typography variant="h6">LOCATIONS</Typography>
        <Typography variant="body2">Locations</Typography>
        <Typography variant="body2">Locations</Typography>
        <Typography variant="body2">Locations</Typography>
        <Typography variant="body2">Locations</Typography>
        <Typography variant="body2">Locations</Typography>
        </Grid>


        <Grid item lg={2}>
        <Typography variant="h6">FAQS</Typography>
        <Typography variant="body2">FAQs</Typography>
        <Typography variant="body2">FAQs</Typography>
        <Typography variant="body2">FAQs</Typography>
        <Typography variant="body2">FAQs</Typography>
        </Grid>


        <Grid item lg={2}>
        <Typography variant="h6">CONTACT US</Typography>
        <Typography variant="body2">Contact Us</Typography>
        <Typography variant="body2">Contact Us</Typography>
        <Typography variant="body2">Contact Us</Typography>
        <Typography variant="body2">Contact Us</Typography>
        <Typography variant="body2">Contact Us</Typography>
        </Grid>

        </Grid>
        </Grid>

        </Grid>
        </Box>
    );
}
 
export default FooterComponent;