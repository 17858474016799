import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import TitleHC from "../common/title/titleHC";
import HomeContentHC from "./homeContentHC";

const HomeHC = () => {
    return (
        <Fragment>
            <Box>
                <Grid
                    container
                    display="flex"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    columnSpacing={2}>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TitleHC title={"Welcome to Echikitsalaya Healthcamp"} />
                    </Grid> */}

                    {/* <Grid item sx={{ flexGrow: 1 }}></Grid> */}
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        <HomeContentHC />
                    </Grid>
                    {/* <Grid item sx={{ flexGrow: 1 }}></Grid> */}
                </Grid>
            </Box>
        </Fragment>
    );
};

export default HomeHC;
