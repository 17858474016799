import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { Fragment } from "react";
import { patientHistoryContext } from "../common/context/usercontext";
import PatientCaseListMainComp from "./patientCaseList/patientCaseListMainComp";
import SearchPatientMainComp from "./searchPatient/searchPatientMainComp";
import ViewPatientHistoryMainComp from "./viewPatientHistory/viewPatientHistoryMainComp";

const PatientHistoryContent = (props) => {
  const patientHistoryContextData = useContext(patientHistoryContext);

  const viewPageHandler = (data, page, patient) => {
    if (page === "VIEWSEARCHPAGE") {
      setMainContent(<SearchPatientMainComp onViewSearch={viewPageHandler} />);
    } else if (page === "VIEWCASELIST") {
      setMainContent(
        <PatientCaseListMainComp
          onViewCases={viewPageHandler}
          patientId={data}
        />
      );
    } else if (page === "VIEWHISTORYPAGE") {
      setMainContent(
        <ViewPatientHistoryMainComp
          onViewHistory={viewPageHandler}
          pHistory={data}
          patientId={patientHistoryContextData.patientId}
          patient={patientHistoryContextData.patient}
        />
      );
    } else {
      setMainContent(<SearchPatientMainComp onViewSearch={viewPageHandler} />);
    }
    console.log({ data });
  };
  const [mainContent, setMainContent] = useState(
    <SearchPatientMainComp onViewSearch={viewPageHandler} />
  );
  return (
    <Fragment>
      <Box>{mainContent}</Box>
    </Fragment>
  );
};

export default PatientHistoryContent;
