import { Grid, Typography } from "@mui/material"
import React from "react"
import { Fragment } from "react"

const PatientDetailContent = ({ item }) => {
  const formatDob = dob => {
    const options = { day: "numeric", month: "numeric", year: "numeric" }
    const date = new Date(dob)
    return date.toLocaleDateString("en-GB", options)
  }
  return (
    <Fragment>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          padding: "10px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid rgba(0, 0, 0, 0.15)",
          borderRadius: "7.5px",
          marginBlock: "10px",
        }}>
        <Grid item xs={4} lg={4}>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {formatDob(item?.dob)}
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.height}
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.weight}
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.bp}
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              marginTop: "5px",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
            }}>
            {item?.sugar}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default PatientDetailContent
