import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Link, Stack, Typography } from "@mui/material";
import LogoMain from "../common/logo/logoMain";

const MyAppBarAfterLoginHC = ({
  corpId = localStorage.getItem("CORPID"),
  orgName = localStorage.getItem("ORGNAME"),
}) => {
  const [pages, setPages] = React.useState([
    { name: "Home", url: "/landingHC" },
    { name: "Corporate Home", url: "/corporatehome" },
    {
      name: "Consolidated Report",
      url: "/printconsolidated",
    },
    { name: "Packages", url: "/managepackage" },
    { name: "Register", url: "/registeremployee" },
    { name: "Count", url: "/vitalanalytics" },
    { name: "Gallery", url: "/galleryupload" },
    { name: "Manage Permissions", url: "/managepermissions" },

    { name: "Logout", url: "/logoutHC" },
  ]);

  // React.useEffect(() => {
  //   if (
  //     corpId === "b10d9fce-34a9-4a5b-b728-0550bb9014ac" ||
  //     corpId === "d56ab78a-98ab-4bb1-81de-73a235b12c64"
  //   ) {
  //     setPages([
  //       { name: "Home", url: "/landingHC" },
  //       { name: "Corporate Home", url: "/corporatehome" },
  //       {
  //         name: "Consolidated Report",
  //         url: "/printconsolidated",
  //       },
  //       { name: "Packages", url: "/managepackage" },
  //       { name: "Register", url: "/registeremployee" },
  //       { name: "Logout", url: "/logoutHC" },
  //     ]);
  //   } else {
  //     setPages([
  //       { name: "Home", url: "/landingHC" },
  //       { name: "Corporate Home", url: "/corporatehome" },
  //       { name: "Packages", url: "/managepackage" },
  //       { name: "Register", url: "/registeremployee" },
  //       { name: "Logout", url: "/logoutHC" },
  //     ]);
  //   }
  // }, [corpId]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            underline="none"
            href="/"
            sx={{ mr: 2, display: { xs: "none", lg: "flex" } }}
          >
            <Stack display="flex" justifyContent="center" alignItems="center">
              <LogoMain />
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="caption"
                sx={{ fontSize: 8, fontFamily: "Roboto", fontWeight: 700 }}
              >
                {orgName}
              </Typography>
            </Stack>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    underline="none"
                    variant="button"
                    color="text.primary"
                    href={page.url}
                    sx={{ my: 1, mx: 1.5 }}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link
            href="/"
            sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
          >
            <Stack display="flex" justifyContent="center" alignItems="center">
              <LogoMain />
              <Divider sx={{ width: "100%" }} />
              <Typography
                variant="caption"
                sx={{ fontSize: 8, fontFamily: "Roboto", fontWeight: 700 }}
              >
                {orgName}
              </Typography>
            </Stack>
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              mr: 2,
            }}
          >
            {pages.map((page) => (
              <Button
                href={page.url}
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyAppBarAfterLoginHC;
