import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
    Link,
} from "@mui/material";
import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const StandardImageList = ({ reportsList }) => {
    return (
        <ImageList sx={{ width: "100%", mb: 1 }} cols={1}>
            {reportsList?.map((item, index) => (
                <ImageListItem sx={{ mb: 3 }}>
                    <Typography mb={2}> Report {index + 1}</Typography>
                    <Link href={item.reportUrl}>
                        <img
                            src={item.reportUrl}
                            style={{ width: "100%" }}
                            alt={item.img}
                            loading="lazy"
                            key={item.reportUrl}
                        />
                    </Link>
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const ReportsView = ({ reportsList, showReports, setShowReports }) => {
    const handleClose = () => {
        setShowReports(false);
    };

    return (
        <>
            <Dialog
                maxWidth={"md"}
                open={showReports}
                onClose={handleClose}
                aria-labelledby="Reports"
                aria-describedby="reports"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction="row" display="flex" justifyContent="space-between">
                        <Fragment>{"Reports"}</Fragment>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <StandardImageList reportsList={reportsList} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReportsView;
