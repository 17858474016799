import { TextField } from "@mui/material";
import { Fragment } from "react";

const GuradianName = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <TextField
        size={"small"}
        fullWidth
        label="Guradian Name:"
        variant="outlined"
        value={formValues.guardianFirstName || ""}
        onChange={(e) => {
          setFormValues({ ...formValues, guardianFirstName: e.target.value });
        }}
      />
    </Fragment>
  );
};

export default GuradianName;
