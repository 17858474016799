import { Box, Button, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import React from "react"
import search from "../../../adminPortal/assets/images/search.png"
import { useState } from "react"

const SearchBarAshaWoker = () => {
  const [inputValue, setInputValue] = useState("")
  return (
    <Grid container display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            border: "1px solid #D4D4D4",
            borderRadius: "15px",
            "& .MuiFormControl-root": {
              display: "flex",
              justifyContent: "center",
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputLabel-outlined": {
              color: "#127DDD66",
              padding: 0.5,
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
              px: 1,
            },
          }}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            onChange={e => setInputValue(e.target.value)}
            sx={{
              background: "#FFFFFF",
              height: "45px",
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="img" src={search} width={22} height={22} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{
              marginBlock: "2px",
              background: "#17B2FF",
              boxShadow: "0px 1px 6px 1px rgba(18, 125, 221, 0.25)",
              borderRadius: "15px",
              textTransform: "capitalize",
            }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#FFFFFF",
              }}>
              Search
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SearchBarAshaWoker
