import { Fragment } from "react";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import FamilyHistory from "../../marketing/familyHistory"

const FamilyHistoryMK = () => {
  return (
    <Fragment>
      <NavigationBarMarketing />
      <FamilyHistory />
    </Fragment>
  );
};

export default FamilyHistoryMK;
