import { Box, Chip, Container, Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fragment, useRef, useState } from "react";
import ViewReportComponent from "../viewReport/viewReportComponent";
import ReactToPrint from "react-to-print";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs } from "react-pdf";

const PrintComp = (props) => {
  const componentRef = useRef();

  const handleClick = () => {};

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <Fragment>
      <ReactToPrint
        trigger={() => (
          <Chip
            size="small"
            variant="contained"
            label="Print!"
            color="primary"
          />
        )}
        content={() => componentRef.current}
      />

      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box ref={componentRef}>
          <Container component="main" maxWidth={false}>
            <Grid container spacing={0}>
              <Grid item lg={12}>
                <Box
                  sx={{
                    background: "#fff",
                    height: "75vh",
                    width: "100%",
                    borderRadius: 2,
                  }}
                >
                  <Document
                    options={{
                      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                      cMapPacked: true,
                      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
                    }}
                    file={props.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Fragment>
  );
};

export default PrintComp;
