import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
const defaultTheme = createTheme();
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#127DDD",
    },
    secondary: {
      main: "#127DDD",
    },
    customLight: defaultTheme.palette.augmentColor({
      color: { main: "#FFFFFF" },
      name: "customLight",
    }),
    customDark: defaultTheme.palette.augmentColor({
      color: { main: "#127DDD" },
      name: "customDark",
    }),
    action: {
      selectedOpacity: 0.75,
    },
  },

  components: {
    ///header global custumization
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
          color: "#000",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 25,
          height: 27,
        },
        content: {
          P: {
            fontSize: "0.875rem",
            fontWeight: 500,
            color: "#000",
          },
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          maxHeight: 300,
        },
      },
    },
  },

  typography: {
    MuiLink: {
      color: "#000",
    },
  },
});

export default customTheme;
