import { Box, Snackbar } from "@mui/material";
import { forwardRef, Fragment, useState } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NoticeMainComp = ({ severityValue, messageValue, openNoticeValue }) => {
  const [openNotice, setOpenNotice] = useState(
    openNoticeValue ? openNoticeValue : false
  );
  const [severity, setSeverity] = useState(
    severityValue ? severityValue : "info"
  );
  const [message, setMessage] = useState(messageValue ? messageValue : "");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  return (
    <Fragment>
      <Box>
        <Snackbar
          open={openNotice}
          autoHideDuration={3000}
          onClose={handleCloseNotice}
        >
          <Alert
            onClose={handleCloseNotice}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Fragment>
  );
};

export default NoticeMainComp;
