import { Autocomplete as Auto, Box, Grid, TextField } from "@mui/material";
import { Fragment } from "react";

const Autocomplete = ({ formValues, setFormValues, keyValue, label }) => {
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Auto
              freeSolo
              size={"small"}
              fullWidth
              disableClearable
              selectOnFocus
              value={formValues[keyValue] || ""}
              onChange={(e, value) => {
                console.log(value);
              }}
              onInputChange={(e, value) => {
                setFormValues({ ...formValues, [keyValue]: value });
              }}
              options={["Not Available"].map((option) => option)}
              renderInput={(params) => (
                <TextField
                  size={"small"}
                  variant="outlined"
                  {...params}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Autocomplete;
