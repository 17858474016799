import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { getData } from "../../../services/api/getApiCalls";
import { useCallback } from "react";
import { BASE_URL } from "../../../assets/constantsFile";

const Address = ({ formValues, setFormValues }) => {
  const [address, setAddress] = useState(
    formValues.addressVM
      ? formValues.addressVM.address
      : { address: "", pincode: "" }
  );

  useEffect(() => {
    setAddress(formValues.addressVM ? formValues.addressVM.address : "");
  }, [formValues]);

  const filter = createFilterOptions();

  const [addressList, setAddressList] = useState([]);
  const fetchAddress = useCallback(async () => {
    const url =
      BASE_URL + "clinic/address/" + localStorage.getItem("BRANCH_ID");

    const address = await getData(url);

    if (address.error) {
      console.log("error");
    } else {
      console.log("success");
      setAddressList(address.data);
    }
  }, []);

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        size="small"
        value={address}
        onInputChange={(event, inputValue) => {
          setFormValues({
            ...formValues,
            addressVM: {
              // ...formValues.addressVM,
              address: inputValue,
            },
            pinCode: "",
          });
          console.log(inputValue);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setAddress(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setAddress({
              address: newValue.inputValue,
              pincode: "",
            });
            setFormValues({
              ...formValues,
              addressVM: {
                // ...formValues.addressVM,
                address: newValue?.inputValue,
              },
              pinCode: "",
            });
          } else {
            setAddress(newValue);
            setFormValues({
              ...formValues,
              addressVM: {
                // ...formValues.addressVM,
                address: newValue?.address,
              },
              pinCode: newValue?.pincode ? newValue.pincode : "",
            });
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.address
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              address: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={addressList}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }

          // Regular option
          return option.address;
        }}
        renderOption={(props, option) => <li {...props}>{option.address}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Address*"
            helperText={formValues.addressVM ? "" : "Address can not be empty."}
            error={formValues.addressVM ? false : true}
          />
        )}
      />
    </Fragment>
  );
};

export default Address;
