import { Fragment } from 'react';
import LoginComponent from '../../login/loginComponent';
import MyAppBarBeforeLogin from '../../header/myAppBarBeforeLogin';


const LoginPage = (props) => {
        return (
            <Fragment>
                <MyAppBarBeforeLogin/>
                <LoginComponent root={props.root}/>           
            </Fragment>

        );
}
 
export default LoginPage;