import { Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";

const EmployeeBiodata = ({ formValues }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction="row">
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 16,
                textAlign: "center",
                color: "#6B6B6B",
              }}
            >
              Name:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: 16,
                textAlign: "center",
                color: "#000000",
              }}
            >
              {formValues?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8} sm={8} md={4} lg={4}>
          <Stack direction="row">
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 16,
                textAlign: "center",
                color: "#6B6B6B",
              }}
            >
              Phone No:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: 16,
                textAlign: "center",
                color: "#000000",
              }}
            >
              {formValues?.mobile}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2}>
          <Stack direction="row">
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 16,
                textAlign: "center",
                color: "#6B6B6B",
              }}
            >
              Age:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: 16,
                textAlign: "center",
                color: "#000000",
              }}
            >
              {formValues?.age}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EmployeeBiodata;
