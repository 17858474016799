import { Box, Button, Grid, Stack } from "@mui/material";
import { Fragment, useRef } from "react";
import PatientInfo from "../patientInfo/patientInfo";
import ViewPatientHistoryComp from "./viewPatientHistoryComp";
import { useReactToPrint } from "react-to-print";
import PrintHistoryComp from "../print/printHistoryComp";

const ViewPatientHistoryMainComp = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const pHistory = props.pHistory;
  const viewPatientHistory = (event, value) => {
    event.preventDefault();
    props.onViewHistory("", value);
  };

  console.log({ pHistory });
  return (
    <Fragment>
      <Box sx={{ minHeight: "80vh" }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "8vh", background: "fff" }}>
              <Stack direction={"row"} spacing={2}>
                <PatientInfo
                  patientId={props.patientId}
                  patient={props.patient}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{ minHeight: "72vh", background: "#fff" }}
              ref={componentRef}
            >
              <ViewPatientHistoryComp pHistory={pHistory} />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "5vh", background: "pink", mb: 2 }}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ background: "#D9D9D9" }}>
                    <Stack direction={"row"} spacing={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={(e) => viewPatientHistory(e, "VIEWCASELIST")}
                      >
                        Back
                      </Button>

                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handlePrint}
                      >
                        Print!
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ViewPatientHistoryMainComp;
