import { Grid, TextField, Typography } from "@mui/material";
import { Fragment } from "react";

const Age = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={2}>
          <Typography sx={{ pt: 1 }}>Age:</Typography>
        </Grid>
        <Grid item lg={5}>
          <TextField
            fullWidth
            size={"small"}
            label="Year"
            variant="outlined"
            value={formValues.age || ""}
            onChange={(e) => {
              if (
                !isNaN(e.target.value) &&
                e.target.value < 120 &&
                e.target.value >= 0 &&
                e.target.value.length < 4
              ) {
                setFormValues({ ...formValues, age: e.target.value });
              }
            }}
          />
        </Grid>
        <Grid item lg={5}>
          <TextField
            fullWidth
            name="pMonth"
            size={"small"}
            label="Month"
            variant="outlined"
            value={formValues.ageMonths || ""}
            onChange={(e) => {
              if (
                !isNaN(e.target.value) &&
                e.target.value < 12 &&
                e.target.value >= 0 &&
                e.target.value.length < 3
              ) {
                setFormValues({ ...formValues, ageMonths: e.target.value });
              }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Age;
