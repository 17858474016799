import { Box, Container } from "@mui/material";
import BackGroundDesignBottom from "../dashboardCommon/backGroundDesignBottom";
import BackGroundDesignTop from "../dashboardCommon/backGroundDesignTop";
import TableContentPharmacy from "./tableContentPharmacy";

const PharmacyDashborardMainComponent = (props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "89vh",
        zIndex: -5,
      }}
    >
      <BackGroundDesignTop />
      <BackGroundDesignBottom />

      <Container component="main" maxWidth={false}>
        <Box
          sx={{
            position: "fixed",
            height: "80vh",
            width: "97vw",
            mt: 3,
            filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
          }}
        >
          <TableContentPharmacy root={props.root} />
        </Box>
      </Container>
    </Box>
  );
};

export default PharmacyDashborardMainComponent;
