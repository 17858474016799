import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const BPElement = ({ element, variant }) => {
  const [bp, setBP] = useState("");
  return (
    <Fragment>
      <TextField
        sx={{ p: 0.3 }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={variant === "small" ? "Enter BP" : ""}
        value={element.bp || ""}
        onChange={(e) => {
          setBP(e.target.value);
          element["bp"] = e.target.value;
        }}
      />
    </Fragment>
  );
};

export default BPElement;
