import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentDateFormatted } from "../../assets/utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getData } from "../../services/api/getApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintReportComponent from "../printReport/printReportComponent";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";
import PrintReportMainComponent from "../../common/printComps/printReportMainComponent";
import printJS from "print-js";

const ViewReportComponent = (props) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [docDetail, setDocDetail] = useState("");
  let patientDetails = "";
  let doctorId = "";
  let doctorName = "";
  let patientInfo = "";
  let vitalInfo = { bp: "" };
  let observationInfo = "";
  let reportList = [];
  let prescriptionList = [];

  if (props.patientDetails) {
    patientDetails = props.patientDetails;
    doctorId = patientDetails.docId;

    patientInfo = {
      patientId: patientDetails.patientId,
      name: patientDetails.patientName,
      age: patientDetails.age,
      sex: patientDetails.gender,
      issue: patientDetails.symptom,
    };
    vitalInfo = patientDetails.vitals ? patientDetails.vitals : { bp: "" };
    observationInfo = patientDetails.observation
      ? patientDetails.observation
      : "";
    reportList = patientDetails.reports ? patientDetails.reports : [];
    let prescriptionVM = patientDetails.prescriptionVM;

    if (prescriptionVM) {
      prescriptionList = prescriptionVM.medicines;
    }
  }

  const getDocInfo = async (dId) => {
    const user = await getData(BASE_URL + "doctor/" + dId);

    if (user.error) {
    } else {
      const data = user.data;
      setDocDetail(data);
    }
  };

  useEffect(() => {
    getDocInfo(doctorId);
  }, [doctorId]);

  const goBAck = () => {
    props.onViewPres("BACK");
  };

  console.log({ docDetail: docDetail });

  const printPDFFile = (e) => {
    e.preventDefault();
    printJS(`${patientDetails.prescriptionUrl}?date=${Date.now()}`);
  };

  return (
    <Fragment>
      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box
          ref={componentRef}
          sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
        >
          <PrintReportComponent patientDetails={patientDetails} />
        </Box>
      </Box>

      <Container maxWidth={false} sx={{ mr: 0.5, ml: 1, mb: 5 }} disableGutters>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item lg={12}>
              <Box sx={{ minHeight: "20vh" }}>
                <Grid container rowSpacing={2}>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    md={6}
                    sm={6}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                  ></Grid>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    md={6}
                    sm={6}
                    display="flex"
                    justifyContent={"flex-end"}
                  >
                    <Paper
                      component={Stack}
                      direction="row"
                      display={"flex"}
                      justifyContent={"space-between"}
                      elevation={0}
                      sx={{ width: 200, p: 1, height: 50 }}
                    >
                      <Button
                        variant="contained"
                        sx={{ width: 90 }}
                        onClick={printPDFFile}
                      >
                        Print!
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ width: 90 }}
                        onClick={goBAck}
                      >
                        Back
                      </Button>
                    </Paper>
                  </Grid>

                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <Box sx={{ border: "1px solid grey ", mb: 2 }}>
                      <PrintReportComponent patientDetails={patientDetails} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default ViewReportComponent;
