import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  Button,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  Container,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TitleHC from "../../healthcamp/common/title/titleHC";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import { saveData } from "../../services/api/postApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import { useNavigate } from "react-router-dom";
import { getData } from "../../services/api/getApiCalls";
import { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

const InputText = ({
  value,
  onChange,
  label,
  disabled,
  type = "input",
  error,
}) => {
  return (
    <Fragment>
      <TextField
        disabled={disabled}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={label}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        error={error}
      />
    </Fragment>
  );
};

const InputNumber = ({ value, onChange, label, disabled, error, length }) => {
  return (
    <Fragment>
      <TextField
        disabled={disabled}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={label}
        value={value || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length <= length) {
            onChange(e.target.value);
          }
        }}
        error={error}
      />
    </Fragment>
  );
};

const InputSelectGroup = ({ value, onChange, label, disabled }) => {
  return (
    <>
      <Select
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        disabled={disabled}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={9}>9</MenuItem>
      </Select>
    </>
  );
};

const InputRadioGroup = ({ value, onChange, label, disabled }) => {
  return (
    <>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        <FormControlLabel value={1} control={<Radio />} label="1" />
        <FormControlLabel value={2} control={<Radio />} label="2" />
        <FormControlLabel value={3} control={<Radio />} label="3" />
        <FormControlLabel value={4} control={<Radio />} label="4" />
      </RadioGroup>
    </>
  );
};

const InputRadioBool = ({ value, onChange, label, disabled }) => {
  return (
    <>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        <FormControlLabel value={true} control={<Radio />} label="Yes" />
        <FormControlLabel value={false} control={<Radio />} label="No" />
      </RadioGroup>
    </>
  );
};

const UserOnboarding = () => {
  const ref = useRef(null);
  const refMobile = useRef(null);

  const handleClick = (r) => {
    r.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [fullName, setFullName] = useState("");
  const [age, setAge] = useState("");
  const [noOfMales, setNoOfMales] = useState(0);
  const [noOfFemales, setNoOfFemales] = useState(0);
  const [noOfChildrenMales, setNoOfChildrenMales] = useState(0);
  const [noOfChildrenFemales, setNoOfChildrenFemales] = useState(0);
  const [pregnancyMonths, setPregnancyMonths] = useState("");
  const [otherIllness, setOtherIllness] = useState("");
  const [interestedInVisiting, setInterestedInVisiting] = useState(false);
  const [familyDoctor, setFamilyDoctor] = useState("");
  const [existingPatientsFeedback, setExistingPatientsFeedback] = useState("");
  const [mobile, setMobile] = useState("");
  const [others, setOthers] = useState("");
  const [insurance, setInsurance] = useState("");
  const [bookAppointmentDate, setBookAppointmentDate] = useState(new Date());
  let [diseases, setDiseases] = useState({
    Sugar: false,
    HighBP: false,
    Throid: false,
    Heart: false,
    EyeProblem: false,
    SkinProblem: false,
    JointPain: false,
  });
  const [noticeData, setNoticeData] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });

  const getSelectedValues = (state) => {
    let selectedValues = [];
    for (const key in state) {
      if (state[key]) {
        selectedValues.push(key);
      }
    }
    return selectedValues.join(",");
  };

  const handleChange = (event) => {
    setDiseases({ ...diseases, [event.target.name]: event.target.checked });
  };

  const diseasesValues = getSelectedValues(diseases);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleClick(ref);
    const data = {
      fullName,
      age,
      noOfMales,
      noOfFemales,
      noOfChildrenMales,
      noOfChildrenFemales,
      pregnancyMonths,
      diseases: diseasesValues,
      otherIllness,
      interestedInVisiting,
      familyDoctor,
      existingPatientsFeedback,
      mobile,
      others,
      bookAppointmentDate,
      insurance,
      internalStaffPhoneNumber: localStorage.getItem("userMobile"),
    };
    const url = BASE_URL + "healthcamp/marketing";
    console.log({ userData: data, url });

    //if (mobile && mobile.length === 10) {
    const result = await saveData(url, data);

    if (result.error) {
      console.error({ Error: result });
      setNoticeData({
        severity: "error",
        message: "Failed.",
        openNotice: true,
      });
    } else {
      getCount();
      console.log({ Feedback_Result: result.data });
      setFullName("");
      setAge("");
      setNoOfMales(0);
      setNoOfFemales(0);
      setNoOfChildrenMales(0);
      setNoOfChildrenFemales(0);
      setPregnancyMonths("");
      setOtherIllness("");
      setDiseases({
        Sugar: false,
        HighBP: false,
        Throid: false,
        Heart: false,
        EyeProblem: false,
        SkinProblem: false,
        JointPain: false,
      });
      setInterestedInVisiting(false);
      setExistingPatientsFeedback("");
      setOthers("");
      setFamilyDoctor("");
      setMobile("");
      setBookAppointmentDate(new Date());
      setNoticeData({
        severity: "success",
        message: "Assigned Successfully.",
        openNotice: true,
      });
      // navigate(`/marketing/${result.data.id}`);
    }
    // }
  };

  const [count, setCount] = useState(0);
  const [target, setTarget] = useState(0);
  const [associateName, setAssociateName] = useState("");
  const [appreciationText, setAppreciationText] = useState("");

  useEffect(() => {
    getCount();
  }, []);

  const getCount = async () => {
    const url =
      BASE_URL +
      "healthcamp/marketing/internalStaff/info/" +
      localStorage.getItem("userMobile");

    const data = await getData(url);

    if (data.error) {
    } else {
      setCount(data.data.count);
      setTarget(data.data.target);
      setAssociateName(data.data.staffFullName);
      setAppreciationText(data.data.appreciationText);
      console.log({ data: data.data });
    }
  };

  return (
    <Fragment>
      <ShowNoticeMessage
        noticeData={noticeData}
        key={JSON.stringify(noticeData)}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} ref={ref}>
        <TitleHC title={"MARKETING"} />
      </Grid>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: "8vh", mt: 2 }}
        >
          <Grid container spacing={1}>
            <Grid item lg={4} xs={12} display="flex" justifyContent="center">
              <Typography variant="h5">
                Welcome{associateName && ", "} {associateName} !
              </Typography>
            </Grid>
            <Grid item lg={4} xs={12} display="flex" justifyContent="center">
              <Typography variant="h6">
                Families visited: {count}
                {target ? "/" + target : ""}
              </Typography>
            </Grid>
            <Grid item lg={4} xs={12} display="flex" justifyContent="center">
              <Typography variant="body2">{appreciationText}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{ p: 1, mt: 2 }}>
        <Box
          sx={{
            p: 1,
            border: "1px solid #208F94",
            borderRadius: 2,
            minHeight: "63vh",
          }}
          border={1}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0}>
              <Grid item xs={2} lg={2}></Grid>
              <Grid item xs={8} lg={8}>
                <Box sx={{ minHeight: "40vh", mt: 7 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                      <Typography>Name:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputText
                        value={fullName}
                        label={"Full Name"}
                        onChange={setFullName}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Insurance:</Typography>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                      <RadioGroup
                        row
                        value={insurance}
                        label={"Insurance"}
                        onChange={(e) => setInsurance(e.target.value)}
                      >
                        <FormControlLabel
                          value={"NO"}
                          control={<Radio />}
                          label="NO"
                        />
                        <FormControlLabel
                          value={"ABHA"}
                          control={<Radio />}
                          label="ABHA"
                        />
                        <FormControlLabel
                          value={"PRIVATE"}
                          control={<Radio />}
                          label="PRIVATE"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Age:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={age}
                        label={"Age"}
                        onChange={(e) => setAge(e.target.value)}
                      >
                        <FormControlLabel
                          value={25}
                          control={<Radio />}
                          label="20-30"
                        />
                        <FormControlLabel
                          value={35}
                          control={<Radio />}
                          label="30-40"
                        />
                        <FormControlLabel
                          value={45}
                          control={<Radio />}
                          label="40-50"
                        />
                        <FormControlLabel
                          value={55}
                          control={<Radio />}
                          label="50-60"
                        />
                        <FormControlLabel
                          value={65}
                          control={<Radio />}
                          label="65+"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Number of Males:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputRadioGroup
                        value={noOfMales}
                        label={"Males"}
                        onChange={setNoOfMales}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Number of Females:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputRadioGroup
                        value={noOfFemales}
                        label={"Females"}
                        onChange={setNoOfFemales}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Number of Child Males:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputRadioGroup
                        value={noOfChildrenMales}
                        label={"Child Males"}
                        onChange={setNoOfChildrenMales}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Number of Child Females:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputRadioGroup
                        value={noOfChildrenFemales}
                        label={"Child Females"}
                        onChange={setNoOfChildrenFemales}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Pregnancy Months:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputText
                        value={pregnancyMonths}
                        label={"Pregnancy Months"}
                        onChange={setPregnancyMonths}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Diseases:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.Sugar}
                              onChange={handleChange}
                              name="Sugar"
                            />
                          }
                          label="Sugar"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.HighBP}
                              onChange={handleChange}
                              name="HighBP"
                            />
                          }
                          label="High BP"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.Throid}
                              onChange={handleChange}
                              name="Throid"
                            />
                          }
                          label="Throid"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.Heart}
                              onChange={handleChange}
                              name="Heart"
                            />
                          }
                          label="Heart"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.EyeProblem}
                              onChange={handleChange}
                              name="EyeProblem"
                            />
                          }
                          label="Eye Problem"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.SkinProblem}
                              onChange={handleChange}
                              name="SkinProblem"
                            />
                          }
                          label="Skin Problem"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={diseases.JointPain}
                              onChange={handleChange}
                              name="JointPain"
                            />
                          }
                          label="Joint Pain"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Other Illness:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputText
                        value={otherIllness}
                        label={"Other Illness"}
                        onChange={setOtherIllness}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Interested In Visiting:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={interestedInVisiting}
                        label={"Interested In Visiting"}
                        onChange={(e) =>
                          setInterestedInVisiting(e.target.value)
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Typography>Family Doctor:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={familyDoctor}
                        label={"Family Doctor"}
                        onChange={(e) => setFamilyDoctor(e.target.value)}
                      >
                        <FormControlLabel
                          value={"Dr Patil"}
                          control={<Radio />}
                          label="Dr Patil"
                        />
                        <FormControlLabel
                          value={"Dr Sinha"}
                          control={<Radio />}
                          label="Dr Sinha"
                        />
                        <FormControlLabel
                          value={"Dr Asolia"}
                          control={<Radio />}
                          label="Dr Asolia"
                        />
                        <FormControlLabel
                          value={"Others-Mhow"}
                          control={<Radio />}
                          label="Others-Mhow"
                        />
                        <FormControlLabel
                          value={"Others-indore"}
                          control={<Radio />}
                          label="Others-indore"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Existing Patient Feedback:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={existingPatientsFeedback}
                        label={"Existing Patient Feedback"}
                        onChange={(e) =>
                          setExistingPatientsFeedback(e.target.value)
                        }
                      >
                        <FormControlLabel
                          value={"EXCELLENT"}
                          control={<Radio />}
                          label="EXCELLENT"
                        />
                        <FormControlLabel
                          value={"GOOD"}
                          control={<Radio />}
                          label="GOOD"
                        />
                        <FormControlLabel
                          value={"OK"}
                          control={<Radio />}
                          label="OK"
                        />
                        <FormControlLabel
                          value={"BAD"}
                          control={<Radio />}
                          label="BAD"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Contact Number:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8} ref={refMobile}>
                      <InputNumber
                        value={mobile}
                        label={"Contact Number"}
                        onChange={setMobile}
                        length={10}
                        error={mobile.length !== 10}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Others:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <InputText
                        value={others}
                        label={"Other Illness/Disease"}
                        onChange={setOthers}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography>Book Appointment:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Book Appointment"
                          openTo="day"
                          views={["year", "month", "day"]}
                          disableMaskedInput={true}
                          inputFormat="dd MMM yyyy"
                          value={bookAppointmentDate}
                          onChange={(newValue) => {
                            setBookAppointmentDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={5} lg={6}></Grid>
              <Grid item xs={2} lg={4}>
                <Box sx={{ mt: 1 }}>
                  <Button variant="outlined" type="submit">
                    Submit
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={5} lg={2}></Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Fragment>
  );
};
export default UserOnboarding;
