import { Box, IconButton } from "@mui/material"
import React from "react"
import { Fragment } from "react"
import { useLocation, useNavigate } from "react-router"
import PatientDetailCard from "../components/patientDetailCard.jsx/PatientDetailCard"
import PatientDetailHeading from "../components/patientDeatailHeading/PatientDetailHeading"
import PatientDetailContent from "../components/patientDetailContent/PatientDetailContent"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useState } from "react"
import HeaderAshaWoker from "../header/headerAshaWoker"
import NavigationBarAshaWorkers from "../components/navigationBar/navigationBarAshaWorkers"

const DetailPageAshaWoker = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const data = location.state
  console.log(data)
  const [dateValue, setDateValue] = useState(new Date())

  const handleShowForm = () => {
    navigate("/patientRegisterAshaWorker")
  }
  return (
    <Fragment>
      <NavigationBarAshaWorkers/>
      <Box sx={{ paddingInline: "10px", marginTop: "10px" }}>
        <Box>
          <IconButton
            onClick={() => {
              navigate("/marketing/asha-worker")
            }}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <HeaderAshaWoker
          setDateValue={setDateValue}
          dateValue={dateValue}
          handleShowForm={handleShowForm}
        />
        <PatientDetailCard item={data} />
        <PatientDetailHeading />
        <PatientDetailContent item={data} />
      </Box>
    </Fragment>
  )
}

export default DetailPageAshaWoker
