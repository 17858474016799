import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomIOSSwitch } from "../../../healthcamp/corporateForm/comps/customIOSSwitch";

const OnBehalfOf = ({ formValues, setFormValues }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={4} display="flex" alignItems="center">
          <Typography>POC:</Typography>
        </Grid>
        <Grid item lg={8} xs={8}>
          <CustomIOSSwitch
            formValues={formValues}
            setFormValues={setFormValues}
            title=""
            property="onBehalfOf"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default OnBehalfOf;
