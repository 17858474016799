import { Chip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fragment } from "react";

const ViewPrescriptionCompMain = (props) => {

    let prescriptionVM = "";
    if (props.patientDetails) {

        prescriptionVM = props.patientDetails.prescriptionVM;
    }
    console.log({"prescriptionVM":prescriptionVM});

    let flag = false;

    ((prescriptionVM) && (prescriptionVM.prescriptions)>0)? flag = false : flag=true;

    flag = false;
    const viewPrescription = () => {
        props.onViewPrescription(props.patientDetails);
    };

    return (

        <Fragment>
           <Chip disabled={flag || props.patientDetails.directBooking} color={(flag || props.patientDetails.directBooking) ? "default" : "primary"} icon={<VisibilityIcon />} size="small" variant="contained" label="View" clickable onClick={viewPrescription} />        
      </Fragment>
    );
}
 
export default ViewPrescriptionCompMain;