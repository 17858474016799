import { ContactSupportOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import { Fragment } from "react";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "../../../assets/constantsFile";
import { saveData } from "../../../services/api/postApiCalls";
import PrintMain from "../print/printMain";

const FreeTokenHC = ({ open, handleClose, token, clearData, isFreeTokenPage = "false" }) => {
    const freeTokenHandler = () => {
        freeToken();
    };
    const freeToken = async () => {
        const url = BASE_URL + "healthcamp/freeToken/" + token;

        const tokenData = await saveData(url, "");

        if (tokenData.error) {
            console.log("error");
        } else {
            console.log("success");
            handleClose();
            clearData();
            setIsFreeToken(false);
        }
    };

    const [isFreeToken, setIsFreeToken] = useState(false);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <Fragment>
            <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
                <Box>
                    <PrintMain token={token} />
                </Box>
            </Box>
            <Box>
                <Dialog
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" align="center">
                        Free Token
                    </DialogTitle>

                    <DialogContent dividers>
                        <Box>
                            <Grid container>
                                {isFreeToken ? (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography>Are you sure you want to free token no {token}?</Typography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box ref={componentRef}>
                                            <PrintMain token={token} />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Box sx={{ width: "100%", p: 2 }}>
                            {isFreeToken ? (
                                <Grid container spacing={4}>
                                    <Grid item lg={6}>
                                        <Button onClick={freeTokenHandler} variant={"contained"} fullWidth>
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <Button
                                            onClick={() => {
                                                setIsFreeToken(false);
                                            }}
                                            variant={"contained"}
                                            fullWidth>
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={4} display="flex" justifyContent={"center"}>
                                    {!isFreeTokenPage ? (
                                        <Grid item lg={4}>
                                            <Button
                                                onClick={() => {
                                                    setIsFreeToken(true);
                                                }}
                                                variant={"contained"}
                                                fullWidth>
                                                Free Token
                                            </Button>
                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                    <Grid item lg={4}>
                                        <Button onClick={handleClose} variant={"contained"} fullWidth>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Button onClick={handlePrint} variant={"contained"} fullWidth>
                                            Print!
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </DialogActions>
                </Dialog>
            </Box>
        </Fragment>
    );
};

export default FreeTokenHC;
