import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { useCallback } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const PhoneNumber = ({ formValues, setFormValues }) => {
  const [oldPatientList, setOldPatientList] = useState([]);

  const fetchOldPatient = useCallback(async (nameValue) => {
    const url = BASE_URL + "patient/cms/search?phoneNo=" + nameValue;

    const oldPatient = await getData(url);

    if (oldPatient.error) {
      console.log("error");
    } else {
      console.log("success");
      setOldPatientList(oldPatient.data);
    }
  }, []);

  console.log({ oldPatientList });

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        freeSolo
        disableClearable
        size="small"
        options={oldPatientList}
        getOptionLabel={(option) => (option ? option.mobile : "")}
        renderOption={(props, option) => (
          <li {...props} key={option.patientId}>
            {option.mobile}
          </li>
        )}
        onInputChange={(event, newInputValue) => {
          if (
            newInputValue &&
            !isNaN(newInputValue) &&
            newInputValue.length < 11
          ) {
            fetchOldPatient(newInputValue);
            formValues["mobile"] = newInputValue;
          }
        }}
        value={formValues}
        selectOnFocus
        onChange={(event, value) => {
          //formValues["fullName"] = value.fullName;
          setFormValues(value);
          console.log({ selectedPatient: value });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Phone Number*"
            variant="outlined"
            size="small"
            helperText={
              formValues.mobile
                ? formValues.mobile.length < 10
                  ? "Phone must be 10 digits"
                  : ""
                : "Phone no can not be empty."
            }
            error={
              formValues.mobile
                ? formValues.mobile.length < 10
                  ? true
                  : false
                : true
            }
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Fragment>
  );
};

export default PhoneNumber;
