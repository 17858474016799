import { BASE_URL } from "../../../assets/constantsFile";

export const TextFieldList = (permissions) => {
  return [
    {
      index: 1,
      title: "Height (cm)",
      property: "height",
      visibility: permissions["height"],
    },
    {
      index: 2,
      title: "Weight (Kgs)",
      property: "weight",
      visibility: permissions["weight"],
    },
    {
      index: 3,
      title: "BP(mmHg)",
      property: "bp",
      visibility: permissions["bp"],
    },
    {
      index: 4,
      title: "RBS",
      property: "sugar",
      visibility: permissions["sugar"],
    },
    {
      index: 5,
      title: "Blood Group",
      property: "bloodGroup",
      visibility: permissions["bloodGroup"],
    },
    {
      index: 6,
      title: "Body built",
      property: "bodyBuilt",
      visibility: permissions["bodyBuilt"],
    },
    {
      index: 7,
      title: "Hair",
      property: "hairs",
      visibility: permissions["hairs"],
    },
    {
      index: 8,
      title: "Nails",
      property: "nails",
      visibility: permissions["nails"],
    },
    {
      index: 9,
      title: "BMI",
      property: "bmi",
      visibility: permissions["bmi"],
    },
  ];
};

export const SwitchFieldList = (permissions) => {
  return [
    /////just toggles without report
    {
      index: 1,
      title: "Fit to Work",
      property: "fitToWork",
      display: permissions["fitToWork"],
    },

    {
      index: 2,
      title: "Eyesight OK",
      property: "eyeSightOk",
      display: permissions["eyeSightOk"],
    },
    {
      index: 3,
      title: "Farsighted",
      property: "farSighted",
      display: permissions["farSighted"],
    },
    {
      index: 4,
      title: "Nearsighted",
      property: "nearSighted",
      display: permissions["nearSighted"],
    },
    {
      index: 5,
      title: "Glasses Required",
      property: "glass",
      display: permissions["glass"],
    },
    {
      index: 6,
      title: "Cataract",
      property: "cataract",
      display: permissions["cataract"],
    },
    {
      index: 7,
      title: "Smoking",
      property: "smoking",
      display: permissions["smoking"],
    },
    {
      index: 8,
      title: "Alcohol",
      property: "alchohol",
      display: permissions["alchohol"],
    },
    {
      index: 9,
      title: "Pan Chewing",
      property: "panChewing",
      display: permissions["panChewing"],
    },
    {
      index: 10,
      title: "Gambling",
      property: "gambling",
      display: permissions["gambling"],
    },
    {
      index: 11,
      title: "Drug Addiction",
      property: "drugAddiction",
      display: permissions["drugAddiction"],
    },
    {
      index: 12,
      title: "Eye Operation",
      property: "eyeOperation",
      display: permissions["eyeOperation"],
    },

    ///// toggles with report
    {
      index: 13,
      title: "ECG",
      property: "ecg",
      display: permissions["ecg"],
    },
    {
      index: 14,
      title: "X-Ray",
      property: "xrayDone",
      display: permissions["xrayDone"],
    },
    {
      index: 15,
      title: "Blood Sample",
      property: "bloodSampleCollected",
      display: permissions["bloodSampleCollected"],
    },
    {
      index: 16,
      title: "Urine Sample",
      property: "urineSampleCollected",
      display: permissions["urineSampleCollected"],
    },
    {
      index: 17,
      title: "Stool Sample",
      property: "stoolSampleCollected",
      display: permissions["stoolSampleCollected"],
    },
    {
      index: 18,
      title: "Audiometry",
      property: "audiometryDone",
      display: permissions["audiometryDone"],
    },
    {
      index: 19,
      title: "PFT",
      property: "pft",
      display: permissions["pft"],
    },
    {
      index: 20,
      title: "Eye Test",
      property: "eyeTest",
      display: permissions["eyeTest"],
    },

    {
      index: 21,
      title: "CBC",
      property: "cbc",
      display: permissions["cbc"],
    },
    {
      index: 22,
      title: "Serum Bilirubin",
      property: "serumBilirubin",
      display: permissions["serumBilirubin"],
    },
    {
      index: 23,
      title: "Fitness Certificate",
      property: "fitnessCertificate",
      display: permissions["fitnessCertificate"],
    },

    {
      index: 24,
      title: "hbsAgPF Sample Collected",
      property: "hbsAgPFSampleCollected",
      display: permissions["hbsAgPFSampleCollected"],
    },
  ];
};

export const UploadFieldList = (formValues, corpId, permissions) => {
  return [
    {
      index: 1,
      title: "Take photo",
      property: "imageUrl",
      url:
        BASE_URL +
        `org/profilePic/upload?empId=${formValues.empId}&corpId=${corpId}`,

      display: permissions["imageUrl"],
    },

    {
      index: 2,
      title: "Record PRESCRIPTION",
      property: "prescriptionUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PRESCRIPTION&corpId=${corpId}`,

      display: permissions["prescriptionUrl"],
    },
    {
      index: 3,
      title: "Additional Document",
      property: "miscellaneousUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=MISCELLANEOUS&corpId=${corpId}`,

      display: permissions["miscellaneousUrl"],
    },
    {
      index: 4,
      title: "ECG",
      property: "ecgUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=ECG&corpId=${corpId}`,

      display: permissions["ecgUrl"],
    },
    {
      index: 5,
      title: "X-Ray Document",
      property: "xrayUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=XRAY&corpId=${corpId}`,
      display: permissions["xrayUrl"],
    },

    {
      index: 6,
      title: "X-Ray Film",
      property: "xrayFilmUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=XRAY_FILM&corpId=${corpId}`,
      display: permissions["xrayFilmUrl"],
    },
    {
      index: 7,
      title: "Blood Test ",
      property: "bloodTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=BLOODTEST&corpId=${corpId}`,

      display: permissions["bloodTestUrl"],
    },
    {
      index: 8,
      title: "Urine Test Document",
      property: "urineTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=URINETEST&corpId=${corpId}`,

      display: permissions["urineTestUrl"],
    },

    {
      index: 9,
      title: "Stool Test Document",
      property: "stoolTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=STOOLTEST&corpId=${corpId}`,
      display: permissions["stoolTestUrl"],
    },
    {
      index: 10,
      title: "Audiometry Document",
      property: "audiometryUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=AUDIOMETRY&corpId=${corpId}`,

      display: permissions["audiometryUrl"],
    },

    {
      index: 11,
      title: "PFT Document",
      property: "pftUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PFT&corpId=${corpId}`,
      display: permissions["pftUrl"],
    },
    {
      index: 12,
      title: "Record Eye Report",
      property: "eyeTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=EYE_TEST&corpId=${corpId}`,
      display: permissions["eyeTestUrl"],
    },

    {
      index: 13,
      title: "CBC Document",
      property: "cbcUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=CBC&corpId=${corpId}`,
      display: permissions["cbcUrl"],
    },

    {
      index: 14,
      title: "Serum Bilirubin",
      property: "serumBilirubinUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=SERUM_BILIRUBIN&corpId=${corpId}`,
      display: permissions["serumBilirubinUrl"],
    },
    {
      index: 15,
      title: "Fitness Certificate",
      property: "fitnessCertificateUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=FITNESS_CERTIFICATE&corpId=${corpId}`,
      display: permissions["fitnessCertificateUrl"],
    },

    {
      index: 16,
      title: "HBsAgPF",
      property: "hbsAgPFTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=HBSAGPF&corpId=${corpId}`,
      display: permissions["hbsAgPFTestUrl"],
    },

    {
      index: 17,
      title: "Physical Fitness Certificate",
      property: "physicalFitnessCertificateUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PHYSICAL_FITNESS_CERTIFICATE&corpId=${corpId}`,
      display: permissions["physicalFitnessCertificateUrl"],
    },

    {
      index: 18,
      title: "FORM 35",
      property: "form35Url",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=FORM_35&corpId=${corpId}`,
      display: permissions["form35Url"],
    },

    {
      index: 19,
      title: "Physical Fitness Form",
      property: "physicalFitnessFormUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PHYSICAL_FITNESS_FORM&corpId=${corpId}`,
      display: permissions["physicalFitnessFormUrl"],
    },

    {
      index: 19,
      title: "Vaccination Certificate",
      property: "vaccinationCertificateUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=VACCINATION_CERTIFICATE&corpId=${corpId}`,
      display: true,
    },
  ];
};
