import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "typeface-roboto";
import LoginPage from "./pages/login/loginPage";
import customTheme from "./assets/themes/customTheme";
import { ThemeProvider } from "@mui/material";
import LabDashboard from "./pages/dashboards/labDashboard";
import PharmacyDashboard from "./pages/dashboards/pharmacyDashboard";
import Payment from "./pages/payment/payment";
import BranchName from "./pages/branchName/branchName";
import PatientRegistration from "./pages/patientRegistration/patientregistration";
import ClinicDashboard from "./pages/dashboards/clinicDashboard";
import Home from "./pages/home";
import Logout from "./pages/logout";
import DoctorRegistration from "./pages/doctorRegistration/doctorRegistration";
import DoctorDetailsEntry from "./pages/doctorRegistration/doctorDetailsEntry";
import DoctorSummary from "./pages/doctorRegistration/doctorSummary";
import { userContext } from "./common/context/usercontext";
import PatientHistory from "./pages/patientHistory/patientHistory";
import MyImage from "./pages/myImage";
import PatientRegistrationBeta from "./pages/patientRegistration/patientregistrationBeta";

import LoginPageHC from "./pages/healthCampLogin/loginPageHC";
import LandingPageHC from "./pages/healthCampLogin/landingPageHC";
import AddFamilyHC from "./pages/healthCampLogin/addFamilyHC";
import HeightPageHC from "./pages/healthCampLogin/heightPageHC";
import VitalsPageHC from "./pages/healthCampLogin/vitalsPageHC";
import LogoutPageHC from "./pages/healthCampLogin/logoutPageHC";
import BPPageHC from "./pages/healthCampLogin/bpPageHC";
import EyeSightPageHC from "./pages/healthCampLogin/eyeSightPageHC";
import WeightPageHC from "./pages/healthCampLogin/weightPageHC";

import LoginPageMarketing from "./pages/marketing/LoginPageMarketing";
import UserOnboardingMK from "./pages/marketing/userOnboardingMK";
import UserInformationMK from "./pages/marketing/UserInformationMK";
import FamilyHistoryMK from "./pages/marketing/familyHistoryMK";
import CaseHistoryMK from "./pages/marketing/caseHistoryMK";
import ReportsMK from "./pages/marketing/reportsMK";

import PrivateRouter from "./common/privateRouter/privateRouter";
import DashboardMK from "./pages/marketing/dashboardMK";
import CorpoprateIndex from "./pages/healthCampLogin/corpoprateIndex";
import VideoCallMain from "./clinicDashboard/videoCall/videoCallMain";
import AdminDashboard from "./adminPortal/pages/adminDashboard";
import DocRegistration from "./adminPortal/pages/registerDoctor";
import AdminLogin from "./adminPortal/pages/adminLogin";
import AdminLogout from "./adminPortal/pages/adminLogout";
import LandingHCNew from "./marketing/ashaWoker/landingAshaWorker";
import LandingAshaWoker from "./marketing/ashaWoker/landingAshaWorker";
import PatientRegisterAshaWorker from "./marketing/ashaWoker/patientRegister/patientRegister";
import DetailPageAshaWoker from "./marketing/ashaWoker/patientDetailPageAshaWorker/DetailPageAshaWoker";
import ConsolodatedReportIndex from "./healthcamp/pages/consolodatedReportIndex";
import ManagePackageIndex from "./healthcamp/pages/managePackageIndex";
import CorpRegistrationIndex from "./healthcamp/pages/corpRegistrationIndex";
import VitalAnalyticsIndex from "./healthcamp/pages/vitalAnalyticsIndex";
import GalleryUpload from "./healthcamp/pages/galleryUpload";
import CorpPermissionDashboardIndex from "./healthcamp/pages/corpPermissionDashboardIndex";
import GatherInfoIndex from "./corp/pages/gatherInfoIndex";
import LogoutCorp from "./corp/pages/logoutCorp";
import CorpLoginIndex from "./corp/pages/corpLoginIndex";
import CorpHomeIndex from "./corp/pages/corpHomeIndex";
import ExecutionInfoIndex from "./corp/pages/executionInfoIndex";
import SelectCorpIndex from "./corp/pages/selectCorpIndex";
import CreateCorpIndex from "./corp/pages/createCorpIndex";

const MyApp = () => {
  return (
    <userContext.Provider
      value={{ root: root, imageUrl: "", selectedMobileMarketing: null }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home root={root} />} />
          <Route path="/clinic" element={<Home root={root} />} />
          <Route path="/login" element={<LoginPage root={root} />} />
          <Route path="/logout" element={<Logout root={root} />} />
          <Route
            path="/clinicDashboard"
            element={
              <PrivateRouter
                Page={ClinicDashboard}
                Default={LoginPage}
                root={root}
              />
            }
          />
          <Route
            path="/call"
            element={<PrivateRouter Page={VideoCallMain} Default={LoginPage} />}
          />
          <Route path="/labDashboard" element={<LabDashboard root={root} />} />
          <Route
            path="/pharmacyDashboard"
            element={<PharmacyDashboard root={root} />}
          />
          <Route
            path="/registration"
            element={<PatientRegistration root={root} />}
          />
          <Route
            path="/registrationnew"
            element={<PatientRegistrationBeta />}
          />
          <Route path="/payment" element={<Payment root={root} />} />
          <Route path="/branchname" element={<BranchName root={root} />} />
          <Route
            path="/docregistration"
            element={<DoctorRegistration root={root} />}
          />
          <Route
            path="/doctorDetails"
            element={<DoctorDetailsEntry root={root} />}
          />
          <Route
            path="/patientHistory"
            element={<PatientHistory root={root} />}
          />
          <Route
            path="/doctorSummary"
            element={<DoctorSummary root={root} />}
          />
          <Route path="/myimage" element={<MyImage root={root} />} />

          {/* Healthcamp section start */}
          <Route path="/loginHC" element={<LoginPageHC />} />
          <Route path="/landingHC" element={<LandingPageHC />} />
          <Route path="/assignTokenHC" element={<AddFamilyHC />} />
          <Route path="/addFamilyHC" element={<AddFamilyHC />} />
          <Route path="/heightHC" element={<HeightPageHC />} />
          <Route path="/weightHC" element={<WeightPageHC />} />
          <Route path="/eyesightHC" element={<EyeSightPageHC />} />
          <Route path="/bpHC" element={<BPPageHC />} />
          <Route path="/vitalsHC" element={<VitalsPageHC />} />
          <Route path="/freeTokenHC" element={<VitalsPageHC />} />
          <Route path="/logoutHC" element={<LogoutPageHC />} />
          <Route path="/corporatehome" element={<CorpoprateIndex />} />
          <Route
            path="/printconsolidated"
            element={<ConsolodatedReportIndex />}
          />
          <Route path="/managepackage" element={<ManagePackageIndex />} />
          <Route path="/registeremployee" element={<CorpRegistrationIndex />} />
          <Route path="/galleryupload" element={<GalleryUpload />} />
          <Route
            path="/managepermissions"
            element={<CorpPermissionDashboardIndex />}
          />
          <Route path="/vitalanalytics" element={<VitalAnalyticsIndex />} />

          <Route
            path="/patientRegisterAshaWorker"
            element={<PatientRegisterAshaWorker />}
          />
          <Route
            path="/patientDetailsAshaWorker"
            element={<DetailPageAshaWoker />}
          />

          {/* Healthcamp section end */}

          <Route path="/login-marketing" element={<LoginPageMarketing />} />

          <Route
            path="/marketing/asha-worker"
            element={
              <PrivateRouter
                Page={LandingAshaWoker}
                Default={LoginPageMarketing}
              />
            }
          />
          <Route
            path="/marketing/user-onboarding"
            element={
              <PrivateRouter
                Page={UserOnboardingMK}
                Default={LoginPageMarketing}
              />
            }
          />
          <Route
            path="/marketing/:userId"
            element={
              <PrivateRouter
                Page={UserInformationMK}
                Default={LoginPageMarketing}
              />
            }
          />
          <Route
            path="/marketing/family-history"
            element={
              <PrivateRouter
                Page={FamilyHistoryMK}
                Default={LoginPageMarketing}
              />
            }
          />
          <Route
            path="/marketing/case-history/:patientName/:patientId"
            element={
              <PrivateRouter
                Page={CaseHistoryMK}
                Default={LoginPageMarketing}
              />
            }
          />

          <Route path="/corp/login" element={<CorpLoginIndex />} />
          <Route path="/corp/home" element={<CorpHomeIndex />} />
          <Route path="/corp/select-corp" element={<SelectCorpIndex />} />
          <Route path="/corp/create-corp" element={<CreateCorpIndex />} />
          <Route path="/corp/gather-info" element={<GatherInfoIndex />} />
          <Route path="/corp/execution-info" element={<ExecutionInfoIndex />} />
          <Route path="/corp/logout" element={<LogoutCorp />} />

          {/* Not being used right now  */}
          <Route
            path="/marketing/reports/:caseId"
            element={
              <PrivateRouter Page={ReportsMK} Default={LoginPageMarketing} />
            }
          />
          <Route
            path="/marketing/dashboard"
            element={
              <PrivateRouter Page={DashboardMK} Default={LoginPageMarketing} />
            }
          />

          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/registration" element={<DocRegistration />} />
          <Route path="/admin/logout" element={<AdminLogout />} />
        </Routes>
      </BrowserRouter>
    </userContext.Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ThemeProvider theme={customTheme}>
    <MyApp />
  </ThemeProvider>
);

export default MyApp;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
