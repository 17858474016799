import {
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { saveData } from "../../services/api/postApiCalls";
import { BASE_URL } from "../../assets/constantsFile";
import axios from "axios";
import PrintComp from "./printComp";
import PaymentComponent from "../../common/paymentComponent/paymentComponent";
import { useFileUpload } from "use-file-upload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const ReportForm = (props) => {
  const patientId = props.pDetails.patientId;
  const appointmentId = props.pDetails.appointmentId;
  const directBooking = props.pDetails.directBooking;
  let reportsList = "";

  console.log({ "props.pDetails": props.pDetails });
  if (props.pDetails.reports) {
    reportsList = props.pDetails.reports;
  }

  const paymentVM = {
    paymentVM: props.pDetails.paymentVM ? props.pDetails.paymentVM : null,
    appointmentId: appointmentId,
    patientId: patientId,
    directBooking: directBooking,
  };

  const [formValues, setFormValues] = useState([]);
  const [reportsUploadFlag, setReportsUploadFlag] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setFormValues(reportsList);
  }, [reportsUploadFlag]);

  // console.log({"reportsreports_reports":reportsList})

  const [selectedFile, setSelectedFile] = useState(null);
  const [reportId, setReportId] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    console.log({ selectedFile });
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);

    uploadFile(formData);

    console.log({ formData_upload: formData });
  };

  const handleSubmitNew = (name, size, source, file, reportId, index) => {
    console.log({ file });
    console.log({ reportId });
    console.log({ index });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", name);

    uploadFile(formData, reportId, index);

    console.log({ formData_upload: formData });
  };
  console.log({ selectedFile });
  const uploadFile = async (formData, reportId, index) => {
    try {
      const response = await axios({
        method: "post",
        url: BASE_URL + "lab/tests/upload?reportId=" + reportId,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authHeader_local"),
        },
      });
      const data = await response.data;
      console.log({ formData_upload_2: response.data });

      reportsList[index] = data;
      setReportsUploadFlag(!reportsUploadFlag);
      props.onAddReports(data);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log({ reports_upload: reportsList });

  const closePopOver = () => {
    props.onAddReports("BACK");
  };

  const handleFileSelect = (i, event) => {
    console.log({ event_reports: reportsList[i] });
    setReportId(reportsList[i].reportId);
    setCurrentIndex(i);
    setSelectedFile(event.target.files[0]);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.checked;
    setFormValues(newFormValues);
  };

  const handleAvailedChange = (element, e) => {
    console.log({ handleAvailedChange: element });

    const url = BASE_URL + "lab/markReportAvailed";

    const obj = {
      reportId: element.reportId,
      appointmentId: appointmentId,
      availed: element.availed,
      labId: localStorage.getItem("LAB_ID"),
    };

    console.log({ "my availed to be saved": obj });

    updateAvailedStatus(url, obj);
  };

  const handleSampleChange = (element, e) => {
    console.log({ handleSampleChange: element });

    const url = BASE_URL + "lab/markSampleCollected/";

    const obj = {
      sampleCollected: element.sampleCollected,
      reportUploaded: element.reportUploaded,
      reportId: element.reportId,
    };

    updateSampleStatus(url, obj);
  };

  const updateAvailedStatus = async (URL, OBJ) => {
    const user = await saveData(URL, OBJ);

    if (user.error) {
    } else {
      console.log("success from availed");
    }
    props.onAddReports(user);
  };

  const updateSampleStatus = async (URL, OBJ) => {
    const user = await saveData(URL, OBJ);
    if (user.error) {
    } else {
      console.log("success from sample");
    }
    props.onAddReports(user);
  };

  const defaultSrc =
    "https://www.pngkit.com/png/full/301-3012694_account-user-profile-avatar-comments-fa-user-circle.png";
  const [files, selectFiles] = useFileUpload();

  return (
    <Container maxWidth={false} disableGutters sx={{ background: "green" }}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#F3F3F3",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>{props.pDetails.patientName}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#D9D9D9",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container spacing={1}>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Test Name</Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Availed</Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Payable</Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Sample Collected</Typography>
              </Grid>

              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Report</Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography>Upload</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              minHeight: "36vh",
              background: "#fff",
              pt: 2,
            }}
          >
            {formValues.length > 0
              ? formValues
                  .filter((formValue, index) => !formValue.broughtByPatient)
                  .map((element, index) => (
                    <Grid container key={index}>
                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography>{element.reportName}</Typography>
                      </Grid>

                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Switch
                          disabled={element.sampleCollected || directBooking}
                          name="availed"
                          checked={element.availed || false}
                          onChange={(e) => {
                            handleChange(index, e);
                            handleAvailedChange(element, e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography>
                          {element.availed ? element.reportPrice : "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Switch
                          disabled={!element.availed}
                          name="sampleCollected"
                          checked={element.sampleCollected || false}
                          onChange={(e) => {
                            handleChange(index, e);
                            handleSampleChange(element, e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>

                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        {element.reportUploaded ? (
                          <PrintComp url={element.reportURL} />
                        ) : (
                          <Chip disabled label="Pending" />
                        )}
                      </Grid>

                      <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ mt: 0.5 }}
                          justifyContent="flex-end"
                        >
                          <Fragment>
                            {element.reportUploaded ? (
                              <div>
                                {element.reportFileType === "PDF" ? (
                                  <Link
                                    href={
                                      "http://docs.google.com/gview?url=" +
                                      element.reportURL +
                                      "&embedded=true"
                                    }
                                    target="_blank"
                                    underline="none"
                                  >
                                    <PictureAsPdfIcon />
                                  </Link>
                                ) : (
                                  <Link
                                    href={
                                      "/myimage?ImageUrl=" + element.reportURL
                                    }
                                    target="_blank"
                                    underline="none"
                                  >
                                    <img
                                      src={element.reportURL}
                                      alt="preview"
                                      height="25"
                                      width="25"
                                    />
                                  </Link>
                                )}
                              </div>
                            ) : (
                              <Typography variant="caption">
                                No file selected
                              </Typography>
                            )}
                          </Fragment>
                          <Fragment>
                            <Button
                              onClick={() =>
                                selectFiles(
                                  { accept: "*" },
                                  ({ name, size, source, file }) => {
                                    handleSubmitNew(
                                      name,
                                      size,
                                      source,
                                      file,
                                      element.reportId,
                                      index
                                    );
                                    console.log("Files Selected", {
                                      name,
                                      size,
                                      source,
                                      file,
                                    });
                                  }
                                )
                              }
                              variant="outlined"
                              startIcon={<AttachFileIcon />}
                              sx={{
                                minWidth: 32,
                                p: 0,
                                pl: 1.5,
                                minHeight: 32,
                              }}
                            ></Button>
                          </Fragment>
                        </Stack>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ))
              : "no reports here"}
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              minHeight: "10vh",
              background: "#F3F3F3",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container rowSpacing={1}>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                Total Payable
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                Paid till Date
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                Record New Payment
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                &#8377;{" "}
                {props.pDetails.paymentVM
                  ? props.pDetails.paymentVM.finalAmountPayable
                  : "N/A"}
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                &#8377;{" "}
                {props.pDetails.paymentVM
                  ? props.pDetails.paymentVM.amountCollected
                  : "N/A"}
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <PaymentComponent
                  root={props.root}
                  paymentVM={paymentVM}
                  portal="LAB"
                  portalId={localStorage.getItem("LAB_ID")}
                  dashBoard={"1"}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    onClick={closePopOver}
                    variant="contained"
                    sx={{ width: 200 }}
                  >
                    Back
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReportForm;
