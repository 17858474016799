import { Grid } from "@mui/material"
import CustomDate from "./CustomDate"
import CustomAutoComplete from "./CustomAutoComplete"
import CustomMultilineTextField from "./CustomMultilineTextField"
import CustomSelect from "./CustomSelect"
import CustomTextField from "./CustomTextField"
import CustomTextFieldUpload from "./CustomTextFieldUpload"
import { Fragment } from "react"

const DynamicForm = ({
  fields,
  setMarketingPatientId,
  formValues,
  setFormValues,
  setDate,
  setImageUrl,
  patientId,
  genderData,
  date,
  caseId,
  appointmentId,
  size,
  fullWidth,
  freeSolo,
  id,
  name,
  value,
  disableClearable,
  options,
  onChange,
  getOptionLabel,
  label,
}) => {
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        {fields?.map((item, index) => {
          if (item.type === "text") {
            return (
              <Grid key={index} item xs={item.gridWidth} lg={item.gridWidth}>
                <CustomTextField
                  key={item.property}
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Grid>
            )
          } else if (item.type === "upload") {
            return (
              <Grid key={index} item xs={item.gridWidth} lg={item.gridWidth}>
                <CustomTextFieldUpload
                  key={index}
                  item={item}
                  setMarketingPatientId={setMarketingPatientId}
                  // url={item.url}
                  setImageUrl={setImageUrl}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  // patientId={patientId}
                  // date={date}
                  // caseId={caseId}
                  // appointmentId={appointmentId}
                />
              </Grid>
            )
          } else if (item.type === "date") {
            return (
              <Grid key={index} item xs={item.gridWidth} lg={item.gridWidth}>
                <CustomDate
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  key={index}
                  setDate={setDate}
                  date={date}
                />
              </Grid>
            )
          } else if (item.type === "multiline") {
            return (
              <Grid key={index} item xs={item.gridWidth} lg={item.gridWidth}>
                <CustomMultilineTextField
                  key={index}
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Grid>
            )
          } else if (item.type === "select") {
            return (
              <Grid key={index} item xs={item.gridWidth} lg={item.gridWidth}>
                <CustomSelect
                  key={index}
                  item={item}
                  genderData={genderData}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    </Fragment>
  )
}

export default DynamicForm
