import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const HeightElement = ({ element, variant }) => {
  const [height, setHeight] = useState("");
  return (
    <Fragment>
      <TextField
        sx={{ p: 0.3 }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={variant === "small" ? "Enter Height" : ""}
        value={element.height || ""}
        onChange={(e) => {
          setHeight(e.target.value);
          element["height"] = e.target.value;
        }}
      />
    </Fragment>
  );
};

export default HeightElement;
