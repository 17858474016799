import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const _genderGroup = [
  { title: "हड्डी रोग(Ortho)", value: "ORTHO" },
  { title: "स्त्री रोग(Gynae)", value: "GYNAE" },
  { title: "आँखों की बीमारी(Eye)", value: "EYE" },
  { title: "अन्य(MD)", value: "MD" },
];
const DiseaseGroup = ({ formValues, setFormValues, leadType }) => {
  useEffect(() => {
    if (leadType === "GENERAL_PUBLIC") {
      setFormValues({ ...formValues, diseaseGroup: "" });
    } else {
      setFormValues({ ...formValues, diseaseGroup: "MD" });
    }
  }, [leadType]);
  return (
    <Box>
      <Grid container display={"flex"} alignItems={"center"}>
        <Grid item xs={2} lg={4}>
          <Typography>Disease:</Typography>
        </Grid>
        <Grid
          item
          xs={10}
          lg={8}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <RadioGroup
            row
            value={formValues["diseaseGroup"] || ""}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                diseaseGroup: e.target.value,
                diseases: e.target.value,
              });
            }}
          >
            {_genderGroup.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item?.value}
                control={
                  <Radio
                    required={leadType === "GENERAL_PUBLIC" ? false : true}
                  />
                }
                label={item?.title}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiseaseGroup;
