import { Box, Button, Container, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import { saveData } from "../../services/api/postApiCalls";
import Address from "./registrationFormElements/address";
import Age from "./registrationFormElements/age";
import EmailId from "./registrationFormElements/emailId";
import Gender from "./registrationFormElements/gender";
import GuradianName from "./registrationFormElements/guradianName";
import GuradianPhoneNumber from "./registrationFormElements/guradianPhoneNumber";
import MarketingSource from "./registrationFormElements/marketingSource";
import PatientName from "./registrationFormElements/patientName";
import PhoneNumber from "./registrationFormElements/phoneNumber";
import PinCode from "./registrationFormElements/pinCode";

const RegisterContentMain = ({
  bookAppointmentHandler,
  bookLabHandler,
  bookPharmacyHandler,
}) => {
  const [formValues, setFormValues] = useState({
    id: null,
    fullName: "",
    mobile: "",
    email: "",
    gender: "MALE",
  });

  const submitHandlerPR = (event) => {
    event.preventDefault();

    console.log({ data: formValues });

    const pObj = {
      patientId: formValues.patientId,
      fullName: formValues.fullName,
      firstName: formValues.fullName,
      gender: formValues.gender,
      email: formValues.email,
      mobile: formValues.mobile,
      age: formValues.age,
      ageMonths: formValues.ageMonths,
      guardianFirstName: formValues.guardianFirstName,
      guardianContactNumber: formValues.guardianContactNumber,
      source: formValues.source,
      addressVM: {
        city: "",
        state: "",
        country: "",
        address: formValues.addressVM.address,
      },
      pinCode: formValues.pinCode,
      branchId: localStorage.getItem("BRANCH_ID"),
    };
    console.log({ registration_formValues: pObj });
    if (validateFields(pObj)) {
      savePatient(pObj);
    }
  };

  console.log({ formValues });
  const savePatient = async (obj) => {
    //saveData
    const regPatient = await saveData(BASE_URL + "patient", obj);

    if (regPatient.error) {
      console.log({ Error: regPatient.data });
      setNoticeData({
        severity: "error",
        message: "Failed.",
        openNotice: true,
      });
    } else {
      setFormValues(regPatient.data);
      setNoticeData({
        severity: "success",
        message: "Saved Successfully.",
        openNotice: true,
      });
      console.log({ "Success Data": regPatient.data });
    }
  };

  const validateFields = (data) => {
    let isValidated = false;

    if (
      data.fullName &&
      data.mobile &&
      data.mobile.length === 10 &&
      data.source &&
      data.addressVM &&
      data.addressVM.address
    ) {
      isValidated = true;
    }

    return isValidated;
  };

  const [noticeData, setNoticeData] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });

  return (
    <Fragment>
      <ShowNoticeMessage
        noticeData={noticeData}
        key={JSON.stringify(noticeData)}
      />
      <Container>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <PatientName
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>
            <Grid item lg={6}>
              <PhoneNumber
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>
            <Grid item lg={6}>
              <EmailId formValues={formValues} setFormValues={setFormValues} />
            </Grid>
            <Grid item lg={4}>
              <Age formValues={formValues} setFormValues={setFormValues} />
            </Grid>
            <Grid item lg={2}>
              <Gender formValues={formValues} setFormValues={setFormValues} />
            </Grid>
            <Grid item lg={6}>
              <MarketingSource
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>
            <Grid item lg={9}>
              <Address formValues={formValues} setFormValues={setFormValues} />
            </Grid>
            <Grid item lg={3}>
              <PinCode formValues={formValues} setFormValues={setFormValues} />
            </Grid>

            <Grid item lg={6}>
              <GuradianName
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>

            <Grid item lg={6}>
              <GuradianPhoneNumber
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Grid>
            <Grid
              item
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                borderTop: "3px solid #127DDD",
                mt: 3,
              }}
            >
              <Stack direction="row" spacing={2}>
                {formValues.patientId ? (
                  <Button
                    variant="outlined"
                    onClick={submitHandlerPR}
                    disabled={!validateFields(formValues)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={submitHandlerPR}
                    disabled={!validateFields(formValues)}
                  >
                    Register
                  </Button>
                )}

                {formValues.patientId ? (
                  <Fragment>
                    <Button
                      variant="outlined"
                      disabled={!validateFields(formValues)}
                      onClick={(e) => {
                        bookAppointmentHandler(formValues);
                        submitHandlerPR(e);
                      }}
                    >
                      Book Appointment
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={!validateFields(formValues)}
                      onClick={(e) => {
                        bookLabHandler(formValues);
                        submitHandlerPR(e);
                      }}
                    >
                      Book Lab
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={!validateFields(formValues)}
                      onClick={(e) => {
                        bookPharmacyHandler(formValues);
                        submitHandlerPR(e);
                      }}
                    >
                      Book Pharmacy
                    </Button>
                  </Fragment>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default RegisterContentMain;
