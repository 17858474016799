import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../../services/api/getApiCalls";
import { BASE_URL } from "../../../assets/constantsFile";

const OnBehalfOfPerson = ({ formValues, setFormValues }) => {
  const [marketingUsersList, setMarketingUsersList] = useState([]);
  const _fetchMarketingUsers = async () => {
    const url = BASE_URL + `healthcamp/marketingUsers`;
    const users = await getData(url);

    if (users.data) {
      setMarketingUsersList(users.data);
    } else {
      setMarketingUsersList([]);
    }
  };

  useEffect(() => {
    _fetchMarketingUsers();
  }, []);

  const [value, setValue] = useState(null);
  console.log({ marketingUsersList });
  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={12}>
          <Typography>POC Name:</Typography>
        </Grid>
        <Grid item lg={8} xs={12}>
          {/* <TextField
            size="small"
            fullWidth
            placeholder={"POC Name"}
            value={formValues.onBehalfOfPersonName || ""}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                onBehalfOfPersonName: e.target.value,
              })
            }
          /> */}

          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setFormValues({
                ...formValues,
                onBehalfOfPersonName: newValue.name,
              });
            }}
            id="UsersMarketing"
            options={marketingUsersList}
            getOptionLabel={(option) =>
              `${option.name || "N/A"}, ${option.mobile}`
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.mobile}>
                  {`${option.name || "N/A"}, ${option.mobile}`}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                size="small"
                placeholder="POC Name"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default OnBehalfOfPerson;
