import { CssBaseline } from "@mui/material";
import React, { Fragment } from "react";
import DocDetailsMainComponent from "../../doctorRegistration/docDetailsMainComponent";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";

const DoctorDetailsEntry = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />
      <DocDetailsMainComponent />
    </Fragment>
  );
};

export default DoctorDetailsEntry;
