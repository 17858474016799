import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import BookAppointmentDialog from "./bookAppointment/bookAppointmentDialog";
import BookLabDialog from "./bookLab/bookLabDialog";
import BookPharmacyDialog from "./bookPharmacy/bookPharmacyDialog";
import RegisterContentMain from "./registration/registerContentMain";

const PatientRegistrationContent = () => {
  const [patientId, setPatientId] = useState("");
  const [patientData, setPatientData] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const [open12, setOpen12] = useState(false);
  const handleClickOpen12 = () => {
    setOpen12(true);
  };
  const handleClose12 = () => {
    setOpen12(false);
  };

  const bookAppointmentHandler = (data) => {
    handleClickOpen12();
    setPatientId(data.patientId);
    setPatientData(data);
    console.log({ "book appointment": data });
  };
  const bookLabHandler = (data) => {
    handleClickOpen();
    setPatientId(data.patientId);
    setPatientData(data);
    console.log({ "book lab": data });
  };
  const bookPharmacyHandler = (data) => {
    handleClickOpen1();
    setPatientId(data.patientId);
    setPatientData(data);
    console.log({ "book pharmacy": data });
  };
  return (
    <Fragment>
      <BookAppointmentDialog
        open={open12}
        handleClose={handleClose12}
        patientId={patientId}
        patientData={patientData}
      />

      <BookLabDialog
        open={open}
        handleClose={handleClose}
        patientId={patientId}
        patientData={patientData}
      />

      <BookPharmacyDialog
        open={open1}
        handleClose={handleClose1}
        patientId={patientId}
        patientData={patientData}
      />

      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "center",
                borderBottom: "3px solid #127DDD",
              }}
            >
              <Typography variant="h6">Patient Registration</Typography>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box>
              <RegisterContentMain
                bookAppointmentHandler={bookAppointmentHandler}
                bookLabHandler={bookLabHandler}
                bookPharmacyHandler={bookPharmacyHandler}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientRegistrationContent;
