import { Fragment, useState } from "react";
import { getOrgPermissions } from "../dataandpermissions/permissions";
import {
  Alert,
  AppBar,
  Box,
  Container,
  Grid,
  Snackbar,
  Toolbar,
} from "@mui/material";
import SearchEmployee from "./comps/searchEmployee";
import { init, initNew } from "../dataandpermissions/data";
import PrintConsolidatedReport from "./comps/printConsolidatedReport";
import EmployeeBiodata from "./comps/employeeBiodata";
import FormComponentMain from "./comps/formComponentMain";
import EmployeeSubmitComp from "./comps/employeeSubmitComp";
import FormComponentMainAlt from "./comps/formComponentMainAlt";

const CorporateHomeMainAlt = ({
  corpId = localStorage.getItem("CORPID"),
  consolidatedReport,
}) => {
  const permissions = getOrgPermissions(corpId);

  const [employeeId, setEmployeeId] = useState("");
  const [formValues, setFormValues] = useState(initNew(permissions?.isEnabled));
  const [searchedEmployeeId, setSearchedEmployeeId] = useState(null);

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  console.log({ formValues, permissions });
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Container
        maxWidth={false}
        sx={{ backgroundColor: "#F5F5F5", minHeight: "90vh" }}
      >
        <Box sx={{ pt: 1, mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <SearchEmployee
                corpId={corpId}
                setFormValues={setFormValues}
                setSearchedEmployeeId={setSearchedEmployeeId}
                employeeId={employeeId}
                setEmployeeId={setEmployeeId}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenNotice={setOpenNotice}
              />
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              {consolidatedReport && (
                <PrintConsolidatedReport
                  corpId={corpId}
                  searchedEmployeeId={searchedEmployeeId}
                />
              )}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <EmployeeBiodata formValues={formValues} />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormComponentMainAlt
                formValues={formValues}
                setFormValues={setFormValues}
                permissions={permissions}
                gridSize={2}
                minHeightVal={"10vh"}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {/* <EmployeeSubmitComp
                formValues={formValues}
                setFormValues={setFormValues}
                permissions={permissions}
                corpId={corpId}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenNotice={setOpenNotice}
                setEmployeeId={setEmployeeId}
                setSearchedEmployeeId={setSearchedEmployeeId}
              /> */}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
          </Grid>
        </Box>
      </Container>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          top: "auto",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Toolbar>
          <EmployeeSubmitComp
            formValues={formValues}
            setFormValues={setFormValues}
            permissions={permissions}
            corpId={corpId}
            setSeverity={setSeverity}
            setMessage={setMessage}
            setOpenNotice={setOpenNotice}
            setEmployeeId={setEmployeeId}
            setSearchedEmployeeId={setSearchedEmployeeId}
          />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default CorporateHomeMainAlt;
