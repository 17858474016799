import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import FlagIcon from "../../../assets/FlagIcon.png";

const MobileField = ({ mobile, setMobile }) => {
  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginBlock: "5px" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#6B6B6B",
          }}
        >
          Mobile Number
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          autoComplete="mobile"
          required
          value={mobile}
          onChange={(e) => {
            if (!isNaN(e.target.value) && e.target.value.length < 11) {
              setMobile(e.target.value);
            }
          }}
          InputLabelProps={{
            style: {
              marginTop: "3px",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#777777",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component={"img"}
                  src={FlagIcon}
                  width={22}
                  height={22}
                  alt="logo"
                />
                <Box
                  sx={{
                    borderBottom: 0.5,
                    borderColor: "#6B6B6B",
                    width: "30px",
                    rotate: "90deg",
                    marginRight: "-15px",
                    marginLeft: "-5px",
                  }}
                ></Box>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default MobileField;
