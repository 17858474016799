import { Box, Grid, Stack, Typography } from "@mui/material";
import EChokitsalayaLogo from "../../../logoNew.svg";

const LogoMain = () => {
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
              <img src={EChokitsalayaLogo} alt="logo" height={"70"} />
              {/* <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 50,
                  lineHeight: "1rem",
                  textAlign: "center",
                  border: "2px solid #000",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  borderRadius: 1,
                  pl: 1,
                  pr: 1,
                  pt: 2.5,
                  pb: 2.5,
                  ml: 0,
                }}
              >
                eChikitsalaya
              </Typography> */}
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
              <img src={EChokitsalayaLogo} alt="logo" height={"70"} />
              {/* <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 35,
                  lineHeight: "1rem",
                  textAlign: "center",
                  border: "2px solid #000",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  borderRadius: 1,
                  pl: 1,
                  pr: 1,
                  pt: 2.5,
                  pb: 2.5,
                  ml: 0,
                }}
              >
                eChikitsalaya
              </Typography> */}
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LogoMain;
