import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const WeightElement = ({ element, variant }) => {
  const [weight, setWeight] = useState("");
  return (
    <Fragment>
      <TextField
        sx={{ p: 0.3 }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={variant === "small" ? "Enter Weight" : ""}
        value={element.weight || ""}
        onChange={(e) => {
          setWeight(e.target.value);
          element["weight"] = e.target.value;
        }}
      />
    </Fragment>
  );
};

export default WeightElement;
