import { Box, Chip, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import PaymentComponentLab from "./dataGridComponents/paymentComponentLab";
import UploadIcon from "@mui/icons-material/Upload";
import ReportForm from "./uploadReports/reportForm";
import PaymentComponent from "../common/paymentComponent/paymentComponent";

const PrintPatientListLab = (props) => {
  const rows = props.patients.filter(
    (patient, index) => patient.attended !== true
  );
  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      type: "number",
      width: 100,
    },
    {
      field: "patientName",
      headerAlign: "center",
      align: "center",
      headerName: "NAME",
      width: 300,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "mobileNo",
      headerAlign: "center",
      align: "center",
      headerName: "CONTACT",
      width: 140,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "prescribedBy",
      headerAlign: "center",
      align: "center",
      headerName: "PRESCRIBED BY",
      width: 160,
      editable: false,
    },

    {
      field: "reports",
      headerAlign: "center",
      align: "center",
      headerName: "REPORTS",
      width: 140,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            size="small"
            icon={<UploadIcon />}
            label="Print"
            onClick={(e) => openReportUpdate(e, cellValues.value)}
            clickable
          />
        );
      },
    },
    {
      field: "paymentVM",
      headerAlign: "center",
      align: "center",
      headerName: "PAYMENT",
      width: 140,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <PaymentComponent
            root={props.root}
            paymentVM={cellValues.value}
            portal="LAB"
            portalId={localStorage.getItem("LAB_ID")}
          />
        );
      },
    },
  ];

  const [content, setContent] = useState();
  useEffect(() => {
    setContent(
      <DataGrid
        getRowId={(row) => row.index}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    );
  }, [props.patients]);

  const saveReportHandler = (data) => {
    props.onUpdateFields(data);

    if (data === "BACK") {
      setContent(
        <DataGrid
          getRowId={(row) => row.index}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      );
    } else if (data.error) {
    } else {
      console.log({ savedvitalsdata_uploadVitals: data });
    }
  };

  const openReportUpdate = (e, v) => {
    console.log(v);
    setContent(<ReportForm onAddReports={saveReportHandler} pDetails={v} />);
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <Grid container spacing={0} align="center">
          <Grid item lg={12} xs={12}>
            <Box style={{ height: "68vh", width: "100%" }}>
              <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>{content}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PrintPatientListLab;
