import { Box, Divider, Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import PrintHeader from "./printHeader";
import PrintPatientHeader from "./printPatientHeader";
import PrintVitals from "./printVitals";
import printHeader from "../../../assets/images/svg/printHeader.svg";
import printFooter from "../../../assets/images/svg/printFooter.svg";

const PrintMain = ({ token }) => {
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    getPatientByToken(token);
  }, [token]);

  const getPatientByToken = async (token) => {
    const url = BASE_URL + "healthcamp/getToken/" + token;

    const patientData = await getData(url);

    if (patientData.error) {
      console.log("error");
    } else {
      setPatient(patientData.data);
      console.log({ success: patientData.data });
    }
  };
  return (
    <Fragment>
      <Box>
        <Grid container sx={{ display: "flex" }}>
          <img src={printHeader} style={{ width: "100%" }} />
        </Grid>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="flex-start">
            <PrintHeader />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <PrintPatientHeader patient={patient} />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <PrintVitals patient={patient} />
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex" }}>
          <img
            src={printFooter}
            style={{ width: "100%", position: "fixed", bottom: 0 }}
          />
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PrintMain;
