import { Box, Grid } from "@mui/material";

const PrintHistoryComp =(props) => {

    let pHistory = "";
let  appointmentList = [];
let reportVMs = [];

if (props.pHistory) {

  pHistory = props.pHistory;
  appointmentList = pHistory.appointmentList;
  reportVMs = pHistory.reportVMs;
}
    return (
        <Box
        sx={{
          minHeight: "54vh",
          background: "#fff",
          borderRadius: 2,
        }}
      >
      { pHistory ? 
      
      <Grid container>


      <Grid item lg={12} xs={12} >

      <h6>Appointments</h6>

      <Grid container>

      <Grid item lg={2} xs={12} >Doctor Name</Grid>
      <Grid item lg={2} xs={12} >Symptom</Grid>
      <Grid item lg={2} xs={12} >Observation</Grid>
      <Grid item lg={2} xs={12} >First Consultation Date</Grid>
      <Grid item lg={2} xs={12} >Last Consultation Date</Grid>
      <Grid item lg={2} xs={12} >modeOfPayment</Grid>
  </Grid>

      {appointmentList ?
        appointmentList.map( (appoint,index) => (
          <Grid container key={index} >
          <Grid item lg={2} xs={12} > {appoint.docId} </Grid>
          <Grid item lg={2} xs={12} > {appoint.symptom} </Grid>
          <Grid item lg={2} xs={12} > {appoint.observation} </Grid>
          <Grid item lg={2} xs={12} > {appoint.firstConsultationDate} </Grid>
          <Grid item lg={2} xs={12} > {appoint.lastConsultationDate} </Grid>
          <Grid item lg={2} xs={12} > {appoint.modeOfPayment} </Grid>
          </Grid>
        ))
        : ""

      }
      

      

      </Grid>



      <Grid item lg={12} xs={12} >

      <h6>Reports</h6>

      <Grid container>

        <Grid item lg={2} xs={12} >Report Id</Grid>
        <Grid item lg={2} xs={12} >Report Name</Grid>
        <Grid item lg={2} xs={12} >Report Price</Grid>
        <Grid item lg={2} xs={12} >Report URL</Grid>
        <Grid item lg={2} xs={12} >Report Upload Date</Grid>   
        <Grid item lg={2} xs={12} >Sample Collection Date</Grid>

      </Grid>

      {reportVMs ?
        reportVMs.map( (report,index) => (
          <Grid container key={index} >
          <Grid item lg={2} xs={12} > {report.reportId} </Grid>
          <Grid item lg={2} xs={12} > {report.reportName} </Grid>
          <Grid item lg={2} xs={12} > {report.reportPrice} </Grid>
          <Grid item lg={2} xs={12} > {report.reportURL} </Grid>
          <Grid item lg={2} xs={12} > {report.reportUploadDate} </Grid>
          <Grid item lg={2} xs={12} > {report.sampleCollectionDate} </Grid>
          </Grid>
        ))
        : ""

      }

      </Grid>


      </Grid>
       
      
      : "No History available..."

      }
      

      </Box>
    );
}
 
export default PrintHistoryComp;