import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import { CustomIOSSwitch } from "../../comps/customIOSSwitch";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApiCalls";

const CustomTextDisplay = ({ flag, title }) => {
  return (
    <Fragment>
      {flag ? (
        <Typography sx={{ fontWeight: 600 }} variant="body2">
          {title}:&nbsp;
          <Box component="span" sx={{ color: "green" }}>
            YES
          </Box>
        </Typography>
      ) : (
        <Typography sx={{ fontWeight: 600 }} variant="body2">
          {title}:&nbsp;
          <Box component="span" sx={{ color: "red" }}>
            NO
          </Box>
        </Typography>
      )}
    </Fragment>
  );
};

const DisplayPackage = ({ selectedPackage }) => {
  const [formValues, setFormValues] = useState({
    isActive: selectedPackage.isActive,
  });

  const _submitHandler = async () => {
    const url = BASE_URL + `org/package`;

    const payload = {
      isActive: formValues.isActive,
      corpId: selectedPackage.corpId,
      id: selectedPackage.id,
    };

    const packageData = await saveData(url, payload);

    if (packageData.error) {
      console.log({ packageData_error: packageData.error });
      setSeverity("error");
      setMessage("Failed to save!");
      setOpenNotice(true);
    } else {
      console.log({ packageData_success: packageData.data });
      setSeverity("success");
      setMessage("saved successfully");
      setOpenNotice(true);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box sx={{ p: 1 }}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <CustomIOSSwitch
              title={"Is Active"}
              property={"isActive"}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay title="CBC" flag={selectedPackage?.cbc} />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay title="Urine" flag={selectedPackage?.urine} />
          </Grid>
          <Grid item lg={2} xs={6}>
            <CustomTextDisplay
              title="Fitness"
              flag={selectedPackage?.fitness}
            />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay title="Eye" flag={selectedPackage?.eye} />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay
              title="Audiometry"
              flag={selectedPackage?.audiometry}
            />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay title="PFT" flag={selectedPackage?.pft} />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay
              title="Test Code"
              flag={selectedPackage?.testCode}
            />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay
              title="Stool Sample"
              flag={selectedPackage?.stoolSample}
            />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay title="X-Ray" flag={selectedPackage?.xray} />
          </Grid>

          <Grid item lg={2} xs={6}>
            <CustomTextDisplay
              title="Serum Bilirubin"
              flag={selectedPackage?.sbilirubin}
            />
          </Grid>
          <Grid item lg={2} xs={12}></Grid>
          <Grid item lg={2} xs={12}></Grid>

          <Grid
            item
            lg={12}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Button
              onClick={_submitHandler}
              variant="contained"
              sx={{ width: 160 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DisplayPackage;
