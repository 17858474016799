import { FormControl, MenuItem, Select, Typography } from "@mui/material"
import React, { Fragment } from "react"

const CustomSelect = ({ item, formValues, setFormValues, genderData }) => {
  const handleChange = e => {
    const newFormValues = { ...formValues, [item.property]: e.target.value }
    setFormValues(newFormValues)
  }

  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#6B6B6B",
          marginBlock: "5px",
        }}>
        {item.label}
      </Typography>
      <FormControl fullWidth>
        <Select
          sx={{
            background: "#fff",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            paddingTop: "3px",
            width: "100%",
          }}
          size="small"
          displayEmpty
          value={formValues[item.property] || ""}
          onChange={handleChange}>
          <MenuItem value="" disabled>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#D3D3D3",
              }}>
              {item.placeHolder}
            </Typography>
          </MenuItem>
          {genderData?.map((d, i) => (
            <MenuItem key={i} value={d.value}>
              {d.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  )
}

export default CustomSelect
