import React, { Fragment, useRef } from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import calendar from "../../../../assets/images/calendar.png"
import call from "../../../../assets/images/call.png"
import printer from "../../../../assets/images/printer.png"
import maleIcon from "../../../../assets/images/maleIcon.png"
import femaleIcon from "../../../../assets/images/femaleIcon.png"
import dummyUserIcon from "../../../../assets/images/dummyUserIcon.png"
import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import PrintCard from "../../printCard/printCard"
import ShareIcon from "@mui/icons-material/Share"
import { useState } from "react"
import { useReactToPrint } from "react-to-print"
import printJS from "print-js"

const WaitingCard = ({ item }) => {
  const navigate = useNavigate()
  // const componentRef = useRef()

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // })

  const handleNavigation = () => {
    navigate("/patientDetailsAshaWorker", { state: item.allData })
  }

  const handleShare = url => {
    const message = encodeURIComponent(`Check out this link: ${url}`)
    const whatsappUrl = `https://wa.me/?text=${message}`

    window.open(whatsappUrl, "_blank")
  }

  const printPDFFile = url => {
    const printWindow = window.open(url, "_blank")
    printWindow.addEventListener("load", () => {
      printWindow.print()
    })
  }

  return (
    <Fragment>
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.15)",
          borderRadius: "15px",
          padding: "10px",
          marginY: "10px",
        }}>
        <Grid item xs={4.5} display="flex">
          <Box
            component="img"
            src={item.userIcon ? item.userIcon : dummyUserIcon}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
            alt="Icon"
          />
          <Grid item style={{ paddingLeft: "10px" }}>
            <Typography
              sx={{
                marginTop: "5px",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#404040",
              }}>
              {item.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2.5} display="flex">
          <Box
            component="img"
            src={item.gender === "MALE" ? maleIcon : femaleIcon}
            style={{ width: 15, height: 15 }}
            alt="Icon"
          />
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#404040",
              paddingLeft: "10px",
            }}>
            {item.gender}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right" }}>
          <Box sx={{ marginLeft: "10px", display: "flex" }}>
            <Box component="img" src={calendar} style={{ width: 15, height: 15 }} alt="Icon" />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#404040",
                paddingLeft: "5px",
              }}>
              {item.dob}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} textAlign="right" sx={{ marginTop: "7px" }}>
          <IconButton onClick={handleNavigation}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>

        <Grid
          xs={12}
          item
          sx={{
            justifyContent: "Space-Between",
            display: "flex",
          }}>
          <Grid
            item
            display="flex"
            sx={{ backgroundColor: "#CFFFCE", borderRadius: "7.5px", padding: "10px" }}>
            <Box
              component="img"
              src={call}
              style={{ width: 15, height: 15, marginRight: "5px" }}
              alt="Icon"
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#20945C",
              }}>
              {item.mobile}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => printPDFFile(item.allData.icardUrl)}
              sx={{
                backgroundColor: "#ECF3FF",
                borderRadius: "7.5px",
                textTransform: "capitalize",
              }}>
              <Box
                component="img"
                src={printer}
                style={{ width: 15, height: 15, marginRight: "5px" }}
                alt="Icon"
              />
              Print
            </Button>
            <IconButton onClick={() => handleShare(item.allData.icardUrl)}>
              <ShareIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box ref={componentRef}>
          <PrintCard data={item.allData} />
        </Box>
      </Box> */}
    </Fragment>
  )
}

export default WaitingCard
