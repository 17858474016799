import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import CustomTextFieldWithTitle from "../../../components/formComps/customTextFieldWithTitle";

const BasicDetails = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"Company Official Name"}
            placeholder={"Enter Company Name"}
            value={formValues.corpName || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, corpName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"Company Address"}
            placeholder={"Enter Company Address"}
            value={formValues.address}
            onChange={(e) =>
              setFormValues({ ...formValues, address: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"Unocare SPOC"}
            placeholder={"Enter Unocare SPOC"}
            value={formValues.unoSPOC || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, unoSPOC: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"CORP SPOC Name"}
            placeholder={"Enter CORP SPOC Name"}
            value={formValues.corpSPOC || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, corpSPOC: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"CORP SPOC Mobile"}
            placeholder={"Enter CORP SPOC Mobile"}
            value={formValues.corpSPOCMobile || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, corpSPOCMobile: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomTextFieldWithTitle
            title={"GST No For Billing"}
            placeholder={"Enter GST No"}
            value={formValues.gst || ""}
            onChange={(e) => {
              setFormValues({ ...formValues, gst: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BasicDetails;
