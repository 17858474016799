import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const _BRANCHES = {
  Pithampur: "fe355d95-721b-4d36-b5b1-4b7c9a2290e7",
  Gautampura: "16fe1752-adfc-41c0-9df1-238c4309fb11",
  Ghatabillod: "ec26badd-1353-4301-a5db-897fa37ee8a8",
  Banedia: "32ea4782-7306-492c-b9fd-35f063fc6914",
  Depalpur: "0326e37c-c7fe-4d6a-8618-ae5cd70aa5a9",
};

const other = [
  {
    address: "Other",
    addressType: "VILLAGE",
    branchId: null,
    id: null,
    isActive: true,
    pincode: null,
  },
];

const SurveyLocation = ({
  formValues,
  setFormValues,
  location = localStorage.getItem("SURVEY_LOCATION"),
  branchLocation = localStorage.getItem("CLINIC_LOCATION"),
}) => {
  const [value, setValue] = useState(null);

  console.log({ location321: location });

  useEffect(() => {
    const getData = setTimeout(() => {
      setFormValues({ ...formValues, surveyLocation: value?.address || "" });
      localStorage.setItem("SURVEY_LOCATION", value?.address || "");
    }, 10);

    return () => clearTimeout(getData);
  }, [value]);

  const [villageList, setVillageList] = useState([]);
  const _fetchVillageList = async () => {
    const branchId = _BRANCHES[branchLocation];

    console.log({ branchId });
    const url = BASE_URL + `healthcamp/village?branchId=${branchId}`;

    const villages = await getData(url);

    if (villages.data) {
      const list = [...villages.data, ...other];
      setVillageList(list);
      setValue(
        location ? list.filter((val) => val.address === location)[0] : null
      );
    } else {
      setVillageList([]);
    }
  };

  useEffect(() => {
    _fetchVillageList();
  }, [branchLocation]);

  console.log({ villageList });

  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={12}>
          <Typography>Survey Location:</Typography>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Autocomplete
            value={value || null}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            id="villages"
            options={villageList}
            getOptionLabel={(option) => option.address}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.address}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="" size="small" />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default SurveyLocation;
