import { Box, Chip, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import PaymentComponentPharmacy from "./dataGridComponents/paymentComponentPharmacy";
import ViewPrescriptionComponent from "./viewPrescription/viewPrescriptionComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentComponent from "../common/paymentComponent/paymentComponent";

const PatientListPharmacyUpcoming = (props) => {
  const rows = props.patients.filter(
    (patient, index) => patient.attended !== true
  );

  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      type: "number",
      width: 100,
    },
    {
      field: "patientName",
      headerAlign: "center",
      align: "center",
      headerName: "PATIENT",
      width: 300,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "mobileNo",
      headerAlign: "center",
      align: "center",
      headerName: "CONTACT NO.",
      width: 200,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "printPrescription",
      headerAlign: "center",
      align: "center",
      headerName: "PRESCRIPTION",
      width: 160,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            disabled={cellValues.value.directBooking}
            color={cellValues.value.directBooking ? "default" : "primary"}
            icon={<VisibilityIcon />}
            size="small"
            variant="contained"
            label="View"
            clickable
            onClick={() => viewPrescription(cellValues.value)}
          />
        );
      },
    },
    {
      field: "paymentVM",
      headerAlign: "center",
      align: "center",
      headerName: "PAYMENT STATUS",
      width: 140,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <PaymentComponent
            root={props.root}
            paymentVM={cellValues.value}
            portal="PHARMACY"
            portalId={localStorage.getItem("PHARMACY_ID")}
          />
        );
      },
    },
  ];

  const viewPrescriptionHandler = (data) => {
    setContent(
      <DataGrid
        getRowId={(row) => row.index}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    );
  };

  const viewPrescription = (data) => {
    setContent(
      <ViewPrescriptionComponent
        patientDetails={data}
        onGoBack={viewPrescriptionHandler}
      />
    );
  };

  useEffect(() => {
    setContent(
      <DataGrid
        getRowId={(row) => row.index}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    );
  }, [props.patients]);
  const [content, setContent] = useState(
    <DataGrid
      getRowId={(row) => row.index}
      rows={rows}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
    />
  );

  return (
    <Container maxWidth={false} sx={{ overflow: "auto", maxHeight: "68vh" }}>
      <Box>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Box style={{ height: "68vh", width: "100%" }}>
              <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>{content}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PatientListPharmacyUpcoming;
