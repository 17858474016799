import { BASE_URL } from "../../../../assets/constantsFile";

export const TextFieldList = [
  {
    index: 1,
    title: "Height (cm)",
    property: "height",
    visibility: true,
  },
  {
    index: 2,
    title: "Weight (Kgs)",
    property: "weight",
    visibility: true,
  },
  {
    index: 3,
    title: "BP(mmHg)",
    property: "bp",
    visibility: true,
  },
  {
    index: 4,
    title: "RBS",
    property: "sugar",
    visibility: true,
  },
  {
    index: 5,
    title: "Body built",
    property: "bodyBuilt",
    visibility: false,
  },
  {
    index: 6,
    title: "Hair",
    property: "hairs",
    visibility: false,
  },
  {
    index: 7,
    title: "Nails",
    property: "nails",
    visibility: false,
  },
  {
    index: 8,
    title: "Blood Group",
    property: "bloodGroup",
    visibility: true,
  },
];

export const SwitchFieldList = (values, selectedPackage) => {
  return [
    {
      index: 45,
      title: "ECG",
      property: "ecg",
      display: false,
    },
    {
      index: 13,
      title: "X-Ray",
      property: "xrayDone",
      display: true,
    },
    {
      index: 9,
      title: "Blood Sample",
      property: "bloodSampleCollected",
      display: true,
    },
    {
      index: 10,
      title: "Urine Sample",
      property: "urineSampleCollected",
      display: false,
    },
    {
      index: 17,
      title: "Stool Sample",
      property: "stoolSampleCollected",
      display: false,
    },
    {
      index: 11,
      title: "Audiometry",
      property: "audiometryDone",
      display: false,
    },
    {
      index: 12,
      title: "PFT",
      property: "pft",
      display: false,
    },
    {
      index: 18,
      title: "Eye Test",
      property: "eyeTest",
      display: false,
    },
    {
      index: 1,
      title: "Glasses Required",
      property: "glass",
      display: false,
    },
    {
      index: 2,
      title: "Cataract",
      property: "cataract",
      display: false,
    },
    {
      index: 3,
      title: "Smoking",
      property: "smoking",
      display: false,
    },
    {
      index: 4,
      title: "Alcohol",
      property: "alchohol",
      display: false,
    },
    {
      index: 5,
      title: "Pan Chewing",
      property: "panChewing",
      display: false,
    },
    {
      index: 6,
      title: "Gambling",
      property: "gambling",
      display: false,
    },
    {
      index: 7,
      title: "Drug Addiction",
      property: "drugAddiction",
      display: false,
    },
    {
      index: 8,
      title: "Eye Operation",
      property: "eyeOperation",
      display: false,
    },

    {
      index: 14,
      title: "CBC",
      property: "cbc",
      display: false,
    },
    {
      index: 15,
      title: "Serum Bilirubin",
      property: "serumBilirubin",
      display: false,
    },
    {
      index: 16,
      title: "Fitness Certificate",
      property: "fitnessCertificate",
      display: false,
    },

    {
      index: 44,
      title: "Fit to Work",
      property: "fitToWork",
      display: false,
      //values?.display?.eyeTest ? true : false,
    },
  ];
};

export const UploadFieldList = (formValues, corpId) => {
  return [
    {
      index: 1,
      title: "Take photo",
      property: "imageUrl",
      url:
        BASE_URL +
        `org/profilePic/upload?empId=${formValues.empId}&corpId=${corpId}`,

      display: true,
    },

    {
      index: 2,
      title: "Record PRESCRIPTION",
      property: "prescriptionUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PRESCRIPTION&corpId=${corpId}`,

      display: false,
    },
    {
      index: 3,
      title: "Additional Document",
      property: "miscellaneousUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=MISCELLANEOUS&corpId=${corpId}`,

      display: false,
    },
    {
      index: 14,
      title: "ECG",
      property: "ecgUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=ECG&corpId=${corpId}`,

      display: false,
    },
    {
      index: 7,
      title: "X-Ray Document",
      property: "xrayUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=XRAY&corpId=${corpId}`,
      display: true,
    },
    {
      index: 4,
      title: "Blood Test ",
      property: "bloodTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=BLOODTEST&corpId=${corpId}`,

      display: true,
    },
    {
      index: 5,
      title: "Urine Test Document",
      property: "urineTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=URINETEST&corpId=${corpId}`,

      display: false,
    },
    {
      index: 6,
      title: "Audiometry Document",
      property: "audiometryUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=AUDIOMETRY&corpId=${corpId}`,

      display: false,
    },

    {
      index: 8,
      title: "Fitness Certificate",
      property: "fitnessCertificateUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=FITNESS_CERTIFICATE&corpId=${corpId}`,
      display: false,
    },
    {
      index: 9,
      title: "Serum Bilirubin",
      property: "serumBilirubinUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=SERUM_BILIRUBIN&corpId=${corpId}`,
      display: false,
    },

    {
      index: 11,
      title: "PFT Document",
      property: "pftUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=PFT&corpId=${corpId}`,
      display: false,
    },
    {
      index: 10,
      title: "Record Eye Report",
      property: "eyeTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=EYE_TEST&corpId=${corpId}`,
      display: false,
    },
    {
      index: 12,
      title: "CBC Document",
      property: "cbcUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=CBC&corpId=${corpId}`,
      display: false,
    },
    {
      index: 13,
      title: "Stool Test Document",
      property: "stoolTestUrl",
      url:
        BASE_URL +
        `org/upload?empId=${formValues.empId}&fileType=STOOLTEST&corpId=${corpId}`,
      display: false,
    },
  ];
};
