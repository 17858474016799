import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { Fragment } from "react";

const DrugHistoryFormComp = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Autocomplete
              freeSolo
              size={"small"}
              fullWidth
              disableClearable
              selectOnFocus
              value={formValues.pDrugHistory || ""}
              onChange={(e, value) => {
                console.log(value);
              }}
              onInputChange={(e, value) => {
                setFormValues({ ...formValues, pDrugHistory: value });
              }}
              options={["Not Available"].map((option) => option)}
              renderInput={(params) => (
                <TextField
                  size={"small"}
                  variant="outlined"
                  {...params}
                  label="Drug History:"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DrugHistoryFormComp;
