import { Typography, colors } from "@mui/material"

export const TypographyTitle = ({ children }) => (
  <Typography
    sx={{
      // fontFamily: "Roboto",
      // fontStyle: "normal",
      marginBlock: "10px",
      fontWeight: "600",
      fontSize: 13,
      lineHeight: "15px",
      textAlign: "center",
      color: "#404040",
    }}>
    {children}
  </Typography>
)
