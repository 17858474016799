import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useCallback, useEffect, useState } from "react";
import { getData } from "../../../services/api/getApiCalls";
import { BASE_URL, paymentMode } from "../../../assets/constantsFile";
import { saveData } from "../../../services/api/postApiCalls";
import ReportedIssue from "../appointmetFormElements/reportedIssue";
import { VITALS_SUGAR } from "../../../assets/constantsText";
const ResumeConsultationregistrationForm = (props) => {
  const patientId = props.patientId;

  const [patientData, setPatientData] = useState(props.patientData);
  console.log({ patientData });

  const [specializationList, setSpecializationList] = useState([]);
  const [listOfDoctors, setListOfDoctors] = useState([]);
  const [docSlots, setDocSlots] = useState([]);
  const [dateValue, setDateValue] = useState(new Date());
  const [availablePositionKey, setAvailablePositionKey] = useState("");
  const [availablePosition, setAvailablePosition] = useState(null);

  const [cDoctor, setCDoctor] = useState("");
  const [cSlotNo, setCSlotNo] = useState("");
  const [cAmmountPayable, setCAmmountPayable] = useState(0);
  const [cAmmountReceived, setCAmmountReceived] = useState(0);

  let isDocaValue = false;

  useEffect(() => {
    let doc = listOfDoctors.find(checkcurrentDoc);
    doc ? setCDoctor(doc) : (isDocaValue = !isDocaValue);
    doc ? setDocSlots(doc.slots) : setDocSlots([]);
    doc ? setCAmmountPayable(doc.fees) : setCAmmountPayable(0);

    console.log({ listOfDoctors_doc: doc });
  }, [listOfDoctors]);

  let flag = true;

  const checkcurrentDoc = (doc) => {
    isDocaValue = !isDocaValue;

    return doc.docId === patientData.docId;
  };

  console.log({ listOfDoctors: listOfDoctors });

  const [formDataClinic, setFormDataClinic] = useState({
    cIssue: patientData.issue,
    cSpeciality: patientData.doctorSpeciality,
    cDOA: new Date(),
    cPayMode: "CASH",
    cStartTime: "",
    cEndTime: "",
    bp: "",
    heartRate: "",
    height: "",
    spo2: "",
    weight: "",
    temperature: "",
    sugar: "",
  });

  let handleChangeClinic = (e) => {
    setFormDataClinic({ ...formDataClinic, [e.target.name]: e.target.value });
  };

  const fetchSpecializationHandler = useCallback(async () => {
    const user = await getData(
      BASE_URL +
        "clinic/getSpecializations/" +
        localStorage.getItem("BRANCH_ID")
    );

    if (user.error) {
    } else {
      const data1 = await user.data;
      setSpecializationList(data1);
      console.log({ setSpecializationList: data1 });
    }
  }, []);

  useEffect(() => {
    fetchSpecializationHandler();
  }, [fetchSpecializationHandler, props.patientData]);

  const fetchDoctorListHandler = useCallback(async (sp, d) => {
    const user = await getData(
      BASE_URL +
        "clinic/searchDoctor/" +
        sp +
        "?date=" +
        d +
        "&branchId=" +
        localStorage.getItem("BRANCH_ID")
    );
    const data1 = await user.data;
    setListOfDoctors(data1);
  }, []);
  console.log({ Resume_ListOfDoctors: listOfDoctors });
  useEffect(() => {
    fetchDoctorListHandler(
      formDataClinic.cSpeciality,
      dateValue.toISOString().split("T")[0]
    );

    setCDoctor("");
    setCSlotNo("");
    setDocSlots([]);
  }, [dateValue, formDataClinic.cSpeciality]);

  const submitHandlerC = (event) => {
    event.preventDefault();
    //dateValue.toISOString().split("T")[0]
    const pObj = {
      docId: cDoctor.docId,
      clinicId: localStorage.getItem("CLINIC_ID"),
      patientId: patientId,
      paymentRequired: true,
      issue: issues.symptom,
      startTime: cSlotNo ? cSlotNo.startTime : "",
      endTime: cSlotNo ? cSlotNo.endTime : "",
      date: formDataClinic.cDOA.toISOString().split("T")[0],
      observation: "",
      slotNo: cSlotNo ? cSlotNo.slotNo : 0,
      consultationType: "SCHEDULED",
      caseId: patientData.caseId,
      paymentVM: {
        appointmentSource: "CLINIC",
        amountPayable: cDoctor.fees,
        amountCollected: 0,
        finalAmountPayable: cDoctor.fees,
        branchId: localStorage.getItem("BRANCH_ID"),
      },
      appointmentSource: "CLINIC",
      videoURL: "",
      vitalsVM: {
        patientId: patientId,
        bp: formDataClinic.bp,
        spo2: formDataClinic.spo2,
        heartRate: formDataClinic.heartRate,
        temperature: formDataClinic.temperature,
        height: formDataClinic.height,
        weight: formDataClinic.weight,
        sugar: formDataClinic.sugar,
      },
      previousPaymentValid: patientData.previousPaymentValid,
      paymentValidNoOfDays: cDoctor.paymentValidNoOfDays,
    };
    console.log({ "myobj to be saved": pObj });
    if (patientId && flag) {
      flag = false;
      bookAppointment(pObj, cDoctor);
    }

    console.log({ formDataClinic: formDataClinic });
  };
  const bookAppointment = async (obj, doctor) => {
    const bApp = await saveData(BASE_URL + "clinic/appointment", obj);

    props.onResumeConsult(bApp, doctor);

    console.log({ formDataClinic_afterSubmit: bApp.data });
  };

  const backToCaseTable = () => {
    props.onResumeConsult("BACK");
  };

  console.log({ formDataClinic_availablePositionKey: availablePositionKey });
  console.log({
    "formDataClinic_formDataClinic.availablePosition": availablePosition
      ? availablePosition[availablePositionKey]
      : "",
  });
  console.log({ "formDataClinic_formDataClinic.cSlotNo": cSlotNo });

  const [issues, setIssues] = useState({
    id: null,
    symptom: patientData.issue,
  });

  console.log({ formDataClinic_data: formDataClinic });

  console.log({ issues });
  return (
    <form id="consultReg" onSubmit={submitHandlerC}>
      <Box
        sx={{
          minHeight: "47vh",
          background: "#F3F3F3",
          borderRadius: 2,
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} sm={12} md={12}>
            <ReportedIssue issues={issues} setIssues={setIssues} />
          </Grid>

          <Grid item xs={12} lg={3} sm={12} md={12}>
            <Autocomplete
              size={"small"}
              fullWidth
              freeSolo
              id="specializationList"
              name="cSpeciality"
              value={formDataClinic.cSpeciality}
              disableClearable
              onChange={(event, newValue) => {
                setFormDataClinic({
                  ...formDataClinic,
                  cSpeciality: newValue,
                });
              }}
              options={specializationList.filter((ele) => {
                return ele !== null;
              })}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Doctor's Specialization" />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={3} sm={12} md={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd MMM yyyy"
                disableMaskedInput={true}
                disablePast
                label="Date"
                openTo="day"
                views={["year", "month", "day"]}
                value={formDataClinic.cDOA}
                name="cDOA"
                onChange={(newValue) => {
                  setFormDataClinic({
                    ...formDataClinic,
                    cDOA: newValue,
                  });
                  setDateValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    sx={{ fontSize: 10 }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} lg={4} sm={12} md={12}>
            <Autocomplete
              size={"small"}
              freeSolo
              id="doctorSearch"
              name="cDoctor"
              value={cDoctor}
              disableClearable
              options={listOfDoctors}
              getOptionLabel={(option) =>
                (option.firstName ? option.firstName : "") +
                " " +
                (option.middleName ? option.middleName : "") +
                " " +
                (option.lastName ? option.lastName : "")
              }
              onChange={(event, value) => {
                setCDoctor(value);
                setCSlotNo("");
                console.log({ cDoctor: cDoctor });
                value ? setDocSlots(value.slots) : setDocSlots([]);
                value ? setCAmmountPayable(value.fees) : setCAmmountPayable(0);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  {...params}
                  label="Doctor's Name"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={12} md={12}>
            <Autocomplete
              size={"small"}
              freeSolo
              id="slotBooking"
              name="cSlotNo"
              value={cSlotNo}
              disableClearable
              options={docSlots}
              getOptionLabel={(option) =>
                option ? option.startTime + " to " + option.endTime : ""
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.startTime} to {option.endTime}
                </Box>
              )}
              onChange={(event, value) => {
                setCSlotNo(value);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  {...params}
                  label="Choose a slot"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            {cSlotNo && cSlotNo.startTime ? (
              <TextField
                fullWidth
                disabled
                size="small"
                label="Start Time"
                variant="outlined"
                value={cSlotNo.startTime}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            {cSlotNo && cSlotNo.endTime ? (
              <TextField
                fullWidth
                disabled
                size="small"
                label="End Time"
                variant="outlined"
                value={cSlotNo.endTime}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider>Vitals</Divider>
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="bp"
              label="BP"
              variant="outlined"
              value={formDataClinic.bp || ""}
              onChange={(e) => handleChangeClinic(e)}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mmHg</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="heartRate"
              label="Heart Rate"
              variant="outlined"
              value={formDataClinic.heartRate}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">bpm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="height"
              label="Height"
              variant="outlined"
              value={formDataClinic.height}
              onFocus={(el) => {
                el.target.select();
              }}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="spo2"
              label="SpO2"
              variant="outlined"
              value={formDataClinic.spo2}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="weight"
              label="Weight"
              variant="outlined"
              value={formDataClinic.weight}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="temperature"
              label="Temperature"
              variant="outlined"
              value={formDataClinic.temperature}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">F</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2} sm={12} md={12}>
            <TextField
              size="small"
              fullWidth
              name="sugar"
              label={VITALS_SUGAR}
              variant="outlined"
              value={formDataClinic.sugar}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleChangeClinic(e);
                }
              }}
              onFocus={(el) => {
                el.target.select();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mmol/L</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: "5vh",
          background: "#fff",
          p: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={backToCaseTable}>
              back
            </Button>
            <Button sx={{ ml: 2 }} variant="contained" type="submit">
              book appointment
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default ResumeConsultationregistrationForm;
