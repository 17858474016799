import { Box } from "@mui/material";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import MyAppBarAfterLogin from "../../header/myAppBarAfterLogin";
import DooctorInfoSection from "../doctorInfo/dooctorInfoSection";
import CallSection from "./callSection";

const VideoCallMain = () => {
  const location = useLocation();
  const { calls } = location.state;
  const [toggleCaller, setToggleCaller] = useState(true);
  const [openStetho, setOpenStetho] = useState(false);
  return (
    <Fragment>
      <Box>
        <MyAppBarAfterLogin />
        <DooctorInfoSection
          docDetails={calls.docDetails}
          patient={calls}
          toggleCaller={toggleCaller}
          setToggleCaller={setToggleCaller}
          openStetho={openStetho}
          setOpenStetho={setOpenStetho}
        />
        <CallSection
          patient={calls}
          toggleCaller={toggleCaller}
          openStetho={openStetho}
        />
      </Box>
    </Fragment>
  );
};
export default VideoCallMain;
