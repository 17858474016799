import { Stack, TextField } from "@mui/material";
import { CustomTypography } from "./customTypography";
import ReactInputMask from "react-input-mask";

export const TextFieldItem = ({
  title,
  property,
  formValues,
  setFormValues,
}) => {
  return (
    <Stack>
      <CustomTypography>{title}</CustomTypography>

      {property === "bp" ? (
        <ReactInputMask
          mask="999/999"
          value={formValues[property] || ""}
          onChange={(e) => {
            let newFormValues = { ...formValues };
            newFormValues[property] = e.target.value;
            setFormValues(newFormValues);
          }}
          maskChar=" "
        >
          {() => <TextField size="small" variant="outlined" />}
        </ReactInputMask>
      ) : (
        <TextField
          size="small"
          variant="outlined"
          value={formValues[property] || ""}
          onChange={(e) => {
            let newFormValues = { ...formValues };
            newFormValues[property] = e.target.value;
            setFormValues(newFormValues);
          }}
        />
      )}
    </Stack>
  );
};
