import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import CardImg4 from "../assets/images/svg/card4.svg";
import CardImg5 from "../assets/images/svg/card5.svg";
import CardImg6 from "../assets/images/svg/card6.svg";


const AvailableDoctorsComponent = () => {

        return (
<Box sx={{ width: "100%", pt: 4,  }}>
  <Typography variant="h3" sx={{mb:3}}>Available Doctors</Typography>

  <Grid container columnSpacing={2} sx={{minHeight:100}} alignItems='center'>

    <Grid item lg={4}>
    <Box component="img"
      alt="card1"
      src={CardImg4}
      sx={{ 
        height:235,
        width:262,
        background: 'rgba(255, 255, 255, 0.75)',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }} 
    />
<Typography variant="body2">Pediatrician</Typography>
    </Grid>

    <Grid item lg={4}>

    <Box component="img"
      alt="card1"
      src={CardImg5}
      sx={{ 
        height:372,
        width:399,
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }}  
    />
   <Typography variant="body2">General Physician</Typography>
    </Grid>

    <Grid item lg={4}>

    <Box component="img"
      alt="card1"
      src={CardImg6}
      sx={{ 
        height:235,
        width:262,
        background: 'rgba(255, 255, 255, 0.75)',
        boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }} 
    />
<Typography variant="body2">Gynaecologist</Typography>
    </Grid>

  </Grid>
</Box>
        );

}
 
export default AvailableDoctorsComponent;