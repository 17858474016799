import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, Stack } from "@mui/material";
import EChokitsalayaLogo from "../logoNew.svg";
import LogoMain from "../common/logo/logoMain";
import { useEffect } from "react";

const MyAppBarAfterLogin = () => {
  let pages = [];
  // let reg =
  //   localStorage.getItem("BRANCH_ID") ===
  //   "25216457-cdb6-4b79-9c55-0e8219f89917"
  //     ? { name: "registration new", url: "/registrationnew" }
  //     : { name: "registration", url: "/registration" };

  // useEffect(() => {
  //   reg =
  //     localStorage.getItem("BRANCH_ID") ===
  //     "25216457-cdb6-4b79-9c55-0e8219f89917"
  //       ? { name: "registration new", url: "/registrationnew" }
  //       : { name: "registration", url: "/registration" };
  // }, [localStorage.getItem("BRANCH_ID")]);

  const reg = { name: "registration", url: "/registration" };

  if (localStorage.getItem("ROLE") === "ADMIN") {
    pages = [
      reg,
      { name: "clinic", url: "/clinicDashboard" },
      { name: "lab", url: "/labDashboard" },
      { name: "pharmacy", url: "/pharmacyDashboard" },
      { name: "payments", url: "/payment" },
      { name: "branch", url: "/branchname" },
      { name: "doctor registration", url: "/docregistration" },
      { name: "history", url: "/patientHistory" },
      { name: "logout", url: "/logout" },
    ];
  } else if (localStorage.getItem("ROLE") === "CLINIC") {
    pages = [
      reg,
      { name: "clinic", url: "/clinicDashboard" },
      { name: "payments", url: "/payment" },
      { name: "branch name", url: "/branchname" },
      { name: "logout", url: "/" },
    ];
  } else if (localStorage.getItem("ROLE") === "LAB") {
    pages = [
      reg,
      { name: "lab", url: "/labDashboard" },
      { name: "payments", url: "/payment" },
      { name: "branch name", url: "/branchname" },
      { name: "logout", url: "/" },
    ];
  } else if (localStorage.getItem("ROLE") === "PHARMACY") {
    pages = [
      reg,
      { name: "pharmacy", url: "/pharmacyDashboard" },
      { name: "payments", url: "/payment" },
      { name: "branch name", url: "/branchname" },
      { name: "logout", url: "/" },
    ];
  } else {
    pages = [
      reg,
      { name: "clinic", url: "/clinicDashboard" },
      { name: "lab", url: "/labDashboard" },
      { name: "pharmacy", url: "/pharmacyDashboard" },
      { name: "payments", url: "/payment" },
      { name: "branch", url: "/branchname" },
      { name: "doctor registration", url: "/docregistration" },
      { name: "history", url: "/patientHistory" },
      { name: "logout", url: "/" },
    ];
  }

  const settings = [
    { name: "Profile", url: "/profiles" },
    { name: "Logout", url: "/logout" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            underline="none"
            href="/"
            sx={{ mr: 2, display: { xs: "none", lg: "flex" } }}
          >
            <LogoMain />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    underline="none"
                    variant="button"
                    color="text.primary"
                    href={page.url}
                    sx={{ my: 1, mx: 1.5 }}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link
            href="/"
            sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
          >
            <LogoMain />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              mr: 2,
            }}
          >
            {pages.map((page) => (
              <Button
                href={page.url}
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyAppBarAfterLogin;
