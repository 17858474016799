import { TextField, Typography } from "@mui/material"
import React, { Fragment } from "react"

const CustomMultilineTextField = ({ item, formValues, setFormValues }) => {
  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#6B6B6B",
          marginBlock: "5px",
        }}>
        {item.label}
      </Typography>

      <TextField
        multiline
        sx={{
          background: "#fff",
          color: "#127DDD",
          fontWeight: "300",
          fontSize: "13px",
          lineHeight: " 15px",
          "& input::placeholder": {
            color: "#777777",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
          },
        }}
        size="small"
        fullWidth
        placeholder={item.placeHolder}
        value={formValues[item.property] || ""}
        onChange={e => {
          let newFormValues = { ...formValues }
          newFormValues[item.property] = e.target.value
          setFormValues(newFormValues)
        }}
        onInput={e => {
          e.target.style.color = "#127DDD"
        }}
        inputProps={{
          style: {
            height: 100,
            color: "#777777",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
          },
        }}
      />
    </Fragment>
  )
}

export default CustomMultilineTextField
