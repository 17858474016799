import { Fragment } from "react";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import UserOnboarding from "../../marketing/userOnboarding";
import { CssBaseline } from "@mui/material";
import UserOnBoardingNew from "../../marketing/userOnboarding/userOnBoardingNew";

const userOnboardingMK = () => {
  return (
    <Fragment>
      <CssBaseline />
      <NavigationBarMarketing />
      <UserOnBoardingNew />
    </Fragment>
  );
};

export default userOnboardingMK;
