import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import BackButton from "../../components/backButton";

const CorpSelectMain = () => {
  const navigate = useNavigate();
  const [selectedCorp, setSelectedCorp] = useState(null);
  const [corpListOptions, setCorpListOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchCorps = async () => {
    setIsLoading(true);
    const url = BASE_URL + "org/corpConfigInfo/all";
    const response = await getData(url);
    if (response.data) {
      setIsLoading(false);
      const temp = response?.data
        ?.filter(
          (item, index, self) =>
            self.findIndex((t) => t.corpName === item.corpName) === index
        )
        ?.map((item) => ({
          corpId: item.corpId,
          corpName: item.corpName,
        }));
      setCorpListOptions(temp);
    } else {
      setIsLoading(false);
      console.error({ ERROR: response.error });
      setCorpListOptions([]);
    }
  };

  useEffect(() => {
    fetchCorps();
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    localStorage.removeItem("CORP_ID");
    localStorage.removeItem("CORP_NAME");
    setSelectedCorp(newValue);
    localStorage.setItem("CORP_ID", newValue.corpId);
    localStorage.setItem("CORP_NAME", newValue.corpName);
    navigate("/corp/home");
  };

  console.log({ corpListOptions });

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <BackButton url="/corp/home" />
      <Typography
        sx={{
          textAlign: "center",
          color: "#000",
          marginTop: "-30px",
          fontSize: "16px",
          fontWeight: "600",
          marginBottom: "20px",
        }}
      >
        Select Corp
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        options={corpListOptions}
        getOptionLabel={(option) => option.corpName}
        value={selectedCorp}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              background: "#fff",
              color: "#127DDD",
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#000000",
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            variant="outlined"
            placeholder="Select Corp"
            label="Select Corp"
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "Search Corp",
            }}
          />
        )}
      />
    </Box>
  );
};
export default CorpSelectMain;
