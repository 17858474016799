import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Fragment } from "react";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelAppointmentForm from "../cancelAppointment/cancelAppointmentForm";

const CancelAppointmentComponent = ({ pDetails, tabClick, setValue }) => {
  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const cancelAppointmentHandler = (data) => {
    handleClose();

    if (data === "CANCEL") {
    } else {
      if (data.error) {
      } else {
      }
    }
    console.log({ savedvitalsdata_uploadVitals: data });
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<DeleteIcon />}
        sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
      ></Button>

      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="Reports"
        aria-describedby="report form"
      >
        <DialogTitle id="alert-dialog-title">
          {pDetails.patientName}
          {"'s Appointment"}
        </DialogTitle>
        <DialogContent dividers>
          <CancelAppointmentForm
            tabClick={tabClick}
            setValue={setValue}
            onCancelAppointment={cancelAppointmentHandler}
            pDetails={pDetails}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CancelAppointmentComponent;
