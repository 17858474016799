import { TextField } from "@mui/material";

const UserNameComp = ({ userName, setUserName }) => {
  return (
    <TextField
      fullWidth
      size="medium"
      required
      label="User Name"
      autoComplete="userName"
      autoFocus
      value={userName || ""}
      onChange={(e) => {
        setUserName(e.target.value);
      }}
      InputProps={{
        style: { color: "#127DDD" },
      }}
      InputLabelProps={{
        style: { fontSize: ".875rem", color: "#127DDD" },
      }}
    />
  );
};

export default UserNameComp;
