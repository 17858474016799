import { useState, Fragment, useEffect, useContext } from "react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
    TextField,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import AgeElement from "../../healthcamp/registration/formComps/ageElement";
import DOBElement from "../../healthcamp/registration/formComps/dobElement";
import NameElement from "../../healthcamp/registration/formComps/nameElement";
import PhoneNumberElement from "../../healthcamp/registration/formComps/phoneNumberElement";
import RelationElement from "../../healthcamp/registration/formComps/relationElement";
import GenderElement from "../../healthcamp/registration/formComps/genderElement";
import CaseUpload from "../caseUpload";

import ActionHC from "../../healthcamp/common/action/actionHC";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import { getData } from "../../services/api/getApiCalls";
import { saveData } from "../../services/api/postApiCalls";
import TitleHC from "../../healthcamp/common/title/titleHC";
import { BASE_URL } from "../../assets/constantsFile";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../common/context/usercontext";

// Form component
const FamilyHistoryForm = ({
    searchMobile,
    setSearchMobile,
    searchMobileHandler,
    isPatient,
    setisPatient,
    patient,
    title,
    isFirstLoad,
}) => {
    const [noticeData, setNoticeData] = useState({
        severity: "info",
        message: "",
        openNotice: false,
    });
    const [formValues, setFormValues] = useState([{ dob: null }]);
    const [key, setKey] = useState("");

    const [age, setAge] = useState("");
    const [dob, setDOB] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        console.log({ useEffect: patient });
        setFormValues(patient && patient.patientInfoVMList ? patient.patientInfoVMList : []);
    }, [patient]);

    const validate = (data) => {
        return data.filter((item, index) => item.name);
    };

    let addFormFields = () => {
        setFormValues([...formValues, { dateOfBirth: null }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const clearData = () => {
        setisPatient(false);
        setFormValues([]);
    };

    const updateData = async (data) => {
        const url = BASE_URL + "patient/family";
        const responce = await saveData(url, data);
        if (responce.error) {
            console.log("error");
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "error",
                message: "Failed.",
                openNotice: true,
            });
        } else {
            console.log({ responceSucess: responce.data });
            clearData();
            setKey(crypto.randomUUID());
            setNoticeData({
                severity: "success",
                message: "Assigned Successfully.",
                openNotice: true,
            });
        }
    };
    const handleSubmit = () => {
        console.log({ newFormValues: formValues });
        // const newFormValues = validate(formValues);
        const updatedForm = {
            internalStaffPhoneNumber: localStorage.getItem("userMobile"),
            marketingId: patient.marketingId,
            patientInfoVMList: formValues.filter((e) => e.fullName),
        };
        console.log({ updatedForm: updatedForm });
        updateData(updatedForm);
    };

    const routeChange = (patientId, patientName) => {
        console.log({ patientIdRoute: patientId });
        let path = `/marketing/case-history/${patientName}/${patientId}`;
        navigate(path);
    };
    console.log({ patientData: patient?.patientInfoVMList });

    // components
    const SearchBar = (
        <>
            <form onSubmit={searchMobileHandler}>
                <Grid container>
                    <Grid item xs={9} lg={6} display="flex" justifyContent="center" sx={{ p: 1 }}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Search Mobile"
                            value={searchMobile || ""}
                            onChange={(e) => {
                                setSearchMobile(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={3} lg={6} sx={{ p: 1 }}>
                        <Button variant="outlined" type="submit">
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
    const headingBanner = (
        <>
            {" "}
            <Grid container>
                <Grid item xs={12} lg={12} sx={{ mb: 1 }}>
                    <Divider />
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Typography>Name:</Typography>
                </Grid>
                <Grid item lg={4} xs={6}>
                    <Typography sx={{ textTransform: "capitalize" }}>
                        {patient?.patientInfoVMList[0].fullName}
                    </Typography>
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Typography>Phone Number:</Typography>
                </Grid>
                <Grid item lg={4} xs={6}>
                    <Typography>{patient?.patientInfoVMList[0].mobile}</Typography>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ mt: 1 }}>
                    <Divider />
                </Grid>
            </Grid>
        </>
    );

    const tableHeading = (
        <>
            {" "}
            <Grid container border={1} textAlign="center" columns={18}>
                <Grid item lg={1} borderRight={1}>
                    <Typography>S. No.</Typography>
                </Grid>
                <Grid item lg={4} borderRight={1}>
                    <Typography>Name</Typography>
                </Grid>
                <Grid item lg={2} borderRight={1}>
                    <Typography>Relation</Typography>
                </Grid>
                <Grid item lg={2} borderRight={1}>
                    <Typography>Phone No.</Typography>
                </Grid>
                <Grid item lg={2} borderRight={1}>
                    <Typography>Gender</Typography>
                </Grid>
                <Grid item lg={3} xs={12} borderRight={1}>
                    <Typography>DOB</Typography>
                </Grid>
                <Grid item lg={1} xs={12} borderRight={1}>
                    <Typography>Age</Typography>
                </Grid>
                {patient?.patientInfoVMList[0]?.patientId > 0 && (
                    <>
                        <Grid item lg={1} xs={12} borderRight={1}>
                            <Typography>Upload Case </Typography>
                        </Grid>
                        <Grid item lg={1} xs={12} borderRight={1}>
                            <Typography>View Cases </Typography>
                        </Grid>
                    </>
                )}
                <Grid item lg={1} xs={12} borderRight={1}>
                    <Typography>Add /Delete</Typography>
                </Grid>
                {/* <Grid item lg={1} xs={12}></Grid> */}
            </Grid>
        </>
    );

    const userFormDesktop = (
        <>
            {formValues.map((element, index) => (
                <Grid container border={1} borderTop={0} key={index} columns={18}>
                    <Grid
                        item
                        lg={1}
                        borderRight={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography>{index + 1}</Typography>
                    </Grid>
                    <Grid item lg={4} borderRight={1}>
                        <NameElement element={element} index={index} />
                    </Grid>
                    <Grid item lg={2} borderRight={1}>
                        <RelationElement element={element} />
                    </Grid>
                    <Grid item lg={2} borderRight={1}>
                        <PhoneNumberElement element={element} />
                    </Grid>
                    <Grid item lg={2} borderRight={1}>
                        <GenderElement element={element} />
                    </Grid>
                    <Grid item lg={3} borderRight={1}>
                        <DOBElement element={element} setAge={setAge} dob={dob} setDOB={setDOB} />
                    </Grid>
                    <Grid item lg={1} borderRight={1}>
                        <AgeElement element={element} age={age} setAge={setAge} setDOB={setDOB} />
                    </Grid>
                    {element?.patientId && (
                        <>
                            <Grid
                                item
                                lg={1}
                                borderRight={1}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <CaseUpload
                                    patientId={element?.patientId}
                                    patientName={element.fullName}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={1}
                                borderRight={1}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    color="primary"
                                    className="px-4"
                                    onClick={() =>
                                        routeChange(element?.patientId, element?.fullName)
                                    }
                                >
                                    View
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid item lg={1} display="flex" justifyContent="center" borderRight={1}>
                        <Stack
                            direction="row"
                            spacing={0}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <IconButton
                                sx={{ pl: 0 }}
                                aria-label="add"
                                onClick={() => addFormFields()}
                            >
                                <AddCircleIcon fontSize="small" />
                            </IconButton>

                            <Divider orientation="vertical" flexItem />
                            <IconButton
                                disabled={element.relation === "self"}
                                sx={{ pl: 0 }}
                                aria-label="remove"
                                onClick={() => removeFormFields(index)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const userFormMobile = (
        <>
            {" "}
            {formValues.map((element, index) => (
                <Grid container key={index} spacing={2}>
                    <Grid
                        item
                        lg={1}
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Avatar
                            sx={{
                                width: 20,
                                height: 20,
                                mt: 1,
                                bgcolor: "#208F94",
                            }}
                        >
                            {index + 1}
                        </Avatar>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <NameElement element={element} variant="small" index={index} />
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <RelationElement element={element} variant="small" />
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <PhoneNumberElement element={element} variant="small" />
                    </Grid>
                    <Grid item lg={1} xs={12}>
                        <GenderElement element={element} variant="small" />
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <DOBElement
                            element={element}
                            setAge={setAge}
                            dob={dob}
                            setDOB={setDOB}
                            variant="small"
                        />
                    </Grid>
                    <Grid item lg={1} xs={6}>
                        <AgeElement
                            element={element}
                            age={age}
                            setAge={setAge}
                            setDOB={setDOB}
                            variant="small"
                        />
                    </Grid>
                    {element?.patientId && (
                        <>
                            <Grid
                                item
                                lg={1}
                                xs={6}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <CaseUpload
                                    patientId={element?.patientId}
                                    patientName={element.fullName}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={1}
                                xs={6}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    color="primary"
                                    className="px-4"
                                    onClick={() =>
                                        routeChange(element?.patientId, element?.fullName)
                                    }
                                >
                                    View
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        lg={1}
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        borderBottom={1}
                    >
                        <Stack
                            direction="row"
                            spacing={0}
                            display="flex"
                            justifyContent="space-between"
                        >
                            {index === formValues.length - 1 ? (
                                <Fragment>
                                    <IconButton
                                        sx={{ pl: 0 }}
                                        aria-label="add"
                                        onClick={() => addFormFields()}
                                    >
                                        <AddCircleIcon fontSize="small" />
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                </Fragment>
                            ) : (
                                ""
                            )}

                            <IconButton
                                disabled={element.relation === "self"}
                                sx={{ pl: 0 }}
                                aria-label="remove"
                                onClick={() => removeFormFields(index)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const noPatient = (
        <>
            <Divider />
            <Grid item xs={12} lg={4}></Grid>
            <Grid item xs={12} lg={4}>
                <Typography>No Patient found with the mobile number</Typography>
            </Grid>
            <Grid item xs={12} lg={4}></Grid>
        </>
    );
    return (
        <Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TitleHC title={title} />
            </Grid>
            <ShowNoticeMessage noticeData={noticeData} key={key} />
            <Box sx={{ p: 1, mt: 5 }}>
                <Box
                    sx={{
                        p: 2,
                        border: "1px solid #208F94",
                        borderRadius: 2,
                        minHeight: "63vh",
                    }}
                    border={1}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            {SearchBar}
                        </Grid>
                        {!isPatient && !isFirstLoad && (
                            <Grid item xs={12} lg={12}>
                                {noPatient}
                            </Grid>
                        )}
                        {isPatient && (
                            <>
                                <Grid item xs={12} lg={12}>
                                    <Box sx={{ minHeight: "40vh" }}>
                                        <Box sx={{ mb: 2, background: "#F3F3F3", pl: 1, pr: 1 }}>
                                            {headingBanner}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: { xs: "none", lg: "block" },
                                            }}
                                        >
                                            {tableHeading}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: { xs: "none", lg: "block" },
                                            }}
                                        >
                                            {userFormDesktop}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: { xs: "block", lg: "none" },
                                            }}
                                        >
                                            {userFormMobile}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={12} display="flex" justifyContent="center">
                                    <ActionHC
                                        buttonText1={"Save"}
                                        buttonText2={"Clear"}
                                        saveAction={handleSubmit}
                                        cancelAction={clearData}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Box>
        </Fragment>
    );
};

// Parent Container component
const FamilyHistoryMK = () => {
    const [searchMobile, setSearchMobile] = useState("");
    const [patient, setPatient] = useState(null);
    const [isPatient, setisPatient] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const myContext = useContext(userContext);

    const [notification, setNotification] = useState({
        severity: "info",
        message: "",
        openNotice: false,
    });

    useEffect(() => {
        if (myContext.selectedMobileMarketing) {
            console.log({ useEffectMobile: myContext.selectedMobileMarketing });
            setSearchMobile(myContext.selectedMobileMarketing);
            getPatientByMobile(myContext.selectedMobileMarketing);
        }
    }, [myContext.selectedMobileMarketing]);

    const getPatientByMobile = async (mobile) => {
        const url = BASE_URL + "healthcamp/marketing/mobile/" + mobile;

        try {
            const patient = await getData(url);
            myContext.selectedMobileMarketing = mobile;
            console.log({ selectedMobileMarketing: myContext.selectedMobileMarketing });

            if (patient?.data?.familyVM?.patientInfoVMList?.length === 0) {
                let targetObject = {
                    marketingId: patient.data.id,
                    patientInfoVMList: [
                        {
                            fullName: patient.data.fullName,
                            relation: "self",
                            mobile: patient.data.mobile,
                            dateOfBirth: null,
                            age: patient.data.age,
                            gender: "MALE",
                            sequence: 0,
                            bp: null,
                            spo2: null,
                            heartRate: null,
                            temperature: null,
                            height: null,
                            weight: null,
                            sugar: null,
                            eyesight: null,
                        },
                    ],
                };

                const member = {
                    fullName: null,
                    relation: "family member",
                    mobile: null,
                    dateOfBirth: null,
                    age: 0,
                    gender: "MALE",
                    sequence: 1,
                    bp: null,
                    spo2: null,
                    heartRate: null,
                    temperature: null,
                    height: null,
                    weight: null,
                    sugar: null,
                    eyesight: null,
                };

                const addFamilyMembers = (
                    targetObject,
                    member,
                    numberOfMembers,
                    male,
                    female,
                    childMale,
                    childFemale
                ) => {
                    let count = 1;
                    const totalMale = male + childMale;
                    const totalFemale = female + childFemale;
                    const totalMember = totalFemale + totalMale;
                    const populateFemales = totalMember >= numberOfMembers;

                    console.log({
                        totalMale: totalMale,
                        totalFemale: totalFemale,
                        numberOfMembers: numberOfMembers,
                        populateFemales: populateFemales,
                    });

                    if (populateFemales) {
                        for (let i = 0; i < totalMale - 1; i++) {
                            targetObject.patientInfoVMList.push({
                                ...member,
                                sequence: count++,
                            });
                        }
                        for (let i = 0; i < totalFemale; i++) {
                            targetObject.patientInfoVMList.push({
                                ...member,
                                sequence: count++,
                                gender: "FEMALE",
                            });
                        }
                    } else {
                        for (let i = 0; i < numberOfMembers - 1; i++) {
                            targetObject.patientInfoVMList.push({
                                ...member,
                                sequence: count++,
                            });
                        }
                    }

                    return targetObject;
                };

                targetObject = addFamilyMembers(
                    targetObject,
                    member,
                    parseInt(patient.data.noOfMembers),
                    parseInt(patient.data.noOfMales),
                    parseInt(patient.data.noOfFemales),
                    parseInt(patient.data.noOfChildrenMales),
                    parseInt(patient.data.noOfChildrenFemales)
                );

                setPatient(targetObject);
                console.log({ formated_Patient_case1: targetObject });
            } else {
                setPatient(patient?.data?.familyVM);
            }
            !patient.data.error && setisPatient(true);
        } catch {
            console.log("error");
            setisPatient(false);
            console.log({ isPatient });
        }
    };
    const searchMobileHandler = (e) => {
        e.preventDefault();
        getPatientByMobile(searchMobile);
        setIsFirstLoad(false);
    };

    return (
        <Fragment>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FamilyHistoryForm
                            searchMobile={searchMobile}
                            setSearchMobile={setSearchMobile}
                            searchMobileHandler={searchMobileHandler}
                            isPatient={isPatient}
                            setisPatient={setisPatient}
                            patient={patient}
                            isFirstLoad={isFirstLoad}
                            title="Family Members"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
};

export default FamilyHistoryMK;
