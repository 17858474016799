import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material"
import { getCurrentDateFormatted } from "../../assets/utils"
import { BASE_URL } from "../../assets/constantsFile"
import { getData } from "../../services/api/getApiCalls"
import { useState } from "react"
import { useEffect } from "react"

const PrintPatientDetail = props => {
  const patientInfo = props.patientInfo
  const patientId = patientInfo.patientId
  const caseId = patientInfo.caseId
  const appointmentId = patientInfo.appointmentId

  const [patient, setPatient] = useState("")

  const getQrCode = async () => {
    const url =
      BASE_URL +
      "patient/detailed/" +
      patientId +
      "?appointmentId=" +
      appointmentId +
      "&caseId=" +
      caseId

    const patientInformation = await getData(url)

    if (patientInformation.error) {
      console.log("error")
    } else {
      setPatient(patientInformation.data)
      console.log({ patientInformation: patientInformation.data })
    }
  }

  useEffect(() => {
    getQrCode()
  }, [])

  return (
    <Grid container display={"flex"}>
      <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "9px",

              color: "#6B6B6B",
            }}>
            PATIENT NAME:&nbsp;
          </Typography>
          <Typography variant="caption" sx={{}}>
            {patientInfo.name}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={1.2} md={1.2} sm={1.2} xs={1.2} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "9px",

              color: "#6B6B6B",
            }}>
            AGE:&nbsp;
          </Typography>
          <Typography variant="caption" sx={{}}>
            {patientInfo.age}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "9px",

              color: "#6B6B6B",
            }}>
            GENDER:&nbsp;
          </Typography>
          <Typography variant="caption" sx={{}}>
            {patientInfo.sex}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "9px",
              color: "#6B6B6B",
            }}>
            DATE:&nbsp;
          </Typography>
          <Typography variant="caption" sx={{}}>
            {getCurrentDateFormatted(" ")}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "9px",
              color: "#6B6B6B",
            }}>
            PATIENT ID:&nbsp;
          </Typography>
          <Typography variant="caption" sx={{}}>
            {patientInfo.patientId}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={0.3} md={0.3} sm={0.3} xs={0.3} sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Click to Print">
          <a onClick={() => window.print()}>
            {patient && patient.qrCode && (
              <img
                src={`data:image/png;base64,${patient.qrCode}`}
                alt="example"
                style={{
                  height: "50px",
                  weight: "50px",
                  marginLeft: "20px",
                }}
              />
            )}
          </a>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default PrintPatientDetail
