import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { Fragment } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApiCalls";
import { _fetchEmployeeById } from "../../services/services";

const SearchEmployee = ({
  corpId,
  setFormValues,
  setSearchedEmployeeId,
  employeeId,
  setEmployeeId,
  setSeverity,
  setMessage,
  setOpenNotice,
  setIsTokenNumber,
}) => {
  const fetchEmployee = async (e) => {
    e.preventDefault();

    _fetchEmployeeById(
      corpId,
      setSeverity,
      setMessage,
      setOpenNotice,
      setSearchedEmployeeId,
      setFormValues,
      employeeId,
      setIsTokenNumber
    );
    // const url = BASE_URL + `org/detailed/${employeeId}?corpId=${corpId}`;

    // const empData = await getData(url);
    // if (empData.error) {
    //   setSeverity("error");
    //   setMessage(empData.error?.response?.data?.message);
    //   setOpenNotice(true);
    //   setSearchedEmployeeId(null);
    // } else {
    //   setFormValues(empData.data);
    //   setSearchedEmployeeId(empData.data.empId);
    //   console.log({ display: empData.data.display });
    // }
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item xs>
          <form onSubmit={fetchEmployee}>
            <Box>
              <Stack direction="row">
                <TextField
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  variant="outlined"
                  label=""
                  fullWidth
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    "& fieldset": {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    px: 5,
                  }}
                >
                  Search
                </Button>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SearchEmployee;
