import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useCallback } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const PatientName = ({ formValues, setFormValues }) => {
  const [oldPatientList, setOldPatientList] = useState([]);

  const fetchOldPatient = useCallback(async (nameValue) => {
    const url = BASE_URL + "patient/cms/search?name=" + nameValue;

    const oldPatient = await getData(url);

    if (oldPatient.error) {
      console.log("error");
    } else {
      console.log("success");
      setOldPatientList(oldPatient.data);
    }
  }, []);

  console.log({ oldPatientList });

  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              size="small"
              options={oldPatientList}
              getOptionLabel={(option) => (option ? option.fullName : "")}
              renderOption={(props, option) => (
                <li {...props} key={option.patientId}>
                  <Stack
                    direction="row"
                    width="50%"
                    display="flex"
                    justifyContent={"flex-start"}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {option["fullName"]}
                    </Typography>
                    <Typography sx={{ fontSize: 11, fontWeight: 600, mt: 0.7 }}>
                      {option.mobile ? ", " + option.mobile : ""}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    width="50%"
                    display="flex"
                    justifyContent={"flex-end"}
                  >
                    <Box
                      component={Stack}
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ fontSize: 10, fontWeight: 600, mt: 1 }}
                    >
                      {option?.age === 0 ? "" : option?.age}
                      {option?.age === 0 ? option?.ageMonths + "m" : ""}
                      {option.gender ? ", " + option.gender : ""}
                    </Box>
                  </Stack>
                </li>
              )}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                  fetchOldPatient(newInputValue);
                  formValues["fullName"] = newInputValue;
                }
              }}
              value={formValues}
              selectOnFocus
              onChange={(event, value) => {
                //formValues["fullName"] = value.fullName;
                setFormValues(value);
                console.log({ selectedPatient: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Patient Name*"
                  variant="outlined"
                  size="small"
                  helperText={
                    formValues.fullName ? "" : "Name can not be empty."
                  }
                  error={formValues.fullName ? false : true}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientName;
