import { Box, Grid, Stack, Typography } from "@mui/material";

const PatientInfo = (props) => {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ minHeight: "5vh", background: "#D9D9D9", p: 1 }}>
          <Stack direction={"row"} display="flex" justifyContent="center">
            <Typography variant="h6">
              {props.patient ? props.patient.firstName : ""}
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientInfo;
