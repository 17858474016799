import { Box, Grid, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import LogoMain from "../logo/logoMain"
import LogoMainCustom from "../logo/logoMainCustom"

const PrintHeader = props => {
  const docDetail = props.docDetail
  console.log({ docDetail: docDetail })
  const isWalkin = props.patientDetails.consultationType === "WALKIN"
  const isDolphine = docDetail.docId === "7873cd5c-8a98-426b-b645-5e45e440088d"

  const walkInComponent = (
    <Fragment>
      <Grid container columnSpacing={1} sx={{ marginLeft: "10px" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="flex-start"
          alignItems={"center"}
          sx={{}}>
          {docDetail?.clinicLogoURL && <LogoMainCustom clinicLogoURL={docDetail.clinicLogoURL} />}
          {!docDetail?.clinicLogoURL && (
            <Fragment>
              <Box sx={{ pl: 2, py: 1 }}>
                <Stack>
                  <Stack direction="row" display="flex" justifyContent="flex-start">
                    <Typography variant="body2" sx={{ fontWeight: 600, color: "#127DDD" }}>
                      {docDetail.fullName ? "Dr. " + docDetail.fullName : ""}
                    </Typography>
                  </Stack>
                  <Stack direction="row" display="flex" justifyContent="flex-start">
                    <Typography variant="body2" sx={{ fontWeight: 600, color: "#127DDD" }}>
                      {docDetail?.degree}
                    </Typography>
                  </Stack>
                  <Stack direction="row" display="flex" justifyContent="flex-start">
                    <Typography variant="body2" sx={{ fontWeight: 600, color: "#127DDD" }}>
                      {docDetail?.specialization}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Fragment>
  )

  const dolphineComponent = (
    <Fragment>
      <Stack spacing={1} sx={{ marginLeft: "10px" }}>
        <Stack direction="row" display="flex" justifyContent="flex-start">
          <Typography variant="body1" sx={{ fontWeight: 600, color: "#127DDD" }}>
            Dolphin Hospital
          </Typography>
        </Stack>

        <Stack direction="row" display="flex" justifyContent="flex-start">
          <Typography variant="body1" sx={{ fontWeight: 600, color: "#127DDD" }}>
            Paediatrics Department
          </Typography>
        </Stack>
      </Stack>
    </Fragment>
  )

  return (
    <Fragment>
      {docDetail && (
        <Fragment>
          {isWalkin && walkInComponent}
          {!isWalkin && (
            <Fragment>
              <Grid container columnSpacing={1} sx={{ marginRight: "10px" }}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end">
                  <Fragment>
                    {isDolphine && dolphineComponent}
                    {!isDolphine && (
                      <Fragment>
                        <Box sx={{ px: 2, py: 1 }}>
                          <Stack>
                            <Stack
                              direction="row"
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center">
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: 600,
                                  color: "#127DDD",
                                  textTransform: "capitalize",
                                }}>
                                {docDetail?.hospitalVM?.name}
                              </Typography>
                            </Stack>
                            <Stack direction="row" display="flex" justifyContent="flex-start">
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "#127DDD" }}>
                                {docDetail.fullName ? "Dr. " + docDetail.fullName : ""}
                                {", "}
                                {docDetail?.degree}
                              </Typography>
                            </Stack>

                            <Stack direction="row" display="flex" justifyContent="flex-start">
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "#127DDD" }}></Typography>
                            </Stack>

                            <Stack direction="row" display="flex" justifyContent="flex-start">
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: "#127DDD" }}>
                                {docDetail?.specialization}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Fragment>
                    )}
                  </Fragment>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default PrintHeader
