import React, { Fragment } from "react";
import NavbarCorp from "../components/NavbarCorp";
import { Container } from "@mui/material";
import CreateCorpMain from "../module/createCorp/createCorpMain";

const CreateCorpIndex = () => {
  return (
    <Fragment>
      <NavbarCorp />
      <Container maxWidth={false}>
        <CreateCorpMain />
      </Container>
    </Fragment>
  );
};

export default CreateCorpIndex;
