import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";

const ReportedIssue = ({ issues, setIssues }) => {
  console.log({ issues });
  const [sympt, setSympt] = useState(issues ? issues : null);

  const [symptomsList, setSymptomsList] = useState([]);

  const getSymptomsList = async () => {
    const url = BASE_URL + "clinic/symptoms";

    const data = await getData(url);

    if (data.error) {
      setSymptomsList([]);
    } else {
      setSymptomsList(data.data);
    }
  };

  useEffect(() => {
    getSymptomsList();
  }, []);

  console.log({ symptomsList: symptomsList });
  return (
    <Fragment>
      <Autocomplete
        size={"small"}
        freeSolo
        value={sympt}
        disableClearable
        options={symptomsList}
        getOptionLabel={(option) => option.symptom}
        onChange={(event, value) => {
          setIssues(value);
          setSympt(value);
        }}
        onInputChange={(event, newValue) => {
          setSympt({ id: null, symptom: newValue });
          setIssues({ id: null, symptom: newValue });
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            variant="outlined"
            {...params}
            label="Issue Reported"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Fragment>
  );
};

export default ReportedIssue;
