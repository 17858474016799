import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import corporate from "../../assets/corporate.png";
import { useNavigate } from "react-router";
import CorpOtpLoginForm from "./corpOtpLoginForm";
import CorpLoginEmailForm from "./corpLoginEmailForm";

const CorpLoginMain = () => {
  const navigate = useNavigate();
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);
  const [otpSignUp, setOtpSignUp] = useState(true);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={1000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "13px",
              lineHeight: "15px",
              textAlign: "center",
              color: "#404040",
              marginBottom: "15px",
            }}
          >
            SNOP Login
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              src={corporate}
              style={{ objectFit: "contain", height: "230px", width: "100%" }}
              alt="Banner"
            />
          </Box>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              textAlign: "center",
              color: "#404040",
              marginBlock: "10px",
            }}
          >
            Building Healthcare For Bharat
          </Typography>
          {otpSignUp === true ? (
            <CorpOtpLoginForm
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
              handleCloseNotice={handleCloseNotice}
            />
          ) : (
            <CorpLoginEmailForm
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
              handleCloseNotice={handleCloseNotice}
            />
          )}

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#404040",
                  textAlign: "center",
                }}
              >
                or
              </Typography>
              {otpSignUp === true ? (
                <Button
                  onClick={() => {
                    setOtpSignUp(false);
                  }}
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}
                >
                  Login With Email
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setOtpSignUp(true);
                  }}
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}
                >
                  Login With OTP
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CorpLoginMain;
