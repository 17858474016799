import { Box, IconButton, TextField, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React, { useState } from "react"
import { format } from "date-fns"
import { Fragment } from "react"

const CustomDate = ({ setFormValues, formValues, item, date, setDate }) => {
  const [dateValue, setDateValue] = useState(new Date())

  const handleDateChange = dateValue => {
    setDateValue(dateValue)
    const formattedDate = format(dateValue, "yyyy-MM-dd")
    setFormValues({ ...formValues, [item.property]: formattedDate })
    setDate(dateValue)
  }
  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#6B6B6B",
          marginBlock: "5px",
        }}>
        {item.label}
      </Typography>
      <Box
        sx={{
          width: "100%",
          "& .MuiFormControl-root": {
            display: "flex",
            justifyContent: "center",
            // marginTop: "-10px",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "0.5px solid #D4D4D4",
            borderRadius: "5px",
            height: "44px",
          },
        }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label=""
            openTo="day"
            views={["year", "month", "day"]}
            value={dateValue}
            format="MMMM dd, yyyy"
            onChange={handleDateChange}
            renderInput={params => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Box>
    </Fragment>
  )
}

export default CustomDate
