import { TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DOBElement = ({ element, setAge, dob, setDOB, variant }) => {
  useEffect(() => {
    setDOB(element.dob ? new Date(element.dob) : null);
  }, []);
  //const [dob, setDOB] = useState(element.dob ? new Date(element.dob) : null);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd MMM yyyy"
          disableMaskedInput={true}
          disableFuture
          label={variant === "small" ? "Enter DOB..." : ""}
          openTo="day"
          views={["year", "month", "day"]}
          value={element.dob}
          
          onChange={(newValue) => {
            setDOB(newValue);
            element["dob"] = newValue.toISOString().split("T")[0];
            element["age"] = getAge(newValue);
            setAge(getAge(newValue));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              InputLabelProps={{ style: { fontSize: 12 } }}
              fullWidth
              
              sx={{ fontSize: 10, p: 0.3 }}
            />
          )}
        />
      </LocalizationProvider>
    </Fragment>
  );
};

export default DOBElement;
