import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Portal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDate from "../../components/CustomDate";
import CustomTime from "../../components/CustomTime";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApiCalls";
import { saveData } from "../../../services/api/postApiCalls";
import CustomButtonBlue from "../../components/customButton";
import { updateData } from "../../../services/api/patchApi";

const CustomField = ({ formValues, setFormValues }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Official Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Name"
          value={formValues.corpName}
          onChange={(e) =>
            setFormValues({ ...formValues, corpName: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Company Address</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Company Address"
          value={formValues.address}
          onChange={(e) =>
            setFormValues({ ...formValues, address: e.target.value })
          }
        />
        {/* <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <SaveIcon />
          </IconButton> */}
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>Unocare SPOC</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter Unocare SPOC"
          value={formValues.unoSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, unoSPOC: e.target.value })
          }
        />
        {/* <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <SaveIcon />
          </IconButton> */}
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Name</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Name"
          value={formValues.corpSPOC}
          onChange={(e) =>
            setFormValues({ ...formValues, corpSPOC: e.target.value })
          }
        />
        {/* <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <SaveIcon />
          </IconButton> */}
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>CORP SPOC Mobile</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter SPOC Mobile"
          value={formValues.corpSPOCMobile}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue.length <= 10) {
              setFormValues({ ...formValues, corpSPOCMobile: inputValue });
            }
          }}
        />
        {/* <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <SaveIcon />
          </IconButton> */}
      </Grid>
      <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
        <Typography sx={{ color: "#000" }}>GST No For Billing</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
        <TextField
          disabled={true}
          sx={{
            background: "#fff",
            color: "#000000",
            fontWeight: "300",
            fontSize: "13px",
            lineHeight: " 15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
            },
          }}
          size="small"
          fullWidth
          placeholder="Enter GST No"
          value={formValues.gst}
          onChange={(e) =>
            setFormValues({ ...formValues, gst: e.target.value })
          }
        />
        {/* <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <SaveIcon />
          </IconButton> */}
      </Grid>
    </Grid>
  );
};

const TestDetail = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.testDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              key={index}
              container
              spacing={1}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography>{item.label}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!item.isIncluded}
                      onChange={handleChange(
                        "testDetails",
                        index,
                        "isIncluded"
                      )}
                      name="height"
                    />
                  }
                  sx={{ fontSize: "14px" }}
                  label="Yes/no"
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  label="#Employees"
                  placeholder="#Employees"
                  value={item.noOfEmp}
                  onChange={handleChange("testDetails", index, "noOfEmp")}
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  label="Price"
                  placeholder="Price"
                  value={item.costPrice}
                  onChange={handleChange("testDetails", index, "costPrice")}
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  label="Remark"
                  placeholder="Remark"
                  value={item.remark}
                  onChange={handleChange("testDetails", index, "remark")}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};
const Vaccination = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.vaccinationDetails?.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={6} lg={3}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange(
                    "vaccinationDetails",
                    index,
                    "isIncluded"
                  )}
                  name="height"
                />
              }
              sx={{ fontSize: "14px" }}
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="#Employees"
              placeholder="#Employees"
              value={item.noOfEmp}
              onChange={handleChange("vaccinationDetails", index, "noOfEmp")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Price"
              placeholder="Price"
              value={item.costPrice}
              onChange={handleChange("vaccinationDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Remark"
              placeholder="Remark"
              value={item.remark}
              onChange={handleChange("vaccinationDetails", index, "remark")}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
const Pathology = ({ formValues, setFormValues, handleChange }) => {
  const [newPathology, setNewPathology] = useState({
    name: "",
    isIncluded: false,
    noOfEmp: "",
    costPrice: "",
    remark: "",
  });
  const handleAddPathology = () => {
    if (
      (newPathology.name !== "" || newPathology.noOfEmp !== "",
      newPathology.costPrice !== "",
      newPathology.remark !== "")
    ) {
      setFormValues({
        ...formValues,
        pathologyDetails: [...formValues.pathologyDetails, newPathology],
      });
    }
    setNewPathology({
      name: "",
      isIncluded: false,
      noOfEmp: "",
      costPrice: "",
      remark: "",
    });
  };
  return (
    <Fragment>
      {formValues?.pathologyDetails?.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          sx={{
            border: "0.5px solid #000",
            borderRadius: "10px",
            marginBlock: "10px",
            padding: "5px",
          }}
        >
          <Grid item xs={6} lg={3}>
            <Typography>{item.name}</Typography>
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange("testDetails", index, "isIncluded")}
                />
              }
              sx={{ fontSize: "14px" }}
              label="Yes/no"
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="#Employees"
              placeholder="#Employees"
              value={item.noOfEmp}
              onChange={handleChange("pathologyDetails", index, "noOfEmp")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Price"
              placeholder="Price"
              value={item.costPrice}
              onChange={handleChange("pathologyDetails", index, "costPrice")}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              disabled={true}
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              label="Remark"
              placeholder="Remark"
              value={item.remark}
              onChange={handleChange("pathologyDetails", index, "remark")}
            />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};
const FormCertificates = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Box>
      {formValues?.formDetails?.map(
        (item, index) =>
          item.isIncluded && (
            <Grid
              container
              spacing={1}
              key={index}
              sx={{
                border: "0.5px solid #000",
                borderRadius: "10px",
                marginBlock: "10px",
                padding: "5px",
              }}
            >
              <Grid item xs={6} lg={3}>
                <Typography>{item.label}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!item.isIncluded}
                      onChange={handleChange(
                        "formDetails",
                        index,
                        "isIncluded"
                      )}
                    />
                  }
                  label="Yes/no"
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Seal/Sign"
                  label="Seal/Sign"
                  value={item.sealSign}
                  onChange={handleChange("formDetails", index, "sealSign")}
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Price"
                  label="Price"
                  value={item.costPrice}
                  onChange={handleChange("formDetails", index, "costPrice")}
                />
              </Grid>
              <Grid item xs={4} lg={2}>
                <TextField
                  disabled={true}
                  sx={{
                    background: "#fff",
                    color: "#000000",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Remark"
                  label="Remark"
                  value={item.remark}
                  onChange={handleChange("formDetails", index, "remark")}
                />
              </Grid>
            </Grid>
          )
      )}
    </Box>
  );
};
const TechReq = ({ formValues, setFormValues, handleChange }) => {
  return (
    <Fragment>
      {formValues?.techRequirements?.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={6} lg={6}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!item.isIncluded}
                  onChange={handleChange(
                    "techRequirements",
                    index,
                    "isIncluded"
                  )}
                />
              }
              label="Yes/no"
            />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

const GatherInfoView = ({
  setExpandedAccordion,
  expandedAccordion,
  handleChangeAccordion,
  handleCollapseAll,
  corpId = localStorage.getItem("CORP_ID"),
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [time, setTime] = useState(new Date());
  const [isSpocNameModal, setIsSpocNameModal] = useState(false);
  const toggleSpocNameModal = () => {
    setIsSpocNameModal(!isSpocNameModal);
  };

  const [newRemark, setNewRemarks] = useState("");
  const [newSpocName, setNewSpocname] = useState("");

  const handleAddSpocName = () => {
    if (newSpocName) {
      setFormValues({
        ...formValues,
        spocsInfo: [...formValues.spocsInfo, { spocname: newSpocName }],
      });
      toggleSpocNameModal();
      setNewSpocname("");
    }
  };
  const handleAddRemark = () => {
    if (newRemark) {
      setFormValues({
        ...formValues,
        remarksInfo: [...formValues.remarksInfo, { remarks: newRemark }],
      });
      toggleRemark();
      setNewRemarks("");
    }
  };

  const [isRemarkModal, setIsRemarkModal] = useState(false);
  const toggleRemark = () => {
    setIsRemarkModal(!isRemarkModal);
  };

  const [formValues, setFormValues] = useState({
    corpName: "",
    address: "",
    unoSPOC: "",
    unoSPOCId: "",
    corpSPOC: "",
    corpSPOCMobile: "",
    gst: "",
    testDetails: [
      {
        name: "height",
        label: "Height",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "weight",
        label: "Weight",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "bp",
        label: "BP",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "rbs",
        label: "RBS",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "eyeVision",
        label: "Eye Vision",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "pft",
        label: "PFT",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "audio",
        label: "Audio",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "ecg",
        label: "ECG",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xray",
        label: "Xray",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "doctorVisit",
        label: "Doctor Visit",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    vaccinationDetails: [
      {
        name: "typhoidVaccine",
        label: "Typhoid Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "hepBVaccine",
        label: "Hep B Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "tetnusVaccine",
        label: "Tetnus Vaccine",
        isIncluded: false,
        noOfEmp: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    formDetails: [
      {
        name: "Form32",
        label: "Form 32",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "medicalFitnessCertificate",
        label: "Medical Fitness Certificate",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "physicalFitnessCerticate",
        label: "Physical Fitness Certificate",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form35",
        label: "Form 35",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "foodSafetyForm",
        label: "Food Safety Form",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "Form21_HealthRegister",
        label: "Form 21 Health Register",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
      {
        name: "xrayReport",
        label: "X-ray Report",
        isIncluded: false,
        sealSign: "",
        costPrice: "",
        remark: "",
        isActive: true,
      },
    ],
    techRequirements: [
      {
        name: "mobileApp",
        label: "Mobile App",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "OffRoll_OnRoll",
        label: "OffRoll/OnRoll",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Pending_Complete",
        label: "Pending/Complete",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Healthy_Unhealthy",
        label: "Healthy/Unhealthy",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "Department_Wise",
        label: "Department Wise",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bpChart",
        label: "BP CharT",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "rbsChart",
        label: "RBS Chart",
        isIncluded: false,
        isActive: true,
      },
      {
        name: "bmiChart",
        label: "BMI Chart",
        isIncluded: false,
        isActive: true,
      },
    ],
    pathologyDetails: [],
    spocsInfo: [],
    remarksInfo: [],
  });

  const handleChange = (field, index, property) => (event) => {
    const updatedFormValues = [...formValues?.[field]];
    const value =
      property === "isIncluded" ? event.target.checked : event.target.value;

    updatedFormValues[index][property] =
      property === "isIncluded" ? !!value : value;

    setFormValues({
      ...formValues,
      [field]: updatedFormValues,
    });
  };

  const [corpDetail, setCorpDetail] = useState("");
  const fetchCorpDetails = async () => {
    if (corpId !== null) {
      const url = BASE_URL + "org/corpConfigInfo/corp?corpId=" + corpId;
      const result = await getData(url);
      if (result.data) {
        console.log("SUCCESS", result?.data);
        setCorpDetail(result?.data);
        setTimeout(() => {
          setFromDate(
            result?.data?.requirementsDate
              ? new Date(result?.data?.requirementsDate?.fromDate)
              : new Date()
          );
          setTime(
            result?.data?.requirementsDate
              ? new Date(result?.data?.requirementsDate?.time)
              : new Date()
          );
          setToDate(
            result?.data?.requirementsDate
              ? new Date(result?.data?.requirementsDate?.toDate)
              : new Date()
          );
          setFormValues({
            id: result?.data?.id,
            corpName: result?.data?.corpName || "",
            address: result?.data?.address || "",
            unoSPOC: result?.data?.unoSPOC || "",
            corpSPOC: result?.data?.corpSPOC || "",
            corpSPOCMobile: result?.data?.corpSPOCMobile || "",
            gst: result?.data?.gst || "",
            testDetails:
              result?.data?.testDetails
                ?.filter((item) => item.isIncluded)
                ?.map((item, index) => ({
                  id: item?.id || "",
                  name: item?.fieldName || "",
                  label: item?.fieldName,
                  isIncluded: item?.isIncluded || false,
                  noOfEmp: item?.employees || "",
                  costPrice: item?.costPrice || "",
                  remark: item?.remarks || "",
                })) || formValues?.testDetails,
            vaccinationDetails:
              result?.data?.vaccinationDetails
                ?.filter((item) => item.isIncluded)
                ?.map((item, index) => ({
                  id: item?.id || "",
                  name: item?.fieldName || "",
                  label: item?.fieldName,
                  isIncluded: item?.isIncluded || false,
                  noOfEmp: item?.employees || "",
                  costPrice: item?.costPrice || "",
                  remark: item?.remarks || "",
                })) || formValues?.vaccinationDetails,
            pathologyDetails:
              result?.data?.pathologyDetails
                ?.filter((item) => item.isIncluded)
                ?.map((item, index) => ({
                  id: item?.id || "",
                  name: item?.fieldName || "",
                  label: item?.fieldName,
                  isIncluded: item?.isIncluded || false,
                  noOfEmp: item?.employees || "",
                  costPrice: item?.costPrice || "",
                  remark: item?.remarks || "",
                })) || formValues.pathologyDetails,
            formDetails:
              result?.data?.formDetails
                ?.filter((item) => item.isIncluded)
                ?.map((item, index) => ({
                  id: item?.id || "",
                  name: item?.fieldName || "",
                  label: formValues?.formDetails[index].label,
                  isIncluded: item?.isIncluded || false,
                  noOfEmp: item?.employees || "",
                  costPrice: item?.costPrice || "",
                  remark: item?.remarks || "",
                  sealSign: item?.seal || "",
                })) || formValues.formDetails,
            spocsInfo: result?.data?.spocsInfo || [],
            remarksInfo: result?.data?.remarksInfo || [],
            techRequirements:
              result?.data?.techRequirements
                ?.filter((item) => item.isIncluded)
                ?.map((item, index) => ({
                  id: item?.id || "",
                  name: item?.fieldName || "",
                  label: formValues.techRequirements[index]
                    ? formValues.techRequirements[index].label
                    : item.fieldName || "",
                  isIncluded: item?.isIncluded || false,
                })) || formValues?.techRequirements,
          });
        }, 500);

        console.log({ TIME: new Date(result?.data?.requirementsDate?.time) });
      } else {
        console.log("ERROR", result.error);
        setCorpDetail("");
      }
    }
  };

  useEffect(() => {
    fetchCorpDetails();
  }, []);

  const convertToAMPMFormat = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    return formattedTime;
  };

  // const [expandedAccordion, setExpandedAccordion] = useState([]);

  // const handleChangeAccordion = (panel) => {
  //   setExpandedAccordion((prevExpanded) => {
  //     if (prevExpanded.includes(panel)) {
  //       // If the clicked panel is already expanded, collapse it
  //       return [];
  //     } else {
  //       // If a new panel is clicked, collapse the previous one and expand the new one
  //       return [panel];
  //     }
  //   });
  // };

  // const handleCollapseAll = () => {
  //   setExpandedAccordion([]);
  // };

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={3000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box>
        <Typography sx={{ textAlign: "center", color: "#000" }}>
          Requirement Gathered
        </Typography>
        <Divider />

        {/* <Box sx={{ textAlign: "center", marginBlock: 1 }}>
          <Button variant="outlined" onClick={handleCollapseAll}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Collapse All
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setExpandedAccordion([
                "panel1",
                "panel2",
                "panel3",
                "panel4",
                "panel5",
                "panel6",
                "panel7",
                "panel8",
                "panel9",
              ])
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#127DDD",
                textTransform: "capitalize",
              }}
            >
              Expand All
            </Typography>
          </Button>
        </Box> */}

        <Accordion
          expanded={expandedAccordion.includes("panel1")}
          onChange={() => handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Company Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomField
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion.includes("panel2")}
          onChange={() => handleChangeAccordion("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Execution Date Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                <Typography sx={{ color: "#000" }}>
                  Execution Date Req
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000", marginRight: "10px" }}>
                  From
                </Typography>
                <CustomDate
                  setDate={setFromDate}
                  initialDate={fromDate}
                  disablePast={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000", marginInline: "10px" }}>
                  To
                </Typography>
                <CustomDate
                  setDate={setToDate}
                  initialDate={toDate}
                  disablePast={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ color: "#000" }}>Reporting Time</Typography>
              </Grid>
              <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
                <CustomTime setTime={setTime} time={time} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion.includes("panel3")}
          onChange={() => handleChangeAccordion("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Test Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TestDetail
              formValues={formValues}
              setFormValues={setFormValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion.includes("panel4")}
          onChange={() => handleChangeAccordion("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Vacination
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Vaccination
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        {formValues.pathologyDetails.length > 0 ? (
          <Accordion
            expanded={expandedAccordion.includes("panel5")}
            onChange={() => handleChangeAccordion("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                Pathology
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Pathology
                formValues={formValues}
                setFormValues={setFormValues}
                handleChange={handleChange}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Accordion
          expanded={expandedAccordion.includes("panel6")}
          onChange={() => handleChangeAccordion("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Forms/Certificates
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormCertificates
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion.includes("panel7")}
          onChange={() => handleChangeAccordion("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ textAlign: "center", color: "#000" }}>
              Tech Requirements
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TechReq
              setFormValues={setFormValues}
              formValues={formValues}
              handleChange={handleChange}
            />
          </AccordionDetails>
        </Accordion>
        {formValues?.spocsInfo.length > 0 ? (
          <Accordion
            expanded={expandedAccordion.includes("panel8")}
            onChange={() => handleChangeAccordion("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                CORP SPOC Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {formValues?.spocsInfo?.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#000" }}>
                            {item.spocname}
                          </Typography>
                          <Typography
                            sx={{ color: "#000", marginLeft: "10px" }}
                          >
                            {item.spocmobile}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {formValues?.remarksInfo.length > 0 ? (
          <Accordion
            expanded={expandedAccordion.includes("panel9")}
            onChange={() => handleChangeAccordion("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ textAlign: "center", color: "#000" }}>
                Remark Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {formValues?.remarksInfo?.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#000" }}>
                            {item.remarks}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
    </Fragment>
  );
};

export default GatherInfoView;
