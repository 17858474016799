import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import PaymentComponent from "../../common/paymentComponent/paymentComponent";
import { useNavigate } from "react-router-dom";
import GoToRegistration from "../goToRegistration";

const BookPharmacySuccess = (props) => {
  let navigate = useNavigate();

  const toPharmacyHome = () => {
    navigate("/pharmacyDashboard");
  };
  console.log(props.data);
  const patient = props.patient;
  const data = props.data;

  const name =
    (patient.firstName ? patient.firstName + " " : "") +
    (patient.middleName ? patient.middleName + " " : "") +
    (patient.lastName ? patient.lastName : "");
  const age = patient.age ? patient.age : "";
  const gender = patient.gender ? patient.gender : "";

  return (
    <Box
      sx={{
        minHeight: "47vh",
        background: "#fff",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "5vh" }}>
            <Typography>{name} has successfully booked appointment.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "30vh" }}>
            <Typography variant="h6">Appointment Details</Typography>

            <Stack direction={"row"} spacing={2}>
              <Typography>Patient Name:</Typography>
              <Typography>{name}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Age:</Typography>
              <Typography>{age}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Gender:</Typography>
              <Typography>{gender}</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "7vh" }}>
            <Stack direction="row" spacing={2}>
              <PaymentComponent
                root={props.root}
                paymentVM={props.paymentVM}
                portal="LAB"
                portalId={localStorage.getItem("LAB_ID")}
                origin={"BOOKING"}
              />

              <Button variant="outlined" onClick={toPharmacyHome}>
                Pay Later
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Divider color="primary" />
        </Grid>

        <Grid item xs={12} lg={12} display="flex" justifyContent={"center"}>
          <GoToRegistration tabValue={"PHARMACY"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookPharmacySuccess;
