import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Fragment, useState } from "react";

const SelectTokenElement = () => {
  const [token, setToken] = useState("");

  const handleChange = (event) => {
    setToken(event.target.value);
  };
  return (
    <Fragment>
      <Box sx={{ mt: 5, mb: 2, minWidth: 250 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Select Token</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={token}
            label="Select Token"
            onChange={handleChange}
          >
            <MenuItem value={10}>Token 1</MenuItem>
            <MenuItem value={20}>Token 2</MenuItem>
            <MenuItem value={30}>Token 3</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Fragment>
  );
};

export default SelectTokenElement;
