import { Fragment } from "react"
import DashboardIndex from "../components/dashboard/dashboardIndex"
import NavigationBarAdminPortal from "../../header/NavigationBarAdminPortal"

const AdminDashboard = props => {
  return (
    <Fragment>
      <NavigationBarAdminPortal />
      <DashboardIndex />
    </Fragment>
  )
}

export default AdminDashboard
