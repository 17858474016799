import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { VITALS_SUGAR } from "../../../assets/constantsText";
import LogoMain from "../logo/logoMain";

const PrintVitals = ({ patient }) => {
    return (
        <Box>
            {patient ? (
                <Grid container>
                    <Grid item xs={12}>
                        {patient.familyMembers ? (
                            <Fragment>
                                <Box
                                    sx={{
                                        background: "#C0E5FF",
                                        p: 2,
                                        borderRadius: "10px",
                                        ml: 2,
                                        mr: 2,
                                    }}>
                                    <Grid
                                        container
                                        textAlign="center"
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: { xs: "7px", sm: "15px", md: "15px", lg: "15px" },
                                        }}>
                                        <Grid item lg={2} xs={2} textAlign="left">
                                            Name
                                        </Grid>
                                        <Grid item lg={2} xs={2}>
                                            Relation
                                        </Grid>

                                        <Grid item lg xs>
                                            Height <br />
                                            (cm)
                                        </Grid>

                                        <Grid item lg xs>
                                            Weight <br />
                                            (kg)
                                        </Grid>

                                        <Grid item lg xs>
                                            Eye Sight <br />
                                            (dpt)
                                        </Grid>
                                        <Grid item lg xs>
                                            BP <br />
                                            (mmHg)
                                        </Grid>
                                        <Grid item lg xs>
                                            {VITALS_SUGAR}
                                            <br /> (mg/dl)
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{ p: 2 }}>
                                    {patient.familyMembers.map((element, index) => (
                                        <Grid
                                            container
                                            display="flex"
                                            justifyContent="center"
                                            key={index}
                                            textAlign="center"
                                            // borderBottom={1}
                                            sx={{
                                                mb: 2,
                                                p: 2,
                                                backgroundColor: "#F1F8FF",
                                                borderRadius: "10px",
                                            }}>
                                            <Grid item lg={2} xs={2} textAlign="left">
                                                <Typography
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {element.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={2} xs={2}>
                                                <Typography
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {element.relation}
                                                </Typography>
                                            </Grid>

                                            <Grid item lg xs>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {element.height}
                                                </Typography>
                                            </Grid>

                                            <Grid item lg xs>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {" "}
                                                    {element.weight}
                                                </Typography>
                                            </Grid>

                                            <Grid item lg xs>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {" "}
                                                    {element.eyesight}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg xs>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {" "}
                                                    {element.bp}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg xs>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: "8px", sm: "15px", md: "15px", lg: "15px" },
                                                    }}>
                                                    {element.sugar}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
            ) : (
                ""
            )}
        </Box>
    );
};

export default PrintVitals;
