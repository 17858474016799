import { Fragment, useEffect, useState } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { BASE_URL, paymentMode } from "../../assets/constantsFile";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { saveData } from "../../services/api/postApiCalls";
import { getData } from "../../services/api/getApiCalls";
import useHttpGet from "../../common/hooks/customServiceHooks";
import useDiscount from "../../common/hooks/discountHook";

const PaymentComponentLab = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsSendOTP(false);
    setOpen(false);
  };

  const [discount, setDiscount] = useState(0);
  const transformDiscountData = (data) => {
    setDiscount(data);
  };

  const { fetchDataHandler: fetcDiscount } = useDiscount(
    {
      url: BASE_URL + "payment/discount/lab/" + localStorage.getItem("LAB_ID"),
    },
    transformDiscountData
  );

  useEffect(() => {
    fetcDiscount();
  }, []);

  console.log({ discount });

  let paymentVM = "";
  let appointmentId = "";
  let patientId = "";

  const [flag, setFlag] = useState(false);

  if (props.paymentVM.paymentVM) {
    paymentVM = props.paymentVM.paymentVM;
    appointmentId = props.paymentVM.appointmentId;
    patientId = props.paymentVM.patientId;
  }

  const [couponDiscount, setCouponDiscount] = useState(0);
  const [ammountPayable, setAmmountPayable] = useState(
    paymentVM ? paymentVM.amountPayable : 0
  );
  const [cashierList, setCashierList] = useState([]);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [netAmmount, setNetAmmount] = useState(
    paymentVM
      ? paymentVM.amountPayable - (paymentVM.amountPayable * discount) / 100
      : 0
  );
  const [couponCode, setCouponCode] = useState("");
  const [payMode, setPayMode] = useState("CASH");
  const [cashier, setCashier] = useState(null);

  if (props.paymentVM.paymentVM) {
    paymentVM = props.paymentVM.paymentVM;
    appointmentId = props.paymentVM.appointmentId;
    patientId = props.paymentVM.patientId;
  }

  const [formValues, setFormValues] = useState({
    ammountPayable: paymentVM ? paymentVM.amountPayable : 0,
    ammountReceived: paymentVM ? paymentVM.amountCollected : 0,
    payMode: paymentVM ? paymentVM.modeOfPayment : "CASH",
  });

  const [couponData, setCouponData] = useState("");
  const validateCoupon = async (e) => {
    e.preventDefault();
    if (couponCode) {
      const url = BASE_URL + "payment/coupon/" + couponCode;
      const couponData = await getData(url);

      if (couponData.error) {
      } else {
        const data = couponData.data;
        setCouponData(data);
        setNetAmmount(netAmmount - (data.discount * netAmmount) / 100);
        console.log({ couponData: data });
        setCouponDiscount(data.discount);
      }
    }
  };

  const getOTPHandler = async () => {
    if (cashier) {
      setIsSendOTP(true);
      const url = BASE_URL + "payment/otp/" + cashier.mobile;

      const otpData = await saveData(url, "");

      if (otpData.error) {
        console.log({ otpData_error_otp: otpData.error });
      } else {
        console.log({ otpData_data_otp: otpData.data });
      }
    }
  };

  const makePaymentHandler = async (event) => {
    event.preventDefault();
    handleClose();
    const url = BASE_URL + "payment/otp/" + cashier.mobile + "?otp=" + otpValue;

    const otpData = await getData(url);

    if (otpData.error) {
      console.log({ "otpData.error": otpData.error });
    } else {
      const data = otpData.data;
      console.log({ "otpData.data": otpData.data });

      if (data.success === true) {
        const obj = {
          id: props.pDetails.paymentVM ? props.pDetails.paymentVM.id : null,
          patientId: patientId,
          appointmentId: appointmentId,
          appointmentSource: "LAB",
          amountPayable: ammountPayable,
          amountCollected: netAmmount,
          sourceId: localStorage.getItem("LAB_ID"),
          modeOfPayment: payMode,
          paid: true,
          discount: discount,
          finalAmountPayable: netAmmount,
          couponId: couponData ? couponData.id : null,
          cashierId: cashier.id,
          transactionAmountPayable: ammountPayable,
        };

        savePayment(obj);
      }
    }
  };

  const savePayment = async (obj) => {
    const url = BASE_URL + "payment/lab";
    const data = await saveData(url, obj);

    if (data.error) {
      console.log({ data_error: data.error });
    } else {
      console.log({ data_data: data.data });
      setFlag(true);
    }
  };

  const transformData = (data) => {
    console.log({ data });
    setCashierList(data);
  };
  const {
    isLoading,
    error,
    fetchDataHandler: fetchData,
  } = useHttpGet(
    {
      url: BASE_URL + "payment/cashier",
    },
    transformData
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      {paymentVM.paid || flag ? (
        <Chip
          size="small"
          icon={<PaymentIcon />}
          label="Paid"
          onClick={handleClickOpen}
          clickable
          color="primary"
        />
      ) : (
        <Chip
          size="small"
          icon={<PaymentIcon />}
          label="Pending"
          onClick={handleClickOpen}
          clickable
        />
      )}

      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Record New Payment"}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              p: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Total Amount"
                  variant="outlined"
                  value={ammountPayable || 0}
                  onChange={(e) => {
                    setAmmountPayable(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Discount"
                  variant="outlined"
                  value={discount || 0}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    if (
                      !isNaN(e.target.value) &&
                      e.target.value >= 0 &&
                      e.target.value <= 100
                    ) {
                      setDiscount(e.target.value);
                      setNetAmmount(
                        paymentVM.amountPayable -
                          (paymentVM.amountPayable * e.target.value) / 100
                      );
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                {" "}
                <form onSubmit={validateCoupon}>
                  <Stack direction="row">
                    <TextField
                      size={"small"}
                      fullWidth
                      label="Enter Coupon"
                      variant="outlined"
                      value={couponCode || ""}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                        if (e.target.value) {
                        } else {
                          setCouponData("");
                          setNetAmmount(ammountPayable);
                        }
                      }}
                      helperText={
                        couponData
                          ? "you get " + couponData.discount + "% off"
                          : ""
                      }
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: 40 }}
                    >
                      Apply
                    </Button>
                  </Stack>
                </form>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Net Amount"
                  variant="outlined"
                  value={netAmmount || 0}
                  onChange={(e) => {
                    setNetAmmount(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Net Amount"
                  variant="outlined"
                  value={netAmmount || 0}
                  onChange={(e) => {
                    setNetAmmount(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  fullWidth
                  size={"small"}
                  select
                  label="Mode"
                  value={payMode || ""}
                  onChange={(e) => {
                    setPayMode(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  helperText=""
                >
                  {paymentMode.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <Autocomplete
                  fullWidth
                  size={"small"}
                  value={cashier}
                  disableClearable
                  options={cashierList}
                  getOptionLabel={(option) => option.fullName}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setCashier(value);
                    console.log({ value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...params}
                      label="Cashier"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                display="flex"
              >
                <Button
                  onClick={getOTPHandler}
                  variant="contained"
                  sx={{ width: 120 }}
                >
                  Send OTP
                </Button>
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                display="flex"
              >
                {isSendOTP ? (
                  <TextField
                    size={"small"}
                    fullWidth
                    label="Enter OTP"
                    variant="outlined"
                    value={otpValue || ""}
                    onChange={(e) => {
                      setOtpValue(e.target.value);
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-end"
                display="flex"
              >
                {isSendOTP ? (
                  <Button onClick={getOTPHandler} sx={{ width: 120 }}>
                    ReSend OTP
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#fff",
            }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{ width: 120 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isSendOTP}
                    onClick={makePaymentHandler}
                    variant="contained"
                    sx={{ width: 120 }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PaymentComponentLab;
