import { Fragment } from "react";
import NavigationBarAdminPortal from "../../header/NavigationBarAdminPortal";
import DocRegistrationIndex from "../components/doctorRegistration/docRegistrationIndex";

const DocRegistration = () => {
  return (
    <Fragment>
      <NavigationBarAdminPortal />
      <DocRegistrationIndex />
    </Fragment>
  );
};

export default DocRegistration;
