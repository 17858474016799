import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { getFormattedDDMonthYYYY } from "../../assets/utils";
import PaymentComponent from "../../common/paymentComponent/paymentComponent";
import GoToRegistration from "../goToRegistration";
import { useNavigate } from "react-router-dom";

const ClinicAppointmentSucess = (props) => {
  let navigate = useNavigate();

  const toClinicHome = () => {
    navigate("/clinicDashboard");
  };
  console.log(props.data);
  const patient = props.patient;
  const doctor = props.doctor;
  const data = props.data;

  const name =
    (patient.firstName ? patient.firstName + " " : "") +
    (patient.middleName ? patient.middleName + " " : "") +
    (patient.lastName ? patient.lastName : "");
  const age = patient.age ? patient.age : "";
  const gender = patient.gender ? patient.gender : "";
  let doctorName = "";
  if (doctor) {
    doctorName =
      (doctor.firstName ? doctor.firstName + " " : "") +
      (doctor.middleName ? doctor.middleName + " " : "") +
      (doctor.lastName ? doctor.lastName : "");
  }

  const appointmentDate = getFormattedDDMonthYYYY(new Date(data.date));
  const startTime = data.startTime;
  const endTime = data.endTime;

  console.log({ "props.paymentVM": props.paymentVM });
  return (
    <Box
      sx={{
        minHeight: "47vh",
        background: "#fff",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "5vh" }}>
            <Typography>{name} has successfully booked appointment.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "30vh" }}>
            <Typography variant="h6">Appointment Details</Typography>

            <Stack direction={"row"} spacing={2}>
              <Typography>Patient Name:</Typography>
              <Typography>{name}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Age:</Typography>
              <Typography>{age}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Gender:</Typography>
              <Typography>{gender}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Doctor Name:</Typography>
              <Typography>{doctorName}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Appointment Date:</Typography>
              <Typography>{appointmentDate}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography>Appointment Time:</Typography>
              <Typography>{startTime}</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} lg={12} display="flex" justifyContent="center">
          <Box sx={{ minHeight: "7vh" }}>
            <Stack direction="row" spacing={2}>
              <PaymentComponent
                root={props.root}
                paymentVM={props.paymentVM}
                portal="CLINIC"
                portalId={localStorage.getItem("CLINIC_ID")}
                origin={"BOOKING"}
              />

              <Button variant="outlined" onClick={toClinicHome}>
                Pay Later
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Divider color="primary" />
        </Grid>

        <Grid item xs={12} lg={12} display="flex" justifyContent={"center"}>
          <GoToRegistration tabValue={"CLINIC"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClinicAppointmentSucess;
