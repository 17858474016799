import { Box, Button, Grid, ImageList, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import BannerImg from "../assets/images/svg/mainBanner.svg";
import HeaderLogoImg from "../assets/images/svg/headerLogo.svg";

const BannerComponent = () => {
    return (

        <Box
        sx={{
          width: "100%",
          height: "98.5%",
          background: "linear-gradient(90deg, #9AE7EB -1.35%, #D5E1E1 101.6%)",
          pl: 4,
          pt: 4,
        }}
      >
        <Grid container>
          <Grid item lg={12} xs={12}>
          
            <Typography variant="h2">Primary Healthcare for Bharat</Typography>
            
          </Grid>
          <Grid item lg={8} xs={12}>
          <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>

          </Grid>
          <Grid item lg={8} xs={12}>

            <Box component="img"
                alt="card1"
                src={BannerImg}
                
              />
          </Grid>
      
          <Grid item lg={4} xs={12}>
            <Box>
            <Stack alignItems="center">
                <img alt="login" src={HeaderLogoImg} height="160" />
                <Typography variant="body2">
                  Please Enter your 10 Digit Mobile Number
                </Typography>
      
                <TextField
                  size="small"
                  label=""
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: "25ch", background: "#fff", borderRadius: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91-</InputAdornment>
                    ),
                  }}
                />
      
                <Button variant="contained" size="small" sx={{ width: 226 }}>
                  Book Appointment
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      
    );
}
 
export default BannerComponent;