import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { IconButton, InputAdornment, Snackbar, Stack } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Imge from "../../assets/images/medical.png";
import jwt_decode from "jwt-decode";
import {
  getUserToken,
  getUserTokenByMobile,
} from "../../services/api/apiCalls";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Fragment } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import { useEffect } from "react";
import { BASE_URL_AUTH } from "../../assets/constantsFile";

const LoginPageMarketing = () => {
  let navigate = useNavigate();

  useEffect(() => {
    const savedToken = localStorage.getItem("SAVEDTOKENMARKETING");
    if (savedToken) {
      navigate("/marketing/user-onboarding");
    }
  }, []);

  return (
    <Fragment>
      <NavigationBarMarketing />
      <LoginMarketing />
    </Fragment>
  );
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginMarketing = (props) => {
  const [severity, setSeverity] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [openNotice, setOpenNotice] = React.useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  let fffSDa = null;
  let navigate = useNavigate();

  const fetchDoctorDataHandler = React.useCallback(
    async (username, password, role, url) => {
      const user = await getUserToken(username, password, role, url);
      if (user.error) {
        console.log({ "auth data error": "error" });
      } else {
        const data = user.data;
        var token = await data.token;
        var dData = await jwt_decode(token);
        localStorage.setItem("authHeader_local", token);
        localStorage.setItem("SAVEDTOKENMARKETING", token);
        console.log({ "auth data success": dData });
      }
      return dData;
    },
    []
  );

  const myDta = async (a, b, c, d) => {
    fffSDa = await fetchDoctorDataHandler(a, b, c, d);
  };

  const [helperTxtUser, setHelperTxtUser] = React.useState("");
  const [helperTxtPass, setHelperTxtPass] = React.useState("");
  const [helperTxtLogin, setHelperTxtLogin] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userName = data.get("userName");
    const password = data.get("password");
    const role = "MARKETING";
    if (userName === "") {
      setHelperTxtUser("Enter user name.");
    } else {
      setHelperTxtUser("");
    }
    if (password === "") {
      setHelperTxtPass("Enter password.");
    } else {
      setHelperTxtPass("");
    }

    await myDta(userName, password, role);

    if (fffSDa && fffSDa.role === "MARKETING" && userName === fffSDa.sub) {
      localStorage.setItem("loginUserData_local", fffSDa);
      navigate("/marketing/user-onboarding");
      setSeverity("success");
      setMessage("Login successfull");
      setOpenNotice(true);
    } else {
      setSeverity("error");
      setMessage("Login failed! Enter Correct Credentials!");
      setOpenNotice(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  const [isSendOTP, setIsSendOTP] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  const getOTPHandler = async () => {
    if (mobileNumber) {
      console.log({ mobileNumber });
      setIsSendOTP(true);
      const url = BASE_URL_AUTH + "authenticate?sendOtp=true";
      const data = {
        username: mobileNumber,
        role: "MARKETING",
      };

      const otpData = await getUserTokenByMobile(url, data);

      if (otpData.error) {
        console.log({ otpData_error_otp: otpData.error });
        setSeverity("error");
        setMessage("OTP generation faliled.");
        setOpenNotice(true);
      } else {
        console.log({ otpData_data_otp: otpData.data });
        setSeverity("success");
        setMessage("OTP Send to your registered mobile number");
        setOpenNotice(true);
      }
    }
  };

  const handleSubmitOTP = async (event) => {
    event.preventDefault();

    const userName = mobileNumber;
    const password = otp;
    const role = "MARKETING";

    const url = BASE_URL_AUTH + "authenticate?authOnOTP=true";

    if (userName && password) {
      await myDta(mobileNumber, otp, role, url);

      console.log({ useruseruseruser: fffSDa });
      if (fffSDa && fffSDa.role === "MARKETING" && userName === fffSDa.sub) {
        setHelperTxtUser("");
        setHelperTxtPass("");
        setHelperTxtLogin("Login successfull");
        localStorage.setItem("userMobile", fffSDa.sub);
        console.log({ uzerdata: fffSDa });

        setSeverity("success");
        setMessage("Login successfull");
        setOpenNotice(true);
        navigate("/marketing/user-onboarding", { replace: true });
      } else {
        setHelperTxtUser("");
        setHelperTxtPass("");
        setHelperTxtLogin("Login Failed");
        setSeverity("error");
        setMessage("Login failed! Enter Correct Credentials!");
        setOpenNotice(true);
      }
    }
  };

  const otpLogin = (
    <>
      <Box sx={{ background: "#fff" }}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Box
              component="form"
              onSubmit={handleSubmitOTP}
              noValidate
              sx={{ mt: 1 }}
            >
              <Box sx={{ minHeight: "36vh" }}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      required
                      id="mobileNumber"
                      autoComplete="mobileNumber"
                      name="mobileNumber"
                      label="MOBILE NUMBER"
                      helperText={""}
                      value={mobileNumber || ""}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        fullWidth
                        onClick={getOTPHandler}
                        variant="contained"
                      >
                        Send OTP
                      </Button>

                      <Button fullWidth size="small" onClick={getOTPHandler}>
                        Resend
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      variant="standard"
                      size="small"
                      required
                      fullWidth
                      autoComplete="new-password"
                      name="otp"
                      label="ENTER OTP"
                      value={otp || ""}
                      type={showOTP ? "text" : "password"}
                      id="otp"
                      helperText={helperTxtPass}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowOTP(!showOTP);
                              }}
                              onMouseDown={() => {
                                setShowOTP(!showOTP);
                              }}
                            >
                              {showOTP ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2 }}
                    >
                      Sign-in
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  const oldLogin = (
    <>
      {" "}
      <Grid container>
        <Typography variant="h6" sx={{ mt: 5 }}>
          MARKETING
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            size="small"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="User Name"
            name="userName"
            autoComplete="userName"
            helperText={helperTxtUser}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            helperText={helperTxtPass}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Link href="#" underline="none">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign-in
          </Button>

          <Stack direction={"row"}>
            <Typography>Don't have an account?&nbsp;</Typography>
            <Link href="#" underline="none">
              Sign-up
            </Link>
          </Stack>
        </Box>
      </Grid>
    </>
  );
  return (
    <Fragment>
      <Box>
        <Container component="main">
          <Box
            sx={{
              width: "100%",
              height: "84vh",
              mt: 2,
            }}
          >
            <Snackbar
              open={openNotice}
              autoHideDuration={6000}
              onClose={handleCloseNotice}
            >
              <Alert
                onClose={handleCloseNotice}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
            <Grid container>
              <Grid item lg={2}></Grid>
              <Grid item lg={8} xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "56vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#fff",
                    boxShadow: "0px 1px 9px #208F94",
                    borderRadius: "22px",
                    mt: "5rem",
                    p: 1,
                  }}
                >
                  <Grid container rowSpacing={4}>
                    <Grid item lg={12} display="flex" justifyContent="center">
                      <Typography variant="h6" sx={{ mt: 5 }}>
                        MARKETING
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Box sx={{ background: "#fff" }}>
                        <img alt="login" src={Imge} height={355} width={297} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={7}>
                      <Box sx={{ background: "#fff" }}>
                        <Grid
                          container
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {" "}
                          <Grid item lg={8}>
                            {" "}
                            {otpLogin}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default LoginPageMarketing;
