import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useCallback, useState } from "react";
import EmailField from "./components/EmailField";
import jwt_decode from "jwt-decode";
import Password from "./components/Password";
import { useNavigate } from "react-router";
import { saveDataWithoutToken } from "../../../services/api/postApiCalls";
import { Link } from "react-router-dom";
import { BASE_URL_AUTH } from "../../../assets/constantsFile";

const CorpLoginEmailForm = ({ setSeverity, setMessage, setOpenNotice }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);

  const _fetchLoginDetails = useCallback(async (username, password) => {
    const url = BASE_URL_AUTH + `authenticate`;
    const payload = {
      username: username,
      password: password,
      portal: "SNOP",
    };
    const user = await saveDataWithoutToken(url, payload);

    if (user.error) {
      setSeverity("error");
      setMessage("Login failed! Enter Correct Credentials!");
      setOpenNotice(true);
    } else {
      setSeverity("success");
      setMessage("Login successful");
      setOpenNotice(true);

      const data = user.data;
      const token = data.token;

      const userData = await jwt_decode(token);

      localStorage.setItem("authHeader_local", token);
      localStorage.setItem("ROLE_CORP", userData?.role);
      localStorage.setItem("BRANCH_ID_CORP", userData?.userID);
      localStorage.setItem("BRANCH_ID_UNIQUE_CORP", userData?.userID);
      localStorage.setItem("CLINIC_ID_CORP", userData?.clinicId);
      localStorage.setItem("BRANCH_NAME_CORP", userData?.branchName);
      localStorage.setItem("USER_NAME_CORP", userData?.name);
      localStorage.setItem("USER_MOBILE_CORP", userData?.mobile);
      localStorage.setItem("USER_ID_CORP", userData?.id);
      localStorage.setItem("PORTAL_CORP", userData?.portal);
      localStorage.setItem("LAB_ID_CORP", userData?.labId);
      localStorage.setItem(
        "PERMISSION_CORP",
        userData?.permissions ? JSON.stringify(userData?.permissions) : null
      );

      console.log({ SUCCESSLOGIN: userData });

      if (userData !== null) {
        localStorage.setItem("isAdminUser_local", "ADMIN");
        navigate("/corp/select-corp");
      }
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    _fetchLoginDetails(userName, password);
  };
  return (
    <Fragment>
      <Box component="form" onSubmit={(e) => submitHandler(e)}>
        <Grid container>
          <EmailField userName={userName} setUserName={setUserName} />
          <Password password={password} setPassword={setPassword} />

          <Grid item xs={12} sx={{ textAlign: "center", marginBlock: "20px" }}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={isRememberMe}
                    color="primary"
                    onChange={(e) => setIsRememberMe(e.target.checked)}
                  />
                }
                label="Remember me"
                sx={{ ".MuiFormControlLabel-label": { fontSize: 14 } }}
              />
              <Link href="#" underline="none" sx={{ fontSize: 14 }}>
                Forgot password?
              </Link>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{
                borderRadius: "15px",
                height: "40px",
                width: "200px",
                textTransform: "none",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#FFFFFF",
                }}
              >
                Login
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#404040",
              }}
            >
              Don’t Have An Account?
            </Typography>
            <Button
              sx={{
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#127DDD",
              }}
            >
              SIGN-UP
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CorpLoginEmailForm;
