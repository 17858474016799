import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const SOSReason = ({ formValues, setFormValues }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={4} xs={2}>
          <Typography>SOS Reason:</Typography>
        </Grid>
        <Grid item lg={8} xs={10}>
          <TextField
            size="small"
            fullWidth
            placeholder={"SOS Reason"}
            value={formValues.sosReason || ""}
            onChange={(e) =>
              setFormValues({ ...formValues, sosReason: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default SOSReason;
