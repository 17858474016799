import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import RegisterContentMain from "./registerContentMain";

import RegisterNewPatientMainComp from "./registerNewPatient/registerNewPatientMainComp";
import RegisterOldPatientMainComp from "./registerOldPatient/registerOldPatientMainComp";

const PatientRegistrationMainComp = (props) => {
  const contentHandler = (page, data) => {
    if (page === "NEWPATIENT") {
      setContent(
        <RegisterNewPatientMainComp onChangeContent={contentHandler} />
      );
    } else if (page === "OLDPATIENT") {
      setContent(
        <RegisterOldPatientMainComp onChangeContent={contentHandler} />
      );
    } else if (page === "BACK") {
      setContent(<RegisterContentMain onChangeContent={contentHandler} />);
    }
  };
  const [content, setContent] = useState(
    <RegisterContentMain onChangeContent={contentHandler} />
  );

  return (
    <Container
      disableGutters
      component="main"
      maxWidth={false}
      sx={{ height: "89vh", overflow: "hidden" }}
    >
      <Grid container>
        <Grid item lg={12}>
          <Box
            sx={{
              position: "absolute",
              top: "9vh",
              minHeight: "89vh",
              width: "100%",
            }}
          >
            <Container maxWidth={false}>
              <Box
                sx={{
                  mt: 5,
                  maxHeight: "88vh",
                  overflow: "auto",
                  width: "100%",
                  filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
                  background: "#fff",
                }}
              >
                {content}
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PatientRegistrationMainComp;
