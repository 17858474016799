import { Avatar, Box, Chip, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ViewReportComponent from "./viewReport/viewReportComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentComponent from "../common/paymentComponent/paymentComponent";
import PrintIcon from "@mui/icons-material/Print";
import printJS from "print-js";
const printPDFFile = (url) => {
  //e.preventDefault();
  printJS(`${url}?date=${Date.now()}`);
};

const PatientListClinicAttended = (props) => {
  const rows = props.patients.filter(
    (patient, index) => patient.appointmentStatus === "ATTENDED"
  );

  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      type: "number",
      width: 100,
    },
    {
      field: "patient",
      headerAlign: "center",
      align: "center",
      headerName: "PATIENT",
      width: 200,
      editable: false,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      renderCell: (cellValues) => {
        return (
          <Grid container spacing={0} alignItems="center">
            <Grid item lg={12}>
              <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontWeight="fontWeightMedium"
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {cellValues.value.name}
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: ".65rem" }}>
                    {cellValues.value.gender?.charAt(0).toUpperCase()},{" "}
                    {cellValues.value.age}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "doctorFullName",
      headerAlign: "center",
      align: "center",
      headerName: "DOCTOR",
      width: 140,
    },
    {
      field: "mobile",
      headerAlign: "center",
      align: "center",
      headerName: "CONTACT NO.",
      width: 120,
      editable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "startTime",
      headerAlign: "center",
      align: "center",
      headerName: "APPOINTMENT",
      width: 140,
      editable: false,
    },

    {
      field: "printPrescription",
      headerAlign: "center",
      align: "center",
      headerName: "PRESCRIPTION",
      width: 120,
      editable: false,
      renderCell: (cellValues) => {
        const disabled = !(
          (cellValues.value.prescriptionVM &&
            cellValues.value.prescriptionVM.medicines &&
            cellValues.value.prescriptionVM.medicines.length > 0) ||
          (cellValues.value.reports && cellValues.value.reports.length > 0) ||
          cellValues.value.observation
        );

        return (
          <Chip
            icon={<PrintIcon />}
            size="small"
            variant="contained"
            label="print"
            onClick={() => viewPrescriptions(cellValues.value)}
          />
        );
      },
    },
    {
      field: "paymentVM",
      headerAlign: "center",
      align: "center",
      headerName: "PAYMENT",
      width: 140,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <PaymentComponent
            root={props.root}
            paymentVM={cellValues.value}
            portal="CLINIC"
            portalId={localStorage.getItem("CLINIC_ID")}
          />
        );
      },
    },
  ];

  const viewhandler = () => {
    setContent(
      <DataGrid
        getRowId={(row) => row.appointmentId}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
      />
    );
  };

  const viewPrescriptions = (value) => {
    console.log(value);

    printPDFFile(value?.prescriptionUrl);

    // setContent(
    //   <ViewReportComponent patientDetails={value} onViewPres={viewhandler} />
    // );
  };

  const [content, setContent] = useState(
    <DataGrid
      getRowId={(row) => row.appointmentId}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
    />
  );

  useEffect(() => {
    setContent(
      <DataGrid
        getRowId={(row) => row.appointmentId}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
      />
    );
  }, [props.patients]);
  return (
    <Container maxWidth={false} sx={{ overflow: "auto", maxHeight: "72vh" }}>
      <Box>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Box style={{ height: "72vh", width: "100%" }}>
              <Box style={{ display: "flex", height: "100%" }}>
                <Box style={{ flexGrow: 1 }}>{content}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PatientListClinicAttended;
