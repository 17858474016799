import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { registerNewPatientContext } from "../../common/context/usercontext";

const SearchResults = (props) => {
  const [selectionModel, setSelectionModel] = useState([]);

  const contextData = useContext(registerNewPatientContext);
  const rows = contextData.patientList;

  useEffect(() => {}, [selectionModel]);

  const columns = [
    {
      field: "patientId",
      headerAlign: "center",
      align: "center",
      headerName: "P ID",
      width: 150,
    },

    {
      field: "firstName",
      headerAlign: "center",
      align: "center",
      headerName: "First Name",
      width: 100,
    },

    {
      field: "middleName",
      headerAlign: "center",
      align: "center",
      headerName: "Middle Name",
      width: 100,
    },

    {
      field: "lastName",
      headerAlign: "center",
      align: "center",
      headerName: "Last Name",
      width: 100,
    },

    {
      field: "age",
      headerAlign: "center",
      align: "center",
      headerName: "Age",
      width: 100,
    },

    {
      field: "gender",
      headerAlign: "center",
      align: "center",
      headerName: "Gender",
      width: 70,
    },

    {
      field: "email",
      headerAlign: "center",
      align: "center",
      headerName: "Email",
      width: 150,
    },

    {
      field: "mobile",
      headerAlign: "center",
      align: "center",
      headerName: "Mobile No.",
      width: 100,
    },

    {
      field: "guardianFirstName",
      headerAlign: "center",
      align: "center",
      headerName: "Guardian Name",
      width: 200,
    },

    {
      field: "guardianContactNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Guardian Contact No.",
      width: 150,
    },
  ];
  console.log({ selectionModel: selectionModel });

  return (
    <Box sx={{ height: "53vh", p: 2, background: "#fff" }}>
      <Box style={{ display: "flex", height: "100%" }}>
        <Box style={{ flexGrow: 1 }}>
          <DataGrid
            getRowId={(row) => row.patientId}
            onSelectionModelChange={(newSelectionModel) => {
              props.onRowSelect(newSelectionModel);
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableMultipleSelection
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchResults;
