import React from "react";
import { Fragment } from "react";
import { Typography, TextField, Grid } from "@mui/material";

const CustomTextFieldWithTitle = ({
  title,
  label,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <Typography
          sx={{ marginBottom: "10px", fontSize: "17px", color: "#000000" }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <TextField
          sx={{
            background: "#ffffff",
            color: "#000000",
            fontWeight: 300,
            fontSize: "13px",
            lineHeight: "15px",
            "& input::placeholder": {
              color: "#777777",
              fontWeight: 300,
              fontSize: "13px",
              lineHeight: "15px",
            },
          }}
          size="small"
          fullWidth
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default CustomTextFieldWithTitle;
