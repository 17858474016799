import { Box, TextField, Typography } from "@mui/material";
import {
  LocalizationProvider,
  //   MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers/";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { Fragment, useState } from "react";

const CustomTime = ({
  time,
  setTime,
  setFormValues,
  formValues,
  disabled,
  property,
}) => {
  const [timeValue, setTimeValue] = useState(
    time ? new Date(time) : new Date()
  );

  const handleTimeChange = (newValue) => {
    setTimeValue(newValue);
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = newValue.toLocaleString("en-US", timeOptions);
    setTime(formattedTime);
    if (formValues && setFormValues) {
      setFormValues({ ...formValues, [property]: formattedTime || null });
    }
  };
  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          disabled={disabled}
          label=""
          value={timeValue}
          onChange={handleTimeChange}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </LocalizationProvider>
    </Fragment>
  );
};

export default CustomTime;
