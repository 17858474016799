import { Box, Grid, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import { VITALS_SUGAR } from "../../assets/constantsText"

const PrintVitals = props => {
  const vitalInfo = props.vitalInfo
  return (
    <Fragment>
      {vitalInfo &&
      (vitalInfo.bp ||
        vitalInfo.weight ||
        vitalInfo.height ||
        vitalInfo.heartRate ||
        vitalInfo.spo2 ||
        vitalInfo.temperature ||
        vitalInfo.sugar) ? (
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex">
            <Grid item lg={1} md={1} sm={1} xs={1} justifyContent="flex-start" direction={"row"}>
              <Stack direction={"row"} display="flex">
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: "13px",
                    lineHeight: "9px",
                    textTransform: "uppercase",
                    color: "#127DDD",
                  }}>
                  Vitals:
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              lg={11}
              md={11}
              sm={11}
              xs={11}
              display="flex"
              justifyContent="space-between">
              {vitalInfo.heartRate && vitalInfo.heartRate > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>Heart Rate:&nbsp;</Typography>
                  <Typography sx={styles.headingData}>
                    {vitalInfo.heartRate} {" bpm"}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.bp && vitalInfo.bp.length > 1 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>BP-&nbsp;</Typography>
                  <Typography sx={styles.headingData}>
                    {vitalInfo.bp} {" mmHg"}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.weight && vitalInfo.weight > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>Weight-&nbsp;</Typography>
                  <Typography sx={styles.headingData}>
                    {vitalInfo.weight} {" kg"}{" "}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.height && vitalInfo.height > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>Height-&nbsp;</Typography>
                  <Typography sx={styles.headingData}>
                    {vitalInfo.height} {" cm"}{" "}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.spo2 && vitalInfo.spo2 > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>SpO2-&nbsp;</Typography>
                  <Typography sx={styles.headingData}>
                    {vitalInfo.spo2} {" %"}{" "}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.temperature && vitalInfo.temperature > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>Temp-&nbsp;</Typography>
                  <Typography sx={styles.headingData}>{vitalInfo.temperature} &#8457; </Typography>
                </Stack>
              ) : (
                ""
              )}

              {vitalInfo.sugar && vitalInfo.sugar > 0 ? (
                <Stack direction={"row"} display="flex" alignItems={"center"}>
                  <Typography sx={styles.heading}>{VITALS_SUGAR}:&nbsp;</Typography>
                  <Typography sx={styles.headingData}>{vitalInfo.sugar} mmol/L</Typography>
                </Stack>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item lg={12} md={12} sm={12} xs={12} display="flex">
          <Stack direction={"row"} display="flex" alignItems={"center"}>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: "13px",
                marginRight: "5px",
                lineHeight: "9px",
                textTransform: "uppercase",
                color: "#127DDD",
              }}>
              Vitals:
            </Typography>
            <Typography sx={styles.headingData}>Vital data not available.</Typography>
          </Stack>
        </Grid>
      )}
    </Fragment>
  )
}

export default PrintVitals

const styles = {
  heading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
  },
  headingData: {
    fontWeight: 400,
    marginleft: "300px",
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
  },
}
