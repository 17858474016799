import { useCallback, useEffect, useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import unoLogo from "../../assets/svg/unoLogo.svg";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import OTPLoginForm from "./otpLoginForm";
import UserNameLoginForm from "./userNameLoginForm";

const LoginRightPane = ({
  setSeverity,
  setMessage,
  setOpenNotice,
  handleCloseNotice,
}) => {
  const [showOTPForm, setShowOTPForm] = useState(true);
  return (
    <Box>
      <Grid container>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            sx={{
              width: "40%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            alt="Logo"
            src={unoLogo}
          />
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Typography
            fontSize="2rem"
            fontWeight={600}
            color="#404040"
            sx={{ mt: 4, mb: 4 }}
          >
            Login
          </Typography>
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12}>
          {showOTPForm ? (
            <OTPLoginForm
              setShowOTPForm={setShowOTPForm}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
            />
          ) : (
            <UserNameLoginForm
              setShowOTPForm={setShowOTPForm}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginRightPane;
