import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  TextField,
} from "@mui/material";
import { Fragment } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApiCalls";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PaymentComp from "../paymentComp/paymentComp";
import { saveData } from "../../services/api/postApiCalls";
import ReportedIssue from "./appointmetFormElements/reportedIssue";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ResumeConsultationregistrationForm from "./resumeConsultation/resumeConsultationRegistrationForm";
import ResumeConsltMainComp from "./resumeConsultation/resumeConsltMainComp";
import PatientHistoryMainComp from "../registration/patientHistoryFormComps/patientHistoryMainComp";
import { VITALS_SUGAR } from "../../assets/constantsText";

const BookAppointmentDialog = ({
  open,
  handleClose,
  patientId,
  patientData,
}) => {
  const [appointmentData, seAppointmentData] = useState();
  const [isBooked, setIsBooked] = useState(false);
  const [isPatientInfo, setIsPatientInfo] = useState(true);
  const [isDocAutoSelect, setIsDocAutoSelect] = useState(false);

  const [specializationList, setSpecializationList] = useState([]);
  const [listOfDoctors, setListOfDoctors] = useState([]);
  const [docSlots, setDocSlots] = useState([]);
  const [dateValue, setDateValue] = useState(new Date());
  const [availablePositionKey, setAvailablePositionKey] = useState("");
  const [availablePosition, setAvailablePosition] = useState(null);

  const [cDoctor, setCDoctor] = useState("");
  const [cSlotNo, setCSlotNo] = useState("");
  const [cAmmountPayable, setCAmmountPayable] = useState(0);
  const [cAmmountReceived, setCAmmountReceived] = useState(0);
  let flag = true;

  const [formDataClinic, setFormDataClinic] = useState({
    cIssue: "",
    cSpeciality: {
      autoAssignPatient: false,
      specialization: "General Physician",
    },
    cDOA: new Date(),
    cPayMode: "CASH",
    cStartTime: "",
    cEndTime: "",
    bp: "",
    heartRate: "",
    height: "",
    spo2: "",
    weight: "",
    temperature: "",
    sugar: "",
  });

  let handleChangeClinic = (e) => {
    setFormDataClinic({ ...formDataClinic, [e.target.name]: e.target.value });
  };

  const fetchSpecializationHandler = useCallback(async () => {
    const url =
      BASE_URL +
      "clinic/getSpecializations/" +
      localStorage.getItem("BRANCH_ID");
    const url1 = BASE_URL + "clinic/getSpecializationsWithConfig";
    const user = await getData(url1);

    if (user.error) {
      console.log("error");
    } else {
      const data1 = user.data;
      setSpecializationList(data1);
      console.log({ setSpecializationList: data1 });
    }
  }, []);

  useEffect(() => {
    fetchSpecializationHandler();
  }, [fetchSpecializationHandler]);

  const fetchDoctorListHandler = useCallback(async (sp, d) => {
    console.log({ sp: sp.specialization });
    const url =
      BASE_URL +
      "clinic/searchDoctor/" +
      sp.specialization +
      "?date=" +
      d +
      "&branchId=" +
      localStorage.getItem("BRANCH_ID_UNIQUE") +
      "&isEdit=" +
      !sp.autoAssignPatient;
    const user = await getData(url);

    if (user.error) {
      setListOfDoctors([]);
    } else {
      const data1 = user.data;
      setListOfDoctors(data1);
      console.log({ listofdoctors: data1 });
      setCDoctor(data1 && data1.length > 0 ? data1[0] : "");
      setDocSlots(data1 && data1.length > 0 ? data1[0].slots : []);
    }
  }, []);

  useEffect(() => {
    fetchDoctorListHandler(
      formDataClinic.cSpeciality,
      dateValue.toISOString().split("T")[0]
    );

    //setCDoctor("");
    setCSlotNo("");

    // setDocSlots([]);
  }, [dateValue, formDataClinic.cSpeciality]);

  const submitHandlerC = (event) => {
    event.preventDefault();

    //dateValue.toISOString().split("T")[0]
    const pObj = {
      docId: cDoctor.docId,
      clinicId: localStorage.getItem("CLINIC_ID"),
      patientId: patientId,
      paymentRequired: true,
      issue: issues.symptom,
      startTime: cSlotNo ? cSlotNo.startTime : "",
      endTime: cSlotNo ? cSlotNo.endTime : "",
      date: formDataClinic.cDOA.toISOString().split("T")[0],
      observation: "",
      slotNo: cSlotNo ? cSlotNo.slotNo : 0,
      consultationType: "SCHEDULED",
      paymentVM: {
        appointmentSource: "CLINIC",
        amountPayable: cDoctor.fees,
        amountCollected: 0,
        finalAmountPayable: cDoctor.fees,
        branchId: localStorage.getItem("BRANCH_ID"),
      },
      appointmentSource: "CLINIC",
      videoURL: "",
      vitalsVM: {
        patientId: patientId,
        bp: formDataClinic.bp,
        spo2: formDataClinic.spo2,
        heartRate: formDataClinic.heartRate,
        temperature: formDataClinic.temperature,
        height: formDataClinic.height,
        weight: formDataClinic.weight,
        sugar: formDataClinic.sugar,
      },
    };
    console.log({ "myobj to be saved": pObj });
    if (patientId && flag) {
      flag = false;
      bookAppointment(pObj, cDoctor);
    }

    console.log({ formDataClinic: formDataClinic });
  };
  const bookAppointment = useCallback(async (obj, doctor) => {
    const bApp = await saveData(BASE_URL + "clinic/appointment", obj);

    if (bApp.error) {
    } else {
      seAppointmentData(bApp.data);
      setIsBooked(true);
      console.log({ formDataClinic_afterSubmit: bApp.data });
    }
  }, []);

  console.log({ formDataClinic_availablePositionKey: availablePositionKey });
  console.log({
    "formDataClinic_formDataClinic.availablePosition": availablePosition
      ? availablePosition[availablePositionKey]
      : "",
  });
  console.log({ "formDataClinic_formDataClinic.cSlotNo": cSlotNo });

  const [issues, setIssues] = useState("");

  console.log({ issues });
  const [value, setValue] = useState("2");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeDailog = () => {
    setIsPatientInfo(true);
    handleClose();
    setValue("2");
  };
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={closeDailog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" align="center">
          Book Appointment
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            {isBooked ? (
              <PaymentComp
                data={appointmentData}
                patientId={patientId}
                source="CLINIC"
              />
            ) : isPatientInfo ? (
              <Grid container>
                <Grid item lg={12}>
                  <Box sx={{ height: "70vh", p: 2, background: "#fff" }}>
                    <PatientHistoryMainComp
                      patientId={patientId}
                      patientData={patientData}
                      handleClose={closeDailog}
                      setIsPatientInfo={setIsPatientInfo}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item lg={12}>
                  <Box sx={{ height: "70vh", p: 2, background: "#fff" }}>
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          borderRadius: 4,
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          centered
                          textColor="primary"
                          indicatorColor="primary"
                        >
                          <Tab
                            label={"New Consultation"}
                            value="1"
                            sx={{
                              width: 200,
                              height: 10,
                              borderRadius: 10,
                              backgroundColor: "#208F94",
                            }}
                          />
                          <Tab
                            label={"Resume Consultation"}
                            value="2"
                            sx={{
                              width: 200,
                              height: 10,
                              borderRadius: 10,
                              backgroundColor: "#208F94",
                            }}
                          />
                        </TabList>
                      </Box>

                      <TabPanel value="1">
                        <Box>
                          <form id="consultReg" onSubmit={submitHandlerC}>
                            <Box
                              sx={{
                                minHeight: "47vh",
                                background: "#F3F3F3",
                                borderRadius: 2,
                                p: 2,
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} lg={6} sm={12} md={12}>
                                  <ReportedIssue
                                    issues={issues}
                                    setIssues={setIssues}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={3} sm={12} md={12}>
                                  <Autocomplete
                                    size={"small"}
                                    fullWidth
                                    freeSolo
                                    id="specializationList"
                                    name="cSpeciality"
                                    value={formDataClinic.cSpeciality}
                                    disableClearable
                                    options={specializationList.filter(
                                      (ele) => {
                                        return ele !== null;
                                      }
                                    )}
                                    onChange={(event, newValue) => {
                                      setFormDataClinic({
                                        ...formDataClinic,
                                        cSpeciality: newValue,
                                      });

                                      setIsDocAutoSelect(
                                        newValue.autoAssignPatient
                                      );
                                    }}
                                    getOptionLabel={(option) =>
                                      option.specialization
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Doctor's Specialization"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={3} sm={12} md={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      disablePast
                                      inputFormat="dd MMM yyyy"
                                      disableMaskedInput={true}
                                      label="Date"
                                      openTo="day"
                                      views={["year", "month", "day"]}
                                      value={formDataClinic.cDOA}
                                      name="cDOA"
                                      onChange={(newValue) => {
                                        setFormDataClinic({
                                          ...formDataClinic,
                                          cDOA: newValue,
                                        });
                                        setDateValue(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size="small"
                                          fullWidth
                                          sx={{ fontSize: 10 }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} lg={4} sm={12} md={12}>
                                  <Autocomplete
                                    size={"small"}
                                    freeSolo
                                    id="doctorSearch"
                                    name="cDoctor"
                                    value={cDoctor}
                                    disableClearable
                                    disabled={isDocAutoSelect}
                                    options={listOfDoctors}
                                    getOptionLabel={(option) =>
                                      (option.firstName
                                        ? option.firstName
                                        : "") +
                                      " " +
                                      (option.middleName
                                        ? option.middleName
                                        : "") +
                                      " " +
                                      (option.lastName ? option.lastName : "")
                                    }
                                    onChange={(event, value) => {
                                      setCDoctor(value);
                                      setCSlotNo("");
                                      console.log({ cDoctor: value });
                                      value
                                        ? setDocSlots(value.slots)
                                        : setDocSlots([]);
                                      value
                                        ? setCAmmountPayable(value.fees)
                                        : setCAmmountPayable(0);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...params}
                                        label="Doctor's Name"
                                        InputProps={{
                                          ...params.InputProps,
                                          type: "search",
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={4} sm={12} md={12}>
                                  <Autocomplete
                                    size={"small"}
                                    freeSolo
                                    id="slotBooking"
                                    name="cSlotNo"
                                    value={cSlotNo}
                                    disableClearable
                                    options={docSlots}
                                    getOptionLabel={(option) =>
                                      option
                                        ? option.startTime +
                                          " to " +
                                          option.endTime
                                        : ""
                                    }
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.startTime} to {option.endTime}
                                      </Box>
                                    )}
                                    onChange={(event, value) => {
                                      setCSlotNo(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...params}
                                        label="Choose a slot"
                                        InputProps={{
                                          ...params.InputProps,
                                          type: "search",
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  {cSlotNo && cSlotNo.startTime ? (
                                    <TextField
                                      fullWidth
                                      disabled
                                      size="small"
                                      label="Start Time"
                                      variant="outlined"
                                      value={cSlotNo.startTime}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  {cSlotNo && cSlotNo.endTime ? (
                                    <TextField
                                      fullWidth
                                      disabled
                                      size="small"
                                      label="End Time"
                                      variant="outlined"
                                      value={cSlotNo.endTime}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                <Grid item xs={12} lg={12} sm={12} md={12}>
                                  <Divider>Vitals</Divider>
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="bp"
                                    label="BP"
                                    variant="outlined"
                                    value={formDataClinic.bp || ""}
                                    onChange={(e) => handleChangeClinic(e)}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          mmHg
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="heartRate"
                                    label="Heart Rate"
                                    variant="outlined"
                                    value={formDataClinic.heartRate}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          bpm
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="height"
                                    label="Height"
                                    variant="outlined"
                                    value={formDataClinic.height}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          cm
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="spo2"
                                    label="SpO2"
                                    variant="outlined"
                                    value={formDataClinic.spo2}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="weight"
                                    label="Weight"
                                    variant="outlined"
                                    value={formDataClinic.weight}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          kg
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="temperature"
                                    label="Temperature"
                                    variant="outlined"
                                    value={formDataClinic.temperature}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          F
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} lg={2} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="sugar"
                                    label={VITALS_SUGAR}
                                    variant="outlined"
                                    value={formDataClinic.sugar}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleChangeClinic(e);
                                      }
                                    }}
                                    onFocus={(el) => {
                                      el.target.select();
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          mmol/L
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box
                              sx={{
                                height: "3vh",
                                background: "#fff",
                                p: 2,
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  display="flex"
                                  justifyContent="flex-end"
                                >
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      variant="contained"
                                      onClick={closeDailog}
                                    >
                                      Cancel
                                    </Button>
                                    <Button variant="contained" type="submit">
                                      book appointment
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        </Box>
                      </TabPanel>
                      <TabPanel value="2">
                        <Box>
                          <ResumeConsltMainComp
                            patientId={patientId}
                            patientData={patientData}
                            handleClose={closeDailog}
                          />
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default BookAppointmentDialog;
