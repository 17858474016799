import { Box, Grid, Stack, Typography } from "@mui/material";

const LogoMainCustom = ({ clinicLogoURL }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
            <img src={clinicLogoURL} alt="logo" height="221" />
          </Stack>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
      </Grid>
    </Box>
  );
};

export default LogoMainCustom;
