import { TextField } from "@mui/material";
import { Fragment } from "react";

const AgeElement = ({ element, age, setAge, setDOB, variant }) => {
  return (
    <Fragment>
      <TextField
        sx={{ p: 0.3 }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label={variant === "small" ? "Enter Age..." : ""}
        value={element.age || ""}
        onChange={(e) => {
          if (!isNaN(e.target.value) && e.target.value.length < 3) {
            element["age"] = e.target.value;
            setAge(e.target.value);
            setDOB(null);
            element["dob"] = null;
          }
        }}
      />
    </Fragment>
  );
};

export default AgeElement;
