import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApiCalls";
import printJS from "print-js";

const PrintConsolidatedReport = ({ corpId, searchedEmployeeId }) => {
  const [isLoadng, setIsLoading] = useState(false);

  const _handleSubmit = async () => {
    const url = BASE_URL + `org/print/tests`;
    setIsLoading(true);

    const payload = {
      corpId: corpId,
      empId: searchedEmployeeId,
    };

    const printData = await saveData(url, payload);

    if (printData.error) {
      console.log({ error: printData.error });
    } else {
      console.log({ success: printData.data });
      printJS(printData.data);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item lg>
          <Stack direction="row-reverse">
            <Button
              disabled={searchedEmployeeId ? false : true}
              variant="contained"
              //sx={{ width: 300 }}
              onClick={_handleSubmit}
            >
              Print Consolidated Report
              {isLoadng && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              )}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PrintConsolidatedReport;
