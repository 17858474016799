import React, { Fragment } from "react"
import DashboardAshaWorker from "./dashboardAshaWorker/dashboardAshaWoker"
import NavigationBarAshaWorkers from "./components/navigationBar/navigationBarAshaWorkers"

const LandingAshaWorker = () => {
  return (
    <Fragment>
      <NavigationBarAshaWorkers />
      <DashboardAshaWorker />
    </Fragment>
  )
}

export default LandingAshaWorker
