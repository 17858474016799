import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import CustomTime from "../../../components/CustomTime";
import CustomDate from "../../../components/CustomDate";

const ExecutionDetails = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  setTime,
  time,
}) => {
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
          <Typography sx={{ color: "#000" }}>Execution Date Req</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography sx={{ color: "#000", marginRight: "10px" }}>
            From
          </Typography>
          <CustomDate
            setDate={setFromDate}
            initialDate={fromDate}
            disablePast={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography sx={{ color: "#000", marginInline: "10px" }}>
            To
          </Typography>
          <CustomDate
            setDate={setToDate}
            initialDate={toDate}
            disablePast={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography sx={{ color: "#000" }}>Reporting Time</Typography>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
          <CustomTime setTime={setTime} time={time} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ExecutionDetails;
