import { Button, Divider, Grid, Link, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import axios from "axios";

// Modal component for Entering Case Details
const CaseForm = ({
    patientId,
    caseId = null,
    editHandler = null,
    buttonText = "submit",
    patientData,
    handleClose,
}) => {
    const [doctorName, setDoctorName] = useState("");
    const [issue, setIssue] = useState("");
    const [date, setDate] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    console.log({ patientDataEdit: patientData });

    useEffect(() => {
        if (patientData) {
            setDoctorName(patientData.doctorName);
            setIssue(patientData.issue);
            setDate(patientData.date);
        }
    }, []);

    const handleDoctorNameChange = (event) => {
        setDoctorName(event.target.value);
    };

    const handleIssueChange = (event) => {
        setIssue(event.target.value);
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // const standardFormat = new Date(date).toISOString();

        const userMobile = localStorage.getItem("userMobile");

        let formData = new FormData();
        formData.append("doctorName", doctorName);
        formData.append("issue", issue);
        formData.append("patientId", patientId);
        formData.append("date", date);
        formData.append("internalStaffPhoneNumber", userMobile);
        caseId && formData.append("caseId", caseId);

        for (const file of selectedFiles) {
            formData.append("file", file);
        }
        try {
            const responce = await axios.post(
                BASE_URL + "healthcamp/digitalize/reports",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("authHeader_local"),
                    },
                }
            );
            const { data } = responce;

            editHandler && editHandler();

            const imageUrls = data.digitalReports.map((report) => report.reportUrl);
            setImageUrls(imageUrls);
            handleClose();
        } catch (error) {
            console.error(error);
            // handleClose();
        }
        // const payload = { doctorName, issue, patientId: 12360, date, file: selectedFiles, appointmentId: 12369 };
    };

    return (
        <>
            <Grid container spacing={1}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} sx={{ m: 1, p: 0.3, mb: 4 }}>
                        <Grid item lg={2} sx={12}>
                            <TextField
                                id="doctor-name"
                                label="Doctor Name"
                                value={doctorName}
                                onChange={handleDoctorNameChange}
                            />
                        </Grid>
                        <Grid item lg={2} sx={12}>
                            <TextField
                                id="issue"
                                label="Issue"
                                value={issue}
                                onChange={handleIssueChange}
                            />
                        </Grid>
                        <Grid item lg={2} sx={12}>
                            <TextField
                                id="date"
                                label="date"
                                value={date}
                                onChange={handleDateChange}
                            />
                        </Grid>
                        <Grid item lg={2} sx={12}>
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" sx={{ p: 1.7 }} component="span">
                                    Upload
                                </Button>
                            </label>
                        </Grid>
                        <Grid item lg={2} sx={12}>
                            <Button variant="outlined" type="submit" sx={{ p: 1.7 }}>
                                {buttonText}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Divider />

            {/* improve this to Gallery View */}

            {imageUrls.length > 0 && (
                <Grid container lg={12} spacing={1}>
                    <Grid item lg={4} borderRight={1}>
                        Image{" "}
                    </Grid>
                    <Grid item lg={8}>
                        URL
                    </Grid>
                    <Divider />
                    {imageUrls.map((e, index) => (
                        <>
                            <Grid item lg={4} borderRight={1}>
                                {`image${index + 1}`}
                            </Grid>
                            <Grid item lg={8} sx={{ h: 1, w: 1 }}>
                                <Link href={e}>{e}</Link>
                            </Grid>
                        </>
                    ))}
                </Grid>
            )}
        </>
    );
};
export default CaseForm;
