import { Fragment } from "react";
import LogoutIndex from "../components/logout/logoutIndex";

const AdminLogout = (props) => {
  return (
    <Fragment>
      <LogoutIndex />
    </Fragment>
  );
};

export default AdminLogout;
