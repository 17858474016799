import { Button, Stack } from "@mui/material";
import { Fragment } from "react";

const ActionHC = ({ saveAction, cancelAction, buttonText1, buttonText2 }) => {
    return (
        <Fragment>
            <Stack direction="row" spacing={3} sx={{ width: "100%" }}>
                <Button variant="outlined" fullWidth onClick={cancelAction}>
                    {buttonText2 ? buttonText2 : "Cancel"}
                </Button>

                <Button variant="contained" fullWidth onClick={saveAction}>
                    {buttonText1 ? buttonText1 : "Save"}
                </Button>
            </Stack>
        </Fragment>
    );
};

export default ActionHC;
