import { Fragment } from "react";
import NavigationBarMarketing from "../../header/NavigationBarMarketing";
import UserInformation from "../../marketing/userInformation";

const UserInformationMK = () => {
  return (
    <Fragment>
      <NavigationBarMarketing />
      <UserInformation />
    </Fragment>
  );
};

export default UserInformationMK;
