import { Fragment, useEffect, useState } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { BASE_URL, LAB_ID, paymentMode } from "../../assets/constantsFile";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { saveData } from "../../services/api/postApiCalls";
import { getData } from "../../services/api/getApiCalls";
import useHttpGet from "../hooks/customServiceHooks";
import useDiscount from "../hooks/discountHook";
import AddIcon from "@mui/icons-material/Add";

const PaymentComponent = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    if (props.portal === "CLINIC") {
      console.log("CLINIC");
      fetcDiscountClinic();
    } else if (props.portal === "LAB") {
      console.log("LAB");
      fetcDiscountLab();
    } else if (props.portal === "PHARMACY") {
      console.log("PHARMACY");
      fetcDiscountPharmacy();
    }
  };
  const handleClose = () => {
    setIsSendOTP(false);
    setOpen(false);
  };

  let paymentVM = "";
  let appointmentId = "";
  let patientId = "";
  let docId = "";

  const [flag, setFlag] = useState(false);

  if (props.paymentVM.paymentVM) {
    paymentVM = props.paymentVM.paymentVM;
    appointmentId = props.paymentVM.appointmentId;
    patientId = props.paymentVM.patientId;
    docId = props.paymentVM.docId;
  }

  const [discount, setDiscount] = useState(paymentVM ? paymentVM.discount : 0);
  const transformDiscountData = (data) => {
    if (paymentVM && paymentVM.discount) {
      setDiscount(paymentVM.discount);
    } else {
      setDiscount(data);
    }
  };

  const { fetchDataHandler: fetcDiscountClinic } = useDiscount(
    {
      url: BASE_URL + "payment/discount/clinic/" + docId,
    },
    transformDiscountData
  );

  const { fetchDataHandler: fetcDiscountLab } = useDiscount(
    {
      url: BASE_URL + "payment/discount/lab/" + props.portalId,
    },
    transformDiscountData
  );

  const { fetchDataHandler: fetcDiscountPharmacy } = useDiscount(
    {
      url: BASE_URL + "payment/discount/pharmacy/" + props.portalId,
    },
    transformDiscountData
  );

  console.log({ discount });

  const [couponDiscount, setCouponDiscount] = useState(0);
  const [ammountPayable, setAmmountPayable] = useState(
    paymentVM ? paymentVM.amountPayable : 0
  );

  console.log({ paymentVM });
  const [cashierList, setCashierList] = useState([]);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [netAmmount, setNetAmmount] = useState(
    paymentVM
      ? paymentVM.finalAmountPayable
        ? paymentVM.finalAmountPayable
        : ammountPayable -
          (ammountPayable * (parseInt(couponDiscount) + parseInt(discount))) /
            100
      : 0
  );
  const [ammountPaid, setAmmountPaid] = useState(0);
  const [ammountCollected, setAmmountCollected] = useState(
    paymentVM ? paymentVM.amountCollected : 0
  );
  const [ammountPending, setAmmountPending] = useState(
    netAmmount - ammountCollected
  );
  const [couponCode, setCouponCode] = useState(
    paymentVM && paymentVM.couponVM ? paymentVM.couponVM.couponCode : ""
  );
  const [payMode, setPayMode] = useState("CASH");
  const [cashier, setCashier] = useState(null);

  const [couponData, setCouponData] = useState(
    paymentVM && paymentVM.couponVM ? paymentVM.couponVM : ""
  );
  const validateCoupon = async (e) => {
    e.preventDefault();
    if (couponCode) {
      const url = BASE_URL + "payment/coupon/" + couponCode;
      const couponData = await getData(url);

      if (couponData.error) {
      } else {
        const data = couponData.data;
        setCouponData(data);

        let disc = 0;

        if (parseInt(discount) + parseInt(data.discount) < 100) {
          disc = (parseInt(discount) + parseInt(data.discount)) / 100;
        } else {
          disc = 1;
        }
        console.log({ disc });

        setNetAmmount(ammountPayable - ammountPayable * disc);
        setAmmountPending(
          ammountPayable - ammountPayable * disc - ammountCollected
        );
        console.log({ couponData: data });
        setCouponDiscount(data.discount);
      }
    }
  };

  const getOTPHandler = async () => {
    if (cashier) {
      setIsSendOTP(true);
      const url = BASE_URL + "payment/otp/" + cashier.mobile;

      const otpData = await saveData(url, "");

      if (otpData.error) {
        console.log({ otpData_error_otp: otpData.error });
      } else {
        console.log({ otpData_data_otp: otpData.data });
      }
    }
  };

  const makePaymentHandler = async (event) => {
    event.preventDefault();
    handleClose();
    const url = BASE_URL + "payment/otp/" + cashier.mobile + "?otp=" + otpValue;

    const otpData = await getData(url);

    if (otpData.error) {
      console.log({ "otpData.error": otpData.error });
    } else {
      const data = otpData.data;
      console.log({ "otpData.data": otpData.data });

      if (data.success === true) {
        const obj = {
          id: paymentVM ? paymentVM.id : null,
          patientId: patientId,
          appointmentId: appointmentId,
          appointmentSource: props.portal,
          amountPayable: ammountPayable,
          amountCollected: ammountCollected
            ? parseInt(ammountCollected) + parseInt(ammountPaid)
            : ammountPaid,
          sourceId: props.portalId,
          modeOfPayment: payMode,
          paid:
            netAmmount === parseInt(ammountCollected) + parseInt(ammountPaid)
              ? true
              : false,
          discount: discount,
          finalAmountPayable: netAmmount,
          couponId: couponData ? couponData.id : null,
          cashierId: cashier.id,
          docId: docId,
          transactionAmountPayable: ammountPaid,
          branchId: localStorage.getItem("BRANCH_ID"),
        };
        console.log({ "payment obj to be saved": obj });
        savePayment(obj);
      }
    }
  };

  const savePayment = async (obj) => {
    let url = "";
    if (props.portal === "CLINIC") {
      console.log("CLINIC");
      url = BASE_URL + "payment/clinic";
    } else if (props.portal === "LAB") {
      console.log("LAB");
      url = BASE_URL + "payment/lab";
    } else if (props.portal === "PHARMACY") {
      console.log("PHARMACY");
      url = BASE_URL + "payment/pharmacy  ";
    }

    const data = await saveData(url, obj);

    if (data.error) {
      console.log({ data_error: data.error });
    } else {
      console.log({ data_data: data.data });
      setFlag(true);
    }
  };

  const transformData = (data) => {
    console.log({ data });
    setCashierList(data);
  };
  const {
    isLoading,
    error,
    fetchDataHandler: fetchData,
  } = useHttpGet(
    {
      url:
        BASE_URL +
        "payment/cashier?branchId=" +
        localStorage.getItem("BRANCH_ID"),
    },
    transformData
  );

  useEffect(() => {
    fetchData();
  }, []);

  if (props.origin === "BOOKING") {
    return (
      <Fragment>
        <Button variant="outlined" onClick={handleClickOpen}>
          Pay Now
        </Button>

        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Record New Payment"}
          </DialogTitle>
          <DialogContent dividers sx={{ p: 0 }}>
            <Box
              sx={{
                minHeight: "7vh",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Total Amount"
                    variant="outlined"
                    value={ammountPayable || 0}
                    onChange={(e) => {
                      setAmmountPayable(e.target.value);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled={paymentVM.paid}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Discount"
                    variant="outlined"
                    value={discount || 0}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value) &&
                        e.target.value >= 0 &&
                        e.target.value <= 100
                      ) {
                        setDiscount(e.target.value);

                        let disc = 0;

                        if (
                          parseInt(couponDiscount) + parseInt(e.target.value) <
                          100
                        ) {
                          disc =
                            (parseInt(couponDiscount) +
                              parseInt(e.target.value)) /
                            100;
                        } else {
                          disc = 1;
                        }

                        console.log({ disc });

                        setNetAmmount(ammountPayable - ammountPayable * disc);
                        setAmmountPending(
                          ammountPayable -
                            ammountPayable * disc -
                            ammountCollected
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <form onSubmit={validateCoupon}>
                    <Stack direction="row">
                      <TextField
                        disabled={paymentVM.paid}
                        size={"small"}
                        fullWidth
                        label="Enter Coupon"
                        variant="outlined"
                        value={couponCode || ""}
                        onChange={(e) => {
                          setCouponCode(e.target.value);
                          if (e.target.value) {
                          } else {
                            setCouponData("");
                            setCouponDiscount(0);

                            let disc = parseInt(discount) / 100;

                            setNetAmmount(
                              ammountPayable - ammountPayable * disc
                            );
                            setAmmountPending(
                              ammountPayable -
                                ammountPayable * disc -
                                ammountCollected
                            );
                          }
                        }}
                        helperText={
                          couponData
                            ? "you get " + couponData.discount + "% off"
                            : ""
                        }
                      />
                      <Button
                        disabled={paymentVM.paid}
                        variant="contained"
                        type="submit"
                        sx={{ height: 40 }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </form>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Net Amount"
                    variant="outlined"
                    value={netAmmount || 0}
                    onChange={(e) => {
                      setNetAmmount(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Amount Collected"
                    variant="outlined"
                    value={ammountCollected || 0}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => {
                      setAmmountCollected(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Amount Pending"
                    variant="outlined"
                    value={ammountPending || 0}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => {
                      setAmmountPaid(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled={paymentVM.paid}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon color="primary" fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    size={"small"}
                    fullWidth
                    label="Amount Paid"
                    variant="outlined"
                    value={ammountPaid || 0}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value) &&
                        e.target.value >= 0 &&
                        e.target.value <= ammountPending
                      ) {
                        setAmmountPaid(e.target.value);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <TextField
                    disabled={paymentVM.paid}
                    fullWidth
                    size={"small"}
                    select
                    label="Mode"
                    value={payMode || ""}
                    onChange={(e) => {
                      setPayMode(e.target.value);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    helperText=""
                  >
                    {paymentMode.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <Autocomplete
                    disabled={paymentVM.paid}
                    fullWidth
                    size={"small"}
                    value={cashier}
                    disableClearable
                    options={cashierList}
                    getOptionLabel={(option) => option.fullName}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => {
                      setCashier(value);
                      console.log({ value });
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...params}
                        label="Cashier"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  display="flex"
                >
                  <Button
                    disabled={paymentVM.paid}
                    onClick={getOTPHandler}
                    variant="contained"
                    sx={{ width: 120 }}
                  >
                    Send OTP
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  display="flex"
                >
                  {isSendOTP ? (
                    <TextField
                      disabled={paymentVM.paid}
                      size={"small"}
                      fullWidth
                      label="Enter OTP"
                      variant="outlined"
                      value={otpValue || ""}
                      onChange={(e) => {
                        setOtpValue(e.target.value);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-end"
                  display="flex"
                >
                  {isSendOTP ? (
                    <Button
                      disabled={paymentVM.paid}
                      onClick={getOTPHandler}
                      sx={{ width: 120 }}
                    >
                      ReSend OTP
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                minHeight: "7vh",
                background: "#fff",
              }}
            >
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      sx={{ width: 120 }}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={!isSendOTP || paymentVM.paid}
                      onClick={makePaymentHandler}
                      variant="contained"
                      sx={{ width: 120 }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {props.dashBoard === "1" ? (
        <IconButton aria-label="add" color="primary" onClick={handleClickOpen}>
          <AddIcon fontSize="large" />
        </IconButton>
      ) : (
        <Fragment>
          {paymentVM.paid || flag ? (
            <Chip
              size="small"
              icon={<PaymentIcon />}
              label="Paid"
              onClick={handleClickOpen}
              clickable
              color="primary"
            />
          ) : (
            <Chip
              size="small"
              icon={<PaymentIcon />}
              label="Pending"
              onClick={handleClickOpen}
              clickable
            />
          )}
        </Fragment>
      )}

      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Record New Payment"}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              p: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Total Amount"
                  variant="outlined"
                  value={ammountPayable || 0}
                  onChange={(e) => {
                    setAmmountPayable(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled={paymentVM.paid}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Discount"
                  variant="outlined"
                  value={discount || 0}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    if (
                      !isNaN(e.target.value) &&
                      e.target.value >= 0 &&
                      e.target.value <= 100
                    ) {
                      setDiscount(e.target.value);

                      let disc = 0;

                      if (
                        parseInt(couponDiscount) + parseInt(e.target.value) <
                        100
                      ) {
                        disc =
                          (parseInt(couponDiscount) +
                            parseInt(e.target.value)) /
                          100;
                      } else {
                        disc = 1;
                      }

                      console.log({ disc });

                      setNetAmmount(ammountPayable - ammountPayable * disc);
                      setAmmountPending(
                        ammountPayable -
                          ammountPayable * disc -
                          ammountCollected
                      );
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <form onSubmit={validateCoupon}>
                  <Stack direction="row">
                    <TextField
                      disabled={paymentVM.paid}
                      size={"small"}
                      fullWidth
                      label="Enter Coupon"
                      variant="outlined"
                      value={couponCode || ""}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                        if (e.target.value) {
                        } else {
                          setCouponData("");
                          setCouponDiscount(0);

                          let disc = parseInt(discount) / 100;

                          setNetAmmount(ammountPayable - ammountPayable * disc);
                          setAmmountPending(
                            ammountPayable -
                              ammountPayable * disc -
                              ammountCollected
                          );
                        }
                      }}
                      helperText={
                        couponData
                          ? "you get " + couponData.discount + "% off"
                          : ""
                      }
                    />
                    <Button
                      disabled={paymentVM.paid}
                      variant="contained"
                      type="submit"
                      sx={{ height: 40 }}
                    >
                      Apply
                    </Button>
                  </Stack>
                </form>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Net Amount"
                  variant="outlined"
                  value={netAmmount || 0}
                  onChange={(e) => {
                    setNetAmmount(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Amount Collected"
                  variant="outlined"
                  value={ammountCollected || 0}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    setAmmountCollected(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Amount Pending"
                  variant="outlined"
                  value={ammountPending || 0}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    setAmmountPaid(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled={paymentVM.paid}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size={"small"}
                  fullWidth
                  label="Amount Paid"
                  variant="outlined"
                  value={ammountPaid || 0}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    if (
                      !isNaN(e.target.value) &&
                      e.target.value >= 0 &&
                      e.target.value <= ammountPending
                    ) {
                      setAmmountPaid(e.target.value);
                    }
                  }}
                />
              </Grid>

              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <TextField
                  disabled={paymentVM.paid}
                  fullWidth
                  size={"small"}
                  select
                  label="Mode"
                  value={payMode || ""}
                  onChange={(e) => {
                    setPayMode(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  helperText=""
                >
                  {paymentMode.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <Autocomplete
                  disabled={paymentVM.paid}
                  fullWidth
                  size={"small"}
                  value={cashier}
                  disableClearable
                  options={cashierList}
                  getOptionLabel={(option) => option.fullName}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setCashier(value);
                    console.log({ value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...params}
                      label="Cashier"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                display="flex"
              >
                <Button
                  disabled={paymentVM.paid}
                  onClick={getOTPHandler}
                  variant="contained"
                  sx={{ width: 120 }}
                >
                  Send OTP
                </Button>
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                display="flex"
              >
                {isSendOTP ? (
                  <TextField
                    disabled={paymentVM.paid}
                    size={"small"}
                    fullWidth
                    label="Enter OTP"
                    variant="outlined"
                    value={otpValue || ""}
                    onChange={(e) => {
                      setOtpValue(e.target.value);
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-end"
                display="flex"
              >
                {isSendOTP ? (
                  <Button
                    disabled={paymentVM.paid}
                    onClick={getOTPHandler}
                    sx={{ width: 120 }}
                  >
                    ReSend OTP
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              minHeight: "7vh",
              background: "#fff",
            }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{ width: 120 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isSendOTP || paymentVM.paid}
                    onClick={makePaymentHandler}
                    variant="contained"
                    sx={{ width: 120 }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PaymentComponent;
