import { TextField } from "@mui/material";
import { Fragment, useState } from "react";

const OrgElement = ({ org, setOrg }) => {
  //const [org, setOrg] = useState("");
  return (
    <Fragment>
      <TextField
        disabled
        InputLabelProps={{ style: { fontSize: 12 } }}
        size="small"
        fullWidth
        label="Enter  Organization/School"
        value={org || ""}
        onChange={(e) => {
          setOrg(e.target.value);
        }}
      />
    </Fragment>
  );
};

export default OrgElement;
