import {
  Button,
  Container,
  CssBaseline,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { VITALS_SUGAR } from "../../assets/constantsText";
import { uploadVitals } from "../../services/api/apiCalls";
import { saveData } from "../../services/api/postApiCalls";

const VitalForm = (props) => {
  const patientId = props.pDetails.patientId;
  const appointmentId = props.pDetails.appointmentId;
  const vitals = props.vitals;

  const [formValues, setFormValues] = useState({
    bp: vitals ? vitals.bp : "",
    heartRate: vitals ? vitals.heartRate : "",
    height: vitals ? vitals.height : "",
    spo2: vitals ? vitals.spo2 : "",
    weight: vitals ? vitals.weight : "",
    temperature: vitals ? vitals.temperature : "",
    sugar: vitals ? vitals.sugar : "",
  });

  const [state, setState] = useState();

  let handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const submitVitalHandler = (event) => {
    event.preventDefault();

    console.log({ formValues_uploadVitals: formValues });

    const vitalObj = {
      patientId: patientId,
      bp: formValues.bp,
      spo2: formValues.spo2,
      heartRate: formValues.heartRate,
      temperature: formValues.temperature,
      height: formValues.height,
      weight: formValues.weight,
      sugar: formValues.sugar,
      appointmentId: appointmentId,
    };

    console.log({ objtobesaved_uploadVitals: formValues });

    saveVitals(BASE_URL + "patient/vitals", vitalObj);
  };

  const saveVitals = async (url, obj) => {
    const user = await saveData(url, obj);
    props.onAddVitals(user);
  };

  const closePopOver = () => {
    props.onAddVitals("CANCEL");
  };

  return (
    <React.Fragment>
      <Container>
        <Box sx={{ p: 2 }}>
          <form id="myVitalForm" onSubmit={submitVitalHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="bp"
                  label="BP"
                  variant="outlined"
                  value={formValues.bp || ""}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => handleChange(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mmHg</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="heartRate"
                  label="Heart Rate"
                  variant="outlined"
                  value={formValues.heartRate || ""}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">bpm</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="height"
                  label="Height"
                  variant="outlined"
                  value={formValues.height || ""}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="spo2"
                  label="SpO2"
                  variant="outlined"
                  value={formValues.spo2 || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="weight"
                  label="Weight"
                  variant="outlined"
                  value={formValues.weight || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="temperature"
                  label="Temperature"
                  variant="outlined"
                  value={formValues.temperature || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">F</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  name="sugar"
                  label={VITALS_SUGAR}
                  variant="outlined"
                  value={formValues.sugar || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onFocus={(el) => {
                    el.target.select();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mmol/L</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <Stack direction={"row"}>
                  <Button onClick={closePopOver}>Close</Button>
                  <Button type="submit">save</Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default VitalForm;
