import { Grid, Typography, Stack } from "@mui/material"

const PersonalVisitRequired = props => {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Stack direction={"row"} display="flex" alignItems={"center"}>
          <Typography sx={styles.Mainheading}>Personal Visit Required:&nbsp;</Typography>

          <Typography sx={styles.headingData}>
            {props.personalVisitNeeded ? "YES" : "NO"}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default PersonalVisitRequired
const styles = {
  Mainheading: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#127DDD",
    marginTop: "5px",
    textTransform: "uppercase",
  },
  headingData: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "9px",
    color: "#000000",
    marginTop: "5px",
  },
}
