import React, { Fragment } from "react";
import ExecutionInfo from "../module/executionInfo/ExecutionInfo";
import NavbarCorp from "../components/NavbarCorp";
import { Container, Grid } from "@mui/material";
import GatherInfo from "../module/gatherInfo/GatherInfo";
import GatherInfoView from "../module/executionInfo/gatherInfoView";

const ExecutionInfoIndex = () => {
  return (
    <Fragment>
      <NavbarCorp />{" "}
      <Container maxWidth={false}>
        {/* <Grid container spacing={2}>
          <Grid item lg={6}>
            <GatherInfoView />
          </Grid>
          <Grid item lg={6}> */}
        <ExecutionInfo />
        {/* </Grid>
        </Grid> */}
      </Container>
    </Fragment>
  );
};

export default ExecutionInfoIndex;
