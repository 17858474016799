import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment } from "react";
import CorpPackageReadonly from "../../comps/corpPackageReadonly";
import {
  CustomTypography,
  CustomTypographyBold,
} from "../../comps/customTypography";
import { TextFieldItem } from "../../comps/customTextField";

const EmployeeBioFormfields = ({
  formValues,
  setFormValues,
  isTokenNumber,
}) => {
  return (
    <Fragment>
      <Box
        sx={{
          boxSizing: "border-box",
          background: "#FFFFFF",
          border: "0.5px solid #A6A6A6",
          borderRadius: 5,
          minHeight: "10vh",
          p: 2,
        }}
      >
        <Stack display="flex" justifySelf="center" alignItems="center">
          <Box
            sx={{
              position: "relative",
              top: -28,
              background: "#fff",
              width: 200,
              textAlign: "center",
              borderRadius: 5,
            }}
          >
            <CustomTypographyBold>Employee Details</CustomTypographyBold>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <TextField
              fullWidth
              required
              label="Emp ID"
              size="small"
              value={formValues.empId || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, empId: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2}>
            <TextField
              disabled={isTokenNumber}
              fullWidth
              label="Token"
              size="small"
              value={formValues.tokenNumber || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, tokenNumber: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <TextField
              fullWidth
              label="Department"
              size="small"
              value={formValues.department || ""}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  department: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <CorpPackageReadonly
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              fullWidth
              label="Name"
              size="small"
              value={formValues.name || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, name: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <TextField
              fullWidth
              label="Phone No."
              size="small"
              value={formValues.mobile || ""}
              error={
                !formValues.mobile || formValues.mobile?.length === 10
                  ? false
                  : true
              }
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length <= 10) {
                  setFormValues({
                    ...formValues,
                    mobile: e.target.value,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1}>
            <TextField
              fullWidth
              label="Age"
              size="small"
              value={formValues.age || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length <= 2) {
                  setFormValues({ ...formValues, age: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Autocomplete
              fullWidth
              value={formValues.gender}
              onChange={(event, newValue) => {
                let newFormValues = { ...formValues };
                newFormValues["gender"] = newValue;
                setFormValues(newFormValues);
              }}
              // sx={{ width: 200, mt: 0 }}
              size="small"
              disablePortal
              options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
              renderInput={(params) => (
                <TextField {...params} label="Gender" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EmployeeBioFormfields;
