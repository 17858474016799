import { Fragment } from "react";
import CorporateHomeDemo from "./demo/corporateHomeDemo";
import CorporateHomeJalpa from "./jalpa/corporateHomeJalpa";
import CorporateHomeNationalSteel from "./nationalSteel/corporateHomeNationalSteel";
import CorporateHomeMaralOverseas from "./maralOverseas/corporateHomeMaralOverseas";
import CorporateHomeLiugong from "./liugong/corporateHomeLiugong";
import CorporateHomeCaseConstruction from "./caseConstruction/corporateHomeCaseConstruction";
import CorporateHomeNouvelle from "./nouvelle/corporateHomeNouvelle";
import CorporateHomeSyntho from "./syntho/corporateHomeSyntho";
import CorporateHomeKachMotors from "./kachMotors/corporateHomeKachMotors";
import CorporateHomeCoromandel from "./coromandel/corporateHomeCoromandel";
import CorporateHomeDaawat from "./daawat/corporateHomeDaawat";
import CorporateHomeMain from "../corporateHomeNew/corporateHomeMain";
import { ThemeProvider } from "@mui/material";
import CorpTheme from "../../common/themes/corpTheme";
import CorporateHomeMainAlt from "../corporateHomeNew/corporateHomeMainAlt";

const CorporateMainComp = ({
  corpId = localStorage.getItem("CORPID"),
  consolidatedReport,
}) => {
  console.log({ corpId });
  return (
    <Fragment>
      <ThemeProvider theme={CorpTheme}>
        {/* <CorporateHomeMainAlt consolidatedReport={consolidatedReport} /> */}

        <CorporateHomeMain consolidatedReport={consolidatedReport} />
      </ThemeProvider>
    </Fragment>
  );
};

export default CorporateMainComp;
