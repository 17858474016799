import { Box, Container } from "@mui/material";
import TableContentLab from "./tableContentLab";

const LabDashborardMainComponent = (props) => {
  return (
    <Container component="main" maxWidth={false}>
      <Box
        sx={{
          height: "80vh",
          width: "97vw",
          mt: 3,
          filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
        }}
      >
        <TableContentLab root={props.root} />
      </Box>
    </Container>
  );
};

export default LabDashborardMainComponent;
