import { Autocomplete, TextField } from "@mui/material";
import { Fragment } from "react";
import { SOURCELIST } from "../../../assets/constantsFile";

const MarketingSource = ({ formValues, setFormValues }) => {
  return (
    <Fragment>
      <Autocomplete
        freeSolo
        size={"small"}
        fullWidth
        disableClearable
        value={formValues.source || ""}
        onChange={(e, value) => {
          console.log(value);
        }}
        onInputChange={(e, value) => {
          setFormValues({ ...formValues, source: value });
        }}
        options={SOURCELIST.map((option) => option)}
        renderInput={(params) => (
          <TextField
            size={"small"}
            variant="outlined"
            {...params}
            label="Marketing Source*:"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            helperText={formValues.source ? "" : "Source can not be empty."}
            error={formValues.source ? false : true}
          />
        )}
      />
    </Fragment>
  );
};

export default MarketingSource;
