import { Box } from "@mui/material"

import BackgroundImage from "../../assets/images/adminportallogo.png"
import { Fragment } from "react"

const LoginLeftPane = () => {
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh",
          background: "linear-gradient(to bottom, #B8DDFF,rgba(184, 221, 255, 0))",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          component="img"
          sx={{ objectFit: "contain", height: "70vh" }}
          alt="Banner"
          src={BackgroundImage}
        />
      </Box>
    </Fragment>
  )
}

export default LoginLeftPane
