import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditAppointmentForm from "../editAppointment/editAppointmentForm";
import CancelAppointmentComponent from "./cancelAppointmentComponent";

const EditAppointmentComponent = ({ root, pDetails, tabClick, setValue }) => {
  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const editAppointmentHandler = (data) => {
    handleClose();

    if (data === "CANCEL") {
    } else {
      if (data.error) {
      } else {
      }
    }
    console.log({ savedvitalsdata_uploadVitals: data });
  };

  return (
    <Fragment>
      <Stack direction="row" spacing={1}>
        <Button
          onClick={handleClick}
          variant="outlined"
          startIcon={<EditIcon />}
          sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
        ></Button>
        <CancelAppointmentComponent
          root={root}
          pDetails={pDetails}
          tabClick={tabClick}
          setValue={setValue}
        />
      </Stack>

      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="Reports"
        aria-describedby="report form"
      >
        <DialogTitle id="alert-dialog-title">
          {pDetails.patientName}
          {"'s Appointment"}
        </DialogTitle>
        <DialogContent dividers>
          <EditAppointmentForm
            onEditAppointment={editAppointmentHandler}
            pDetails={pDetails}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EditAppointmentComponent;
