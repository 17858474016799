import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React from "react"
import { Fragment } from "react"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import SearchBarHCNew from "../components/SearchBarAshaWoker"
import SearchBarAshaWoker from "../components/SearchBarAshaWoker"

const HeaderAshaWoker = ({ handleShowForm, dateValue, setDateValue }) => {
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid item>
          <Button
            onClick={handleShowForm}
            variant="outlined"
            sx={{ borderRadius: "7.5px", textTransform: "capitalize", height: "45px" }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                textAlign: "center",
                color: "#127DDD",
              }}>
              {/* + नया मरीज़ जोड़ें */}+ Add New Patient
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                openTo="day"
                views={["year", "month", "day"]}
                value={dateValue}
                onChange={newValue => {
                  setDateValue(newValue)
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <InsertInvitationIcon />
                    </IconButton>
                  ),
                }}
                renderInput={params => (
                  <Box
                    sx={{
                      border: "0.5px solid #D4D4D4",
                      borderRadius: "7.5px",
                      width: "160px",
                      paddingTop: "5px",

                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      " .MuiIconButton-root": {
                        color: "#127DDD",
                      },
                    }}>
                    <TextField {...params} size="small" style={{ borderRadius: "15px" }} />
                  </Box>
                )}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginBlock: "30px" }}>
        <SearchBarAshaWoker />
      </Box>
    </Fragment>
  )
}

export default HeaderAshaWoker
