import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import PaymentContent from "./paymentContent";
import TableContent from "./tableContent";

const PaymentMainComponent = (props) => {
  const pendingDetailHandler = (data, listData) => {
    if (data === "DATA") {
      const transformedData = listData.map((data, index) => {
        return {
          index: index,
          amountPending: data.amountPending,
          appointmentDate: data.appointmentDate,
          fullName: data.fullName,
          mobile: data.mobile,
          patientId: data.patientId,
        };
      });
      setContent(
        <TableContent
          onClickBack={pendingDetailHandler}
          listData={transformedData}
        />
      );
    } else if (data === "BACK") {
      setContent(
        <PaymentContent onViewPendingDetails={pendingDetailHandler} />
      );
    }
  };

  const [content, setContent] = useState(
    <PaymentContent onViewPendingDetails={pendingDetailHandler} />
  );
  return (
    <Container component="main" maxWidth={false} disableGutters>
      <Box
        sx={{
          height: "83vh",
          mt: 1,
        }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ minHeight: "80vh" }}>{content}</Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentMainComponent;
