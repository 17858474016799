import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import CaseForm from "./caseForm";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const CaseUpload = ({
    patientId,
    patientName,
    caseId = null,
    location = "ADD_FORM",
    isEdit,
    setIsEdit,
    buttonText,
    patientData,
}) => {
    const navigate = useNavigate();
    const handleClick = (event) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setIsEdit && setIsEdit(false);
        navigate(0);
    };

    const [open, setOpen] = useState(false);

    const CaseModal = (
        <>
            <Dialog
                maxWidth={"md"}
                open={isEdit || open}
                onClose={handleClose}
                aria-labelledby="Cases"
                aria-describedby="case form"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction="row" display="flex" justifyContent="space-between">
                        <Fragment>{"Cases History : " + patientName?.toUpperCase()}</Fragment>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <CaseForm
                        patientId={patientId}
                        patientName={patientName}
                        buttonText={buttonText}
                        caseId={caseId}
                        patientData={patientData}
                        handleClose={handleClose}
                    />
                </DialogContent>
            </Dialog>
        </>
    );

    return (
        <Fragment>
            {location === "ADD_FORM" && (
                <Button
                    onClick={handleClick}
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ minWidth: 32, p: 0, pl: 1.5, minHeight: 32 }}
                ></Button>
            )}
            {location === "ADD_CASE" && (
                <Button onClick={handleClick} variant="outlined" sx={{ p: 2, m: 3 }}>
                    ADD CASE
                </Button>
            )}
            {CaseModal}
        </Fragment>
    );
};

export default CaseUpload;
