import { Grid, Typography } from "@mui/material"
import React from "react"
import { Fragment } from "react"

const PatientDetailHeading = () => {
  return (
    <Fragment>
      <Grid container justifyContent="space-between" sx={{ marginBlock: "20px", }}>
        <Grid item xs={4} lg={4}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            {/* Date */}
            जन्म की तारीख
            (dd/mm/yyyy)
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            कद ( cm)
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            वज़न (kg)
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            बी.पी. (mmHg)
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#8A8A8A",
            }}>
            शुगर (mm/dl)
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default PatientDetailHeading
