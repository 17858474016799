import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { registerNewPatientContext } from "../../common/context/usercontext";
import { getData } from "../../services/api/getApiCalls";
import SearchIcon from "@mui/icons-material/Search";

const SearchComponent = (props) => {
  const contextData = useContext(registerNewPatientContext);
  const [patientId, setPatientId] = useState("");
  const [pName, setPName] = useState("");
  const [pMobileNo, setPMobileNo] = useState("");

  const getPatientDataByPatientId = async (e) => {
    e.preventDefault();
    if (patientId) {
      const user = await getData(BASE_URL + "patient/" + patientId);

      const data1 = user.data;
      data1 ? props.onSearchPatient(data1) : props.onSearchPatient([]);

      contextData.patientList = data1 ? data1 : [];
    } else {
      props.onSearchPatient([]);
      contextData.patientList = [];
    }
  };

  const getPatientDataByName = async (e) => {
    e.preventDefault();
    if (pName) {
      const user = await getData(BASE_URL + "patient/search?query=" + pName);

      const data1 = user.data;
      data1 ? props.onSearchPatient(data1) : props.onSearchPatient([]);
      contextData.patientList = data1 ? data1 : [];
    } else {
      props.onSearchPatient([]);
      contextData.patientList = [];
    }
  };

  const getPatientDataByMobileNo = async (e) => {
    e.preventDefault();
    if (pMobileNo) {
      const user = await getData(
        BASE_URL + "patient/search?phoneNo=" + pMobileNo
      );

      const data1 = user.data;
      data1 ? props.onSearchPatient(data1) : props.onSearchPatient([]);
      contextData.patientList = data1 ? data1 : [];
    } else {
      props.onSearchPatient([]);
      contextData.patientList = [];
    }
  };

  console.log({ patientId: patientId });

  return (
    <Grid container align={"center"}>
      <Grid item lg={12} xs={12}>
        <Box
          sx={{ height: "5", p: 2 }}
          component={Stack}
          justifyContent={"space-between"}
        >
          <Grid container align={"center"} spacing={2}>
            <Grid item lg={6} xs={12}>
              <form onSubmit={getPatientDataByName}>
                <Stack direction={"row"}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Search patient..."
                    id="fullWidth2"
                    value={pName}
                    onChange={(e) => setPName(e.target.value)}
                  />
                  <Button variant="outlined" type="submit">
                    Search
                  </Button>
                </Stack>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchComponent;
