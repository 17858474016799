import { Box, Grid, Stack, Typography } from "@mui/material";
import EChokitsalayaLogo from "../../logoNew.svg";

const LogoMain = () => {
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
              <img src={EChokitsalayaLogo} alt="logo" height={"55"} />
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
              <img src={EChokitsalayaLogo} alt="logo" height={"70"} />
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LogoMain;
