import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

const _genderGroup = [
  { title: "मरीज/पेशेंट", value: "PATIENT" },
  { title: "सर्जरी/IPD/SOS", value: "SOS" },
  { title: "सामान्य जनता", value: "GENERAL_PUBLIC" },
];
const MarketingLeadType = ({
  formValues,
  setFormValues,
  leadType,
  setLeadType,
}) => {
  return (
    <Box>
      <Grid container display={"flex"} alignItems={"center"}>
        <Grid item xs={2} lg={4}>
          <Typography>Lead Type:</Typography>
        </Grid>
        <Grid
          item
          xs={10}
          lg={8}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <RadioGroup
            row
            value={formValues["marketingLeadType"] || ""}
            onChange={(e) => {
              setLeadType(e.target.value);
              setFormValues({
                ...formValues,
                marketingLeadType: e.target.value,
                sos: e.target.value === "SOS" ? true : false,
              });
            }}
          >
            {_genderGroup.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item?.value}
                control={<Radio />}
                label={item?.title}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingLeadType;
