import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BookAppointmentComponent = () => {
    return (

        <Box
        sx={{
          width: "100%",
          height: '30vh',
          mt:-21,
    
        }}
      >
<Grid container>

<Grid item lg={8} xs={12}>

</Grid>

<Grid item lg={4} xs={12}>
<Box>
              <Stack alignItems="center">
                <Typography variant="body2">
                  Please Enter your 10 Digit Mobile Number
                </Typography>
      
                <TextField
                  size="small"
                  label=""
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: "25ch", background: "#fff", borderRadius: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91-</InputAdornment>
                    ),
                  }}
                />
      

              </Stack>
            </Box>
</Grid>

<Grid item lg={3} xs={12}>

</Grid>

<Grid item lg={8} xs={12} sx={{mt:5}}>
<Box sx={{
    background: '#FFFFFF',
    boxShadow:'0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    height:'12vh',p:1
}}>

<Grid container sx={{height:'12vh'}} align="center">
<Grid item lg={3} xs={12}>
<Button size="small" endIcon={<KeyboardArrowDownIcon />} >date</Button>
<Typography variant="body2">Tue, 3 May, 2022</Typography>
</Grid>
<Grid item lg={3} xs={12}>
<Button size="small" endIcon={<KeyboardArrowDownIcon />} >Location</Button>
<Typography variant="body2">Punjab Branch</Typography>
</Grid>
<Grid item lg={3} xs={12}>
<Button size="small" endIcon={<KeyboardArrowDownIcon />} >Service</Button>
<Typography variant="body2">General Physician Consultation</Typography>
</Grid>
<Grid item lg={3} xs={12} >

<Stack sx={{height:'12vh'}} justifyContent="center">
<Button variant="contained" size="small" sx={{ width: 226 }}>
                  Book Appointment
                </Button>
</Stack>

</Grid>

</Grid>
</Box>
</Grid>

</Grid>
      </Box>
    );
    }
export default BookAppointmentComponent;