import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Portal,
  TextField,
} from "@mui/material";
import { Fragment } from "react";
import { TextFieldItem } from "./customTextField";
import { BASE_URL } from "../../../assets/constantsFile";

const ChangePackageDialog = ({ open, setOpen, formValues, setFormValues }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const _submitPackage = async () => {
    const url = BASE_URL;

    handleClose();
  };
  return (
    <Fragment>
      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Change Package"}</DialogTitle>
          <DialogContent>
            <TextFieldItem
              title="Package "
              property="package"
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={_submitPackage} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default ChangePackageDialog;
