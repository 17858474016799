import { Box, Grid, Stack } from "@mui/material";
import { Fragment } from "react";
import EmployeeBioFormfields from "./employeeBioFormfields";
import EmployeeVitalFormfields from "./employeeVitalFormfields";
import EmployeeEyeTestFormfields from "./employeeEyeTestFormfields";
import EmployeeToggleFormfields from "./employeeToggleFormfields";
import EmployeeMiscFormfields from "./employeeMiscFormfields";

const FormComponentMain = ({
  formValues,
  setFormValues,
  permissions,
  isTokenNumber,
}) => {
  return (
    <Fragment>
      <Box
        sx={{
          boxSizing: "border-box",
          background: "#F5F5F5",
          border: "0.5px solid #A6A6A6",
          borderRadius: 5,
          minHeight: "40vh",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Stack spacing={1}>
              <EmployeeBioFormfields
                formValues={formValues}
                setFormValues={setFormValues}
                isTokenNumber={isTokenNumber}
              />
              <EmployeeMiscFormfields
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <EmployeeVitalFormfields
              formValues={formValues}
              setFormValues={setFormValues}
              permissions={permissions}
            />
          </Grid>

          {permissions["eyeTest"] && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EmployeeEyeTestFormfields
                formValues={formValues}
                setFormValues={setFormValues}
                permissions={permissions}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EmployeeToggleFormfields
              formValues={formValues}
              setFormValues={setFormValues}
              permissions={permissions}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FormComponentMain;
