import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import PackageMainComp from "./packageMainComp";

const CorpPackagesMain = ({ orgName = localStorage.getItem("ORGNAME") }) => {
  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Typography variant="h6">{orgName}</Typography>
      </Box>

      <PackageMainComp />
    </Fragment>
  );
};

export default CorpPackagesMain;
